import { styled } from "@nextui-org/react";
import { useRef } from "react";
import useDraggableScroll from "use-draggable-scroll";
import theme from "../../../../theme";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import CardTable from "../../../../components/CardTable/CardTable";
import { useTranslation } from "react-i18next";
import { mockTableProject } from "./MockData";

const StyledCard = styled("div", {
  [`.${theme} &`]: {
    backgroundColor: "$neutral_50",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    minWidth: "164px",
    color: "$primary_100",
    gap: "1rem",
    h1: {
      color: "$primary_300",
    },
    span: {
      color: "$primary_200",
    },
    [".containerLine"]: {
      paddingLeft: "20px",
      paddingRight: "130px",
    },
    [".space"]: {
      paddingTop: "20px",
    },
  },
});

const StyledCardScroll = styled("div", {
  [`.${theme} &`]: {
    display: "flex",
    gap: "2rem",
    overflowX: "scroll",
    width: "87%",
    paddingBottom: "4px",
    ["&::-webkit-scrollbar"]: {
      height: "5px",
    },
    ["&::-webkit-scrollbar-track"]: {
      borderRadius: "10px",
    },
    ["&::-webkit-scrollbar-thumb"]: {
      background: "#5E6EAB",
      borderRadius: "50px",
    },
    [""]: {},
  },
});

export default function ProjectCard() {
  const ref = useRef("div");
  const { onMouseDown } = useDraggableScroll(ref, { direction: "horizontal" });
  const { t: translate }= useTranslation("Quotation");

  return (
    <StyledCard>
      <div>
        <hr className="separate" />
        <PageTitle>{translate("specifications_project_cargo")}</PageTitle>
      </div>

      <span>
        {translate("specifications_project_cargo_description1")}{" "}
        <b>{translate("specifications_project_cargo_description2")}</b>
      </span>

      <StyledCardScroll ref={ref} onMouseDown={onMouseDown}>
        {mockTableProject.map((card, i) => (
          <CardTable key={i} {...card} />
        ))}
      </StyledCardScroll>

      <span>
        {translate("specifications_project_cargo_description3")}{" "}
        <b>{translate("pre-liquidation")}</b>{" "}
        {translate("specifications_project_cargo_description4")}
      </span>
    </StyledCard>
  );
}
