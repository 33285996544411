import { styled } from '@nextui-org/react';
import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Default Quill style

const StyledDiv = styled("div", {
  minHeight: '300px',
  border: '1px solid rgb(202, 202, 202)',
  borderRadius: '8px',
  ['.ql-container.ql-snow']: {
    // border: 'none !important',
    overflow: 'auto',
    height: 'fit-content',
    minHeight: "300px",
    maxHeight: "500px",
  }
})

const TextEditor = ({ state, setState, readOnly }) => {

  return (
    <StyledDiv>
      <ReactQuill value={state} onChange={(e) => setState(e)} readOnly={readOnly} />
    </StyledDiv>
  );
};

export default TextEditor;
