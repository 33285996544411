import React, { useEffect } from 'react';
import { Modal, styled } from '@nextui-org/react';
import theme from '../../../theme';
import { useForm } from 'react-hook-form';

// Components
import Input from '../../Input/Input';
import Button from '../../Button/Button';

const StyledFormModal = styled(Modal, {
    [`.${theme} &`]: {
        padding: '1.5rem',
        ['h3']: {
            color: '$primary_500'
        }
    }
})

const StyledFooter = styled(Modal.Footer, {
    [`.${theme} &`]: {
        display: 'flex',
        justifyContent: 'start'
    }
})

const FormModal = ({ open, setOpen, title, inputs, button }) => {
    let defaultValues = {};
    inputs.forEach(input => {
        defaultValues[input.name] = ''
    });

    const { register, handleSubmit } = useForm({
        defaultValues: defaultValues
    });

    return (
        <StyledFormModal
            aria-label="modal-title"
            open={open}
            onClose={() => setOpen(false)}
        >
            <Modal.Header>
                <h3>{title}</h3>
            </Modal.Header>
            <Modal.Body>
                {
                    inputs.map((input, i) =>
                        <Input key={i} register={register} name={input.name} label={input.label} placeholder={input.placeholder}></Input>
                    )
                }
            </Modal.Body>
            <StyledFooter>
                <Button handler={handleSubmit(button.submit)} text={button.text} bgColor={button.bgColor} textColor={button.textColor} outline={button.outline}></Button>
            </StyledFooter>
        </StyledFormModal>
    );
}

export default FormModal;
