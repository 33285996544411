import React, { useState } from "react";
import { Container, Grid, Paper, Typography } from "@mui/material";
import RatesForecastChart from "../../components/Graphics/ForeCastCharts/RatesForecastChart";
import FuelCostChart from "../../components/Graphics/ForeCastCharts/FuelCostChart";
import PerformanceRadarChart from "../../components/Graphics/ForeCastCharts/radChart";
import HeatMapChart from "../../components/Graphics/ForeCastCharts/HeatChart";
import AreaChartComponent from "../../components/Graphics/ForeCastCharts/AreaChart";
import StackedAreaChart from "../../components/Graphics/ForeCastCharts/StackedChart";
import ScatterPlot from "../../components/Graphics/ForeCastCharts/DispersionChart";
import GptInterface from "./GptInterface";
import DataSelector from "./DataSelector";

function generateRandomData() {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return months.map((month) => ({
    month,
    rate: Math.floor(Math.random() * 500) + 500, // Valores entre 500 y 1000
    forecast: Math.floor(Math.random() * 500) + 500, // Valores entre 500 y 1000
  }));
}

function generateRandomFuelCostData() {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun"];
  return months.map((month) => ({
    month,
    cost: Math.floor(Math.random() * 150) + 200, // Valores entre 200 y 350
  }));
}

function generateRandomRadarData() {
  const categories = [
    "Eficiencia",
    "Confiabilidad",
    "Precisión",
    "Costo",
    "Capacidad",
    "Flexibilidad",
  ];
  return categories.map((category) => ({
    category,
    score: Math.floor(Math.random() * 50) + 50, // Valores entre 50 y 100
  }));
}

function generateRandomHeatMapData() {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun"];
  const categories = ["Low", "Medium", "High"];
  return months.flatMap((month) =>
    categories.map((category) => ({
      month,
      category,
      value: Math.floor(Math.random() * 50) + 10, // Valores entre 10 y 60
    }))
  );
}

function generateRandomAreaChartData() {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return months.map((month) => ({
    month,
    cost: Math.floor(Math.random() * 300) + 200, // Valores entre 200 y 500
  }));
}

function generateRandomScatterData() {
  return Array.from({ length: 50 }, (_, index) => ({
    x: Math.random() * 100, // Representa la eficiencia en porcentaje
    y: Math.random() * 100, // Representa el costo en dólares
    label: `Point ${index + 1}`,
  }));
}

function generateRandomStackedAreaData() {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return months.map((month) => ({
    month,
    air: Math.floor(Math.random() * 800) + 200, // Valores entre 200 y 1000
    sea: Math.floor(Math.random() * 800) + 200, // Valores entre 200 y 1000
    land: Math.floor(Math.random() * 800) + 200, // Valores entre 200 y 1000
  }));
}

function Dashboard() {
  const [ratesData, setRatesData] = useState(generateRandomData());
  const [fuelCostData, setFuelCostData] = useState(
    generateRandomFuelCostData()
  );
  const [radarData, setRadarData] = useState(generateRandomRadarData());
  // const [heatMapData, setHeatMapData] = useState(generateRandomHeatMapData());
  const [areaChartData, setAreaChartData] = useState(
    generateRandomAreaChartData()
  );
  const [scatterData, setScatterData] = useState(generateRandomScatterData());
  const [stackedAreaData, setStackedAreaData] = useState(
    generateRandomStackedAreaData()
  );

  const handleForecastUpdate = () => {
    setRatesData(generateRandomData());
    setFuelCostData(generateRandomFuelCostData());
    setRadarData(generateRandomRadarData());
    // setHeatMapData(generateRandomHeatMapData());
    setAreaChartData(generateRandomAreaChartData());
    setScatterData(generateRandomScatterData());
    setStackedAreaData(generateRandomStackedAreaData());
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>
        Freight Forecasting Dashboard
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Paper
            elevation={3}
            sx={{ p: 2, display: "flex", flexDirection: "column" }}
          >
            <RatesForecastChart data={ratesData} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper
            elevation={3}
            sx={{ p: 2, display: "flex", flexDirection: "column" }}
          >
            <FuelCostChart data={fuelCostData} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper
            elevation={3}
            sx={{ p: 2, display: "flex", flexDirection: "column" }}
          >
            <ScatterPlot data={scatterData} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper
            elevation={3}
            sx={{ p: 2, display: "flex", flexDirection: "column" }}
          >
            <PerformanceRadarChart data={radarData} />
          </Paper>
        </Grid>
        {/* <Grid item xs={12} md={4}>
          <Paper
            elevation={3}
            sx={{ p: 2, display: "flex", flexDirection: "column" }}
          >
            <HeatMapChart data={heatMapData} />
          </Paper>
        </Grid> */}
        <Grid item xs={12} md={4}>
          <Paper
            elevation={3}
            sx={{ p: 2, display: "flex", flexDirection: "column" }}
          >
            <StackedAreaChart data={stackedAreaData} />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper
            elevation={3}
            sx={{ p: 2, display: "flex", flexDirection: "column" }}
          >
            <GptInterface onGetForecast={handleForecastUpdate} />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper
            elevation={3}
            sx={{ p: 2, display: "flex", flexDirection: "column" }}
          >
            <DataSelector />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Dashboard;
