import React, {useEffect} from 'react'
import FormInput from '../../../../components/FormInput/FormInput'
import { FormProvider, useForm } from 'react-hook-form';
import { styled } from '@nextui-org/react';
import Button from '../../../../components/Button/Button';
import FormInputSelect from '../../../../components/FormInputSelect/FormInputSelect';
import { format } from 'date-fns';
import toast from '../../../../components/Toast/ToastTypes';
import { postByMBL } from '../../../../services/cargoes.services';
import {useSelector} from "react-redux"
const StepContainer = styled("div",{
    display:'flex',
    flexDirection:'column',
    margin:'20px 0',
    paddingBottom:'20px',
    borderBottom:'4px solid $primary_300',
    '.principal':{
        display:'flex',
        gap:'0 20px'
    },
    '.additional':{
        display:'grid',
        gridTemplateColumns:'22% 22% 22% 22%',
        gap:'10px 40px',
    },
    "@media(max-width:1260px)":{
        margin:'0px',
        '.additional':{
            gridTemplateColumns:'auto auto',
            gap:'10px',
        },
        '.principal':{
            display:'flex',
            flexDirection:'column',
        },
    },
    "@media(max-width:600px)":{
        padding:0,
        margin:0,
        '.additional':{
            gridTemplateColumns:' auto',
            gap:'10px',
        },
    }
})
const defaultShipment = {
  visibility: "",
  reference: "",
  shipper: "",
  consignee: "",
  carrier: "",
  mbl: "",
  booking: "",
  incoterms: {
      label: "EXW",
      value: 1,
  },
  eta: null,
  etd: null,
}

export default function MBLForm({selectedOperation, disableForm, carriers, incoterms, operation, optionalRadication, hasPurchaseOrder}) {
    const token = useSelector(state => state.user.token)
    const { formState: { errors, isValid },watch, trigger , getValues, reset, handleSubmit, ...methods } = useForm({
        defaultValues:{
            0: defaultShipment
        }
    })

    useEffect(()=>{
        if (operation) {
            const formValues = getValues()
            let newFormValues = {}
            Object.keys(formValues).map(key=>{
                newFormValues[key] = {
                    ...formValues[key],
                    incoterms : {
                        label: operation?.business_term_name,
                        value: operation?.business_term_name,
                    }
                }
            })
            reset(newFormValues)
        }
    },[operation])

    const formValues = watch()

    function handleAddShipment(){
        const key = Object.keys(formValues).length
        reset({...formValues, [key]: defaultShipment})
    }

    function handleDelete(key){
        let newFormValues = getValues()
        delete newFormValues[key]
        
        const sortedShipments = {}
        const shipmentsArray = Object.keys(newFormValues).map((key,i) => {
            return newFormValues[key]
        })
        shipmentsArray.forEach((shipment, i) => {
            sortedShipments[i]=shipment
        })
        reset (sortedShipments)
    }
    function onSubmit(submitData){
        if (!selectedOperation?.radication && !optionalRadication){
            toast("warn","No se ha añadido el rastreo","No hay operacion seleccionada")
        } else {
            const dataArray = Object.keys(submitData).map(key=>{
                const item = submitData[key]
                const formattedItem = {
                    radication: item.radication || selectedOperation?.radication,
                    shipmentTags: selectedOperation?.radication,
                    oceanLine: item.carrier?.label,
                    purchaseOrder : item.purchase_order,
                    mblNumber: item.mbl,
                    shipmentReference: item.reference,
                    bookingNumber: item.booking,
                    consignee: item.consignee,
                    shipper: item.shipper,
                    promisedEta: item.eta,
                    promisedEtd: item.etd,
                    incoterm: item.incoterms.label,
                }
                return formattedItem
            })
            console.log("dataArray :",dataArray)
            const asyncTasks = dataArray.map((item, index) => {
                const delay = index * 3000; 
                return new Promise((resolve, reject) => {
                    setTimeout(() => {
                        postByMBL(token, item)
                            .then(() => resolve())
                            .catch(err => reject(err));
                    }, delay);
                });
            });
            Promise.all(asyncTasks).then(() => {
                toast("success", "Envíos añadidos con éxito");
                reset([defaultShipment]);
            }).catch(err => toast("error", "Error:", err.message));
        }
        
    }
    return (
        <FormProvider {...methods}>
            <form style={{minHeight:'65vh'}} onSubmit={handleSubmit(onSubmit)}>
            {
                Object.keys(formValues).map((key,i) => {
                    return(
                    <StepContainer>
                        <div>
                            <div style={{display:'flex', justifyContent:'space-between'}}>
                                <h3>Envio {Number(i) + 1}</h3>
                                <Button handler={()=>{handleDelete(i)}} startIcon={{name:'Trash',color:'white'}} bgColor={'primary_300'}/>
                            </div>
                            <div className='principal'>
                                {(hasPurchaseOrder && !optionalRadication) && <FormInput name={i + ".purchase_order"} label="Orden de compra:" required/>}
                                {optionalRadication && <FormInput name={i + ".radication"} label="Referencia de envio:" required/>}
                                <FormInput name={i + ".mbl"} label="MBL #:" required/>
                                <FormInputSelect name={i + ".carrier"} label="Carrier" required
                                    options={carriers.map(carrier => ({label:carrier.carrierName, value:carrier.carrierName}))} 
                                />
                                {/* <FormInputSelect name={i + ".visibility"} label="Visibilidad" options={[{label:'Visible to all', value:1},{label:'Private', value:2}]} required/> */}
                            </div>
                            <h4 style={{margin:'20px 0'}}>Informacion adicional</h4>
                            <div className='additional'>
                                <FormInput name={i + ".booking"} label="Booking #"/>
                                <FormInputSelect name={i + ".incoterms"} label="Incoterms" options={incoterms.map((term => {
                                    return {
                                        label:term?.name,
                                        value:term?.id,
                                    }
                                }))}/>
                                <FormInput name={i + ".reference"} label="Referencia de envio"/>
                                <FormInput name={i + ".shipper"} label="Shipper"/>
                                <FormInput name={i + ".consignee"} label="Consignee"/>
                                <FormInput name={i + ".eta"} label="ETA" type='date'/>
                                <FormInput name={i + ".etd"} label="ETD" type='date'/>
                            </div>
                        </div>
                    </StepContainer>)
                })
            }
            <div style={{display:'flex', justifyContent:'end', gap:'30px'}}>
                {/* <Button handler={()=>{handleAddShipment()}} text={"Añadir envio"} bgColor={'primary_300'} textColor={'neutral_50'} outline/> */}
                <Button text={"Subir envios a rastreo"} disabled={disableForm} type="submit" bgColor={'primary_300'} textColor={'neutral_50'} outline/>
            </div>
            </form>
        </FormProvider>
    )
}