
import React, { useEffect, useState } from 'react'
import ViewsHandler from '../../../../components/ViewsHandler/ViewsHandler';
import Button from '../../../../components/Button/Button';
import { Loading, styled } from '@nextui-org/react';

import ContainerForm from './ContainerForm';
import BookingForm from './BookingForm';
import MBLForm from './MBLForm';
import VesselForm from './VesselForm';
import { getCarriers } from '../../../../services/cargoes.services';
import { getBusinessTerms } from '../../../../services/businessTerms.services'

import { useSelector } from 'react-redux';
import toast from '../../../../components/Toast/ToastTypes';

const Container = styled("div",{
    minHeight:'60vh',
    marginTop:'40px',
    padding:'50px',
    '.buttons':{
        display:'grid',
        gridTemplateColumns:'auto auto auto auto',
    },
    "@media(max-width:1000px)":{
        padding:'0px',
        '.buttons':{ 
            gridTemplateColumns:'auto auto'
        },
    }
})

export default function AddOceanShipment({selectedOperation, disableForm, operation, optionalRadication,hasPurchaseOrder}) {
    const token = useSelector(state=>state.user.token)
    const [tabId, setTabId] = useState(0);
    const [carriers, setCarriers] = useState([])
    const [incoterms, setIncoterms] = useState([])
    const [loading, setLoading] = useState(true)

    const handleChange = (newValue) => {
        setTabId(newValue);
    };

    useEffect(()=>{
        const asyncTasks = [getCarriers(token), getBusinessTerms(token)]
        Promise.all(asyncTasks).then(async res=>{
            setLoading(true)
            const carriersData = await res[0].json();
            const incotermsData = await res[1].json();
            const oceanCarriers = carriersData.filter( carrier => carrier.shipmentType === "INTERMODAL_SHIPMENT")

            setIncoterms(incotermsData)
            setCarriers(oceanCarriers)
            setLoading(false)
        }).catch(()=>{
            setLoading(false)
            toast('error','Ha habido un error cargando la informacion, intente mas tarde')
        })
    },[])
    
    return (
        <Container>
            <div className='buttons'>
                <Button handler={()=>handleChange(0)} text="By container #" outline width="100%"
                    bgColor={tabId == 0 &&'primary_300'}
                    textColor={tabId == 0 &&'neutral_50'}
                />
                <Button handler={()=>handleChange(1)} text="By booking #" outline width="100%"
                    bgColor={tabId == 1 &&'primary_300'}
                    textColor={tabId == 1 &&'neutral_50'}
                />
                <Button handler={()=>handleChange(2)} text="By MBL #" outline width="100%"
                    bgColor={tabId == 2 &&'primary_300'}
                    textColor={tabId == 2 &&'neutral_50'}
                />
                <Button handler={()=>handleChange(3)} text="By vessel #" outline width="100%"
                    bgColor={tabId == 3 &&'primary_300'}
                    textColor={tabId == 3 &&'neutral_50'}
                />
            </div>
            {
                loading? <div style={{width:'100%',margin:'30px auto',borderRadius:'8px',height:'50vh',background:'#D3D3D3',alignItems:'center',display:'flex',justifyContent:'center'}}>
                    <Loading size='xl'/>
                    <p>Cargando informacion</p>
                </div> :
                <ViewsHandler activeView={tabId}>
                {[
                    ContainerForm,
                    BookingForm,
                    MBLForm,
                    VesselForm
                ].map((FormComponent,i )=> (
                    <FormComponent
                        key={i}
                        selectedOperation={selectedOperation}
                        disableForm={disableForm}
                        carriers={carriers}
                        incoterms={incoterms}
                        operation={operation}
                        optionalRadication={optionalRadication}
                        hasPurchaseOrder={hasPurchaseOrder}
                    />
                ))}
            </ViewsHandler>
            
            }
        </Container>
    )
}
