import React, { useEffect, useState } from 'react';
import { styled } from '@nextui-org/react';
import theme from '../../../theme';
import { Step, StepLabel, Stepper } from '@mui/material';
import ViewsHandler from '../../../components/ViewsHandler/ViewsHandler';
import { useNavigate } from 'react-router-dom';
import camelCaseToSnakeCase from "../../../services/case.services";
import { sendUser } from "../../../services/user.services";
import { useSelector } from 'react-redux';

// Components
import Button from '../../../components/Button/Button';
import General from './General/General';
import Accountant from './Accountant/Accountant';
import Partnership from './Partnership/Partnership';
import Representative from './Representative/Representative';
import Banking from './Banking/Banking';
import References from './References/References';
import Beneficiaries from './Beneficiaries/Beneficiaries';
import Exterior from './Exterior/Exterior';
import Accounts from './Accounts/Accounts';
import Documents from './Documents/Documents';
import toast from '../../../components/Toast/ToastTypes'

const StyledProfileForms = styled('div', {
    [`.${theme} &`]: {
        padding: '3rem 2rem',
    }
})

const StyledStep = styled(Step, {
    [`.${theme} &`]: {
        ['.MuiStepLabel-root']: {
            position: 'relative',
            zIndex: '1',
            ['.MuiStepLabel-iconContainer']: {
                backgroundColor: '$primary_50',
                ['svg']: {
                    borderRadius: '1rem',
                    color: '$primary_300',
                    ['circle']: {
                        color: '$primary_300',
                    },
                    ['text']: {
                        fill: '$neutral_50'
                    }
                }
            },
            ['.MuiStepLabel-iconContainer.Mui-disabled']: {
                backgroundColor: '$primary_50',
                ['svg']: {
                    border: 'solid 1px $primary_300',
                    borderRadius: '1rem',
                    ['circle']: {
                        color: '$primary_50',
                    },
                    ['text']: {
                        fill: '$primary_300'
                    }
                }
            },
            ['.MuiStepLabel-label, .MuiStepIcon-text']: {
                fontFamily: 'inherit'
            },
            ['.MuiStepLabel-label.Mui-completed, .MuiStepLabel-label.Mui-active']: {
                color: '$primary_300'
            },
            ['.MuiStepLabel-label.Mui-disabled']: {
                color: '$primary_100'
            }
        },
        ['.MuiStepConnector-root']: {
            zIndex: '0',
            left: '-50%',
            right: '50%',
            ['span']: {
                border: 'solid 1.5px $primary_300',
            }
        }
    }
})

const StyledFooterContainer = styled('div', {
    [`.${theme} &`]: {
        position: 'sticky',
        display: 'flex',
        justifyContent: 'center',
        bottom: '0',
        width: '100%',
        height: '75px',
        ['.footer']: {
            backgroundColor: '$neutral_50',
            width: '90%',
            borderRadius: '8px 8px 0px 0px',
            padding: '1rem',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            boxShadow: '$elevation_1',
            ['.moveSteps']: {
                display: 'flex',
                gap: '1rem'
            }
        }
    }
})

const ProfileForms = () => {
    const token = useSelector((state) => state.user.token)
    const navigate = useNavigate()
    const [activeStep, setActiveStep] = useState(0)
    const steps = [
        {
            value: 'general',
            name: 'General'
        },
        {
            value: 'accountant',
            name: 'Contable'
        },
        {
            value: 'partnership',
            name: 'Societaria'
        },
        {
            value: 'representative',
            name: 'Representante'
        },
        {
            value: 'banking',
            name: 'Bancaria'
        },
        {
            value: 'references',
            name: 'Referencias'
        },
        {
            value: 'beneficiaries',
            name: 'Beneficiarios'
        },
        {
            value: 'exterior',
            name: 'Exterior'
        },
        {
            value: 'accounts',
            name: 'Cuentas'
        },
        {
            value: 'documents',
            name: 'Documentos'
        },
    ]

    const [general, setGeneral] = useState({})
    const [accountant, setAccountant] = useState({})
    const [partnerships, setPartnerships] = useState([])
    const [representatives, setRepresentatives] = useState([])
    const [bankReferences, setBankReferences] = useState([])
    const [tradeReferences, setTradeReferences] = useState([])
    const [beneficiaries, setBeneficiaries] = useState([])
    const [exterior, setExterior] = useState({})
    const [accounts, setAccounts] = useState({})
    const [documents, setDocuments] = useState({})

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        window.scrollTo({ top: 0, behavior: 'smooth' })
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        window.scrollTo({ top: 0, behavior: 'smooth' })
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const save = async () => {
        const forms = camelCaseToSnakeCase({
            general: general,
            accountant: accountant,
            partnerships: partnerships,
            representatives: representatives,
            bankReferences: bankReferences,
            tradeReferences: tradeReferences,
            beneficiaries: beneficiaries,
            exterior: exterior,
            accounts: accounts,
            documents: documents
        })

        await sendUser(forms, token)
            .then(async res => {
                const data = res.json();
                if (res.ok) {
                    navigate(`/profile?tab=documents&created=documents`)
                } else {
                    Object.keys(data.errors).forEach(input => {
                        toast('error', data.errors[input]);
                    });
                }
            })
    }

    return (
        <div>
            <StyledProfileForms>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {
                        steps.map((step, i) => (
                            <StyledStep key={i}>
                                <StepLabel>{step.name}</StepLabel>
                            </StyledStep>
                        ))
                    }
                </Stepper>

                <ViewsHandler
                    activeView={activeStep}
                >
                    <General setGeneral={setGeneral}></General>
                    <Accountant setAccountant={setAccountant}></Accountant>
                    <Partnership setPartnerships={setPartnerships}></Partnership>
                    <Representative setRepresentatives={setRepresentatives}></Representative>
                    <Banking setBankReferences={setBankReferences}></Banking>
                    <References setTradeReferences={setTradeReferences}></References>
                    <Beneficiaries setBeneficiaries={setBeneficiaries}></Beneficiaries>
                    <Exterior setExterior={setExterior}></Exterior>
                    <Accounts setAccounts={setAccounts}></Accounts>
                    <Documents setDocuments={setDocuments}></Documents>
                </ViewsHandler>
            </StyledProfileForms>

            <StyledFooterContainer>
                <div className='footer'>
                    <Button handler={() => navigate('/profile?tab=documents')} text='Salir' bgColor='primary_50' startIcon={{ name: 'ArrowLeft', size: '13' }} outline bold></Button>

                    <div className='moveSteps'>
                        <Button handler={handleBack} text='Atrás' bgColor='primary_50' disabled={activeStep === 0} outline bold></Button>
                        {
                            activeStep === steps.length - 1 ?
                                <Button handler={save} text='Guardar' bgColor='primary_400' textColor='neutral_50' bold></Button>
                                :
                                <Button handler={handleNext} text='Continuar' bgColor='primary_400' disabled={activeStep === steps.length - 1} textColor='neutral_50' bold></Button>
                        }
                    </div>
                </div>
            </StyledFooterContainer>
        </div>
    );
}

export default ProfileForms;
