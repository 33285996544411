const defaultValues = {
    general_costs:[{
        itemId:1,
        Concepto:'VALOR EX-FABRICA',
        "Valor USD":'',
        "Factor USD":'',
        "Factor Col":'',
        "Porcentual":'',
    },
    {
        itemId:2,
        Concepto:'EMERGENCY FEE',
        "Valor USD":'',
        "Factor USD":'',
        "Factor Col":'',
        "Porcentual":'',
    },
    {
        itemId:3,
        Concepto:'TASA DE CAMBIO',
        "Valor USD":'',
        "Factor USD":'',
        "Factor Col":'',
        "Porcentual":'',
    },
    {
        itemId:4,
        Concepto:'FLETES INTERNACIONAL',
        "Valor USD":'',
        "Factor USD":'',
        "Factor Col":'',
        "Porcentual":'',
    },
    {
        itemId:5,
        Concepto:'GASTOS EN ORIGEN ',
        "Valor USD":'',
        "Factor USD":'',
        "Factor Col":'',
        "Porcentual":'',
    }],

    destination_costs:[{
        itemId:1,
        Concepto:'Seguro  Trayecto Exterior',
        "Valor USD":'',
        "Factor USD":'',
        "Factor Col":'',
        "Porcentual":'',
    },
    {
        itemId:2,
        Concepto:'Arancel',
        "Valor USD":'',
        "Factor USD":'',
        "Factor Col":'',
        "Porcentual":'',
    },
    {
        itemId:3,
        Concepto:'Servicio de agenciamiento',
        "Valor USD":'',
        "Factor USD":'',
        "Factor Col":'',
        "Porcentual":'',
    },
    {
        itemId:4,
        Concepto:'Reconocimiento',
        "Valor USD":'',
        "Factor USD":'',
        "Factor Col":'',
        "Porcentual":'',
    },
    {
        itemId:5,
        Concepto:'Declaraciones',
        "Valor USD":'',
        "Factor USD":'',
        "Factor Col":'',
        "Porcentual":'',
    },
    {
        itemId:6,
        Concepto:'Liberación',
        "Valor USD":'',
        "Factor USD":'',
        "Factor Col":'',
        "Porcentual":'',
    },
    {
        itemId:7,
        Concepto:'Otros gastos de aduana',
        "Valor USD":'',
        "Factor USD":'',
        "Factor Col":'',
        "Porcentual":'',
    },
    {
        itemId:8,
        Concepto:'Bodega y alistamiento',
        "Valor USD":'',
        "Factor USD":'',
        "Factor Col":'',
        "Porcentual":'',
    },
    {
        itemId:9,
        Concepto:'Entrega',
        "Valor USD":'',
        "Factor USD":'',
        "Factor Col":'',
        "Porcentual":'',
    },
    {
        itemId:10,
        Concepto:'Seguro  Trayecto interior',
        "Valor USD":'',
        "Factor USD":'',
        "Factor Col":'',
        "Porcentual":'',
    },
    {
        itemId:11,
        Concepto:'IMPREVISTOS',
        "Valor USD":'',
        "Factor USD":'',
        "Factor Col":'',
        "Porcentual":'',
    }],
    provider_costs:[
        {
            itemId:1,
            "Proveedor":'',
            "V/R USD":'',
            "Shipping":'',
            "Total":'',
            "FOB":'',
            "Arancel":'',
            "IVA":'',
            "Declaracion":'',
        },
        {
            itemId:2,
            "Proveedor":'',
            "V/R USD":'',
            "Shipping":'',
            "Total":'',
            "FOB":'',
            "Arancel":'',
            "IVA":'',
            "Declaracion":'',
        }
    ],
    freight_costs:[
        {
            itemId:1,
            "freight":'',
            "insurance":'',
            "others":'',
        },
        {
            itemId:2,
            "freight":'',
            "insurance":'',
            "others":'',
        }
    ]
}
export default defaultValues