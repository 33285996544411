import React, { useEffect } from 'react'
import { styled } from '@nextui-org/react'
import { useFormContext, Controller, useFormState } from 'react-hook-form';

const Container = styled("div",{
    // border:'1px solid #ccc',
    padding:'0 5px',
    width:'100%',
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    ['label']:{
        fontSize:'14px',
    },
    ['input']:{
        fontSize: '14px',
        padding: '8px',
        margin: '5px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        transition: 'box-shadow 0.3s ease',
        background: '#f7f7f7',
        minWidth: '190px',
        ['&:focus']: {
            border: '1px solid #ccc',
        }
    },
    '.inputError':{
        display:'flex',
        flexDirection:'column',
        'span':{
            fontSize:'12px'
        }
    }
    
})

export default function SmallField({name,label,disabled=false, bold=false, fontSize='14px', type='text', required = true}) {

    const { control } = useFormContext();
    const errors = useFormState()?.errors
    const splitError = name.split('.')
    const thisFieldError = errors[splitError[0]]? errors[splitError[0]][splitError[1]] : ''

    return (
    <Controller
        name={name}
        control={control}
        disabled={disabled}
        rules= {required ? { required: "Este campo es obligatorio" } : {}}
        render={({ field }) => (
        <Container>
            <label style={{fontSize:'11px'}} for={name}>{label}</label>
            <div className='inputError'>
                <input 
                    type={type}
                    step="any"
                    id={name}
                    name={name}
                    {...field}
                />
                {thisFieldError && <span style={{color:'red'}}>{thisFieldError?.message}</span>}
            </div>
            
        </Container>
        )}
    />
  )
}
