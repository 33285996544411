import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getUserCo2, compensateCO2 } from "../../../services/CO2.services";
import { getCountries } from "../../../services/countries.services";
import Table from "../../../components/Table/Table";
import { useNavigate } from "react-router-dom";
import { Loading, Modal, styled } from "@nextui-org/react";
import Button from "../../../components/Button/Button";
import { useTranslation } from "react-i18next";

const StyledModal = styled(Modal, {
  color: "$primary_500 !important",
  minWidth: "400px",
  padding: "20px",
});

const TableCO2Payments = () => {
  const { t: translate } = useTranslation("CO2History");

  const [items, setItems] = useState([]);
  const [filterType, setFilterType] = useState("Marítimo");
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.user.token);
  const navigate = useNavigate();
  const [modalStyle, setModalStyle] = useState({ display: "none" });
  const [modal, setModal] = useState(null);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [currentItem, setcurrentItem] = useState(null);
  const selectStyles = {
    container: {
      display: "flex",
      alignItems: "center",
      marginBottom: "20px",
    },
    label: {
      marginRight: "10px",
    },
    selectWrapper: {
      position: "relative",
      display: "inline-block",
    },
    select: {
      padding: "12px",
      fontSize: "18px",
      border: "1px solid #ccc",
      borderRadius: "5px",
      appearance: "none",
      outline: "none",
      marginRight: "30px",
    },
    selectIcon: {
      position: "absolute",
      top: "50%",
      right: "10px",
      transform: "translateY(-50%)",
    },
  };
  const actions = [
    // {
    //     name: 'Ver detalle',
    //     icon: 'View',
    //     handle: (item) => console.log('item :', item)
    // },
    {
      name: "Compensar",
      icon: "View",
      handle: (item) => {
        setOpenConfirmationModal(true), setcurrentItem(item);
      },
    },
  ];
  const compensar = async (item) => {
    try {
      await compensateCO2(token, item.id, "id");
      navigate(`/co2/compensations?id=${item.id}`);
    } catch (error) {
      console.error("Error recalculating emissions:", error);
      toast("error", "Hubo un error calculando el CO\u2082e");
    }
  };
  function calculatePrice(kgAmount) {
    if (kgAmount >= 0 && kgAmount < 5000) {
      return 80;
    } else if (kgAmount >= 5000 && kgAmount < 10000) {
      return 250;
    } else if (kgAmount >= 10000 && kgAmount < 45000) {
      return 420;
    } else {
      return 950;
    }
  }

  const typeToColumnsMap = {
    Marítimo: [
      { name: "", uid: "actions", type: "action" },
      { name: "ID", uid: "calculation_id", type: "text" },
      { name: "Radicado", uid: "radication", type: "text" },
      { name: "Origen", uid: "departure", type: "text" },
      { name: "Destino", uid: "destination", type: "text" },
      { name: "Fecha", uid: "created_at", type: "text" },
      { name: "Tipo Cálculo", uid: "type", type: "text" },
      { name: "CO\u2082e", uid: "kg_amount", type: "text" },
      { name: "Distancia", uid: "distance", type: "text" },
      { name: "Peso", uid: "weight", type: "text" },
      { name: "Unidad Peso", uid: "weight_unit", type: "text" },
      { name: "Especificaciones", uid: "specification", type: "text" },
      { name: "Compensación", uid: "compensation", type: "text" },
    ],
    Aéreo: [
      { name: "", uid: "actions", type: "action" },
      { name: "ID", uid: "calculation_id", type: "text" },
      { name: "Radicado", uid: "radication", type: "text" },
      { name: "Origen", uid: "departure", type: "text" },
      { name: "Destino", uid: "destination", type: "text" },
      { name: "Fecha", uid: "created_at", type: "text" },
      { name: "Tipo Cálculo", uid: "type", type: "text" },
      { name: "CO\u2082e", uid: "kg_amount", type: "text" },
      { name: "Distancia", uid: "distance", type: "text" },
      { name: "Peso", uid: "weight", type: "text" },
      { name: "Unidad Peso", uid: "weight_unit", type: "text" },
      { name: "Compensación", uid: "compensation", type: "text" },
    ],
    Terrestre: [
      { name: "", uid: "actions", type: "action" },
      { name: "ID", uid: "calculation_id", type: "text" },
      { name: "Radicado", uid: "radication", type: "text" },
      { name: "Origen", uid: "departure", type: "text" },
      { name: "Destino", uid: "destination", type: "text" },
      { name: "Fecha", uid: "created_at", type: "text" },
      { name: "Tipo Cálculo", uid: "type", type: "text" },
      { name: "CO\u2082e", uid: "kg_amount", type: "text" },
      { name: "Distancia", uid: "distance", type: "text" },
      { name: "Peso", uid: "weight", type: "text" },
      { name: "Unidad Peso", uid: "weight_unit", type: "text" },
      { name: "Especificaciones", uid: "specification", type: "text" },
      { name: "Compensación", uid: "compensation", type: "text" },
    ],
    car: [
      { name: "", uid: "actions", type: "action" },
      { name: "ID", uid: "calculation_id", type: "text" },
      { name: "Origen", uid: "departure", type: "text" },
      { name: "Destino", uid: "destination", type: "text" },
      { name: "Fecha", uid: "created_at", type: "text" },
      { name: "Tipo Cálculo", uid: "type", type: "text" },
      { name: "Combustible", uid: "fuel", type: "text" },
      { name: "Pasajeros", uid: "passengers", type: "text" },
      { name: "CO\u2082e", uid: "kg_amount", type: "text" },
      { name: "Distancia", uid: "distance", type: "text" },
      { name: "Viaje Regreso", uid: "return_trip", type: "text" },
      { name: "Tipo Vehículo", uid: "vehicle_class", type: "text" },
      { name: "Compensación", uid: "compensation", type: "text" },
    ],
    hotel: [
      { name: "", uid: "actions", type: "action" },
      { name: "ID", uid: "calculation_id", type: "text" },
      { name: "País Estadía", uid: "country", type: "text" },
      { name: "Fecha", uid: "created_at", type: "text" },
      { name: "Tipo Cálculo", uid: "type", type: "text" },
      { name: "CO\u2082e", uid: "kg_amount", type: "text" },
      { name: "Personas Estadía", uid: "guests", type: "text" },
      { name: "Noches Estadía", uid: "nights", type: "text" },
      { name: "Compensación", uid: "compensation", type: "text" },
    ],
    bus: [
      { name: "", uid: "actions", type: "action" },
      { name: "ID", uid: "calculation_id", type: "text" },
      { name: "Origen", uid: "departure", type: "text" },
      { name: "Destino", uid: "destination", type: "text" },
      { name: "Fecha", uid: "created_at", type: "text" },
      { name: "Tipo Cálculo", uid: "type", type: "text" },
      { name: "CO\u2082e", uid: "kg_amount", type: "text" },
      { name: "Distancia", uid: "distance", type: "text" },
      { name: "Viaje Regreso", uid: "return_trip", type: "text" },
      { name: "Compensación", uid: "compensation", type: "text" },
    ],
  };

  const handleFilterChange = (event) => {
    const selectedType = event.target.value;
    setFilterType(selectedType);
  };

  useEffect(() => {
    getUserCo2(user.token, user.idUser).then(async (res) => {
      const items = await res.json();
      let countries = await (await getCountries(user.token)).json();

      const formattedItems = items.map((item) => {
        const formattedDate = new Date(item.created_at).toLocaleDateString();

        let formattedType;
        switch (item.type) {
          case "ship_freight":
            formattedType = "Marítimo";
            break;
          case "air_freight":
            formattedType = "Aéreo";
            break;
          case "road_freight":
            formattedType = "Terrestre";
            break;
          default:
            formattedType = item.type;
        }

        const country = countries.find((c) => c.iso === item.country);
        const countryName = country ? country.name : item.country;
        return {
          ...item,
          created_at: formattedDate,
          type: formattedType,
          country: countryName,
          compensation: item.compensation.price
            ? `USD ${calculatePrice(item.kg_amount)}`
            : "Sin compensar",
        };
      });

      const filteredItems = filterType
        ? formattedItems.filter((item) => item.type === filterType)
        : formattedItems;

      const invertedItems = filteredItems.reverse();
      setItems(invertedItems);
    });
  }, [user, filterType]);

  const columns = typeToColumnsMap[filterType] || [];

  return (
    <div className="h-100">
      <div style={selectStyles.container}>
        <label htmlFor="typeFilter" style={selectStyles.label}>
          Filtrar por Tipo:
        </label>
        <div style={selectStyles.selectWrapper}>
          <select
            id="typeFilter"
            value={filterType}
            onChange={handleFilterChange}
            style={selectStyles.select}
          >
            <option value="Marítimo">🚢 Marítimo</option>
            <option value="Aéreo">✈️ Aéreo</option>
            <option value="Terrestre">🚚 Terrestre</option>
          {/*   <option value="hotel">🏨 Hotel</option>
            <option value="bus">🚌 Bus</option>
            <option value="car">🚗 Carro</option> */}
          </select>
          <div style={selectStyles.selectIcon}>▼</div>
        </div>
      </div>

      <Table columns={columns} items={items} actions={actions} />
      <div style={modalStyle}>{modal}</div>
      <StyledModal
        aria-label="modal-title"
        open={openConfirmationModal}
        onClose={() => setOpenConfirmationModal(false)}
        width="fit-content"
      >
        <Modal.Header justify="space-between">
          <h3>Confirmar</h3>
        </Modal.Header>
        <Modal.Body justify="space-between">
          <p>Confirma que desea compensar el calculo de CO₂e</p>
          <div style={{ display: "flex", justifyContent: "end", gap: "15px" }}>
            <Button
              text="Cancelar"
              bold
              bgColor={"delete"}
              textColor="neutral_50"
              handler={() => {
                setOpenConfirmationModal(false), setcurrentItem(null);
              }}
            />
            <Button
              text="Compensar"
              bold
              bgColor={"primary_300"}
              textColor="neutral_50"
              handler={() => compensar(currentItem)}
            />
          </div>
        </Modal.Body>
      </StyledModal>
    </div>
  );
};

export default TableCO2Payments;
