import React from 'react';
import { Image, styled } from '@nextui-org/react';
import theme from '../../../theme';

const Card5 = ({ title, image, description, src }) => {
    const StyledCard3 = styled('div', {
        [`.${theme} &`]: {
            backgroundColor: '$neutral_50',
            borderRadius: '8px',
            padding: '1.5rem',
            color: '$primary_500',
            width: '100%',
            img: {
                borderRadius: '8px'
            },
            h4: {
                marginTop: '8px',
                marginBottom: '0px'
            },
            ['.description']: {
                color: '$neutral_400',
                marginBottom: '8px'
            }
        }
    })

    return (
        <StyledCard3>
            <Image
                width={'100%'}
                height={200}
                src={image}
                alt="Card Image"
                objectFit="cover"
            />
            <h4>{title}</h4>
            <p className='description'>{description}</p>
            <a href={src}>Ver más</a>
        </StyledCard3>
    );
}

export default Card5;