import React, { useEffect, useState } from 'react'
import { Modal, styled } from '@nextui-org/react'
import { createProvider, getProviderTypes, getServices } from '../../../services/preliquidation.services'
import Button from '../../../components/Button/Button'
import { useSelector } from 'react-redux'
import toast from '../../../components/Toast/ToastTypes'
import { FormProvider, useForm } from 'react-hook-form'
import FormInput from '../../../components/FormInput/FormInput'
import FormInputSelect from '../../../components/FormInputSelect/FormInputSelect'

const StyledModal = styled(Modal, {
    color: '$primary_500 !important',
    padding: '20px',
    display: 'flex',
    minWidth: "500px",
    'form': {
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
    },
    'button': {
        margin: '30px 0 0 0'
    }
})

export default function CreateProviderModal({ open, setOpen, getData }) {

    const token = useSelector(state => state.user.token)
    const [services, setServices] = useState([])
    const [providerTypes, setProviderTypes] = useState([])

    const { handleSubmit, reset, ...methods } = useForm()

    function getOptions() {
        const asyncTasks = [getProviderTypes(token), getServices(token)]
        Promise.all(asyncTasks).then(async res => {
            const providerTypesData = await res[0].json();
            const servicesData = await res[1].json();
            setProviderTypes(providerTypesData)
            setServices(servicesData)
        })
    }

    useEffect(() => {
        getOptions()
    }, [open, setOpen])

    function onSubmit(formData) {
        const submitData = {
            ...formData,
            services: formData.services.map(service => service.value),
            provider_type: formData.provider_type.value
        }
        createProvider(token, submitData).then(() => {
            toast("success", "Proveedor creado con exito")
            setOpen(false)
            reset()
            getData()
        }).catch((err) => {
            toast('error', 'Error, no se pudo crear el proveedor')
            console.log("err :", err)
        })

    }

    function handleFormSubmit(event) {
        event.stopPropagation();
        handleSubmit(onSubmit)(event);
    }

    return (
        <StyledModal
            open={open}
            onClose={() => setOpen(false)}
            width='fit-content'
            closeButton
            placement="left"
        >
            <Modal.Body justify='space-between'>
                <FormProvider {...methods}>
                    <form onSubmit={handleFormSubmit}>
                        <h3>Informacion del proveedor</h3>
                        <FormInput name="name" label="Nombre" required />
                        <FormInput name="mail" label="Mail" required />
                        <FormInputSelect
                            required
                            name="provider_type"
                            label="Tipo de proveedor"
                            options={providerTypes?.map(type => {
                                return {
                                    label: type.name,
                                    value: type.id,
                                }
                            })}
                        />
                        <FormInputSelect
                            required
                            isMulti
                            name="services"
                            label="Servicios"
                            options={services?.map(service => {
                                return {
                                    label: service.name,
                                    value: service?.id,
                                }
                            })}
                        />
                        <Button type="submit" text="Añadir" bgColor={'primary_500'} textColor={'neutral_50'} width="100%" />
                    </form>
                </FormProvider>
            </Modal.Body>
        </StyledModal>
    )
}
