import React, { useState, useEffect } from 'react';
import Autosuggest from 'react-autosuggest';
import { useSelector } from 'react-redux';
import { getConceptRecommendation } from '../../services/preliquidation.services';
import './AutocompleteInput.css';

const AutocompleteInput = ({ name, itemId, value, onChange, placeholder }) => {
  const token = useSelector((state) => state.user.token);
  const [suggestions, setSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState(value || '');

  useEffect(() => {
    if (inputValue) {
      getConceptRecommendation(token, inputValue).then(async (res) => {
        const recommendationData = await res.json();
        setSuggestions(recommendationData.concepts);
      });
    } else {
      setSuggestions([]);
    }
  }, [inputValue, token]);

  const onSuggestionsFetchRequested = ({ value }) => {
    setInputValue(value);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const getSuggestionValue = (suggestion) => suggestion.concept;

  const renderSuggestion = (suggestion) => (
    <div className="suggestion-item">
      {suggestion.concept} - {suggestion.unit}
    </div>
  );

  const onChangeInput = (event, { newValue }) => {
    setInputValue(newValue);
    onChange({
      target: {
        name,
        value: newValue,
        unit: suggestions.find(suggestion => suggestion.concept === newValue)?.unit || '',
      },
    }, itemId);
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={{
        name,
        value: inputValue,
        onChange: onChangeInput,
        placeholder,
      }}
      theme={{
        container: 'autosuggest-container',
        suggestionsContainer: 'autosuggest-suggestions-container',
        suggestionsList: 'autosuggest-suggestions-list',
        suggestion: 'autosuggest-suggestion',
        suggestionHighlighted: 'autosuggest-suggestion--highlighted',
      }}
    />
  );
};

export default AutocompleteInput;

