
import React from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';

const RADIAN = Math.PI / 180;
const data = [
  { name: '0 - 600', value: 600, color: '#00ff00' },
  { name: '600 - 900', value: 900, color: 'yellow' },
  { name: '900 - 1500', value: 1500, color: '#ff0000' },
];
const cx = 150;
const cy = 200;
const iR = 60;
const oR = 120;

export default function NeedlePieChart({ value }) {

  const needle = (value, data, cx, cy, iR, oR, color) => {
    let total = 0;
    data.forEach((v) => {
      total += v.value;
    });
    const ang = 180.0 * (1 - value / total);
    const length = (iR + 2 * oR) / 3;
    const sin = Math.sin(-RADIAN * ang);
    const cos = Math.cos(-RADIAN * ang);
    const r = 5;
    const x0 = cx + 5;
    const y0 = cy + 5;
    const xba = x0 + r * sin;
    const yba = y0 - r * cos;
    const xbb = x0 - r * sin;
    const ybb = y0 + r * cos;
    const xp = x0 + length * cos;
    const yp = y0 + length * sin;

    return [
      <circle cx={x0} cy={y0} r={r} fill={color} stroke="none" />,
      <path d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`} stroke="#none" fill={color} />,
    ];
  };

  return (
    <PieChart width={300} height={300} margin={0} >
      <Pie
        dataKey="value"
        startAngle={180}
        endAngle={0}
        data={data}
        cx={cx}
        cy={cy}
        innerRadius={iR}
        outerRadius={oR}
        fill="#8884d8"
        stroke="none"
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.color}/>
        ))}
      </Pie>
      {needle(value, data, cx, cy, iR, oR, '#d0d000')}
      {/* <Tooltip/> */}
      <Legend />
    </PieChart>
  );
}