import React, { useEffect, useState } from "react";
import { styled } from "@nextui-org/react";
import { getShipmentTracking } from "../../../services/cargoes.services";
import SimpleMap from "../../../components/Map/Map";
import { Tooltip } from "@nextui-org/react";
import { useSelector } from "react-redux";
import { Modal } from "@nextui-org/react";
import { useNavigate } from "react-router-dom"
import Button from "../../../components/Button/Button";
import AirPlane from "../../../components/Graphics/AirPlane/AirPlane";
import { Loading } from '@nextui-org/react';
import Icon from "../../../components/Icon/Icon";
import BasicTimeline from "./Timeline";
import { differenceInDays, differenceInHours, format, formatDistanceToNow, parse } from "date-fns";
import { color } from "d3-color";
import Table from "../../../components/Table/Table";
import { getAirports, getCountriesNew, getPorts } from "../../../services/countries.services";
import ReactCountryFlag from "react-country-flag";

const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
  background: "$neutral_50",
  alignItems: "center",
  gap: "20px",
  width: "95%",
  margin: "3rem auto",
  padding: "20px 40px",
  borderRadius: "8px",
  ".title": {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    color: "$primary_300",
    marginBottom: "20px",
    borderBottom: "3px solid $primary_300",
  },
  boxShadow:'$elevation_1',
  "@media (max-width:1100px)":{
    margin:'20px auto',
    padding:'10px',
    '.title':{
      'h2':{
        fontSize:'15px'
      }
    }
  }
});
const DataContainer = styled("div", {
  display: "grid",
  width: "100%",
  gridTemplateColumns: "45% 45%",
  gap:'0 10%',
  padding:'20px',
  "@media (max-width:1100px)":{
    display:'block',
  }
  
});
const InfoCard = styled("div", {
  fontSize: "13px",
  div: {
    margin: "15px 5px",
    display: "flex",
    justifyContent: "space-between",
    'span':{
      paddingRight:'20px',
    }
  },
  "@media (max-width:1100px)":{
    fontSize:'10px',
  }
});
const UpperData = styled("div", {
  marginBottom: "20px",
  display: "flex",
  gridColumn: "span 2",
  justifyContent: "space-around",
  width: "100%",
  div: {
    display: "flex",
    gap:'10px',
    flexDirection: "column",
  },
  "@media (max-width:1100px)":{
    display:'flex',
    flexDirection:'column',
    div: {
      display: "flex",
      flexDirection:'row',
      fontSize:'12px'
    },
  }
});

const StyledModal = styled(Modal, {
  color: "$primary_500 !important",
  paddingTop:'20px',
  display:'flex',
  flexDirection:'column',
});
const Activity = styled("div",{
  display:'flex',
  justifyContent:'space-between',
  borderBottom:'1px solid $neutral_300',
  marginBottom:'4px',
  'span':{
    marginBottom:'3px',
    fontSize:'12px',
  }
})

const columns = [
  {name:'Transport name', uid:'transport', type:'text'},
  {name:'Origin Port', uid:'origin', type:'text'},
  {name:'Destination Port', uid:'destination', type:'text'},
  {name:'Shipment #', uid:'flight_ref', type:'text'},
  {name:'Departure', uid:'departure', type:'text'},
  {name:'Arrival', uid:'arrival', type:'text'},
]


export default function TrackingDetailsOcean({trackingRef}) {

  const navigate = useNavigate()
  const token = useSelector((state) => state.user.token);
  const [shipment, setShipment] = useState();
  const [shipping, setShipping] = useState();
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false);
  const [noShipment, setNoShipment] = useState(false)
  const [items, setItems] = useState()
  const [lastUpdated, setLastUpdated] = useState("Cargando...")
  const [flags, setFlags] = useState()

  
  useEffect(() => {
    setLoading(true);
    getShipmentTracking(token, trackingRef?.type, trackingRef?.value, 'INTERMODAL_SHIPMENT').then(async (trackingRes) => {
      const shipmentData = await trackingRes.json();
      if (shipmentData.length > 0) {
        console.log("shipmentData[0] :",shipmentData[0])
        setShipment(shipmentData[0]);
        setShipping(shipmentData[0]?.shipmentLegs?.portToPort)
        const segments = shipmentData[0]?.shipmentLegs?.portToPort?.segments?.map((item,i)=>{
          return {
            id:item.tripNumber || i,
            transport: item.transportName,
            origin: item.origin,
            destination: item.destination,
            flight_ref: item.tripNumber,
            departure: item.atd || item.etd,
            arrival: item.ata || item.eta,
          }
        })
        setItems(segments)
        console.log("segments :",segments)
        if (shipmentData[0]?.trackingUpdatedAt){
          const lastUpdatedDate = new Date(shipmentData[0]?.trackingUpdatedAt);
          const lastUpdateToNow = formatDistanceToNow(lastUpdatedDate);
          setLastUpdated(lastUpdateToNow)
        } else {setLastUpdated("")}
      } else {
        setShipment("");
        setOpen(true)
        setLoading(false);
      }
      setLoading(false);
    }).catch((err) => {
      console.log("err :", err);
      setOpen(true);
      setLoading(false);
    });
  }, []);

  useEffect(()=>{
    if (shipping){
      const asyncTasks = [getPorts(token), getCountriesNew(token)]
      Promise.all(asyncTasks).then(async res => {
        const portsData = await res[0].json();
        const countriesData = await res[1].json();
  
        const originPort = portsData.find(port => port[0] == shipping.loadingPortCode)
        const destinationPort = portsData.find(port => port[0] == shipping.dischargePortCode) 

        const originCountry = originPort && countriesData?.find(country => country.name == originPort[1]?.country)
        const destinationCountry = destinationPort && countriesData?.find(country => country.name == destinationPort[1]?.country)
        setFlags({
          origin:originCountry,
          destination:destinationCountry,
        })
      })
    }
  },[shipping])

  useEffect(()=>{
    if (noShipment){
      navigate("/services")
    }
  },[noShipment])

  const events = shipment?.shipmentEvents;
  const locations = events?.map((event) => event.location).reverse();
  const uniqueLocations = [...new Set(locations)];

  const activities = uniqueLocations.map((location,i) => {
    const locationEvents = events.filter((e) => e.location === location)?.reverse();
    const nextLocationEvents = events.filter((e) => e.location === uniqueLocations[i-1])?.reverse();
    return {
      title:location,
      content: locationEvents.map((e, eventIndex) => {
        const eventDateString = e.actualTime
        const nextEventDateString = locationEvents[eventIndex-1]?.actualTime ||
        nextLocationEvents[nextLocationEvents.length - 1]?.actualTime

        const eventDate = parse(eventDateString, "yyyy-MM-dd h:mm:ss a", new Date());
        const nextEventDate = parse(nextEventDateString, "yyyy-MM-dd h:mm:ss a", new Date())

        let durationDays = differenceInDays(nextEventDate, eventDate)
        let durationHours = differenceInHours(nextEventDate, eventDate)
        return(
        <Activity key={eventIndex}>
          <span style={{width:'30%', fontSize:'12px'}}>{e.name}</span>
          <span style={{width:'30%', fontSize:'12px'}}>{ eventDateString ? format(eventDate, 'dd/MM/yyyy , HH:mm') + ' Hs' : "" }</span>
          <span style={{width:'10%', fontSize:'12px'}}>{nextEventDateString ? durationDays? `${durationDays} Dias` : `${durationHours} Hs` : ""}</span>
        </Activity>)
      }) 
    }
  })

  return (
    <div>
      {loading?
      <div style={{height:'80vh', background:'white', borderRadius:'8px', display:'flex', justifyContent:'center', alignItems:'center'}}>
        <Loading size="lg"/>
      </div>:
      shipment && <Container>
      <div className="title">
        <h4>{trackingRef?.type}: {trackingRef?.value}</h4>
        {/* <Button text={"ATRAS"} handler={()=>navigate("/services")}/> */}
        <Tooltip content="Maritimo">
          <Icon name="Maritimo" size="40px" />
        </Tooltip>
      </div>
      <UpperData>
        <div>
          <b>Vessel Name / No.</b>
          <span>
            {shipment?.shipmentLegs?.portToPort?.currentTransportName}
          </span>
        </div>
        <div>
          <b>Container No.</b>
          <span>{shipment?.containerNumber}</span>
        </div>
        <div>
          <b>MBL No.</b>
          <span>{shipment?.mblNumber}</span>
        </div>
        <div>
          <b>Booking No.</b>
          <span>{shipment?.bookingNumber}</span>
        </div>
        <div>
          <b>Carrier</b>
          <span>{shipment?.shipmentLegs?.portToPort?.carrier}</span>
        </div>
        <div>
          <b>Shipment ref</b>
          <span>{shipment?.shipmentNumber}</span>
        </div>
      </UpperData>
      <DataContainer>
        <div className="title" style={{gridColumn:'span 2'}}>
          <h3>Tracking</h3>
          <Icon name="Tracking" size="32px" />
        </div>
        <InfoCard>
          <div>
            <b>Shipper : </b>
            <span>{shipment?.shipper}</span>
          </div>
          <div>
            <b>Origin Port : </b>
            {/* <div style={{display:'flex', margin:"0"}}>
              <span>{shipping?.firstPort || shipping?.originPort}</span>
              <Icon name="Port"/>
            </div>    */}
            <div style={{display:'flex', margin:"0", height:'1.5rem', alignItems:'center'}}>
            <span>{shipping?.firstPort || shipping?.originPort}</span>
              {/* <Icon name="Port"/> */}
              <Tooltip content={flags?.origin?.name} color="primary">
                <ReactCountryFlag
                  countryCode={flags?.origin?.code}
                  svg
                  style={{
                    width: '1.8rem',
                    height: '1.5rem',
                    borderRadius:'6px',
                    margin:0,
                    padding:0,
                  }}
                />
              </Tooltip>
            </div>
          </div>
          <div>
            <b>Port Gate in: </b>
            <span>
              {
                shipment?.shipmentEvents?.find(
                  (event) => event.code == "gateInWithContainerFull"
                )?.actualTime &&
                format(new Date(
                  shipment?.shipmentEvents?.find(
                    (event) => event.code == "gateInWithContainerFull"
                  )?.actualTime
                ), 'dd/MM/yyyy HH:mm')
              }
            </span>
          </div>
          <div>
            <b>Port ATD : </b>
            <span>
              {
                shipment?.shipmentEvents?.find(
                  (event) => event.code == "vesselDepartureWithContainerAIS"
                )?.actualTime &&
                format(new Date(
                  shipment?.shipmentEvents?.find(
                    (event) => event.code == "vesselDepartureWithContainerAIS"
                  )?.actualTime
                ), 'dd/MM/yyyy HH:mm')
              }
            </span>
          </div>
        </InfoCard>
        <InfoCard>
          <div>
            <b>Consignee : </b>
            <span>{shipment?.consignee}</span>
          </div>
          <div>
            <b>Destination Port : </b> 
            {/* <div style={{display:'flex', margin:"0"}}>
              <span>{shipping?.lastPort || shipping?.destinationPort}</span>
              <Icon name="Port"/>
            </div> */}
            <div style={{display:'flex', margin:"0", height:'1.5rem', alignItems:'center'}}>
              <span>{shipping?.lastPort || shipping?.destinationPort}</span>
              {/* <Icon name="Port"/> */}
              <Tooltip content={flags?.destination?.name} color="primary">
                <ReactCountryFlag
                  countryCode={flags?.destination?.code}
                  svg
                  style={{
                    width: '1.8rem',
                    height: '1.5rem',
                    borderRadius:'6px',
                    margin:0,
                    padding:0,
                  }}
                />
              </Tooltip>
            </div>
          </div>
          <div>
            <b>Carrier ETA : </b>
            <span>
              {
                shipment?.shipmentLegs?.portToPort?.lastPortEta &&
                format(new Date(shipment?.shipmentLegs?.portToPort?.lastPortEta), 'dd/MM/yyyy HH:mm')
              }
            </span>
          </div>
          <div>
            <b>Mode : </b>
            <span>{shipment?.serviceMode == 'PORT_TO_PORT'? "Port to port" : shipment?.serviceMode}</span>
          </div>       
        </InfoCard>
        {items && <div style={{gridColumn:'span 2'}}><Table columns={columns} items={items} searchBar={false} excel={false}/></div>}
        <h4>Last updated : {lastUpdated}</h4>
        <div style={{ gridRow: "span 3", marginTop: "15px", gridColumn:'span 2' }}>
          {shipment?.shipmentLegs && <SimpleMap coordinatesArray = {[
            {
              name:shipping?.firstPort,
              lat:shipping?.loadingPortCoordinates?.latitude,
              lng:shipping?.loadingPortCoordinates?.longitude,
            },
            {
              name:shipping?.lastPort,
              lat:shipping?.dischargePortCoordinates?.latitude,
              lng:shipping?.dischargePortCoordinates?.longitude,
            },
          ]}/>}
        </div>
      </DataContainer>
      <div style={{display:'flex', justifyContent:'space-between', width:'100%', padding:'0 5%'}}>
        <b>Location activities</b>
        <b>Date & time</b>
        <b>Duration</b>
      </div>
      <BasicTimeline items={activities}/>  
    </Container>}
    <StyledModal
      open={open}
      onClose={()=>{setNoShipment(true)}}
      width='700px'  
      placement="left"
    >
      <h3>Los resultados que solicitaste están en camino</h3>
      <span>ya sea por mar o por aire, te avisaremos en cuanto lleguen!</span>
      <div style={{height:'100px', margin:'20px 0'}}>
        <AirPlane/>
      </div>
      <Modal.Footer>
        <Button text="Aceptar" handler={() => setOpen(false)} startIcon={{ name: 'CheckModal', color: 'white' }} bgColor='excel' textColor='neutral_50' />
      </Modal.Footer>
    </StyledModal>
    </div>
  );
}
