import React, { useState, useEffect } from "react";
import { styled } from "@nextui-org/react";
import { usePDF } from "react-to-pdf";
import DatesRangePickerModal from "../../components/DateRangePicker/DateRangePicker";
import format from "date-fns/format";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import ColoredPieChart from "../../components/Graphics/ColoredPieChart";
import Icon from "../../components/Icon/Icon";
import { useNavigate } from "react-router-dom";

const colors = ["#89ac76", "#fd7c5c", "#6981ec"];

import { useTranslation } from "react-i18next";

const MainContainer = styled("div", {
  display: "flex",
  width:'100%',
  gap: "40px",
});

const Card = styled("div", {
  color: "white",
  padding: "10px",
  paddingTop: "20px",
  borderRadius: "8px",
  width:'100%',
  background: "#94ba7f",
  boxShadow: "$elevation_1",
  ["div"]: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px",
  },
  [".number"]: {
    fontSize: "60px",
    margin: "10px",
  },
});

export default function CO2Persons({ totalEmmissions, totalCompensations, dates }) {
  const { t: translate } = useTranslation("CO2Persons");

  return (
    <div >
        <MainContainer>
          <Card>
            <h3>Emisiones totales de CO₂</h3>
            {dates.startDate && (
              <p>{`Emisiones totales generadas desde ${format(
                dates?.startDate,
                "dd/MM/yyyy"
              )} hasta ${format(dates?.endDate, "dd/MM/yyyy")}`}</p>
            )}
            <div>
              <h1 className="number">{totalEmmissions} kg</h1>
              <Icon name="GreenEnergy" size="60px" color="white" />
            </div>
          </Card>
          <Card>
            <h3>Emisiones compensadas </h3>
            {dates.startDate && (
              <p>{`Emisiones totales compensadas desde ${format(
                dates?.startDate,
                "dd/MM/yyyy"
              )} hasta ${format(dates?.endDate, "dd/MM/yyyy")}`}</p>
            )}
            <div>
              <h1 className="number">{totalCompensations} kg</h1>
              <Icon name="GreenEnergy" size="60px" color="white" />
            </div>
          </Card>
      </MainContainer>
    </div>
  );
}
