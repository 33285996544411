import React from 'react';
import { Image, styled, Link } from '@nextui-org/react';
import { useNavigate } from 'react-router-dom';
import theme from '../../../theme';

// Components
import Icon from '../../Icon/Icon';

const Card3 = ({ title, image, iconVideo, to }) => {
    const StyledCard3 = styled('div', {
        [`.${theme} &`]: {
            backgroundColor: 'transparent',
            borderRadius: '8px',
            padding: '1.5rem',
            color: '$primary_500',
            width: '200px',
            height:'300px',
            transition: '300ms',
            ['&:hover']: {
                cursor: 'pointer',
                transform: 'translateY(-5px)'
            },
            img: {
                borderRadius: '8px'
            },
            h3: {
                marginTop: '8px',
                marginBottom: '0px'
            },
            ['.icon']: {
                position: 'absolute',
                top: '0',
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            },
            ['.image']: {
                position: 'relative'
            }
        }
    })
    const navigate = useNavigate()
    console.log('to :', to)
    return (
        <StyledCard3 onClick={() => navigate(to)}>
            <div className='image'>
                <Image
                    width={150}
                    height={150}
                    src={image}
                    alt="Card Image"
                    objectFit="cover"
                />
                {
                    iconVideo ?
                        <div className='icon'>
                            <Icon name='Video' size='30' color='white'></Icon>
                        </div>
                        : ''
                }
            </div>
            <h4 style={{fontSize:'20px',margin:'10px 0'}}>{title}</h4>
        </StyledCard3>
    );
}

export default Card3;
