import React, { useEffect, useRef, useState,useMemo  } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import logo from "../../../assets/img/VAL-BLANCO-HORIZONTAL.png"
//services
import { getQuoteData, getSubTypes, getSolutions, getOffices, getClients, getQuoteLanguages, getStatuses, getReasons, postQuotation, deleteQuotation, editQuotation, finishQuotation } from '../../../services/quotations.services';
import { getBusinessTerms } from '../../../services/businessTerms.services';
import { getServiceSplitRadication } from '../../../services/quotation.services';
import { prelDetails, formatLiquidation} from '../../../services/preliquidation.services'
//components
import { Page, Document, StyleSheet, PDFViewer, pdf  } from '@react-pdf/renderer';
import ReactDOMServer from 'react-dom/server';
import Html from 'react-pdf-html';
import { Loading } from '@nextui-org/react';
import { getUsers } from '../../../services/user.services';
import { useTranslation } from "react-i18next";
import InputSelect from '../../../components/Select/inputSelect';
import { URL } from '../../../constants';

function convertToMeters(value, unit ) {
    const currentUnit = unit?.trim()
    switch (currentUnit) {
        case 'cm': return value / 100;
        case 'in': return value * 0.0254;
        case 'm': return value;
        default: return 0;
    }
}
function calculateVolume(amount, long, width, height, longUnit) {
    const longInMeters = convertToMeters(long, longUnit);
    const widthInMeters = convertToMeters(width, longUnit);
    const heightInMeters = convertToMeters(height, longUnit);
    return longInMeters * widthInMeters * heightInMeters * amount;
}
function calculateShippingInfo(loads) {
    let totalAmount = 0;
    let totalVolume = 0;
    let totalWeight = 0;
    let totalValue = 0;
    for (let i = 0; i < loads?.length; i++) {
        const item = loads[i];
        const volume = calculateVolume(item.amount,item.long, item.width, item.height, item.long_unit);
        totalVolume += volume;
        totalAmount += item.amount;
        totalWeight += item.total_weight_unit === 'lb' ? item.total_weight * 0.453592 : item.total_weight;
        totalValue += item.value;
    }
    const totalWeightTons = totalWeight
    return { totalAmount, totalVolume, totalWeightTons, totalValue };
}

const styles = StyleSheet.create({
    fontSize:'10px',
    '.head':{
        padding:'20px',
        backgroundColor:'#292E68',
        color:'white',
        display:'flex',
        justifyContent:'space-around',
        flexDirection:'row',
    },
    '.head_info_container':{
        display: 'flex',
        flexDirection:'column',
        justifyContent:'space-around',
        width: '50%', 
        border:'1px solid white',
        borderRadius:'6px',
        padding:'10px',
    },
    '.head_info':{
        fontSize:'10px',
        display:'flex',
        flexDirection:'column',
        height:'50px',
        width:'100%',
    },
    '.page':{  
        height:'842px',
    },
    'table':{
        fontSize:'10px',
        minHeight:'50px',
        padding:'20px',
    },
    'td':{
        fontSize:'8px',
        minHeight:'15px',
        padding:'5px',
        borderBottom:'1px solid grey',
    },
    'th':{
        fontSize:'8px',
        backgroundColor:'lightgrey',
        padding:'5px',
        border:'1px solid grey',
    },
    '.tableTd':{
        fontSize:'7px',
        margin:0,
        padding:'5px',
        border:'1px solid grey',
    },
    '.section_title':{
        display:'flex',
        height:'60px',
        backgroundColor:'#292E68',
        color:'white',
        justifyContent:'center'
    },
    '.section_title_text':{
        margin:'auto',   
        fontSize: 20,
    },
});


export default function DetailsPdf({showPDF=true, sendBase64 = false, id, radicationPrio, showPurchase=false, showSelectVersion=false}) {
    const token = useSelector(state => state.user.token)
    const [searchParams] = useSearchParams()
    const [loading, setLoading] = useState(false)
    const [quoteData, setQuoteData] = useState()
    const [quoteId, setQuoteId] = useState()
    const [radication, setRadication] = useState()
    const [RFQ, setRFQ] = useState()
    const [prels, setPrels] = useState({})
    const [airCargo, setairCargo] = useState({})
    const [total, setTotal] = useState({})
    const [versions, setVersions] = useState([])
    const [terms, setTerms] = useState('')
    const [current, setCurrentPreliquidation] = useState({});
    const { t: translate } = useTranslation("ManageQuotes");
    const refContainerServicesLoads = useRef();
    const refContainerRemarks = useRef();

    const [heights, setHeights] = useState({
        servicesLoads: 0,
        shipmentRemarks:0
    });
    const [currentVersion, setCurrentVersion] = useState()

    useEffect(() => {
        if (!loading) {
            setHeights({
                servicesLoads: refContainerServicesLoads?.current?.offsetHeight || 0,
                shipmentRemarks: refContainerRemarks?.current?.offsetHeight || 0,
            });
        }
    }, [refContainerServicesLoads]);

    const itemsTranslation = useMemo(() => ({
        "ORIGIN": translate("origin_charges"),
        "DESTINATION": translate("destination_charges"),
        "INSURANCE": translate("insurance_charges"),
        "OCEAN": translate("ocean_charges"),
        "Air cargo": translate("air_charges"),
      }), [translate]);

    useEffect(() => {
        console.log("radicationPrio :",radicationPrio)
        setQuoteId(id || searchParams.get('id'))
        setRadication(radicationPrio || searchParams.get('radication'))
    }, [])
    function formatearFecha(fechaOriginal) {
        if (fechaOriginal) {
            const fechaFormateada = new Date(fechaOriginal).toISOString().split('T')[0];
            return fechaFormateada;
        } else { return ""}
    }

    function getData(currentVersion){
        const asyncTasks = [
            getQuoteData(token, currentVersion?.id || quoteId),
            getSubTypes(token),
            getSolutions(token),
            getOffices(token),
            getBusinessTerms(token),
            getQuoteLanguages(token),
            getStatuses(token),
            getReasons(token),
            getQuoteData(token),
            getQuoteData(token, quoteId),
            getServiceSplitRadication(radication, token),
            getUsers(token),
        ];
        setLoading(true)
        if (quoteId && radication) {
            Promise.all(asyncTasks).then(async responses => {
                const liquidationData = await responses[0].json();
                const subtypesData = await responses[1].json();
                const servicesData = await responses[2].json();
                const officesData = await responses[3].json();
                const incotermsData = await responses[4].json();
                const quoteLanguagesData = await responses[5].json();
                const statusesData = await responses[6].json();
                const reasonsData = await responses[7].json();
                const quotesData = await responses[8].json()
                const currentQuoteData = await responses[9].json()
                const RFQData = await responses[10].json()
                const usersData = await responses[11].json()

                const rfq = RFQData.results[0]
                setRFQ(rfq)
                const client = rfq?.users?.find(user => {
                    let isClient = false
                    user.user?.groups?.forEach(group=>{
                        if (['Usuario','Cliente'].includes(group)){
                            isClient=true
                        }
                    })
                    return isClient;
                })
                const loadsData = calculateShippingInfo(rfq?.loads)

                const prelD = await prelDetails(liquidationData.preliquidation_ids, token);
                let formated = await formatLiquidation([prelD[prelD.length - 1]], token)
                setCurrentPreliquidation(prelD[0])
                setTerms(liquidationData.terms)

                const comercial = usersData.find(user=>user.id === prelD[0]?.user)
                const salesContact = usersData.find(user=>user.id === prelD[0]?.sales_contact)
                
                const groups = {}
                const airCargo = {}

                formated.forEach((item) => {
                    airCargo['Air cargo'] = [item.air_cargo]
                    let group = item.service_group
                    if (groups[group]) {
                        groups[group].push(item)
                    } else {
                        groups[group] = [item]
                    }
                })
                const totals = {
                    allTotal:0
                }
                
                Object.keys(groups).forEach((group) => {
                    const currencies = groups[group].map(e=>(e.sale_currency))
                    const currencyCount = {};
                    currencies.forEach(currency => {
                        if (currencyCount[currency]) {
                            currencyCount[currency]++;
                        } else {
                            currencyCount[currency] = 1;
                        }
                    });

                    let mostCommonCurrency = '';
                    
                    let maxCurrency = 0;
                    for (const currency in currencyCount) {
                        if (currencyCount[currency] > maxCurrency) {
                            maxCurrency = currencyCount[currency];
                            mostCommonCurrency = currency;
                        }
                    }
        
                    const mostCommonCurrencyTRM = groups[group].find(e=>e.sale_currency == mostCommonCurrency)?.sale_trm
                    let totalUSD = 0
                    groups[group].forEach((item) => {
                        totalUSD += item.sale_in_usd
                    })
                    const currencyFormatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: mostCommonCurrency
                    });
                    
                    const totalInMostCommoCurrency = totalUSD * mostCommonCurrencyTRM
                    totals.allTotal = totals.allTotal + totalUSD
                    let extra = ""
                    if (mostCommonCurrency !== 'USD'){
                        extra = `(${totalUSD?.toFixed(2)} USD)`
                    }
                    totals[group] = `TOTAL : ${currencyFormatter.format(totalInMostCommoCurrency)}  (trm ${mostCommonCurrency} : ${mostCommonCurrencyTRM}) ${extra}`;})
                    
                if (airCargo && airCargo['Air cargo'] && airCargo['Air cargo'][0]){
                    totals.allTotal = totals.allTotal + airCargo['Air cargo'][0]?.total_usd
                }
                setPrels(groups)
                setTotal(totals)
                setairCargo(airCargo)
                setLoading(false)
                const formattedData = {
                    subtype: subtypesData.find(e => e.id === liquidationData.subtype),
                    service: servicesData.find(e => e.id === liquidationData.service),
                    office: officesData.find(e => e.id === liquidationData.office),
                    salesContact:salesContact,
                    client:client?.user,
                    comercial,
                    loadsData,
                    origin: liquidationData?.shipping?.origin_boarding_terminal,
                    destination: liquidationData?.shipping?.destination_boarding_terminal,
                    reason: reasonsData.find(e => e.id === liquidationData.reason),
                    incoterm: incotermsData.find(e => e.id === liquidationData.incoterm),
                    language: quoteLanguagesData.find(e => e.id === liquidationData.language),
                    status: statusesData.find(e => e.id === liquidationData.status),
                    notes: liquidationData.observations,
                    created: liquidationData.created_at,
                    approval: liquidationData.approve_date,
                    creation: liquidationData.created_at
                }
                setQuoteData(formattedData);
                const versions = quotesData?.filter((quote => quote.radication === currentQuoteData.radication))
                    .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
                setVersions(versions)
            }).catch(error => console.error('Error:', error));
        }
    }

    useEffect(() => {
        getData(currentVersion)
    }, [radication, quoteId, currentVersion]);

    const boldStyle = { fontWeight: 'bold' };

    const element = (
    <>      
        <div style={{ position:'relative'}}>
                <>
                    <div className='page'>
                        <div className='head' >
                            <div style={{ display: 'flex', flexDirection:'column',justifyContent:'center', alignItems: 'center' }}>
                                <img src={logo} style={{height:"100"}} alt="" />
                                <b className='section_title_text'>Cotización</b>
                                <br/>
                                <div style={{fontWeight:'300px'}}>{radication}</div>
                            </div>
                            <span style={{position:'absolute', top:5, left:5, fontSize:'9px', color:'white'}}>{formatearFecha(quoteData?.created)}</span>
                            <div className='head_info_container'>
                                <div className='head_info'>
                                    <div style={{borderBottom:'1px solid white', width:'45px'}}>{translate("customer")}</div>
                                    <div>{translate("prepared_for")}:  {quoteData?.client?.first_name} {quoteData?.client?.last_name}</div>
                                    <div>{translate("email")} {quoteData?.client?.email}</div>
                                    <div>{translate("phone")} : {quoteData?.client?.phone}</div>
                                </div>
                                <div className='head_info'>
                                    <div style={{borderBottom:'1px solid white', width:'45px'}}>Comercial</div>
                                    <div>{translate("prepared_by")}: {quoteData?.comercial?.name} {quoteData?.comercial?.last_name}</div>
                                    <div>{translate("email")}: {quoteData?.comercial?.email}</div>
                                    <div>{translate("phone")}: {quoteData?.comercial?.phone}</div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className='prel-info'>
                            <div className='section_title'>
                                <span className='section_title_text'>VAL LOGISTICS</span>
                            </div>       
                            <table>
                                <tbody>
                                    <tr>
                                        <td>Cra 55# 152b- Torre  A Oficina 1204</td>
                                        <td>Sales contact : {quoteData?.salesContact?.name} {quoteData?.salesContact?.last_name}</td>
                                    </tr>
                                    <tr>
                                        <td>Bogotá</td>
                                        <td>Phone : {quoteData?.salesContact?.phone}</td>
                                    </tr>
                                    <tr>
                                        <td>Colombia</td>
                                        <td>Email : {quoteData?.salesContact?.email}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='prel-info'>
                            <div className='section_title'>
                                <span className='section_title_text'>Service details</span>
                            </div>
                            <table>
                                <tr>
                                <td style={boldStyle}>{translate("move_type")}: {current?.service_details?.move_type}</td>
                                {current?.service_details?.airline && (
                                    <td><strong>{translate("liner")}: </strong>{current?.service_details?.airline}</td>)}
                                </tr>
                                <tr>
                                    <td><strong>{translate("load_type")}: </strong>{current?.service_details?.load_type}</td>
                                    <td><strong>{translate("port_of_landing")}: </strong>{current?.service_details?.loading_point}</td>
                                </tr>
                                <tr>
                                    <td><strong>{translate("incoterm")}: </strong>{quoteData?.incoterm?.name}</td>
                                    <td><strong>{translate("port_of_discharge")}: </strong>{current?.service_details?.unloading_point}</td>
                                </tr>
                                <tr>
                                    <td><strong>{translate("destination")}: </strong>{quoteData?.destination}</td>
                                    {current?.service_details?.transshipment_via && (
                                    <td><strong>{translate("transhipment_via")}: </strong>{current.service_details.transshipment_via}</td>)}                                </tr>
                                    <tr>
                                    <td><strong>{translate("pickup_address")}: </strong>{current?.service_details?.pickup_address}</td>
                                    <td><strong>{translate("delivery_address")}: </strong>{current?.service_details?.delivery_address}</td>
                                </tr>
                                <tr>
                                {current?.service_details?.free_days && (
                                <td><strong>{translate("free_days")}: </strong>{current.service_details.free_days}</td>)} 
                                <td><strong>{translate("equipment")}: </strong>{current?.service_details?.equipment}</td>
                                </tr>
                            </table>
                            <div className='section_title'>
                                <span className='section_title_text'>{translate("shipping_information")}</span>
                            </div>
                            <table>
                                <tr>
                                    <td><strong>{translate("pieces")}: </strong>{RFQ?.loads.length}</td>
                                    <td><strong>{translate("dangerous_goods")}: </strong>{current?.shipping_information?.dangerous_goods ? 'Sí' : 'No'}</td>
                                </tr>
                                <tr>
                                    <td><strong>{translate("actual_weight")}: </strong>{ quoteData?.loadsData?.totalWeightTons } Kg</td>
                                    <td><strong>{translate("UN")}: </strong>{current?.shipping_information?.UN}</td>
                                </tr>
                                <tr>
                                <td><strong>{translate("actual_volume")}: </strong>{ quoteData?.loadsData?.totalVolume != null ? quoteData.loadsData.totalVolume.toFixed(2) : '0.00' } m³</td>
                                <td><strong>{translate("value_of_cargo")}: </strong>{ quoteData?.loadsData?.totalValue } USD</td>
                                </tr>
                                <tr>
                                    <td><strong>{translate("chargable_vol")}: </strong>{current?.shipping_information?.chargeable_vol.toFixed(2)}</td>
                                    <td><strong>{translate("TRM")}: </strong>{current?.shipping_information?.TRM}</td>
                                </tr>
                                <tr>
                                    <td><strong>{translate("commodity")}: </strong>{current?.shipping_information?.commodity}</td>
                                    <td><strong>{translate("currency")}: </strong>{current?.shipping_information?.currency}</td>
                                </tr>
                                <tr>
                                    <td><strong>{translate("transit_time")}: </strong>{current?.service_details?.transit_time} days</td>
                                    <td><strong>{translate("validity")}: </strong>{current?.service_details?.valid_until}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div className='page' style={{height:'500px'}} ref={refContainerServicesLoads}>
                        <div className='section_title'>
                            <span className='section_title_text'>{translate("services")}</span>
                        </div>
                        <span style={{ fontSize: '8px', display: 'block', textAlign: 'right', marginRight: '10px' }}>
                        {translate("side_note")}
                        </span>
                        <table>
                            <tbody>
                                {Object.keys(prels).map((group, index) => {
                                    return (
                                    itemsTranslation[group]  &&
                                    <React.Fragment key={index}>
                                        <tr>
                                            <td>
                                                <b>{itemsTranslation[group] ? itemsTranslation[group]+':' : 'otro'}</b>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{translate("concept")}</th>
                                            <th>{translate("factor")}</th>
                                            <th>{translate("unit")}</th>
                                            <th>{translate("currency")}</th>
                                            {/* <th>{translate("rate")}</th> */}
                                            <th>Tasa venta</th>
                                            <th>{translate("min")}</th>
                                            <th>Total venta</th>
                                            {showPurchase && <th>Total compra</th>}
                                            {showPurchase && <th>Proveedor</th>}
                                            {/* <th>Total USD</th> */}
                                        </tr>
                                        {prels[group].map((item, i) => {
                                            console.log("item :",item)
                                            let concept = item.service.toLowerCase();
                                            concept = concept.charAt(0).toUpperCase() + concept.slice(1)
                                            return <tr key={i}>
                                                <td className='tableTd'>{concept}</td>
                                                <td className='tableTd'>{item.factor}</td>
                                                <td className='tableTd'>{item.unit}</td>
                                                <td className='tableTd'>{item.sale_currency}</td>
                                                <td className='tableTd'>{(item.item_rate || 0)?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                                <td className='tableTd'>{item.min}</td>
                                                <td className='tableTd'>{item.sale_price?.toFixed(2)} {item.sale_currency}</td>
                                                {showPurchase && <td className='tableTd'>{(item.purchase_price)?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>}
                                                {showPurchase && <td className='tableTd'>{item.provider || "N/a"}</td>}
                                                {/* <td className='tableTd'>{(item.factor * item.item_rate / item.sale_trm)?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td> */}
                                            </tr>
                                        })}
                                        <tr style={{textAlign:'center'}}>
                                            {/* <td style={{ textAlign: 'right' }}>Total :</td> */}
                                            <td >{total[group]}</td>
                                        </tr>
                                    </React.Fragment>
                                    )
                                })}
                            </tbody>
                        </table>
                        {airCargo['Air cargo']?airCargo['Air cargo'][0]?<table>
                            <tbody>
                                {Object.keys(airCargo).map((group, index) => (
                                    <React.Fragment key={index}>
                                        <tr>
                                            <td>
                                                <b>{itemsTranslation[group] ? itemsTranslation[group] +':' : 'otro'}</b>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{translate("currency")}</th>
                                            <th>{translate("frecuency")}</th>
                                            <th>{translate("min")}</th>
                                            <th>{translate("fuel_surcharge_kg")}</th>
                                            <th>Fuel surcharge min</th>
                                            <th>{translate("price_per_kg")}</th>
                                            <th>{translate("total_usd")}</th>
                                        </tr>
                                        {airCargo[group].map((item, i) => (

                                            <tr key={i}>
                                                <td className={'tableTd'}>{item.currency}</td>
                                                <td className={'tableTd'}>{item.frequency}</td>
                                                <td className={'tableTd'}>{item.min}</td>
                                                <td className={'tableTd'}>{item.fuel_surcharge_kg}</td>
                                                <td className={'tableTd'}>{item.fuel_surcharge_min}</td>
                                                <td className={'tableTd'}>{item.price_per_kg?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                                <td className={'tableTd'}>{item.total_usd?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                            </tr>
                                        ))}
                    
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>:null:null}
                        <h4 style={{margin:'auto'}}>Total :{total.allTotal?.toFixed(2)} USD</h4>
                        <br />
                        <div className='section_title'>
                            <span className='section_title_text'>{translate("loads")}</span>
                        </div>
                        {
                            <table>
                                <thead>
                                    <tr>
                                        <th>{translate("load")}</th>
                                        <th>{translate("name")}</th>
                                        <th>{translate("value")}</th>
                                        <th>{translate("amount")}</th>
                                        <th>{translate("measurements")}</th>
                                        <th>{translate("total_weight")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {RFQ?.loads.map((load, index) => (
                                        <tr key={index}>
                                            <td className='tableTd'>{`Carga ${index + 1}`}</td>
                                            <td className='tableTd'>{load?.name}</td>
                                            <td className='tableTd'>{`${load.value} USD`}</td>
                                            <td className='tableTd'>{load.amount}</td>
                                            <td className='tableTd'>{`${load.long}${load.long_unit} x ${load.width}${load.width_unit} x ${load.height}${load.height_unit}`}</td>
                                            <td className='tableTd'>{`${load.total_weight} ${load.total_weight_unit}`}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        }
                        
                <a 
    style={{ fontSize: '14px', display: 'block', textAlign: 'center' }}
    href={`${window.location.origin}/quotation/detail/?radication=${radication}&id=${RFQ?.id}&finished=No`}
    title="Aprobar cotización"
>
    Aprobar cotización
</a>
                    </div>
                    <br />
                    <div className='section_title'>
                        <span className='section_title_text'>Shipment remarks</span>
                    </div>
                    <span style={{ fontSize: '8px', display: 'block', textAlign: 'right', marginRight: '10px' }}>
                    {translate("side_note")}
                    </span>{terms && <div dangerouslySetInnerHTML={{ __html: terms }} style={{fontSize:'9px',padding:'0 20px'}}/>}
                    
                </>
        </div>
    </>
);

const loadingElement = (
    <div>
        {translate("loading")}
    </div>
)

const html = ReactDOMServer.renderToStaticMarkup(element);
const loadingHtml = ReactDOMServer.renderToStaticMarkup(loadingElement);

    const MyDocument = () => (
        <Document title='Quote details'>
            <Page size="A4">
                {
                    <Html stylesheet={styles}>{html}</Html>
                }
            </Page>
        </Document>
    )
    
    useEffect(()=>{
        if (!loading && quoteData && sendBase64){
            pdf(MyDocument()).toBlob().then(async res=>{
                const blob = res
                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64data = reader.result;
                    sendBase64(base64data)
                };
                reader.readAsDataURL(blob);
            }).catch(err=>console.log("err :",err));
        }
    },[loading, quoteData])

    if (showPDF){
    return (
        loading?
        <div style={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            width:'80%',
            height:'40rem',
            margin:'40px auto',
            backgroundColor:'#292E68',
            borderRadius:'8px'
        }}>
            <Loading size='xl' color='white' textColor='white'>{translate("loading")}</Loading>
        </div>:
        <>
            {showSelectVersion && <div style={{margin:'auto',marginTop:'30px', width:'60%'}}>
                <InputSelect 
                    options={versions.map((e,i)=>({label:`Version ${i+1}`, value:e.id}))}
                    value={currentVersion}
                    onChange={e=>setCurrentVersion(e)}
                />
            </div>}
            <PDFViewer  
                style={{width:'100%', height:'40rem', margin:'40px auto', borderRadius:'8px'}}
            >
                <MyDocument />
            </PDFViewer>
        </>
    )}
}
