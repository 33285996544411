import { FormatoCartaGarantia } from '../../../assets/Files/Documents/FormatoCartaGarantia'
import { AutorizacionDatosEstudiosAterceros } from '../../../assets/Files/Documents/AutorizacionDatosEstudiosATerceros';
import { poderEspecialCompas } from '../../../assets/Files/Documents/PoderEspecialCompas';

const items = [
    {
        id: 1,
        name: 'Carta de garantia',
        type: 'PDF',
        Base64: FormatoCartaGarantia,
        dueDate: new Date('2021-10-28T00:00:00-05:00'),
    },
    {
        id: 2,
        name: 'Autorizacion datos estudios a terceros',
        type: 'PDF',
        Base64: AutorizacionDatosEstudiosAterceros,
        dueDate: new Date('2020-06-12T00:00:00-05:00'),
    },
    {
        id: 3,
        name: 'Poder especial compas',
        type: 'PDF',
        Base64: poderEspecialCompas,
        dueDate: new Date('2020-06-12T00:00:00-05:00'),
    },
]

export default items