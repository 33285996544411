import { styled } from '@nextui-org/react';
import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form';
import FormInput from '../../../components/FormInput/FormInput';
import logo from '../../../assets/img/white-logo.png'
import FactorInputTable from './FactorInputTable';
import defaultValues from './DefaultValues';
import ProviderInputTable from './ProviderInputTable';
import TotalsTable from './TotalsTable';
import FreightCostsTable from './FreightCostsTable';
import TableTotals from './TableTotals';

const Container = styled("div",{
    display:'flex',
    flexDirection:'column',
    gap:'10px',
    background:'white',
    margin:'auto',
    boxShadow:'$elevation_1',
    borderRadius:'8px',
    padding:'40px',
    '.header':{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        background:'$primary_300',
        color:'lightgrey',
        padding:'20px',
        borderRadius:'8px',
        'div':{
            display:'flex',
            flexDirection:'column',
        }
    },
    '.top-inputs':{
        display:'flex',
        margin:'30px 5px',
        gap:'10px',
        fontSize:'10px',
        alignItems:'end'
    },
    '.subtitle':{
        color:'$primary_500',
        width:'fit-content',
        borderBottom:'2px solid $primary_500',
    },
})

const totalColumns = [
    {name:"Valor USD", uid:"value" , type:'number'},
    {name:"Factor USD", uid:"factorUSD" , type:'number'},
    {name:"Factor Col", uid:"factorCol" , type:'number'},
    {name:"Porcentual", uid:"porcentual" , type:'number'},
]

export default function FactorLogistico({selectedOperation}) {

    const { formState: { errors, isValid }, ...methods } = useForm({
        defaultValues:defaultValues
    });

    return (    
    <FormProvider {...methods}>
        <Container>
            <div className='header'>
                <img src={logo} alt="" />
                <div>
                    <span>CALCULO FACTORES Y PORCENTAJES DE COSTOS DE IMPORTACION</span>
                    <span># de ORDEN DE COMPRA:  5370213 - 521397</span>
                    <span>RADICADO: VAL-202405-0001</span>
                </div>
            </div>
            <div className='top-inputs'>
                <FormInput type='date' name="liquidation_date" label='Fecha de liquidación'/>
                <FormInput type='date' name="entry_date" label='Fecha de ingreso a bodega'/>
                <FormInput name="via" label='VIA'/>
                <FormInput name="kgs" label='KGS'/>
                <FormInput type='date' name="awb_date" label='Fecha AWB'/>
                <FormInput type="number" name="awb_number" label='AWB No'/>
            </div>
            <h4 className='subtitle'>Costos generales :</h4>

            <FactorInputTable tableName={"General"} id={"general_costs"}/>
            <TableTotals id="general_costs" columns={totalColumns} title='CFP PTO COLOMBIANO'/>

            <h4 className='subtitle'>Costos de nacionalización :</h4>

            <FactorInputTable tableName={"Nationalization costs"} id={"destination_costs"}/>
            <TotalsTable columns={totalColumns} items={[
                {concept:"Total Costo", "value":200, "factorUSD":200, "factorCol":200, "porcentual":15},
                {concept:"IVA IMPORTACIÓN", "value":200, "factorUSD":200, "factorCol":200, "porcentual":15},
                {concept:"IVA SIA", "value":120, "factorUSD":120, "factorCol":120, "porcentual":5},
                {concept:"Costo + Iva", "value":120, "factorUSD":120, "factorCol":120, "porcentual":5, bold:true},
            ]}/>
            <h4 className='subtitle'>Proveedores :</h4>
            <ProviderInputTable/>
            <TableTotals/>
            <h4 className='subtitle'>Otros costos :</h4>
            <FreightCostsTable/>
            <TableTotals/>
        </Container>
    </FormProvider>
    )
}
