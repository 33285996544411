import { styled } from '@nextui-org/react'
import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

const Container = styled("div",{
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    width:'88%',
    margin:'10px 0',
    fontSize:'14px',
    border:'1px solid lightgrey',
    padding:'10px',
    'div':{display:'flex', flexDirection:'column',
        'b':{width:'fit-content', margin:'auto'}
    }
})

export default function TableTotals({title ,id, columns}) {

    const [totalValues, setTotalValues] = useState({})

    const methods = useFormContext()
    useEffect(() => {
        const subscription = methods.watch((value, { name, type }) => {
            if (name.startsWith(id)){
                const relatedTable = value[id]
                let totals={}
                columns.forEach(col => {
                    totals[col.name]=0
                })
                relatedTable.forEach(row => {
                    columns.forEach(col => {
                        const cellValue = Number(row[col.name] || 0)
                        totals[col.name] += isNaN(cellValue)? 0 : cellValue
                    })
                })
                console.log("totals :",totals)
                setTotalValues(totals)
            }
        });
        return () => subscription.unsubscribe();
    }, [methods.watch]);

    return (
    <Container>
        <b>{title}</b>
        {
            columns?.map(col => {
                return <div>
                    <span>Total {col?.name}</span>
                    <b>{totalValues[col.name]}</b>
                </div>
            })
        }
    </Container>
  )
}
