import React from "react";
import Icon from "../../Icon/Icon";
import theme from "../../../theme";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { styled } from "@nextui-org/react";
import { useTranslation } from "react-i18next";

const ChartContainer = styled("div", {
  background: "#fff",
  padding: "20px",
  borderRadius: "10px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  margin: "30px 0",
});

const TitleIconContainer = styled("div", {
  display: "flex",
  gap: "10px",
  alignItems: "center",
  marginBottom: "20px",
});

const Title = styled("p", {
  color: theme.colors.primary_300,
  fontWeight: "800",
  fontSize: "1.2rem",
});

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div style={{ background: "#fff", padding: "10px", border: "1px solid #ccc", borderRadius: "5px" }}>
        <p>{label}</p>
        {payload.map((data, index) => (
          <p key={index} style={{ color: data.fill }}>{`${data.name}: ${data.value.toLocaleString()}`}</p>
        ))}
      </div>
    );
  }
  return null;
};

export default function HorizontalBarChart({ title, icon, data }) {
  const { t: translate } = useTranslation("Home");

  return (
    <ChartContainer>
      <TitleIconContainer>
        <Icon name={icon} color={theme.colors.secondary_200} />
        <Title>{title}</Title>
      </TitleIconContainer>
      {data?.length === 0 ? (
        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", border: "1px solid #CCC", height: "232px", padding: "10px" }}>
          <p>{translate("no-data")}</p>
        </div>
      ) : (
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            layout="vertical"
            data={data}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis type="number" />
            <YAxis dataKey="name" type="category" />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Bar
              dataKey="cantidad"
              fill="url(#colorCantidad)"
              barSize={50}
              barGap={5}
              radius={[0, 10, 10, 0]}
            />
            <defs>
              <linearGradient id="colorCantidad" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#292E68" stopOpacity={0.8} />
                <stop offset="100%" stopColor="#292E68" stopOpacity={0.2} />
              </linearGradient>
            </defs>
          </BarChart>
        </ResponsiveContainer>
      )}
    </ChartContainer>
  );
}
