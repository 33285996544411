import { URL } from "../constants"

export const getUsers = async (token) => {
    const response = await fetch(`${URL.get()}user/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const sendUser = async (data, token) => {
    const response = await fetch(`${URL.get()}user/corporation/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify(data)
    })
    return response
}

export const getProfilePicture = async (data, token, user) => {
    const response = await fetch(`${URL.get()}user-profile-picture/${user}/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify(data)
    })
    return response
}


export const getProfileDocuments = async (token) => {
    const response = await fetch(`${URL.get()}profile/corporation-document/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}
export const GetDocumentData = async (data, token, user, path) => {
    const response = await fetch(`${URL.get()}document/${path}/${user}/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: data?JSON.stringify(data):""
    })
    return response
}

export const impersonateUser = async (token, userId) => {
    const response = await fetch(`${URL.get()}impersonate/${userId}/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
    })
    return response
}
export const stopImpersonate = async (token) => {
    const response = await fetch(`${URL.get()}impersonate/stop/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify(data)
    })
    return response
}