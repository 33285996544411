import React, { useEffect, useState } from 'react'
import { deleteProvider, getProviders, getProviderTypes } from '../../../services/preliquidation.services'
import Table from '../../../components/Table/Table'
import { useSelector } from 'react-redux'
import ProviderModal from './ProviderModal'
import Button from '../../../components/Button/Button'
import { Loading, styled } from '@nextui-org/react'
import CreateProviderModal from './CreateProviderModal'
import DeleteConfirmationModal from '../../../components/DeleteConfirmationModal/DeleteConfirmationModal'
import { useTranslation } from "react-i18next";
import toast from '../../../components/Toast/ToastTypes'

const Container = styled("div",{
    zIndex:1,
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    ".header":{
        display:'flex',
        justifyContent:'space-between',
        borderBottom:'4px solid $primary_500',
        paddingBottom:'5px'
    }
})

export default function ProvidersTable() {  

    const token = useSelector(state=>state.user.token)
    const [loading, setLoading] = useState(false)
    const [items, setItems] = useState([])
    const [openCreateModal, setOpenCreateModal] = useState(false)
    const [openModifyModal, setOpenModifyModal] = useState(false)
    const [openConfirmModal, setOpenConfirmModal] = useState(false)

    const [selectedProvider, setSelectedProvider] = useState()
    const [selectedProviderId, setSelectedProviderId] = useState()
    const { t: translate } = useTranslation("Admin");

    const columns = [
        {name:'', uid:'actions', type:'action'},
        {name:translate("name"), uid:'name', type:'text'},
        {name:translate("email"), uid:'mail', type:'text'},
        {name:translate("provider_type"), uid:'provider_type', type:'text'},
    ]

    const actions = [
        {
          name: translate("view/edit"),
          icon: 'Edit',
          type: 'button',
          handle: (e)=>{
            setSelectedProviderId(e.id)
            setOpenModifyModal(true)
          }
        },
        {
            name: translate("delete"),
            icon: 'Trash',
            type: 'button',
            handle: (e)=>{
                setSelectedProvider(e)
                setOpenConfirmModal(true)
            }
          },
      ];


    function getData(){
        setLoading(true)
        Promise.all([getProviders(token), getProviderTypes(token)]).then(async res=>{
            const providersData = await res[0].json()
            const providerTypesData = await res[1].json()
            const tableData = providersData.map(provider => {
                return {
                    ...provider,
                    provider_type:providerTypesData.find(type => type.id === provider.provider_type)?.name,
                }
            })
            setItems(tableData?.reverse())
            setLoading(false)
        }).catch(()=>setLoading(false))
    }
    function handleDelete(){
        deleteProvider(token,selectedProvider.id).then(()=>{
            toast("success","Proveedor eliminado")
            getData()
        }).catch(err=>{
            console.log("err :",err)
            toast("error","No se pudo eliminar al proveedor")
        })
    }
    useEffect(()=>{
        getData()
    },[])

    return (
        <Container>  
            <div>
                <div className='header'>
                <h3>{translate("providers")}</h3>
                <Button text={translate("add_provider")} handler={()=>setOpenCreateModal(true)} bgColor={'primary_500'} textColor={'neutral_50'}/>
            </div>
            {loading?<Loading size='lg'/> :<Table columns={columns} items={items} actions={actions}/>}
            <ProviderModal open={openModifyModal} setOpen={setOpenModifyModal} providerId={selectedProviderId} updateTable={getData}/>
            <CreateProviderModal open={openCreateModal} setOpen={setOpenCreateModal} getData={getData}/>
            <DeleteConfirmationModal 
                open={openConfirmModal} 
                setOpen={setOpenConfirmModal} 
                handleDelete={handleDelete} 
                text={
                <div>
                    <p>Confirma que desea eliminar el siguiente proveedor</p>
                    <p>Nombre : {selectedProvider?.name}</p>
                    <p>Email : {selectedProvider?.email}</p>
                    <p>ID : {selectedProvider?.id}</p>   
                </div>
                }
            />
            </div>
        </Container>
    )
}
