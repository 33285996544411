import React, { useEffect, useState } from 'react';
import { styled } from '@nextui-org/react';
import PageTitle from '../../../components/PageTitle/PageTitle';
import ManageModal from './ManageModal';
import { getOffices, getReasons, getSolutions, getStatuses, getSubTypes } from '../../../services/quotations.services';
import { getCoordinators, getOperationTypes } from '../../../services/operations.services';
import { useSelector } from 'react-redux';
import Table from '../../../components/Table/Table';
import { useTranslation } from "react-i18next";

const Container = styled("div", {
    background: '$neutral_50',
    margin: '20px',
    padding: '40px 50px',
    borderRadius: '8px',
    boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)',
    ['h2']: {
        color: '$neutral_400',
        fontWeight: '$semibold',
        margin: '15px',
        fontSize: '20px',
    },
});


export default function Manage() {
    const token = useSelector(state => state.user.token)
    const { t: translate } = useTranslation("Admin");

    const columns = [
        {name:translate("name"), uid:'name', type:'text'},
        {name:translate("some_values"), uid:'values', type:'text'},
        {name:translate("amount"), uid:'amount', type:'textCenter'},
        {name:translate("action"), uid:'admin', type:'button'}
    ]

    const [items, setItems] = useState([])

    const [openModal, setOpenModal] = useState(false);
    const [title, setTitle] = useState('');
    const [endpoint, setEndpoint] = useState('');
    const [inputs, setInputs] = useState([]);

    const handleButtonClick = (button) => {
        console.log("button", button)
        setTitle(button.text);
        setEndpoint(button.endpoint);
        setInputs(button.inputs);
        setOpenModal(true);
    };

    function getData(){
        const asyncTasks = [getSubTypes(token),getSolutions(token),getOffices(token),getStatuses(token),getReasons(token),getOperationTypes(token),getCoordinators(token)]
        Promise.all(asyncTasks).then(async res => {
            const subTypesData = await res[0].json();
            const solutionsData = await res[1].json();
            const officesData = await res[2].json();
            const statusesData = await res[3].json();
            const reasonsData = await res[4].json();
            const operationTypesData = await res[5].json();
            const coordinatorsData = await res[6].json();

            const data = [
                { text: translate("subtypes"), data:subTypesData, endpoint: 'quotation/subtypes/', inputs: ['name'] },
                { text: translate("services"),data:solutionsData, endpoint: 'quotation/solutions/', inputs: ['name', 'service_group'] },
                { text: translate("offices"), data:officesData, endpoint:'quotation/offices/', inputs: ['name'] },
                { text: translate("statuses"), data:statusesData, endpoint:'quotation/statuses/', inputs: ['name'] },
                { text: translate("reasons"), data:reasonsData, endpoint:'quotation/reasons/', inputs: ['name'] },
                { text: translate("operations_type"), data:operationTypesData, endpoint: 'operations/operation_types/', inputs: ['name'] },
                { text: translate("coordinators"), data:coordinatorsData, endpoint: 'operations/coordinators/', inputs: ['user'] },
            ]

            const tableData = data.map((item,index) => {
                const values =  item.data?.slice(0,3).map(e => {
                    return e.name
                })
                const valuesString = values.length? values.join(", ") : "Ninguno"
                return {
                    id:index,
                    key:index,
                    name : item.text,
                    values : valuesString,
                    amount: item.data.length,
                    admin : {
                        text: translate("administrate"),
                        handler : (e) => handleButtonClick({
                            ...item,
                        })
                    }
                }
            })
            setItems(tableData)
        })
    }

    useEffect(()=>{
        if (!openModal){
            getData()
        }
    },[openModal])
    // console.log("items :",items)
    return (
        <Container>
            <PageTitle>{translate("administrate")}</PageTitle>
            <div style={{marginTop:'30px'}}>
            <Table columns={columns} items={items} hidePagination/>
            </div>
            
            <ManageModal
                open={openModal}
                setOpen={setOpenModal}
                title={title}
                inputs={inputs}
                endpoint={endpoint}
            />
        </Container>
    );
}