import React, { useEffect, useState } from 'react'
import TablePrel from '../../../components/Table/TablePrel'
import Table from '../../../components/Table/Table'
import { styled } from '@nextui-org/react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const columns = [
  { name: 'Acciones', uid: 'actions', type: 'action' },
  { name: 'Proveedor', uid: 'provider', type: 'text' },
  //{ name: 'Grupo de servicio', uid: 'service_group', type: 'text' },
  { name: 'Servicio', uid: 'service', type: 'text' },
  { name: 'Und', uid: 'unit', type: 'text' },
  { name: 'Factor', uid: 'factor', type: 'text' },
  { name: 'Venta USD', uid: 'sale_in_usd', type: 'text' },
  { name: 'Venta', uid: 'sale_price_unit', type: 'text' },
  { name: 'Moneda', uid: 'sale_currency', type: 'text' },
  { name: 'trm', uid: 'sale_trm', type: 'text' },
  { name: 'Compra', uid: 'purchase_price', type: 'text' },
  //{ name: 'Moneda compra', uid: 'purchase_currency', type: 'text' },
  //{ name: 'trm compra', uid: 'purchase_trm', type: 'text' },
  { name: 'Profit (USD)', uid: 'profit', type: 'text' },
];
const columnsAir = [
  { name: 'ID', uid: 'id', type: 'text' },
  { name: 'Air Rate', uid: 'air_rate', type: 'text' },
  { name: 'Currency', uid: 'currency', type: 'text' },
  { name: 'Frequency', uid: 'frequency', type: 'text' },
  { name: 'Min', uid: 'min', type: 'text' },
  { name: 'Price Per Kg', uid: 'price_per_kg', type: 'text' },
  { name: 'Total Freight (USD)', uid: 'total_freight_usd', type: 'text' },
  { name: 'Total Fuel Surcharge (USD)', uid: 'total_fuel_surcharge_usd', type: 'text' },
  { name: 'Total (USD)', uid: 'total_usd', type: 'text' },
  { name: 'Fuel Surcharge Min', uid: 'fuel_surcharge_min', type: 'text' },
  { name: 'Fuel Surcharge KG', uid: 'fuel_surcharge_kg', type: 'text' },
];


const TotalsContainer = styled("div", {
  display: 'flex',
  marginTop: '20px',
  justifyContent: 'space-around',
  gap: '20px',
  ['div']: {
    width: '100%',
    backgroundColor: '$primary_300',
    color: '$neutral_50',
    padding: '10px',
    borderRadius: '8px',
    boxShadow: '$elevation_1'
  }
})

export default function PreLiquidationTable({ tableData, currentQuote, radication, setOpenModalParticipants }) {

  const navigate = useNavigate()
  const { t: translate } = useTranslation("ManageQuotes");

  const actions = [
    {
      name: 'Seleccionar',
      icon: 'View',
      handle: (item) => {
        setOpenModalParticipants(true)
      }
    },
    {
      name: 'Detalles',
      icon: 'View',
      handle: (item) => {
        navigate(`/manageQuotes/preliquidation-details/?preliquidation=${item.id}&radication=${radication}`)
      }
    },
  ]

  let salesTotalUSD = 0;
  let purchaseTotalUSD = 0;


  tableData.forEach(item => {
    item.sale_price_unit = item.sale_price/item.factor
    salesTotalUSD += item.sale_in_usd
    if (item.purchase_price) {
      purchaseTotalUSD += item.purchase_price / (item.sale_trm || 1);
    }
  });
  
  const groups = {}
  const groupAir = {}

  tableData.forEach((item) => {
    let group = item.service_group

    groupAir['Air cargo']= tableData[0]?.air_cargo? [tableData[0]?.air_cargo] : null
    if (groups[group]) {
      groups[group].push(item)
    } else {
      groups[group] = [item]
    }
  })

  // salesTotalUSD+=groupAir['Air cargo']?groupAir['Air cargo'][0]?groupAir['Air cargo'][0].total_usd:0:0
  salesTotalUSD += groupAir['Air cargo'] ? groupAir['Air cargo'][0]?.total_usd  : 0
  const profitUSD = salesTotalUSD - purchaseTotalUSD
 
  return (
    <div style={{ backgroundColor: '#EBEDF0', padding: '20px', borderRadius: '8px' }}>
      {
        currentQuote ? tableData.length > 0 ?
          <div>
            <TablePrel columns={columns} items={groups} actions={actions} />
            {groupAir["Air cargo"]?<TablePrel columns={columnsAir} items={groupAir} actions={actions} />:null}
          </div>
          : <p>{translate("no_liquidations")}</p> : <p>{translate("select_liquidation")}</p>
      }
      {
        tableData.length > 0 && <TotalsContainer>
          <div>
            <p>Total de ventas</p>
            <h3>{salesTotalUSD?.toFixed(2)}</h3>
          </div>
          <div>
            <p>Total de compras</p>
            <h3>{purchaseTotalUSD?.toFixed(2)}</h3>
          </div>
          <div>
            <p>Profit</p>
            <h3 style={{
              color:
                profitUSD > 350 ? 'white' : profitUSD > 50 ? '#FFC300 ' : '#ff2222'
            }}>{profitUSD?.toFixed(2)}
            </h3>
          </div>
        </TotalsContainer>
      }
    </div>
  )
}