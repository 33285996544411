import React, { useState, useEffect } from 'react';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Tooltip,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import {
  CheckCircle,
  Cancel,
  AccountBalance,
  CreditScore,
  CalendarToday,
  PeopleAlt,
  Info,
} from '@mui/icons-material';
import {
  PieChart,
  Pie,
  Tooltip as RechartsTooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  AreaChart,
  Area,
} from 'recharts';

const CreditOffersDashboard = () => {
        const [offers, setOffers] = useState([
            {
            id: 1,
            provider: 'Global Finance Corp',
            type: 'Préstamo Comercial',
            amount: 500000,
            interestRate: 6.75,
            term: 48,
            monthlyPayment: 11756.78,
            minCreditScore: 710,
            includesInsurance: true,
            gracePeriod: 2,
            collateralRequired: true,
            useOfFunds: 'Expansión de almacenes en el extranjero',
            status: 'Aprobado',
            currentInstallment: 18,
            totalAmountPaid: 211621.24,
            amountInArrears: 0,
            },
           
        ]);
		
  const [totalOffers, setTotalOffers] = useState(offers.length);
  const [approvedOffers, setApprovedOffers] = useState(
    offers.filter((offer) => offer.status === 'Aprobado').length
  );
  const [rejectedOffers, setRejectedOffers] = useState(
    offers.filter((offer) => offer.status === 'Rechazado').length
  );
  const [delinquentOffers, setDelinquentOffers] = useState(
    offers.filter((offer) => offer.status === 'En mora').length
  );
  const [totalAmountPaid, setTotalAmountPaid] = useState(
    offers.reduce((acc, offer) => acc + offer.totalAmountPaid, 0)
  );
  const [totalAmountInArrears, setTotalAmountInArrears] = useState(
    offers.reduce((acc, offer) => acc + offer.amountInArrears, 0)
  );
  const [totalInterestPaid, setTotalInterestPaid] = useState(
    offers.reduce((acc, offer) => acc + (offer.monthlyPayment * offer.currentInstallment) - offer.totalAmountPaid, 0)
  );

  const [open, setOpen] = useState(false);
  const [newOffer, setNewOffer] = useState({
    provider: '',
    type: '',
    amount: '',
    interestRate: '',
    term: '',
    monthlyPayment: '',
    minCreditScore: '',
    includesInsurance: false,
    gracePeriod: '',
    collateralRequired: false,
    useOfFunds: '',
    status: 'Pendiente',
    currentInstallment: 0,
    totalAmountPaid: 0,
    amountInArrears: 0,
  });

  useEffect(() => {
    // Fetch the actual credit offers data from an API or database
    // and update the state accordingly
    // For now, we'll use the mock data
    fetchCreditOffers();
  }, []);

  const fetchCreditOffers = () => {
    // Simulating fetching data from an API
    setOffers([
    
    ]);
    updateDashboardStats([
        {
            id: 1,
            provider: 'Global Finance Corp',
            type: 'Préstamo Comercial',
            amount: 500000,
            interestRate: 6.75,
            term: 48,
            monthlyPayment: 11756.78,
            minCreditScore: 710,
            includesInsurance: true,
            gracePeriod: 2,
            collateralRequired: true,
            useOfFunds: 'Expansión de almacenes en el extranjero',
            status: 'Aprobado',
            currentInstallment: 18,
            totalAmountPaid: 211621.24,
            amountInArrears: 0,
          },
          {
            id: 2,
            provider: 'International Trade Bank',
            type: 'Línea de Crédito Comercial',
            amount: 750000,
            interestRate: 7.1,
            term: 24,
            monthlyPayment: 33750.0,
            drawdownLimit: 250000,
            minCreditScore: 720,
            includesInsurance: false,
            gracePeriod: 0,
            collateralRequired: true,
            useOfFunds: 'Compra de inventario y materias primas',
            status: 'Aprobado',
            currentInstallment: 12,
            totalAmountPaid: 405000.0,
            amountInArrears: 0,
          },
          {
            id: 3,
            provider: 'Mercado Global Financiero',
            type: 'Préstamo para Innovación Tecnológica',
            amount: 600000,
            interestRate: 5.8,
            term: 60,
            monthlyPayment: 11550.23,
            minCreditScore: 680,
            includesInsurance: true,
            gracePeriod: 5,
            collateralRequired: false,
            useOfFunds: 'Implementación de un nuevo sistema de logística',
            status: 'En mora',
            currentInstallment: 30,
            totalAmountPaid: 346506.9,
            amountInArrears: 57751.15,
          },
          {
            id: 4,
            provider: 'Export & Import Financials',
            type: 'Préstamo Personal',
            amount: 80000,
            interestRate: 7.2,
            term: 36,
            monthlyPayment: 2475.35,
            minCreditScore: 650,
            includesInsurance: false,
            gracePeriod: 0,
            collateralRequired: false,
            useOfFunds: 'Capacitación internacional',
            status: 'Aprobado',
            currentInstallment: 20,
            totalAmountPaid: 49507.0,
            amountInArrears: 0,
          },
          {
            id: 5,
            provider: 'TransGlobal Mortgage',
            type: 'Hipoteca',
            amount: 350000,
            interestRate: 4.5,
            term: 180,
            monthlyPayment: 2678.56,
            minCreditScore: 740,
            includesInsurance: true,
            gracePeriod: 10,
            collateralRequired: true,
            useOfFunds: 'Adquisición de un almacén en el puerto',
            status: 'Aprobado',
            currentInstallment: 40,
            totalAmountPaid: 107142.4,
            amountInArrears: 0,
          },
          {
            id: 6,
            provider: 'Global Credit Line',
            type: 'Línea de Crédito Empresarial',
            amount: 250000,
            interestRate: 8.2,
            term: 48,
            monthlyPayment: 6560.12,
            minCreditScore: 700,
            includesInsurance: false,
            gracePeriod: 1,
            collateralRequired: true,
            useOfFunds: 'Mantenimiento de equipos de exportación',
            status: 'Rechazado',
            currentInstallment: 0,
            totalAmountPaid: 0,
            amountInArrears: 0,
          },
          {
            id: 7,
            provider: 'ComercialFinance Ltd.',
            type: 'Préstamo de Capital de Trabajo',
            amount: 450000,
            interestRate: 6.9,
            term: 36,
            monthlyPayment: 13922.56,
            minCreditScore: 730,
            includesInsurance: true,
            gracePeriod: 4,
            collateralRequired: true,
            useOfFunds: 'Financiación de exportaciones',
            status: 'Aprobado',
            currentInstallment: 14,
            totalAmountPaid: 194915.84,
            amountInArrears: 0,
          },
          {
            id: 8,
            provider: 'Trade & Finance Solutions',
            type: 'Préstamo para Expansión de Mercado',
            amount: 900000,
            interestRate: 6.2,
            term: 60,
            monthlyPayment: 17456.78,
            minCreditScore: 750,
            includesInsurance: true,
            gracePeriod: 6,
            collateralRequired: true,
            useOfFunds: 'Apertura de nuevos mercados internacionales',
            status: 'Aprobado',
            currentInstallment: 22,
            totalAmountPaid: 383048.16,
            amountInArrears: 0,
          },
          {
            id: 9,
            provider: 'Global Trade Credit',
            type: 'Línea de Crédito para Exportaciones',
            amount: 500000,
            interestRate: 7.8,
            term: 36,
            monthlyPayment: 16250.0,
            drawdownLimit: 300000,
            minCreditScore: 700,
            includesInsurance: false,
            gracePeriod: 0,
            collateralRequired: true,
            useOfFunds: 'Apoyo en exportaciones de gran volumen',
            status: 'Aprobado',
            currentInstallment: 15,
            totalAmountPaid: 243750.0,
            amountInArrears: 0,
          },
    ]);
  };

  const updateDashboardStats = (offers) => {
    setOffers(offers);
    setTotalOffers(offers.length);
    setApprovedOffers(offers.filter((offer) => offer.status === 'Aprobado').length);
    setRejectedOffers(offers.filter((offer) => offer.status === 'Rechazado').length);
    setDelinquentOffers(offers.filter((offer) => offer.status === 'En mora').length);
    setTotalAmountPaid(offers.reduce((acc, offer) => acc + offer.totalAmountPaid, 0));
    setTotalAmountInArrears(offers.reduce((acc, offer) => acc + offer.amountInArrears, 0));
    setTotalInterestPaid(
      offers.reduce((acc, offer) => acc + (offer.monthlyPayment * offer.currentInstallment) - offer.totalAmountPaid, 0)
    );
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    // Save the new offer to the server
    // and update the offers state
    saveNewOffer(newOffer);
    handleClose();
  };

  const saveNewOffer = (offer) => {
    // Simulating saving the new offer
    const newOfferWithId = { ...offer, id: offers.length + 1 };
    updateDashboardStats([...offers, newOfferWithId]);
        updateDashboardStats([...offers, newOfferWithId]);
  };

  const handleViewDetails = (offer) => {
    // Open a dialog or navigate to a details page
    console.log("Viewing details for offer:", offer);
  };

  const handleContactBank = (offer) => {
    // Open a dialog or navigate to a contact form
    console.log("Contacting bank for offer:", offer);
  };

  return (
    <div className="p-4">
      <Typography variant="h4" component="h1" gutterBottom className="text-blue-800 font-bold">
        Dashboard de Solicitudes de Crédito
      </Typography>

      <Grid container spacing={4} className="mb-8">
        <Grid item xs={12} sm={6} md={3}>
          <Card elevation={3} className="h-full">
            <CardContent>
              <div className="flex justify-between items-center mb-2">
                <Typography variant="h6" component="div" className="font-bold">
                  Total de Solicitudes
                </Typography>
                <PeopleAlt className="text-blue-500" />
              </div>
              <Typography variant="h4" className="font-bold text-green-600">
                {totalOffers}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card elevation={3} className="h-full">
            <CardContent>
              <div className="flex justify-between items-center mb-2">
                <Typography variant="h6" component="div" className="font-bold">
                  Solicitudes Aprobadas
                </Typography>
                <CheckCircle className="text-green-500" />
              </div>
              <Typography variant="h4" className="font-bold text-green-600">
                {approvedOffers}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card elevation={3} className="h-full">
            <CardContent>
              <div className="flex justify-between items-center mb-2">
                <Typography variant="h6" component="div" className="font-bold">
                  Solicitudes Rechazadas
                </Typography>
                <Cancel className="text-red-500" />
              </div>
              <Typography variant="h4" className="font-bold text-red-600">
                {rejectedOffers}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card elevation={3} className="h-full">
            <CardContent>
              <div className="flex justify-between items-center mb-2">
                <Typography variant="h6" component="div" className="font-bold">
                  Solicitudes en Mora
                </Typography>
                <AccountBalance className="text-orange-500" />
              </div>
              <Typography variant="h4" className="font-bold text-orange-600">
                {delinquentOffers}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
{/* 
      <Button variant="contained" color="primary" onClick={handleClickOpen} className="mb-4">
        Solicitar Nuevo Préstamo
      </Button> */}
<p><br></br></p>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Solicitar Nuevo Préstamo</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Complete la información para solicitar un nuevo préstamo.
          </DialogContentText>
          {/* Dialog form fields */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Solicitar
          </Button>
        </DialogActions>
      </Dialog>

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card elevation={3}>
            <CardContent>
              <Typography variant="h5" className="mb-4 font-bold">
                Solicitudes de Crédito
              </Typography>
              <Table>
                <TableHead>
                  <TableRow className="bg-gray-100">
                    <TableCell className="font-bold">Proveedor</TableCell>
                    <TableCell className="font-bold">Tipo de Préstamo</TableCell>
                    <TableCell className="font-bold text-right">Monto</TableCell>
                    <TableCell className="font-bold text-right">Tasa de Interés</TableCell>
                    <TableCell className="font-bold text-right">Plazo</TableCell>
                    <TableCell className="font-bold text-right">Cuota Mensual</TableCell>
                    <TableCell className="font-bold">Estado</TableCell>
                    <TableCell className="font-bold text-right">Cuota Actual</TableCell>
                    <TableCell className="font-bold text-right">Total Pagado</TableCell>
                    <TableCell className="font-bold text-right">Monto en Mora</TableCell>
                    <TableCell className="font-bold">Acciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {offers.map((offer) => (
                    <TableRow key={offer.id} className="border-b border-gray-200">
                      <TableCell className="py-4">{offer.provider}</TableCell>
                      <TableCell className="py-4">{offer.type}</TableCell>
                      <TableCell className="py-4 text-right">${offer.amount.toLocaleString()}</TableCell>
                      <TableCell className="py-4 text-right">{offer.interestRate}%</TableCell>
                      <TableCell className="py-4 text-right">{offer.term} meses</TableCell>
                      <TableCell className="py-4 text-right">${offer.monthlyPayment.toLocaleString()}</TableCell>
                      <TableCell className="py-4">
                        <Chip
                          label={offer.status}
                          color={
                            offer.status === 'Aprobado'
                              ? 'success'
                              : offer.status === 'Rechazado'
                              ? 'error'
                              : 'warning'
                          }
                        />
                      </TableCell>
                      <TableCell className="py-4 text-right">{offer.currentInstallment}</TableCell>
                      <TableCell className="py-4 text-right">${offer.totalAmountPaid.toLocaleString()}</TableCell>
                      <TableCell className="py-4 text-right">${offer.amountInArrears.toLocaleString()}</TableCell>
                      <TableCell className="py-4">
                        <Tooltip title="Ver Detalles">
                          <IconButton color="primary" size="small" onClick={() => handleViewDetails(offer)}>
                            <Info />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Contactar al Banco">
                          <IconButton color="primary" size="small" onClick={() => handleContactBank(offer)}>
                            <CreditScore />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <p><br></br></p>

      <Grid container spacing={4} className="mt-8">
        <Grid item xs={12} md={6}>
          <Card elevation={3}>
            <CardContent>
              <Typography variant="h5" className="mb-4 font-bold">
                Distribución de Solicitudes por Estado
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={[
                      { name: 'Aprobado', value: approvedOffers, fill: '#4CAF50' },
                      { name: 'Rechazado', value: rejectedOffers, fill: '#F44336' },
                      { name: 'En mora', value: delinquentOffers, fill: '#FF9800' },
                    ]}
                    nameKey="name"
                    valueKey="value"
                    label
                  />
                  <RechartsTooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card elevation={3}>
            <CardContent>
              <Typography variant="h5" className="mb-4 font-bold">
                Total Pagado vs. Monto en Mora
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={[{ name: 'Monto', 'Total Pagado': totalAmountPaid, 'Monto en Mora': totalAmountInArrears }]}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <RechartsTooltip />
                  <Legend />
                  <Bar dataKey="Total Pagado" fill="#4CAF50" />
                  <Bar dataKey="Monto en Mora" fill="#F44336" />
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <p><br></br></p>

      <Grid container spacing={4} className="mt-8">
        <Grid item xs={12} md={6}>
          <Card elevation={3}>
            <CardContent>
              <Typography variant="h5" className="mb-4 font-bold">
                Intereses Pagados vs. Principal Pagado
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <AreaChart data={[{ name: 'Monto', 'Intereses Pagados': totalInterestPaid, 'Principal Pagado': totalAmountPaid }]}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <RechartsTooltip />
                  <Legend />
                  <Area type="monotone" dataKey="Intereses Pagados" stroke="#FF9800" fill="#FF9800" />
                  <Area type="monotone" dataKey="Principal Pagado" stroke="#4CAF50" fill="#4CAF50" />
                </AreaChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card elevation={3}>
            <CardContent>
              <Typography variant="h5" className="mb-4 font-bold">
                Solicitudes en Mora por Proveedor
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={offers.filter(offer => offer.status === 'En mora').map(offer => ({ provider: offer.provider, 'Monto en Mora': offer.amountInArrears }))}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="provider" />
                  <YAxis />
                  <RechartsTooltip />
                  <Legend />
                  <Bar dataKey="Monto en Mora" fill="#F44336" />
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default CreditOffersDashboard;