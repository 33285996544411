import React from "react";
import { Typography } from "@mui/material";
import {
  RadarChart,
  Radar,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

function PerformanceRadarChart({ data }) {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Performance Metrics
      </Typography>
      <ResponsiveContainer width="100%" height={300}>
        <RadarChart data={data}>
          <PolarGrid />
          <PolarAngleAxis
            dataKey="category"
            tick={{ fontSize: 12 }} // Ajuste del tamaño de la fuente
          />
          <PolarRadiusAxis angle={30} domain={[0, 100]} />
          <Radar
            name="Score"
            dataKey="score"
            stroke="#8884d8"
            fill="#8884d8"
            fillOpacity={0.6}
          />
          <Tooltip />
        </RadarChart>
      </ResponsiveContainer>
    </>
  );
}

export default PerformanceRadarChart;
