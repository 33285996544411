import { useEffect, useState } from 'react';
import { getUserAuth } from '../services/auth.services';
import { useSelector } from 'react-redux';

const useUserRoles = () => {
    const [userRoles, setUserRoles] = useState([]);
    const token = useSelector((state) => state.user.token)

    const fetchUserRoles = async () => {
        const data = await getUserAuth(token);
        setUserRoles([data?.user?.role_id]);
    };

    useEffect(() => {
        token !== '' && fetchUserRoles();
    }, [token]);

    return userRoles;
};

export default useUserRoles;
