import React, { useState, useEffect } from 'react'
import { Modal, styled } from '@nextui-org/react';
import Button from '../../../components/Button/Button';
import { approveQuotation, finishQuotation } from '../../../services/quotations.services';
import toast from '../../../components/Toast/ToastTypes';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import DetailsPdf from '../quoteDetails/DetailsPdf';
import { getRoles } from '../../../services/operations.services';


const StyledFormModal = styled(Modal, {
    padding: '1rem',
    minWidth:'80%',
    color: '$primary_500 !important',
})


export default function FinishQuotationModal({ open, setOpen, quoteData, getData }) {
    const token = useSelector(state => state.user.token)
    const [disableButton, setDisableButton] = useState(false)
    const [sendToClient,setSendToClient] = useState(false)
    const user = useSelector(state => state.user)
    const [userRoles, setUserRoles] = useState([]);
    useEffect(() => {
        getRoles(user?.token).then(async res => {
            const rolesData = await res.json();
            const userRoles = user.roles.map(rolId => {
                const rol = rolesData.find(rol => rol.id === rolId);
                return rol?.name;
            });
            setUserRoles(userRoles);
            console.log("rolesData :", rolesData);
        });
    }, [open]);

    const handleFinish = (base64data) => {
        if (quoteData?.preliquidation_ids?.length > 0) {
            finishQuotation(token,quoteData?.id, base64data).then(() => {
                toast("success", "Cotización finalizada")
                setOpen(false)
                if (getData){
                    getData()
                }
            }).catch(err => console.log("ERR :", err))
        }else{
            toast("error", "No hay ninguna preliquidación")
        }
    }
    const isAllow = userRoles.includes('Admin') || userRoles.includes('Pricing') || userRoles.includes('Operaciones');
    const handleFinishAndApprove = () => {
        if (quoteData?.preliquidation_ids.length > 0) {
            setDisableButton(true)
            finishQuotation(token,quoteData?.id).then(() => {
                toast("success", "Cotización finalizada")
                approveQuotation(quoteData?.id, true, token).then(() => {
                    toast('success', 'Cotización aprobada')
                    if (getData){
                        getData()
                    }
                    setOpen(false)
                    setDisableButton(false)
                    setOpen(false)
                })
            }).catch(err => console.log("ERR :", err))
        }else{
            toast("error", "No hay ninguna preliquidación")
        }

    }

    return (
        <StyledFormModal
            closeButton
            aria-label="modal-title"
            open={open}
            onClose={() => setOpen(false)}
            width="fit-content"
        >
            <Modal.Body>
                <h3><b>{quoteData?.radication}</b></h3>
                <p>Desea finalizar la liquidación de radicado {quoteData?.radication}?</p>
                <p>Puedes enviar la oferta al cliente para su aprobación o aprobarla automaticamente</p>
                <div>
                <DetailsPdf radicationPrio={quoteData?.radication} id={quoteData?.id} showPurchase={false}/>
                </div>
                
                {sendToClient && <DetailsPdf showPDF={false} showPurchase={false} radicationPrio={quoteData?.radication} id={quoteData?.id} sendBase64={handleFinish}/>}
            </Modal.Body>
            <Modal.Footer>
                <Button text='Cancelar'
                    bold
                    outline
                    handler={() => setOpen(false)}
                />
                
                {isAllow && (
                    <Button text='Finalizar y aprobar'
                        bold
                        bgColor={'primary_300'}
                        textColor='neutral_50'
                        disabled={!quoteData?.id || disableButton}
                        handler={() => handleFinishAndApprove()}
                        endIcon={{name:'CheckModal', color:'white'}}
                    />
                )}
                <Button text='Finalizar y enviar al cliente'
                    bold
                    bgColor={'primary_300'}
                    textColor='neutral_50'
                    disabled={!quoteData?.id}
                    handler={() => setSendToClient(true)}
                    endIcon={{name:'Send', color:'white'}}
                />
            </Modal.Footer>
        </StyledFormModal>
    );
}
