import React, { useEffect, useState } from 'react'
import { getLeadTimes } from '../../../services/kpis.services';
import { useSelector } from 'react-redux';
import Table from '../../../components/Table/Table';
import { styled } from '@nextui-org/react';
import UltraHorizontalBarChart from '../../../components/Graphics/HorizontalChart/UltraHorizontalBarChart';
import ColoredPieChart from '../../../components/Graphics/ColoredPieChart';

const ChartContainer = styled("div", {
  background: 'white',
  borderRadius: '8px',
  padding: '20px',
  width: '100%',
  boxShadow:'$elevation_1',
})

const CustomColumn = styled("div",{
  width:'100%',
  display:'flex',
  padding:' 0 10px',
  flexDirection:'column',
  'b':{margin:'auto'},
  'div':{
    width:'100%',
    display:'flex',
    justifyContent:'space-between',
  }
})

function customColumn(name){
  return <CustomColumn>
    <b>{name}</b>
    <div>
      <span>min</span>
      <span>avg</span>
      <span>max</span>
    </div>
  </CustomColumn>
}
function customCell(min,avg,max){
  return <div style={{display:'flex',justifyContent:'space-between', padding:'0 10px'}}>
      <span style={{ color: '#006400' }}>{min}</span> 
      <span style={{ color: '#1E90FF' }}>{avg}</span> 
      <span style={{ color: '#B22222' }}>{max}</span> 
    </div>
}

const columns = [
  {name:'Route', uid:'route', type:'text'},
  {name:'Qty', uid:'count', type:'textCenter'},
  {name:customColumn('Port to port'), uid:'port_to_port', type:'text'},
  {name:customColumn('Door to Port'), uid:'door_to_port', type:'text'},
  {name:customColumn('Port to Door'), uid:'port_to_door', type:'text'},
  {name:customColumn('Customs'), uid:'customs', type:'text'},
]

export default function LeadTimesDash() {
  const token = useSelector(state=>state.user.token)

  const [items, setItems] = useState([])
  const [leadTimes, setLeadTimes] = useState([])

  useEffect(()=>{
    getLeadTimes(token).then(async res => {
      const leadTimesData = await res.json();

      if (leadTimesData){
        setLeadTimes(leadTimesData)
        const tableData = Object.keys(leadTimesData).map((key,i) => {
          const item = leadTimesData[key]
          return {
            key:i,
            count:item.count,
            route:key,
            port_to_port: customCell(item.port_to_port.min,item.port_to_port.avg, item.port_to_port.max),
            door_to_port: customCell(item.door_to_port.min,item.door_to_port.avg, item.door_to_port.max),
            port_to_door: customCell(item.port_to_door.min,item.port_to_door.avg, item.port_to_door.max),
            customs:      customCell(item.customs.min,item.customs.avg, item.customs.max),
          }
        })
        setItems(tableData)
      }
    })
  },[])

  console.log("leadTimes :",leadTimes)
  const routeTypeData = leadTimes

  return (
    <div>
      <Table columns={columns} items={items}/>
      {/* <ChartContainer>
        <ColoredPieChart/>
      </ChartContainer>
      <ChartContainer>
        <UltraHorizontalBarChart 
          data={data}
          barDataKeys={[
              { key: 'expo', color: '#EB9D3A' },
              { key: 'impo', color: '#3D509A' },
          ]}
        />
      </ChartContainer> */}
    </div>
  )
}
