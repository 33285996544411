import React from 'react';
import { styled, Image } from '@nextui-org/react';
import PageTitle from '../../components/PageTitle/PageTitle';
import Card3 from '../../components/Cards/Card3/Card3';
import YoutubeEmbed from '../../components/Youtube/EmbedYoutube';
import TutorialsData from './TutorialsData';

const StyledContainer = styled("div", {
  display: 'grid',
  gridTemplateColumns: '1fr', 
  gap: '40px',
  '@md': { 
    gridTemplateColumns: 'repeat(4, 1fr)',
  }
});

const StyledMain = styled("div", {
  backgroundColor: '$neutral_50',
  borderRadius: '8px',
  padding: '20px',
  margin: '20px',
  gridColumn: 'span 4',
  ['img']: {
    margin: '20px 0',
    borderRadius: '8px',
    maxWidth: '100%',
  },
  '@md': { 
    gridColumn: 'span 3',
    padding: '50px',
    ['img']: {
      margin: '40px 0',
    }
  },
  ['h3']: {
    margin: '40px 0 20px', 
  },
  ['p']: {
    marginBottom: '20px',
  }
});

const OtherTutorials = styled("div", {
  backgroundColor: '$neutral_50',
  padding: '3px',
  margin: '3px 0',
  display: 'grid',
  gap: '3px', 
  gridTemplateColumns: '1fr', 
  '@md': { 
    gridTemplateColumns: 'repeat(2, 1fr)', 
  },
  '@lg': {
    gridTemplateColumns: 'repeat(3, 1fr)', 
  }
});

const tutorials = TutorialsData;



export default function ExportToChina() {
    return (
        <StyledContainer>
            <StyledMain>
                <PageTitle>¿Cómo exportar a China?</PageTitle>
                <YoutubeEmbed embedId="u31qwQUeGuM" />
                <h3>Consejos esenciales para la exportación a China</h3>
                <p>
                    Explora el fascinante proceso de exportación a China con nuestro video informativo. Obtén valiosos consejos y directrices sobre cómo llevar a cabo con éxito tus operaciones de exportación hacia este mercado tan importante.
                </p>
                <p>
                    Desde entender las regulaciones comerciales hasta optimizar tu logística, abordaremos aspectos cruciales para asegurar que tu proceso de exportación a China sea eficiente y exitoso. ¡Prepárate para expandir tus horizontes comerciales y conquistar el mercado chino!
                </p>
            </StyledMain>
            <OtherTutorials>
                <PageTitle>Explorar mas</PageTitle>
                {
                    tutorials.map(((tutorial, i) => {
                        return <Card3 key={i} title={tutorial.title} image={tutorial.image} to={tutorial.url} iconVideo />
                    }))
                }
            </OtherTutorials>
        </StyledContainer>
    )
}
