import { styled } from '@nextui-org/react'
import React, { useEffect, useState } from 'react'
import Icon from '../../components/Icon/Icon'
import theme from '../../theme'
import { InitMap } from '../../functions/mapFunctions'
import { Step, StepConnector, StepLabel, Stepper } from '@mui/material'
import { format } from 'date-fns'
import { usePDF } from 'react-to-pdf'
import Button from '../../components/Button/Button'
import { getServiceSplitRadication } from '../../services/quotation.services'
import { useSearchParams } from 'react-router-dom'
import { getOperationsSplitRadication, getOperationStatuses, getSubStatuses, getSubStatusesOperations } from '../../services/operations.services'
import { useSelector } from 'react-redux'


const Container = styled("div",{
    background:'white',
    margin:'20px 100px',
    padding:'30px',
    borderRadius:'8px',
    boxShadow:'$elevation_1',
    display:'flex',
    flexDirection:'column',
    gap:'10px',
    '.general':{
        display:'grid',
        gridTemplateColumns:'auto auto',
        gap:'30px',
        '.lines':{
            // border:'1px solid black',
            display:'flex',
            flexDirection:'column',
        },
        '.line':{
            display:'flex',
            justifyContent:'space-between',
            borderBottom:'1px solid grey',
            padding:'5px 20px',
            '.data':{
                fontWeight:'bold'
            }
        }
    },
    '.total':{
        background:'$primary_300',
        borderRadius:'8px',
        marginTop:'20px',
        color:'white',
        width:'100%',
        padding:'20px',
        display:'flex',
        justifyContent:'right',
    }
})
const ShipmentContainer = styled("div", {
    width:'100%',
    gridColumn:'span 2',
    display:'flex',
    justifyContent:'space-around',
    alignItems:'center',
    background:theme.colors.primary_300.value,
    color:'white',
    padding:'20px',
    marginBottom:'25px',
    borderRadius:'6px',
    'h3':{margin:0, fontSize:'20px'},
    'span':{fontSize:'12px'},
    "@media(max-width:1000px)":{
        width:'90%',
        margin:'auto',
        padding:'5px',
        'h3':{margin:0, fontSize:'9px'},
        'span':{fontSize:'10px'},
    }
})
const StyledMap = styled("div",{
    height:'400px',
    width:'100%',
    margin:'40px 0',
})
const StyledTable = styled("table",{
    width: '100%',
    borderCollapse: 'collapse',
    fontSize:'16px',
    marginTop: '20px',
    margin:'auto',
    'th, td': {
        'border': '1px solid #ddd',
        'padding': '4px',
        'text-align': 'left',
    },
    'th': {
        'background-color': '#f2f2f2',
    },
    'tr:nth-child(even)': {
        'background-color': '#f9f9f9',
    },
})

export default function OperationInfo() {
    const token = useSelector(state=>state.user.token)
    const [loadingPdf, setLoadingPdf] = useState(false)
    const { toPDF, targetRef } = usePDF({ filename: "operation_info.pdf" });
    const [searchParams] = useSearchParams();
    const radication = searchParams.get('radication')

    const [error,setError] = useState()
    const [data, setData] = useState({})

    function downloadPDF() {
        setLoadingPdf(true);
        toPDF().then(() => {
            setLoadingPdf(false);
        }).catch((err) => {
          console.log("error al descargar pdf :", err);
          setLoadingPdf(false);
        });
    }
   
    useEffect(()=>{
        const asyncTasks = [
            getServiceSplitRadication(radication,token),
            getOperationsSplitRadication(token,radication),
            getSubStatuses(token),
            getSubStatusesOperations(token),
            getOperationStatuses(token),
        ]
        Promise.all(asyncTasks).then(async res => {
            const serviceData = await res[0].json()
            //console.log("Here is the data:", JSON.stringify(serviceData, null, 2));
            const operationData = await res[1].json()
            //console.log("Here is the operationData:", JSON.stringify(operationData, null, 2));
         
            const subStatusesData = await res[2].json();
            const relations = await res[3].json();
            const operationStatusesData = await res[4].json();

            const service = serviceData.results[0]
            //console.log("HERE IS THE SERVICE DATA"+JSON.stringify(serviceData, null, 2))
            const operation = operationData.results[0]

            if (!service || !operation){
                setError("ERROR, Puede que la operación o servicio no exista")
                return
            }

            const client = service.users.find(user=>user?.user?.groups?.includes("Usuario") || user?.user?.groups?.includes("Cliente"))
            const commercial = service.users.find(user=>user?.user?.groups?.includes("Operations") ||
                user?.user?.groups?.includes("Asesores") ||
                user?.user?.groups?.includes("Pricing") ||
                user?.user?.groups?.includes("Admin"))

            const preliquidation = service?.preliquidations[0]
            let totalCost = 0
            preliquidation?.items?.forEach(item=>{totalCost += item?.total_in_usd})
            console.log("service :",service)

            const currentRelations = relations.filter((relation) => relation.operation === operation?.id);
            const sortedRelations = currentRelations.sort((a, b) => new Date(b.date) - new Date(a.date));
            const logData = sortedRelations.map((relation, index) => {
                const currentSubStatus = subStatusesData.find((sub) => sub.id === relation.operation_sub_status);
                return {
                id: relation.id,
                subStatus: currentSubStatus?.name,
                status: operationStatusesData.find((status) => status.id === currentSubStatus?.operation_status)?.name,
                date: relation?.date ? format(new Date(relation.date), "dd/MM/yyyy : HH:mm") : "",
                };
            });

            setData({
                client : client?.user,
                commercial : commercial?.user,
                purchaseOrder: service?.purchase_order.purchaseNumber,
                shipping: {...service.shipping, incoterm:operation?.business_term_name},
                loads:service.loads,
                serviceDetails : preliquidation.service_details,
                costs : preliquidation?.items,
                totalCost,
                logData,
            })
        }).catch((err)=>{
            setError("ERROR, Puede que la operación o servicio no exista")
        })

        InitMap([
            {"lat": -34.822222,"lng": -58.535833,"label": "Origen"},
            {"lat": 4.701594,"lng": -74.146947,"label": "Destino"}
        ],'rfqMap')
    },[radication])

    console.log("data :",data)

    return (
    error? <h3>{error}</h3>:
    <>
    <div style={{display:'flex', width:'100%', justifyContent:'end', marginTop:'40px',paddingRight:'100px'}}>
        <Button
            handler={() => downloadPDF()}
            text={loadingPdf ? "Cargando..." : "Descargar pdf"}
            endIcon={{ name: "Download" }}
            outline
            disabled={loadingPdf}
        />
    </div>
    <Container ref={targetRef}>
        <div style={{display:'flex',justifyContent:'space-between'}}>
            <h3>Operación {radication}</h3>
            <h3>{format(new Date(),"dd/MM/yyyy")}</h3>
            <h3>Purchase order : {data?.purchaseOrder ? String(data.purchaseOrder) : '-'}</h3>
        </div>
        <div className='general' style={{margin:'30px 0'}}>
            <div className="lines">
                <div className='line'>
                    <span className='label'>Cliente :</span>
                    <span className='data'>{data.client?.first_name} {data.client?.last_name}</span>
                </div>
                <div className='line'>
                    <span className='label'>Mail :</span>
                    <span className='data'>{data.client?.email}</span>
                </div>
                <div className='line'>
                    <span className='label'>Teléfono :</span>
                    <span className='data'>{data.client?.phone}</span>
                </div>
            </div>
            <div className="lines">
                <div className='line'>
                    <span className='label'>Comercial :</span>
                    <span className='data'>{data.commercial?.first_name} {data.commercial?.last_name}</span>
                </div>
                <div className='line'>
                    <span className='label'>Mail :</span>
                    <span className='data'>{data.commercial?.email}</span>
                </div>
                <div className='line'>
                    <span className='label'>Teléfono :</span>
                    <span className='data'>{data.commercial?.phone}</span>
                </div>
            </div>
        </div>
        <ShipmentContainer>
            <div>
                <h3>{data.shipping?.destination_boarding_terminal?.city?.name}, {data.shipping?.destination_boarding_terminal?.city?.country?.name}</h3>
                <span>{data.shipping?.pickup_address}</span>
            </div>
            <Icon name='ArrowRight' color='white'/>
            <div>
                <Icon name="Aereo" size="40" color="white"/>
                <h3>{data?.shipping?.incoterm}</h3>
            </div>
            <Icon name='ArrowRight' color='white'/>
            <div>
                <h3>{data.shipping?.origin_boarding_terminal?.city?.name}, {data.shipping?.origin_boarding_terminal?.city?.country?.name}</h3>
                <span>{data.shipping?.delivery_address}</span>
            </div>
        </ShipmentContainer>
        <div className='general'>
            <div className="lines">
                <div className='line'>
                    <span className='label'>Tipo de Movimiento :</span>
                    <span className='data'>{data.serviceDetails?.move_type}</span>
                </div>
                <div className='line'>
                    <span className='label'>Liner :</span>
                    <span className='data'>{data.serviceDetails?.airline}</span>
                </div>
                <div className='line'>
                    <span className='label'>Tipo de Carga :</span>
                    <span className='data'>{data.serviceDetails?.load_type}</span>
                </div>
                <div className='line'>
                    <span className='label'>Puerto de Descarga :</span>
                    <span className='data'>{data.serviceDetails?.unloading_point}</span>
                </div>
                <div className='line'>
                    <span className='label'>Incoterm :</span>
                    <span className='data'>{data?.shipping?.incoterm}</span>
                </div>
                <div className='line'>
                    <span className='label'>Puerto de Desembarque :</span>
                    <span className='data'>{data.serviceDetails?.loading_point}</span>
                </div>
            </div>
            <div className="lines">
                <div className='line'>
                    <span className='label'>Origen :</span>
                    <span className='data'>{data.shipping?.origin_boarding_terminal?.city?.name}, {data.shipping?.origin_boarding_terminal?.city?.country?.name}</span>
                </div>
                <div className='line'>
                    <span className='label'>Destino :</span>
                    <span className='data'>{data.shipping?.destination_boarding_terminal?.city?.name}, {data.shipping?.destination_boarding_terminal?.city?.country?.name}</span>
                </div>
                <div className='line'>
                    <span className='label'>Transbordo Vía :</span>
                    <span className='data'>{data.serviceDetails?.transshipment_via || '-'}</span>
                </div>
                <div className='line'>
                    <span className='label'>Direccion de origen :</span>
                    <span className='data'>{data.serviceDetails?.pickup_address}</span>
                </div>
                <div className='line'>
                    <span className='label'>Direccion de destino :</span>
                    <span className='data'>{data.serviceDetails?.delivery_address}</span>
                </div>
                <div className='line'>
                    <span className='label'>Free days :</span>
                    <span className='data'>{data.serviceDetails?.free_days}</span>
                </div>
                <div className='line'>
                    <span className='label'>Equipo :</span>
                    <span className='data'>{data.serviceDetails?.equipment}</span>
                </div>
            </div>
        </div>
        <StyledMap 
            id='rfqMap'
            className='w-100 h-100 border-rounded'
            style={{gridRow:'span 3'}}
        />
        <div style={{display:'flex', justifyContent:'space-around'}}>
            <b>Distancia: 728.02 kms</b>
            <b>Emision CO₂ : 4 kg</b>
        </div>
        
        <div>
        <div>
            <b>Cargas :</b>
            <StyledTable>
                <thead>
                    <tr>
                        <th>Tipo de Carga</th>
                        <th>Nombre</th>
                        <th>Valor</th>
                        <th>Dimensiones</th>
                        <th>Peso Total</th>
                    </tr>
                </thead>
                <tbody>
                    {data?.loads?.map((load, index) => (
                        <tr key={index}>
                            <td>{load.load_type.name}</td>
                            <td>{load.name}</td>
                            <td>{load.value} USD</td>
                            <td>
                            {load.long} {load.long_unit} x {load.width} {load.width_unit} x {load.height} {load.height_unit}
                            </td>
                            <td>
                            {load.total_weight} {load.total_weight_unit}
                            </td>
                        </tr>
                        ))}
                </tbody>
            </StyledTable>
        </div>
        </div>
        <b>Costos :</b>
        <StyledTable>
            <thead>
                <tr>
                    <th>Tipo de gasto</th>
                    <th>Servicio</th>
                    <th>Unidad</th>
                    <th>Factor</th>
                    <th>Venta por unidad</th>
                    <th>Moneda</th>
                    <th>Trm</th>
                    <th>Total USD</th>
                </tr>
            </thead>
            <tbody>
                {
                    data?.costs?.map(item => {
                        return <tr>
                            <td>{item?.item_type}</td>
                            <td>{item?.concept}</td>
                            <td>{item?.unit}</td>
                            <td>{item?.factor}</td>
                            <td>{item?.item_rate}</td>
                            <td>{item?.currency}</td>
                            <td>{item?.trm}</td>
                            <td>{item?.total_in_usd}</td>
                        </tr>
                    })
                }
            </tbody>
        </StyledTable>
        <div className='total'>
            <b>Total {data.totalCost} USD</b>
        </div>
        <b style={{margin:'20px 0'}}>Estado de operación :</b>
        <Stepper  activeStep={data?.logData?.length} orientation="vertical" sx={{display:'flex', flexDirection:'column'}}>
            {
                data?.logData?.map((log,i) => (
                    <Step key={i}>
                        <StepLabel>
                            <div style={{display:'flex', justifyContent:'space-between'}}>
                                <span>{log.status}</span>
                                <span>{log.subStatus}</span>
                                <span>{log.date}</span>
                            </div>
                        </StepLabel>
                    </Step>
                ))
            }
        </Stepper>
    </Container>
    </>
  )
}
