"use client"
import React, { useEffect, useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  Slider,
  Typography,
  Box,
  Grid,
  Popover,
  TextField,
  InputAdornment,
  Radio,
  RadioGroup,
} from "@mui/material";
import { FaDollarSign } from "react-icons/fa";

const CostFilter = ({ filterCost, setFilterCost }) => {
  const [manual, setManual] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null); // Corregido el tipo
  const [selectedRange, setSelectedRange] = useState<string>("");

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget); // Se asegura de que el tipo coincida
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "cost-filter-popover" : undefined;

  const handleCheckboxChange = () => {
    setManual(!manual);
    setSelectedRange(""); 
  };

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setFilterCost(newValue as number[]);
        setSelectedRange(""); // Desselecciona cualquier opción predefinida cuando se ajusta manualmente el slider
  };

  const handleRangeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const range = event.target.value;
    setSelectedRange(range);
    setManual(false); // Deshabilita el modo manual al seleccionar una opción predefinida

    // Ajusta el slider según la opción seleccionada
    switch (range) {
      case "<100":
        setFilterCost([0, 100]);
        break;
      case "100-500":
        setFilterCost([100, 500]);
        break;
      case "500-1000":
        setFilterCost([500, 1000]);
        break;
      case ">1000":
        setFilterCost([1000, 10000]);
        break;
      default:
        setFilterCost([0, 1000]);
    }

    handleClose(); // Cierra el Popover al seleccionar una opción
  };

  return (
    <div>
      <TextField
        value={`$${filterCost[0]} - $${filterCost[1]}`}
        onClick={handleClick}
        variant="outlined"
        size="small"
        sx={{
          backgroundColor: "white",
          cursor: "pointer",
          width: "100%",
          height: "100%",
          "& .MuiOutlinedInput-root": {
            height: "100%", // Asegura que el input tenga una altura completa
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FaDollarSign style={{ color: "gray" }} />
            </InputAdornment>
          ),
          readOnly: true,
        }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box p={2} width="300px">
          <RadioGroup value={selectedRange} onChange={handleRangeChange}>
            <FormControlLabel
              value="<100"
              control={<Radio />}
              label="< $100"
            />
            <FormControlLabel
              value="100-500"
              control={<Radio />}
              label="$100 - $500"
            />
            <FormControlLabel
              value="500-1000"
              control={<Radio />}
              label="$500 - $1000"
            />
            <FormControlLabel
              value=">1000"
              control={<Radio />}
              label="> $1000"
            />
          </RadioGroup>
          <FormControlLabel
            control={<Checkbox checked={manual} onChange={handleCheckboxChange} />}
            label="Manualmente:"
          />
          <Box mt={2}>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Typography>${filterCost[0]}</Typography>
              </Grid>
              <Grid item xs>
                <Slider
                  value={filterCost}
                  onChange={handleSliderChange}
                  valueLabelDisplay="auto"
                  min={0}
                  max={10000}
                  disabled={!manual} // El slider está deshabilitado si no está en modo manual
                />
              </Grid>
              <Grid item>
                <Typography>${filterCost[1]}</Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Popover>
    </div>
  );
};

export default CostFilter;
