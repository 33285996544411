import React, { useState } from "react";
import { TextField, Button, Typography, Box } from "@mui/material";

function GptInterface({ onGetForecast }) {
  const [query, setQuery] = useState("");
  const [response, setResponse] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Mock API response
    setResponse(
      `Forecast for "${query}": This is a mock response. In a real implementation, this would be the result from the GPT model based on your query.`
    );
    // Call the prop function to update the data
    onGetForecast();
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Custom Forecast Query
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="E.g., Forecast shipping rates from Shanghai to Los Angeles for the next 6 months"
          margin="normal"
        />
        <Button type="submit" variant="contained" color="primary">
          Get Forecast
        </Button>
      </form>
      {response && (
        <Typography variant="body1" sx={{ mt: 2 }}>
          {response}
        </Typography>
      )}
    </Box>
  );
}

export default GptInterface;
