import { Modal, styled } from '@nextui-org/react'
import React, { useEffect, useState } from 'react'
import { getServiceSplitQuote, patchQuotationByServiceSplit } from '../../../../services/quotation.services'
import { useSelector } from 'react-redux'
import Input from '../../../../components/Input/Input'
import Button from '../../../../components/Button/Button'
import { fontSize } from '@mui/system'

const StyledModal = styled(Modal, {
    display:'flex',
    padding: '20px',
    color: '$primary_500 !important',
    background: '$neutral_50',
    width:'600px',
    '.tag':{
        color:'grey',
        background:'lightgray',
        fontSize:'14px',
        padding:'2px',
        borderRadius:'4px',
    },
    '.delete':{
        padding:'0 5px',
        cursor:'pointer',
        fontSize:'12px',
        transition:'300ms',
        borderRadius:'4px',
        '&:hover':{
            color:'white',
            background:'$delete',
        }
    },
    '@media(max-width:600px)':{
        width:'300px',
    }
})

export default function TagsModal({open, setOpen, serviceId}) {
    const token = useSelector(state=>state.user.token)
    const [tagName, setTagName] = useState("")
    const [service, setService] = useState("")

    function getData(){
        if (serviceId){
            getServiceSplitQuote(serviceId,token).then(async res=>{
                const serviceData = await res.json();
                setService(serviceData)
            })
        }
    }

    useEffect(()=>{
        getData()
    },[serviceId])

    function handleSaveTag(){
        const serviceTags = service.tags || []
        patchQuotationByServiceSplit({
          id:Number(service.id),
          tags:[...serviceTags,tagName],
        },token).then(()=>{
            getData()
            setTagName("")
        }).catch(err=>console.log("err :",err))
    }

    function deleteTag(tag){
        const serviceTags = service.tags
        const newTags = serviceTags.filter(e=> e!==tag)
        patchQuotationByServiceSplit({
            id:service.id,
            tags:newTags,
        },token).then(()=>{
            getData()
        }).catch(err=>console.log("err :",err))
    }

    return (
    <StyledModal
        open={open}
        onClose={() => setOpen(false)}
        closeButton
        aria-label="modal-title"
        width="fit-content"
    >
        <Modal.Body>
            <h3>{service?.radication}</h3>
            {service?.tags?.length? <p>Tags :</p>: <p>Sin tags</p>}
            <div style={{display:'flex', flexWrap:'wrap', gap:'20px'}}>
            {
                service?.tags?.map(e=>{
                    return <div style={{display:'flex'}}>
                        <span className='tag'>{e}</span>
                        <button className='delete' onClick={()=>deleteTag(e)}>X</button>
                    </div>
                })
            }
            </div>
            <Input label={"Agregar tag"} value={tagName} onChange={(e)=>setTagName(e.target.value)} type="text"/>
        </Modal.Body>
        <Modal.Footer>
            <Button text={"Guardar"} handler={(()=>handleSaveTag())}/>
        </Modal.Footer>
    </StyledModal>
  )
}
