import React, { useEffect } from 'react';
import { styled } from '@nextui-org/react';
import theme from '../../../../theme';
import { useFieldArray, useForm } from 'react-hook-form';
import { GetDocumentData } from '../../../../services/user.services';
import { useSelector } from 'react-redux';
// Components
import PageTitle from '../../../../components/PageTitle/PageTitle';
import Input from '../../../../components/Input/Input';
import Button from '../../../../components/Button/Button';

const Exterior = ({ setExterior }) => {
    const user = useSelector(state => state.user);
    const nameForm = 'exterior'
    const { register, control, setValue, getValues, watch } = useForm({
        defaultValues: {
            [nameForm]: [{
                name: '',
                lastName: '',
                email: '',
                phone: '',
            }]
        }
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: nameForm
    });
    watch(nameForm)
    useEffect(() => {
        console.log("entró")
        GetDocumentData([], user.token, user.idUser, 'exteriors').then(async (res) => {
            let data = await res.json();
            console.log(data)
            // Formatear los datos obtenidos para que coincidan con el formato de useFieldArray
            const formattedData = data.map(item => ({
                name: item.name,
                lastName: item.last_name,
                email: item.email,
                phone: item.phone,
            }));
            // Actualizar el estado del formulario con los datos formateados
            setValue(nameForm, formattedData);
        });
    }, [user, setValue, nameForm, control]);

    const StyledExterior = styled('div', {
        [`.${theme} &`]: {
            margin: '3rem 8rem 0rem 8rem',
            padding: '3rem 8rem',
            backgroundColor: '$neutral_50',
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            ['main']: {
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem'
            },
            ['main section.form']: {
                display: 'grid',
                gridTemplateColumns: 'repeat(2, minmax(200px, 1fr))',
                gap: '0.5rem',
                ['.colspan-2']: {
                    gridColumn: '1 / 3',
                    ['&.input > *']: {
                        display: 'flex'
                    }
                }
            },
        }
    })

    const saveData = () => {
        setExterior(getValues()[nameForm])
    }

    useEffect(() => {
        saveData()
    }, []);

    return (
        <StyledExterior>
            <PageTitle>
                Proceso comercio exterior
            </PageTitle>

            <aside>
                Apellidos y nombres de las personas o entidades beneficiarias de las operaciones de comercio exterior.
            </aside>

            <main>
                {
                    fields.map((field, i) =>
                        <div key={field.id}>
                            <h5>Proceso No. {i + 1}</h5>
                            <section className='form'>
                                <Input register={register} onChange={saveData} name={`${nameForm}.${i}.name`} label='Nombre' placeholder='Introducir' required></Input>
                                <Input register={register} onChange={saveData} name={`${nameForm}.${i}.lastName`} label='Apellido' placeholder='Introducir' required></Input>
                                <Input register={register} onChange={saveData} name={`${nameForm}.${i}.email`} label='Correo' placeholder='Introducir' required></Input>
                                <Input register={register} onChange={saveData} name={`${nameForm}.${i}.phone`} label='Télefono' placeholder='Introducir' type='text' required></Input>

                                <div className='d-flex align-items-end'>
                                    {
                                        i < fields.length - 1 ?
                                            <Button handler={() => { remove(i); saveData() }} text='Eliminar' outline></Button>
                                            :
                                            <Button handler={() => { append(); saveData() }} text='Agregar otro' startIcon={{ name: 'Plus', size: '16' }} outline></Button>
                                    }
                                </div>
                            </section>
                        </div>
                    )
                }
            </main>
        </StyledExterior>
    );
}

export default Exterior;
