import React, { useEffect, useState, useRef } from 'react'
import { Tooltip, styled } from '@nextui-org/react'
import { getOperationStatuses, getSubStatuses, getSubStatusesOperations } from '../../../services/operations.services'
import useDraggableScroll from 'use-draggable-scroll'
import Icon from '../../../components/Icon/Icon'
import { useSelector } from 'react-redux'
import toast from '../../../components/Toast/ToastTypes'
import OperationsModal from './OperationsModal'
import ChangeStatusModal from './ChangeStatusModal'

const StyledContainer = styled("div", {
    display: 'flex',
    gap: '15px',
    overflowX: 'scroll',
    backgroundColor: '$neutral_50',
    borderRadius: '8px',
    padding: '20px',
    ['&::-webkit-scrollbar']: {
        height: '5px'
    },
    ['&::-webkit-scrollbar-track']: {
        borderRadius: '10px'
    },
    ['&::-webkit-scrollbar-thumb']: {
        background: '#5E6EAB',
        borderRadius: '50px'
    },
})
const Card = styled("div", {
    minWidth: '250px',
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
    backgroundColor: '$primary_500',
    padding: '20px',
    borderRadius: '8px',
    color: '$neutral_50',
    ['.sub_event']: {
        fontSize: '12px',
        ['&:hover']: {
            borderBottom: '1px solid white',
            cursor: 'pointer',
        }
    },
    ['.operations-number']: {
        display: 'flex',
        justifyContent: 'center',
        fontSize: '12px',
        width: '20px',
        height: '20px',
        border: '1px solid white',
        borderRadius: '50%'
    },
    ['.notes-icon']: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        padding: '2px',
        width: '30px',
        height: '30px',
        transition: '300ms',
        ['&:hover']: {
            cursor: 'pointer',
            background: '$neutral_500',
        }
    }
})

const StyledTooltip = styled(Tooltip, {
    ['&:hover']: {
        cursor: 'pointer',
    }
})

function SubEvent({ text, highlight }) {
    const StyledText = styled("span", {
        fontSize: highlight ? '14px' : '12px',
        color: highlight ? '#00a135' : 'white',
        borderBottom: highlight && '3px solid green',
        ['&:hover']: {
            borderBottom: !highlight && '1px solid white',
            cursor: 'pointer',
        }
    })
    return <StyledText>{text}</StyledText>
}

export default function TrackingCards({ currentOperationData, setSelectedOperation, setUpdateLogs }) {
    const token = useSelector(state => state.user.token)
    const ref = useRef("div");
    const { onMouseDown } = useDraggableScroll(ref, { direction: "horizontal" })

    const [cards, setCards] = useState()
    const [openModal, setOpenModal] = useState()
    const [selectedStep, setSelectedStep] = useState()
    const [subStateRelation, setSubStateRelation] = useState();
    const [operationsOnCurrentStep, setOperationsCurrentStep] = useState([])
    const [openTable, setOpenTable] = useState(false)

    const [subStatusesData, setsubStatusesData] = useState([])

    function getData() {
        const asyncTasks = [getOperationStatuses(token), getSubStatuses(token), getSubStatusesOperations(token)]
        Promise.all(asyncTasks).then(async res => {
            let statusesData = await res[0].json();
            let subStatusesData = await res[1].json();
            setsubStatusesData(subStatusesData)
            const relations = await res[2].json(); //Relacion entre operaciones y subestados

            const currentOperationSubStates = relations.filter(relation => relation.operation === currentOperationData?.id)
            if (currentOperationSubStates) {
                const sortedOperationSubStates = currentOperationSubStates.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
                const lastSubStatus = sortedOperationSubStates[0];
                setSubStateRelation(lastSubStatus)
            }

            const cardsData = statusesData?.map(status => {
                const subStatuses = subStatusesData.filter((subStatus => subStatus.operation_status === status.id))
                return {
                    title: status.name,
                    events: subStatuses?.map(sub => {
                        let operationsAmount = 0
                        let operations = []

                        const operationMap = new Map();
                        relations.forEach(item => {
                            const operationKey = item.operation !== null ? item.operation : 'null';
                            if (!operationMap.has(operationKey) || new Date(item.timestamp) > new Date(operationMap.get(operationKey).timestamp)) {
                                operationMap.set(operationKey, item);
                            }
                        });
                        const uniqueRelations = Array.from(operationMap.values());

                        uniqueRelations.forEach(relation => {
                            if (relation.operation_sub_status === sub.id) {
                                operations.push(relation.operation),
                                    operationsAmount++;
                            }
                        })
                        return {
                            ...sub,
                            operations,
                            operationsAmount,
                            info: `Numero de operaciones en  ${sub.name}`
                        }
                    }),
                }
            })
            setCards(cardsData)
        })
    }

    useEffect(() => {
        getData();
    }, [currentOperationData])

   

    return (
        <StyledContainer ref={ref} onMouseDown={onMouseDown}>
            {
                cards?.map((card, i) => {
                    return (
                        <Card key={card?.title + i}>
                            <p> {card.title}</p>
                            {
                                card.events.map((event, i) => {
                                    return (
                                        <div key={i} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <div style={{ display: 'flex', gap: '5px' }}>
                                                <StyledTooltip content={`Cantidad de operaciones en este paso`} placement='left'>
                                                    <div className='operations-number' onClick={() => {
                                                        setOperationsCurrentStep(event.operations)
                                                        setOpenTable(true)
                                                    }}>
                                                        <span>
                                                            {event.operationsAmount}
                                                        </span>
                                                    </div>
                                                </StyledTooltip>
                                                <div onClick={() => { setSelectedStep(event); currentOperationData.id ? setOpenModal(true) : toast("warn", "No hay operacion seleccionada") }}>
                                                    <StyledTooltip content={event.id === subStateRelation?.operation_sub_status && `Paso en el que se encuentra la operacion seleccionada`}>
                                                        <SubEvent text={event.name} highlight={event.id === subStateRelation?.operation_sub_status} />
                                                    </StyledTooltip>
                                                </div>
                                            </div>
                                            <div className='notes-icon' onClick={() => {
                                                setSelectedStep(event)
                                                currentOperationData.id ? setOpenModal(true) : toast("warn", "No hay operacion seleccionada")
                                            }}>
                                                <Icon name='EditSquare' size='20px' color='white' />
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </Card>
                    )
                })
            }
            <OperationsModal 
                open={openTable}
                setOpen={setOpenTable}
                setSelectedOperation={setSelectedOperation}
                operationsOnCurrentStep={operationsOnCurrentStep} 
            />
            <ChangeStatusModal 
                open={openModal} 
                setOpen={setOpenModal} 
                subStatusesData={subStatusesData} 
                selectedStep={selectedStep} 
                currentOperationData={currentOperationData}
                getData={getData}
            />
        </StyledContainer>
    )
}
