import React from 'react'
import InputTable from '../../../components/InputTable/InputTable'
import TotalsTable from './TotalsTable'



export default function FactorInputTable({tableName, id, defaultData={}}) {
    const columns = [
        {name:"Concepto", uid:"concept" , type:'text'},
        {name:"Valor USD", uid:"value" , type:'number'},
        {name:"Factor USD", uid:"factorUSD" , type:'number'},
        {name:"Factor Col", uid:"factorCol" , type:'number'},
        {name:"Porcentual", uid:"porcentual" , type:'number'},
    ]
    return (
        <div>
            <InputTable tableName={id} columns={columns} defaultData={defaultData}/>
        </div>
    )
}
