import React, { useState } from 'react'
import PlacesAutocomplete from 'react-places-autocomplete'
import Input from '../Input/Input';
import Icon from '../Icon/Icon';
import { Controller, useFormContext, useFormState } from 'react-hook-form';
import { styled } from '@nextui-org/react';

const StyledLabel = styled('div', {
    color: '$primary_500',
    fontSize: '14px',
    paddingLeft: '0.25rem',
    marginBottom: '0.55rem',
    ['span']: {
        color: '#ff676c'
    }
})

export default function FormAutocomplete({name, label, required=false }) {

    const { control } = useFormContext();
    const errors = useFormState()?.errors
    const thisFieldError = errors[name[0]]? errors[name[0]][name.slice(2)] : errors[name] || false
    return (
        <Controller
            name={name}
            control={control}
            rules= {required ? { required: "Este campo es obligatorio" } : {}}
            render={({ field }) => (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <PlacesAutocomplete
                        {...field}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                            <div className='p-relative'>
                                <Input
                                    {...field}
                                    required
                                    label={label}
                                    className={`o_input`}
                                    animated={false}
                                    placeholder='Hasta'
                                    fullWidth={'100%'}
                                    // value={watch('origin_terminal')|| ''}
                                    onBlur={getInputProps().onBlur}
                                    onChange={getInputProps().onChange}
                                    onKeyDown={getInputProps().onKeyDown}
                                />
                                <div className="autocomplete-dropdown-container-bottom" style={{position:'absolute', zIndex:9999}}>
                                    {
                                        suggestions.map((suggestion, i) => {
                                            const style = suggestion.active
                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                            return (
                                                <div
                                                    key={i}
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className: 'suggestion',
                                                        style,
                                                    })}
                                                >
                                                    <Icon name={'Rastreo'} size={'14'}></Icon><span>{suggestion.description}</span>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        )}
                    </PlacesAutocomplete>
                    {thisFieldError && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{thisFieldError.message}</span>}
                </div>
            )}
        />
    )
}
