import { useState, useEffect } from "react";

export function useFetch(url, headers = {}) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);

    fetch(url, {
      headers: headers
    })
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => {
        setError(error);
        setData({}); // Set data to null when there's an error
      })
      .finally(() => setLoading(false));

  }, [url]); // Añadir headers a las dependencias del useEffect

  return { data, loading, error };
}
