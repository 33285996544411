import React from 'react'
import Table from '../../../components/Table/Table';
import { Modal, styled } from '@nextui-org/react';
import { useEffect } from 'react';
import { useState } from 'react';
import Button from '../../../components/Button/Button';
import toast from '../../../components/Toast/ToastTypes';
import { useNavigate } from 'react-router-dom';
import { setSuggested } from '../../../services/quotations.services';
import { useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";

const StyledModal = styled(Modal, {
  color: '$primary_500 !important',
  padding: '20px',
})

export default function VersionsTable({ data, currentQuote, setCurrentQuote }) {
  const [render, setRender] = useState(0)
  const [selectedVersion, setSelectedVersion] = useState()
  const [openModal, setOpenModal] = useState(false)
  const token = useSelector(state => state.user.token)
  const navigate = useNavigate()

  const columns = [
    { name: 'Acciones', uid: 'actions', type: 'action' },
    { name: 'ID', uid: 'id', type: 'text' },
    { name: 'Version', uid: 'versionNumber', type: 'text' },
    { name: 'Motivo', uid: 'reason', type: 'text' },
    { name: 'Subtipo', uid: 'subtype', type: 'text' },
    { name: 'Servicio', uid: 'service', type: 'text' },
    { name: 'Oficina', uid: 'office', type: 'text' },
    { name: 'Cliente', uid: 'client', type: 'text' },
    { name: 'Estado', uid: 'status', type: 'text' },
    { name: 'Costo', uid: 'cost', type: 'text' },
    { name: 'Profit', uid: 'profit', type: 'text' },
    { name: 'Notas', uid: 'observations', type: 'text' },
  ];
  useEffect(() => {
    setRender((prevRender) => prevRender + 1);
  }, [data]);

  const actions = [
    {
        name: 'Seleccionar',
        icon: 'View',
        handle: (item) => {
          setCurrentQuote({
            ...currentQuote,
            id:item.id,
          })
        }
    },
    {
      name: 'Ver detalles',
      icon: 'File',
      handle: (item) => {
          navigate(`/manageQuotes/quotation-details/?id=${item.id}&radication=${data[0].radication}`)
      },
    },
    {
      name: 'Mejor opción',
      icon: 'CheckSquare',
      handle: (item) => { setSelectedVersion(item.id) , setOpenModal(true) }
    },
  ]
  
  const handleBestOption = ()=>{
    setSuggested(selectedVersion, token).then(()=>{
      toast("success","Marcada como sugerida",selectedVersion)
      setOpenModal(false)
    }).catch(err => console.log("err :",err))
  }

  return (
    <div>
      {data.length ? (
        <Table key={render} columns={columns} items={data} actions={actions}/>
      ) : (
        <p>No hay versiones disponibles</p>
      )}
      <StyledModal
        open={openModal}
        onClose={()=>{setOpenModal(false)}}
      >
        <Modal.Header>
          <h3>Marcar como sugerida la versión de ID: {selectedVersion}</h3>
        </Modal.Header>
        <Modal.Body>
          <p>Al cliente se le recomendara esta version a la hora de elegir</p>
        </Modal.Body>
        <Modal.Footer>
          <Button text='Cancelar' outline handler={()=>setOpenModal(false)}/>
          <Button text='Confirmar' outline bold handler={()=>handleBestOption()}/>
        </Modal.Footer>
      </StyledModal>
    </div>
  );
}