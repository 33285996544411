import React, { useEffect, useState } from 'react'
import { Modal,styled } from '@nextui-org/react'
import Button from '../../../components/Button/Button'
import { useSelector } from 'react-redux'
import toast from '../../../components/Toast/ToastTypes'
import { FormProvider, useForm } from 'react-hook-form'
import FormInput from '../../../components/FormInput/FormInput'
import FormInputSelect from '../../../components/FormInputSelect/FormInputSelect'
import { color } from 'd3-color'
import { SubscribeTracking } from '../../../services/cargoes.services'
import Icon from '../../../components/Icon/Icon'

const StyledModal = styled(Modal, {
    color: '$primary_500 !important',
    padding: '20px',
    display:'flex',
    minWidth:"500px",
    'form':{
        display:'flex',
        flexDirection:'column',
        gap:'15px',
    },
    '.header':{
        display:'flex',
        justifyContent:'space-between',
        borderBottom:'2px solid $primary_300',
    }
})

export default function SubscriptionModal({open, setOpen, service}) {
    const token = useSelector(state=>state.user.token)

    const { handleSubmit, reset, watch, getValues,setValue, ...methods} = useForm({
        defaultValues:{
            URL:"",
            currentEmail:"",
            emails:[],
        }
    })

    function addMail(){
        const formValues = getValues()
        if (formValues?.currentEmail){
            setValue('emails',[...formValues.emails, formValues.currentEmail])
            setValue('currentEmail',"")
        }
    }
    function deleteMail(index){
        const formValues = getValues()
        const newMails = formValues?.emails
        newMails.splice(index,1)
        setValue('emails',newMails)
    }

    function onSubmit(formData){
        console.log("formData :",formData)
        const submitData = {
            radication: service.idRadication,
            purchase_order: service?.purchaseOrder || "",
            URL: formData.URL || "",
            emails: formData.emails,
        }
        console.log("submitData :",submitData)
        if (submitData.emails.length<1 && !submitData.URL){
            toast("warn","Por favor ingrese al menos un email o url")
        } else {
            SubscribeTracking(token, submitData).then(()=>toast("success","Subscripción exitosa")).catch(()=>toast("error","error ):<"))
        }
    }

    return (
    <StyledModal
        open={open}
        onClose={()=>setOpen(false)}
        width='fit-content'
        closeButton   
        placement="left" 
    >
        <Modal.Body justify='space-between'>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='header'>
                        <h3>Subscribir a rastreo</h3>
                        <Icon name='Mail' size='30px'/>
                    </div>
                    <p>Radicado : <b>{service?.idRadication}</b></p>
                    <p>Orden de compra : <b>{service?.purchaseOrder}</b></p>
                    <FormInput name="url" label="URL (opcional)"/>
                    <div style={{display:'flex', alignItems:'end',gap:'5px'}}>
                        <FormInput name="currentEmail" label="Mails :"/>
                        <Button endIcon={{name:'Plus', color:'white'}} bgColor={'primary_300'} handler={()=>addMail()}/>
                    </div>
                    {
                        watch("emails")?.length>0 &&
                        <div>
                            <p>Se subscribiran los correos :</p>
                            {
                                watch("emails")?.map((email,i)=>(
                                    <div style={{display:'flex', justifyContent:'space-between', borderBottom:'1px solid lightgrey', margin:'20px 0'}}>
                                        <p key={i}>{email}</p>
                                        <Button
                                            endIcon={{name:'Trash', color:'white'}} 
                                            bgColor='delete'
                                            handler={()=>{deleteMail(i)}}
                                            size="xs"
                                        />
                                    </div>
                                ))
                            }
                        </div>
                    }
                    <div style={{marginTop:'20px'}}>
                        <Button type="submit" text="Confirmar" bgColor={'primary_300'} textColor={'neutral_50'} width="100%"/>
                    </div>
                </form>
            </FormProvider>
        </Modal.Body>
    </StyledModal>
  )
}