import React, { useEffect, useState } from 'react';
import { styled } from '@nextui-org/react';
import theme from '../../../../../theme';
import { useSelector } from 'react-redux';
import { deleteDocument, downloadDocument, getDocuments, handleDownloadDocument } from '../../../../../services/documents.services';
import PdfIcon from '../../../../../assets/Files/pdf.png';
import DocIcon from '../../../../../assets/Files/word.png';
import JpegIcon from '../../../../../assets/Files/jpg.png';
import PngIcon from '../../../../../assets/Files/png.png';
import XlsIcon from '../../../../../assets/Files/excel.png';
import ZipIcon from "../../../../../assets/Files/zip.png";
import toast from '../../../../../components/Toast/ToastTypes';

import { useTranslation } from "react-i18next";
import zIndex from '@mui/material/styles/zIndex';
import DeleteConfirmationModal from '../../../../../components/DeleteConfirmationModal/DeleteConfirmationModal';

const StyledAttachments = styled('div', {
    [`.${theme} &`]: {
        padding: '2rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        position:'relative',
        '.deleteButton':{
            borderRadius:'7px',
            padding:'2px 8px',
            position:'absolute',
            top:0,
            right:0,
            backgroundColor:'$delete',
            color:'white',
            transition:'300ms',
            cursor:'pointer',
            zIndex:'100',
            '&:hover':{
                backgroundColor:'red',
            }
        },
        ['.container']: {
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 200px))',
            gridGap: '1rem',
            ['.card']: {
                position:'relative',
                cursor: 'pointer',
                borderRadius: '8px',
                border: '1px solid $secondary_300',
                backgroundColor: '$neutral_50',
                ['.file']: {
                    padding: '1.5rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    ['img']: {
                        width: '50px'
                    }
                },
                ['.title']: {
                    backgroundColor: '$secondary_50',
                    alignSelf: 'end',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    borderRadius: '0px 0px 8px 8px',
                    padding: '0.5rem 1rem',
                    fontSize: '0.9rem',
                    ['span']: {
                        width: '100%',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                    },
                    ['span.type']: {
                        color: '$secondary_300'
                    }
                }
            }
        }
    }
})

const Attachments = ({ radication }) => {
    const token = useSelector((state) => state.user.token);
    const { t: translate } = useTranslation("Services");

    const [documents, setDocuments] = useState([])
    const [open, setOpen] = useState(false)
    const [documentId,setDocumentId] = useState()

    const download = (document) => {
                downloadDocument(document.route, token).then(res => {
                console.log("res :",res)
                !res.ok && toast('error', 'Hubo un error al intentar descargar el documento')
                return res.json()
            }).then(data => {
                console.log("data :",data)
                handleDownloadDocument(data.document, document.name)
            })
    }

    function getData(){
        getDocuments(radication, token).then(async res => {
            const data = await res.json()
            res.ok && setDocuments(data)
        })
    }

    useEffect(() => {
        getData()
    }, []);

    function getFileIconAndType(fileName) {
        const extension = fileName.split('.').pop().toLowerCase();
        let IconComponent;
        let fileType;

        switch (extension) {
            case 'pdf':
                IconComponent = PdfIcon;
                fileType = 'PDF';
                break;
            case 'doc':
            case 'docx':
                IconComponent = DocIcon;
                fileType = 'DOC';
                break;
            case 'jpeg':
            case 'jpg':
                IconComponent = JpegIcon;
                fileType = 'JPEG';
                break;
            case 'png':
                IconComponent = PngIcon;
                fileType = 'PNG';
                break;
            case 'zip':
                IconComponent = ZipIcon;
                fileType = 'ZIP'
                break;
            case 'xls':
            case 'xlsx':
                IconComponent = XlsIcon;
                fileType = 'XLS';
                break;
            default:
                IconComponent = PdfIcon; // Usa el icono de PDF como predeterminado
                fileType = 'FILE';
        }
        return { IconComponent, fileType };
    }

    function handleClick(document,e){
        e.stopPropagation();
        setOpen(true)
        setDocumentId(document.id)
        console.log("document :",document)
    }

    function handleDelete(){
        deleteDocument(token,radication,documentId).then(res=>{
            getData()
        })
    }

    return (

        <StyledAttachments>
            <h5>{translate("total_attachments")}: {documents.length}</h5>
            <div className='container'>
                {
                    documents.map((document, i) => {
                        const { IconComponent, fileType } = getFileIconAndType(document.name);
                        return (
                            <div key={i} className='card' onClick={() => download(document)}>
                                <button className='deleteButton' onClick={(e)=>handleClick(document,e)}>X</button>
                                <div className='file'>
                                    <img src={IconComponent} alt={fileType} /> {/* Usa la imagen directamente */}
                                </div>
                                <div className='title'>
                                    <span className='name'>{document.name}</span>
                                    <span className='type'>{fileType}</span> {/* Muestra el tipo de archivo */}
                                </div>
                            </div>
                        );
                    })
                }
            </div>
            <DeleteConfirmationModal open={open} setOpen={setOpen} text={'Borrar documento?'} handleDelete={handleDelete}/>
        </StyledAttachments>
    );
}

export default Attachments;
