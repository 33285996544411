import { styled } from '@nextui-org/react';
import React from 'react'

const Container = styled("div", {
    width: '100%',
    margin: '0 auto',
    fontFamily: 'Arial, sans-serif',
    fontSize:'13px',
  });
  
  const Table = styled("table", {
    width: '92%',
    borderCollapse: 'collapse',
    marginBottom: '20px',
    '& th, & td': {
      maxW:'50px',
      border: '1px solid $neutral_200',
      textAlign: 'left',
      padding:'0 20px',
    },
    '& th': {
      backgroundColor: '#f0f0f0',
      paddingLeft:'15px',
      fontWeight: 'bold',
      border:'1px solid $neutral_200',
    },
    '& tr:nth-child(even)': {
      backgroundColor: '#f9f9f9',
    },
  });

export default function TotalsTable({items,columns}) {

  return (
    <Container>
        <Table>
            <thead>
                {/* <tr>
                    {columns.map((header) => (
                        <th key={header.name}>{header.name}</th>
                    ))}
                </tr> */}
            </thead>
            <tbody>
              {
                items.map(item => {
                  return(
                  <tr>
                    {
                      columns.map(col => {
                        return <td>
                          {
                            item.bold? <b>{item[col.uid]}</b> : item[col.uid]
                          }
                        </td>
                      })
                    }
                  </tr>
                  )
                })
              }
            </tbody>
        </Table>
    </Container>
  )
}
