import { Progress, styled } from "@nextui-org/react";
import React, { useState, memo } from "react";
import theme from "../../theme";
import Dropdown from "../Dropdown/Dropdown";
import Icon from "../Icon/Icon";
import { useTranslation } from "react-i18next";
import { scaleLinear } from "d3-scale";
import {
  ComposableMap,
  ZoomableGroup,
  Geographies,
  Geography,
  Sphere,
  Graticule,
} from "react-simple-maps";

const geoUrl = "https://unpkg.com/world-atlas@2.0.2/countries-110m.json";

const colorScale = scaleLinear()
  .domain([0, 1000, 5000, 10000, 50000, 100000]) 
  .range(["#3288bd", "#66c2a5", "#abdda4", "#e6f598", "#fee08b", "#fdae61", "#f46d43"]);

const Cloropeth = ({ setTooltipContent, data, view }) => {
  return (
    <div id="mapclor">
      <ComposableMap>
        <ZoomableGroup>
          {data && Object.keys(data[view]).length > 0 && (
            <Geographies geography={geoUrl}>
              {({ geographies }) =>
                geographies.map((geo) => {
                  const countryName = geo.properties.name.toUpperCase();
                  const countryData = data[view][countryName];
                  const color =
                    countryData && countryData.total_cost
                      ? colorScale(countryData.total_cost)
                      : "#D6D6DA"; // Color gris para datos faltantes

                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      fill={color}
                      onMouseEnter={() => {
                        setTooltipContent(
                          `${countryName}: ${
                            countryData ? countryData.total_cost : "No data"
                          }`
                        );
                      }}
                      onMouseLeave={() => {
                        setTooltipContent("");
                      }}
                      style={{
                        default: {
                          stroke: "black",
                          strokeWidth: 0.2,
                        },
                        hover: {
                          fill: "#3d509a",
                          outline: "none",
                        },
                        pressed: {
                          fill: "#E42",
                          outline: "none",
                        },
                      }}
                    />
                  );
                })
              }
            </Geographies>
          )}
        </ZoomableGroup>
      </ComposableMap>
    </div>
  );
};

export default memo(Cloropeth);
