import React from "react";
import { styled } from "@nextui-org/react";
import { useTranslation } from "react-i18next";

// Components
import PageTitle from "../../components/PageTitle/PageTitle";

const Tariff = () => {
  const { t: translate } = useTranslation("Tariff");

  const StyledTariff = styled("div", {
    padding: "2rem",
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    ".content": {
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
      "@md": { 
        flexDirection: "row",
      },
    },
    section: {
      flex: 1,
      iframe: {
        width: "100%",
        height: "500px",
        border: "none",
      },
    },
    aside: {
      width: "100%",
      "@md": {
        width: "30%",
      },
    },
  });

  return (
    <StyledTariff>
      <PageTitle>{translate("title")}</PageTitle>
      <div className="content">
        <section>
          <div className="tariff">
            <iframe
              src="https://muisca.dian.gov.co/WebArancel/DefMenuConsultas.faces"
              title="Tariff Page"
            ></iframe>
          </div>
        </section>
      </div>
    </StyledTariff>
  );
};

export default Tariff;
