import React, { useState } from 'react';
import { styled } from '@nextui-org/react';
import theme from '../../../theme';
import { useTheme } from '@mui/material/styles';

// Components
import Card3 from '../Card3/Card3';
import Button from '../../Button/Button';

const StyledCarouselCards = styled('div', {
    [`.${theme} &`]: {
        display: 'flex',
        alignItems: 'center',
        ['& > .content']: {
            flex: '1'
        }
    }
})

const CarouselCards = ({ data }) => {
    const themes = useTheme()
    const [activeStep, setActiveStep] = useState(0)
    const [limitStep] = useState(Math.ceil(data.length / 3) - 1)

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <StyledCarouselCards>
            {/* <Button handler={handleBack} startIcon={{ name: 'ArrowLeft', size: '12' }} hoverColor={{ bgColor: 'primary_300', textColor: 'white' }} bgColor={'neutral_50'} disabled={activeStep === 0} rounded shadow></Button> */}
            {
                data.map((step, i) => (
                    <Card3 key={i} title={step.title} image={step.image} to={step.url} iconVideo ></Card3>
                ))
            }
            {/* <Button handler={handleNext} startIcon={{ name: 'ArrowRight', size: '12' }} hoverColor={{ bgColor: 'primary_300', textColor: 'white' }} bgColor={'neutral_50'} disabled={activeStep >= limitStep} rounded shadow></Button> */}
        </StyledCarouselCards>
    );
}

export default CarouselCards;
