import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { getShippers, createTracking } from '../../../services/tracking.services';
import { getQuotation } from '../../../services/quotation.services';

import { Modal, styled } from '@nextui-org/react';
import Input from '../../../components/Input/Input';
import InputSelect from '../../../components/Select/inputSelect';
import Button from '../../../components/Button/Button';
import toast from '../../../components/Toast/ToastTypes';

const StyledModal=styled(Modal,{
    padding:'20px',
    color:'$primary_500 !important',
    overflow:'hidden',
    display:'flex',
})

export default function TrackingModal({open, setOpen, radication}) {
    const token = useSelector(state=>state.user.token)
    const [shippers, setShippers] = useState([])
    const [serviceId, setServiceId] = useState()
    const [number, setNumber] = useState()
    const [shipper, setShipper] = useState({})
   
    useEffect(()=>{
        if (radication){
        getQuotation(radication,token).then((async res=>{
            const service = await res.json();
            setServiceId(service.id)
        }))
        getShippers(token).then(async res => {
            const shippersData = await res.json();
            setShippers(shippersData)
        })}
    },[radication])
    
    function handleSubmit(){
        const submitData = {
            service:radication,
            number,
            shipper:shipper.value,
        }
        createTracking(submitData, token).then((res)=>{
            console.log('res :',res)
            toast("success","Numero asignado y notificado al cliente")
        }).catch(err => {
            console.log('err :',err)
            toast("error","Error, intente nuevamente con otro numero")
        })
    }

    return (
    <StyledModal
        closeButton
        open={open}
        onClose={()=>setOpen(false)}
    >
        <Modal.Header>
            <h3>Rastreo</h3>
        </Modal.Header>
        <Modal.Body>
            {radication}
            <Input label="Numero de rastreo" placeholder={'7417234...'} value={number} onChange={(e)=>setNumber(e.target.value)}/>
            <InputSelect 
                label="Expedidor o cargador"
                onChange={(e)=>setShipper(e)} 
                options={shippers.map(e => {
                    return {
                        label:e.name,
                        value:e.id,
                    }
            })}/>
        </Modal.Body>
        <Modal.Footer>
            <Button text='Asignar numero de rastreo' disabled={!number || !shipper} outline handler={()=>handleSubmit()}/>
        </Modal.Footer>
    </StyledModal>
  )
}
