import React, { useState } from "react";
import { styled } from "@nextui-org/react";
import theme from "../../../theme";
import styles from "./Quotation.module.scss";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import src from "../../../assets/img/onBoardingTableDropdown.png";
// Components
import PageTitle from "../../../components/PageTitle/PageTitle";
import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";
import TableQuotation from "./TableQuotation/TableQuotation";
import OnBoarding from "../../../components/onBoarding/onBoarding";
import { useNavigate  } from "react-router-dom";
import LoadingBar from 'react-top-loading-bar'
import useMediaQueries from "../../../hooks/useMediaQueries";


const StyledCotization = styled("section", {
  [`.${theme} &`]: {
    backgroundColor: "$primary_50",
    height: "100%",
    padding: "3rem 2rem",
    display: "flex",
    flexDirection: "column",
    gap: "1.5rem",
    flexGrow: 1,
    [".header"]: {
      display: "flex",
      gap: "1.5rem",
    },
  },
});

const onBoardingSteps = [
  {
    target: ".quotationPageTitle",
    disableScrolling: true,
    content: (
      <div>
        <h3>Cotizaciones</h3>
        <p>
          Bienvenido al menu de cotizaciones, aqui puedes visualizar,
          administrar y crear cotizaciones
        </p>
      </div>
    ),
  },
  {
    target: ".quotationRequestsTable",
    content: (
      <div style={{ textAlign: "left" }}>
        <h3>Ver informacion</h3>
        <p style={{ fontSize: "13px" }}>
          En esta tabla se muestran todas tus cotizaciones. También te permite
          acceder a mas información de cada cotización haciendo click en "Ver
          detalle" y editar cada una presionando "Editar".
        </p>
        <p style={{ fontSize: "13px" }}>
          Accede a estas opciones en el siguiente menu desplegable :
        </p>
        <img src={src} alt="" />
      </div>
    ),
    disableScrolling: true,
  },
  {
    target: ".newQuotationButton",
    content: (
      <div>
        <h3>Crea cotizaciones</h3>
        <p>
          Crea nuevas cotizaciones siguiendo los pasos que te presentara el
          formulario.
        </p>
      </div>
    ),
    disableScrolling: true,
  },
  {
    target: ".servicesSubMenu",
    content: (
      <div>
        <p>
          <h3>Servicios</h3>
          <p>
            Puedes seguir aprendiendo de la pagina dirigiendote al menu de
            servicios haciendo click aqui cuando estes listo
          </p>
        </p>
      </div>
    ),
    disableScrolling: true,
  },
];

function Quotation() {
  const {sm} = useMediaQueries()
  const { t: translate } = useTranslation("Quotation");
  const [loadingtop, SetLoadingtop] = useState(0)
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [filterType, setFilterType] = useState("Seleccionar");
  const [filterSearch, setFilterSearch] = useState("");

  const options = [
    { key: "Seleccionar", name: translate("select") },
    { key: "Carga general", name: translate("general-cargo") },
    { key: "Carga proyecto", name: translate("project-cargo") },
  ];

  const handleChange = (value) => {
    setFilterSearch(value);
  };
  const debouncedSearch = debounce((value) => {
    handleChange(value);
  }, 800);

  const handleNewQuotationClick = () => {
    navigate("/new-quotation");
  };
  
  return (
    
    <StyledCotization>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
      <LoadingBar
        color='#3D509A'
        progress={loadingtop}
        onLoaderFinished={() => SetLoadingtop(0)}
      />
      </div>
      <OnBoarding steps={onBoardingSteps} />
      <div className="quotationPageTitle">
        <PageTitle> {translate("title")}</PageTitle>
      </div>
      <div className="header">
        <Input
          placeholder={translate("search")}
          startIcon={{ name: "Search" }}
          onChange={(e) => debouncedSearch(e.target.value)}
          disabled={loading}
          fullWidth
        ></Input>
        {/* <Dropdown
          options={options}
          className={styles.dropdown}
          s
          onChange={(data) => setFilterType(data[0])}
          outline
        ></Dropdown> */}
      <div className="newQuotationButton">
        <Button
          fontSize={sm?"10px":'14px'}
          className={styles.btnContainer}
          text={translate("newquotation")}
          textColor={"neutral_50"}
          bgColor={"primary_500"}
          handler={handleNewQuotationClick}
        />
      </div>
       
        
     
      </div>

      <div className="quotationRequestsTable">
        <TableQuotation
          filterType={filterType}
          filterSearch={filterSearch}
          loading={loading}
          setLoading={setLoading}
        ></TableQuotation>
      </div>

      
    </StyledCotization>
  );
}
export default Quotation;
