import React, { useEffect, useState } from 'react'
import { Modal,styled } from '@nextui-org/react'
import { getProviderDetails, getProviderTypes, getServiceGroups, getServices, editProvider } from '../../../services/preliquidation.services'
import Table from '../../../components/Table/Table'
import Select from 'react-select'
import InputSelect from '../../../components/Select/inputSelect'
import Button from '../../../components/Button/Button'
import { useSelector } from 'react-redux'
import theme from '../../../theme'
import toast from '../../../components/Toast/ToastTypes'
import { useTranslation } from "react-i18next";

const StyledModal = styled(Modal, {
    color: '$primary_500 !important',
    padding: '20px',
    display:'flex',
})


export default function ProviderModal({open, setOpen, providerId, updateTable}) {
    const { t: translate } = useTranslation("Admin");

    const columns = [
        {name:'', uid:'actions', type:'action'},
        {name:'Nombre', uid:'name', type:'text'},
        {name:'Grupo de servicio', uid:'serviceGroup', type:'text'},
    ]
    
    const token = useSelector(state=>state.user.token)
    const [services, setServices] = useState([])
    const [selectedServices, setSelectedServices] = useState([])
    const [items, setItems] = useState([])
    const [currentProvider, setCurrentProvider] = useState({})
    const [providerTypes, setProviderTypes] = useState([])
    const [selectedType, setSelectedType] = useState([])

    function getData(){
        const asyncTasks = [getProviderDetails(token, providerId), getProviderTypes(token), getServices(token), getServiceGroups(token)]
        if (providerId){
        Promise.all(asyncTasks).then(async res => {
            const providerData = await res[0].json();
            const providerTypesData = await res[1].json();
            const servicesData = await res[2].json();
            const serviceGroupsData = await res[3].json();

            setProviderTypes(providerTypesData)

            const providerType = providerTypesData.find(type => type.id === providerData.provider_type)

            setSelectedType({
                label:providerType?.name,
                value:providerType?.id
            })

            const filteredServices = servicesData.filter(service => providerData.services.includes(service.id))
            const tableData = filteredServices.map(service => {
                return {
                    id:service.id,
                    name:service.name,
                    serviceGroup:serviceGroupsData.find(group => group.id === service.id)?.name || 'No tiene',
                }
            })
            setCurrentProvider({
                ...providerData,
                provider_type : providerTypesData.find(provider=>provider.id === providerData.provider_type)
            })
            setItems(tableData)
            setServices(servicesData.filter(service => !providerData.services.includes(service.id)))
        })}
    }

    useEffect(()=>{
        if (open){
            getData()
        }
    },[providerId, open])

    const colourStyles = {
        menuPortal: provided => ({ ...provided, zIndex: 9999 }),
        menu: provided => ({ ...provided, zIndex: 9999 }),
        control: (styles) => ({
          ...styles,
          border: '1px solid ' + theme.colors.primary_300,
          borderRadius: '8px',
          margin: 0,
          padding: 0,
          minHeight: '40px',
          width: '500px',
          boxShadow: 'none',
          outline: 'none',
          [':hover']: {
            borderColor: 'grey',
          }
        }),
      };

    function handleAddServices(){
        const selectedValues = selectedServices.map((e)=> (e.value))
        const editData = {
            services: currentProvider.services.concat(selectedValues)
        }
        editProvider(token,providerId,editData).then (res => {
            getData()
            setSelectedServices([])
        }).catch(err => {
            console.log("err :",err)
        })
    }
    function handleChangeType(){
        const editData = {
            provider_type: selectedType?.value,
        }
        editProvider(token,providerId,editData).then (res => {
            toast("success","Tipo de proveedor modificado")
            getData()
        }).catch(err => {
            console.log("err :",err)
        })
    }

    function handleDeleteServices(serviceId){
        const editData = {
            services: currentProvider.services.filter(service => service !== serviceId)
        }
        editProvider(token,providerId,editData).then (res => {
            getData()
        }).catch(err => {
            console.log("err :",err)
        })
    }
    const actions = [
        {
            name: 'Borrar',
            icon: 'Trash',
            handle: (item) => {
                handleDeleteServices(item.id)
            }
        },
    ]

    return (
    <StyledModal
        open={open}
        onClose={()=>{setOpen(false), updateTable()}}
        width='fit-content'
        closeButton   
        placement="left" 
    >
        <Modal.Body justify='space-between'>
            <h3>Informacion del proveedor</h3>
            <p>Nombre : {currentProvider?.name}</p>
            <p>Mail : {currentProvider?.mail}</p>
            <p>Tipo de proveedor : {currentProvider?.provider_type?.name}</p>
            <p>Modificar tipo de proveedor : </p>
            <div style={{display:'flex', gap:'20px', width:'100%'}}>
                <InputSelect 
                width='500px'
                value={selectedType}
                onChange={e=>setSelectedType(e)}
                options={providerTypes.map(type => {
                    return {
                        label:type.name,
                        value:type.id,
                    }
                })}/>
                <Button text="Cambiar" outline handler={()=>handleChangeType()}/>
            </div>
            <p>Añadir servicios :</p>
            <div style={{display:'flex', gap:'20px'}}>
                <Select
                    isMulti={true}
                    styles={colourStyles}
                    onChange={inputValue => setSelectedServices(inputValue.map(e => (e)))}
                    options={services?.map(service => {
                        return {
                            label: service.name,
                            value: service?.id,
                        }
                    })}
                    value={selectedServices}
                />
                <Button text="Añadir" handler={()=>handleAddServices()} outline/>
            </div>
            <Table columns={columns} items={items} actions={actions} searchBar={false} excel={false}/>
        </Modal.Body>
    </StyledModal>
  )
}
