import React, { useEffect, useState } from 'react'
import ComposedBarChart from '../../../components/Graphics/ComposedChart/ComposedChart';
import DashboardHorizontalBarChart from '../../../components/Graphics/HorizontalChart/DashboardHorizontalChart';
import { getCommercialKpis } from '../../../services/kpis.services';

import Button from '../../../components/Button/Button';
import { usePDF } from 'react-to-pdf';
import { useProSidebar } from 'react-pro-sidebar';
import { styled } from "@nextui-org/react";
import Card from './Card';
import { useSelector } from 'react-redux';
import styles from "../../Home/Home.module.scss";
import Input from "../../../components/Input/Input";
import { startOfMonth, format } from "date-fns";
import DatesRangePickerModal from "../../../components/DateRangePicker/DateRangePicker";
import { useTranslation } from "react-i18next";
import ColoredPieChart from '../../../components/Graphics/ColoredPieChart';
import UltraHorizontalBarChart from '../../../components/Graphics/HorizontalChart/UltraHorizontalBarChart';
import theme from '../../../theme';

const StyledContainer = styled("div", {
  display: 'grid',
  gridTemplateColumns: 'auto auto auto auto',
  gap: '15px',
  // gridTemplateRows: '160px 500px 450px',
  '.bigChart':{gridColumn : 'span 3'},
  "@media(max-width:1200px)":{
    display:'grid',
    gridTemplateColumns: '50% 50%',
    '.bigChart':{gridColumn : 'span 2'},
  },
  "@media(max-width:800px)":{
    display:'flex',
    flexDirection:'column',
    gap:'10px'
  }
})
const ChartContainer = styled("div", {
  background: 'white',
  borderRadius: '8px',
  padding: '20px',
  width: '100%',
  boxShadow:'$elevation_1',
})



export default function Dashboard() {
    const [kpis, setKpis] = useState({});
    const [error, setError] = useState(false);
    const [loadingData, setLoadingData] = useState(false)
    const [loading, setLoading] = useState(true)
    const { t: translate } = useTranslation("Admin");
    
    const colors = [theme.colors.primary_500.value, '#EB9D3A ', '#81D393','#5b658d',]

    function stringToNumber(string) {
      const match = string?.match(/([\d,]+\.\d+)/);
      if (match) {
        const numeroStr = match[1].replace(/,/g, '');
        return parseFloat(numeroStr);
      } else {
        return null;
      }
    }

    const getKpis = async () => {
      setLoading(true)
      try {
      setLoadingData(true)
      const kpiPromises = [
      getCommercialKpis( token, format(dates.startDate, "yyyy-MM-dd"), format(dates.endDate, "yyyy-MM-dd")).finally(() => {
        setLoadingData(false)
      })];
      const kpiResponses = await Promise.all(kpiPromises);
      const updatedKpis = {};
      await Promise.all(
        kpiResponses.map(async (res, index) => {
          const key = [
            "statistics",
            "user_count",
            "total_quotations",
            "user_quotations",
            "total_profit",
            "quotations_per_concept",
            "quotations_per_provider",
            "avg_profit_per_concept",
            "avg_profit_per_provider", 
            "total_profit_user",
            "services_per_user_quotation",
            "services_per_user_qrfq",
          ][index];
          updatedKpis[key] = await res?.json();
        })
      );
      setKpis(updatedKpis);
      setLoading(false)
      setError(false);
    } catch (error) {
      setLoading(false)
      setError(error.message);
      console.error(`Error fetching data: ${error.message}`);
    }};
    const [openDates, setOpenDates] = useState(false);
    const [dates, setDates] = useState({
      startDate: startOfMonth(new Date()),
      endDate: new Date(),
    });
    const { toPDF, targetRef } = usePDF({filename: 'admin_dashboard.pdf'});
    const { collapseSidebar, collapsed } = useProSidebar();
    const token = useSelector(state => state.user.token)
    const [loadingPdf, setLoadingPdf] = useState(false)
    
    useEffect(() => {
      getKpis();
      if (!collapsed) {
        collapseSidebar()
      }
    }, [dates]);
    
    function downloadPDF(){
        setLoadingPdf(true)
        toPDF().then(()=>{
            setLoadingPdf(false)
        }).catch((err)=>{
            console.log("error al descargar pdf :",err)
            setLoadingPdf(false)
        })
    }
    const formatNumber = (value) => {
      const numValue = Number(value);
      if (numValue >= 1000) {
        const formattedValue = (numValue / 1000).toFixed(2);
        return `${formattedValue}K`;
      } else {
        return numValue.toFixed(2);
      }
    };
    console.log("kpis", kpis)

    function formatKpiHorizontalBarChart(data, dataKey){
      const formatted = data && Object.keys(data).map(key => {
        return {
          name: key,
          [dataKey]: data[key],
        }
      }).filter(item => item[dataKey]).sort((a,b)=> b[dataKey] - a[dataKey]).slice(0,12)
      return formatted
    }
    function formatKpiHorizontalBarChartProfit(data, dataKey){
      const formatted = data && Object.keys(data).map(key => {
        return {
          name: key,
          [dataKey]: stringToNumber(data[key]),
        }
      }).filter(item => item[dataKey]).sort((a,b)=> b[dataKey] - a[dataKey]).slice(0,12)
      return formatted
    }
    const profitPerService = formatKpiHorizontalBarChartProfit(kpis?.statistics?.avg_profit_per_concept, "Profit")
    const profitPerProvider = formatKpiHorizontalBarChartProfit(kpis?.statistics?.avg_profit_per_provider, "Profit")
    const quotationsByProvider = kpis?.statistics?.quotations_per_provider && Object.keys(kpis?.statistics?.quotations_per_provider).map(key=>{
        return {
          label:key,
          quotations:kpis?.statistics?.quotations_per_provider[key],
        }
    })?.filter(e=>e.quotations)?.slice(0,12)
    
    const quotationsPerService = kpis?.statistics?.quotations_per_concept?.length && kpis?.statistics?.quotations_per_concept?.map(e=>{
      return {
        name:e.concept,
        quotations:e.count,
      }
    }).sort((a,b)=>b.quotations-a.quotations).filter(e=>e.quotations)?.slice(0,12)
    console.log("quotationsPerService :",quotationsPerService)
    const servicesPerUser = kpis?.statistics?.services_per_user_quotation && Object.keys(kpis?.statistics?.services_per_user_quotation).map(key => {
      return {
        name: key,
        "Cantidad de cotizaciones por usuario": kpis.statistics.services_per_user_quotation[key],
      }
    }).filter(item => item["Cantidad de cotizaciones por usuario"]).sort((a,b)=> b["Cantidad de cotizaciones por usuario"] - a["Cantidad de cotizaciones por usuario"]).slice(0,12)
    
    let currency = "";
    if (kpis?.statistics?.total_profit){
      let matchCurrency = kpis?.statistics?.total_profit.match(/([A-Z]{3}|None)$/);
      if (matchCurrency) {
        console.log("bbb")
        currency = matchCurrency[0];
      }
    }
  
    return ( 
    <div>
    <div className={styles.dateclass}>
      <div className="dateFilter">
        <span>{("Fechas")}</span>
        <div
          className="datePicker"
          onClick={() => {setOpenDates(true)}}
          style={{ display: "flex", gap: "20px", width: "fit-content" }}
        >
          <Input label={translate("from")} value={format(dates.startDate, "dd/MM/yyyy")}/>
          <Input label={translate("to")} value={format(dates.endDate, "dd/MM/yyyy")}/>
        </div>
        <DatesRangePickerModal
          aria-labelledby="modal-date-range"
          setDates={setDates}
          open={openDates}
          setOpen={setOpenDates}
        />
      </div>
      <div className={styles.btnContainer}>
        <div className="download">
          <Button
            handler={() => downloadPDF()}
            text={loadingPdf ? "Cargando..." : translate("download-button")}
            endIcon={{ name: "Download" }}
            outline
            disabled={loadingPdf}
          />
        </div>
      </div>
      </div>
        <StyledContainer ref={targetRef}>
          <div><Card title={translate("quotations")} number={kpis?.statistics?.total_quotations &&
          formatNumber(kpis?.statistics?.total_quotations?.toFixed(2) ?? 0)} color={theme.colors.primary_500.value} icon='icon' aditional={translate("quotations_legend")} loading={loadingData}/></div>
          <div ><Card title={translate("$_profit")} number={kpis?.statistics?.total_profit && `${formatNumber(stringToNumber(kpis?.statistics?.total_profit))} ${currency}`} color={theme.colors.primary_300.value} icon='icon' aditional={translate("profit_legend")} loading={loadingData}/></div>
          <div><Card title={translate("average_profit")} number={`${formatNumber(stringToNumber(kpis?.statistics?.total_profit) / kpis?.statistics?.user_quotations.toFixed(2))} ${currency}`} color={theme.colors.primary_500.value} icon='icon' aditional={translate("average_profit_legend")} loading={loadingData}/></div>
          <div><Card title={translate("new_users")}  number={kpis?.statistics?.user_count &&
          formatNumber(kpis?.statistics?.user_count?.toFixed(2) ?? 0)} color={theme.colors.primary_300.value} icon='icon' aditional={translate("users_per_month")} loading={loadingData}/></div>
            <ChartContainer>
                <p style={{ margin: '15px', color: 'grey' }}>{translate("quotations_by_service")}</p>
                <DashboardHorizontalBarChart height={400} color={theme.colors.primary_500.value} dataKey={"quotations"} data={quotationsPerService}/>
            </ChartContainer>
            <ChartContainer>
                <p style={{ margin: '15px', color: 'grey' }}>{translate("profit_by_service")}</p>
                <DashboardHorizontalBarChart dataKey={"Profit"} data={profitPerService} color={theme.colors.primary_300.value}/>
            </ChartContainer>
            <ChartContainer>
                <p style={{ margin: '15px', color: 'grey' }}>{translate("profit_by_provider")}</p>
                <DashboardHorizontalBarChart height={400} color={theme.colors.primary_500.value} dataKey={"Profit"} data={profitPerProvider}/>
            </ChartContainer>
            <ChartContainer>
                <p style={{ margin: '15px', color: 'grey' }}>{translate("quotations_by_user")}</p>
                <DashboardHorizontalBarChart width={400} height={400} color={theme.colors.primary_300.value} dataKey={translate("quotations_amount_by_user")} 
                data={servicesPerUser }/>
            </ChartContainer>
            <ChartContainer className='bigChart'>
                <p style={{ margin: '15px', color: 'grey' }}>{translate("quotations_by_provider")}</p>
                <UltraHorizontalBarChart
                  data={quotationsByProvider}
                  barDataKeys={[{ key: 'quotations', color: theme.colors.primary_500.value }]}
                  lineDataKeys={[]}
                />
            </ChartContainer>
            <ChartContainer>
                <p style={{ margin: '15px', color: 'grey' }}>Qrfq's por usuario</p>
                <ColoredPieChart colors={colors} data={
                    kpis?.statistics?.services_per_user_qrfq && Object.keys(kpis?.statistics?.services_per_user_qrfq).map(key => {
                        return {
                            name: key,
                            value: kpis.statistics.services_per_user_qrfq[key],
                        }
                    })
                } />
            </ChartContainer>
        </StyledContainer>
        </div>
    )
}
