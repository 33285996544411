import { URL } from "../constants"

export const getIndustries = async (token) => {
    const response = await fetch(`${URL.get()}industries/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })

    return response
}