import React, { useState } from "react";
import { TextField, InputAdornment, Popover } from "@mui/material";
import { FaCalendarAlt } from "react-icons/fa";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { format } from "date-fns";

const DateFilter = ({ filterDate, setFilterDate }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [tempDateRange, setTempDateRange] = useState(filterDate); // Mantén un estado temporal

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setFilterDate(tempDateRange); // Actualiza la fecha al cerrar
  };

  const handleSelect = (ranges) => {
    const { startDate, endDate } = ranges.selection;
    setTempDateRange([startDate, endDate]); // Actualiza solo la fecha temporalmente
  };

  const open = Boolean(anchorEl);
  const id = open ? "date-range-popover" : undefined;

  return (
    <div>
      <TextField
        value={`${format(filterDate[0], "yyyy-MM-dd")} / ${format(
          filterDate[1],
          "yyyy-MM-dd"
        )}`}
        onClick={handleClick}
        variant="outlined"
        size="small"
        sx={{
          backgroundColor: "white",
          width: "100%",
          height: "100%",
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FaCalendarAlt style={{ color: "gray" }} />
            </InputAdornment>
          ),
        }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <DateRangePicker
          showSelectionPreview={true}
          onChange={handleSelect}
          ranges={[
            {
              startDate: tempDateRange[0],
              endDate: tempDateRange[1],
              key: "selection",
            },
          ]}
          months={2}
          direction="horizontal"
          moveRangeOnFirstSelection={false}
        />
      </Popover>
    </div>
  );
};

export default DateFilter;
