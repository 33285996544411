import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { styled } from '@nextui-org/react';
// Components
import theme from '../../../theme';
import Input from '../../../components/Input/Input';
import PageTitle from '../../../components/PageTitle/PageTitle';
import Dropdown from '../../../components/Dropdown/Dropdown';
import Button from '../../../components/Button/Button';
import TableServices from './TablePayments/TablePayments';
import Summary from '../Summary/Summary';

const StyledServices = styled('article', {
    [`.${theme} &`]: {
        padding: '3rem 2rem',
        display: "flex",
        height: "100%",
        flexDirection: "column",
        gap: '1.5rem',
        ['.header']: {
            display: 'flex',
            gap: '1.5rem'
        },
    }
})

const Payments = () => {
    const [searchParams] = useSearchParams()
    const [filterType, setFilterType] = useState("Seleccionar");
    const [filterSearch, setFilterSearch] = useState('')

    const [options, setoptions] = useState([
        { key: "Seleccionar", name: "Seleccionar" },
        { key: "Carga general", name: "Carga general" },
        { key: "Carga proyecto", name: "Carga proyecto" }
    ]);

    const handleChange = (e) => {
        const { value } = e.target;
        setFilterSearch(value)
    }

    useEffect(() => {
        setTimeout(() => {
            let message;

            if (searchParams.get('created') === 'liquidation') {
                message = '¡Se ha aprobado con éxito tu liquidación!'
                toast('success', message)
            } else if (searchParams.get('created') === 'pre-liquidation') {
                const title = 'Tu pre-liquidación ha sido aprobada con éxito'
                message = <>Recuerda que en las próximas <b>24 horas</b> recibiras tu liquidación final.</>
                toast('success', message, title)
            }
        }, 1000)
    }, []);

    return (
        <StyledServices>
            { /* 
                <div className="header">
                    <Input placeholder='Buscar' startIcon={{ name: 'Search' }} onChange={handleChange} fullWidth></Input>
                    <Button text={'Descargar'} endIcon={{ name: 'Download' }} outline bold></Button>
                </div>*/ }

            <div className="flex-1 overflow-h">
                <TableServices filterType={filterType} filterSearch={filterSearch}></TableServices>
            </div>
        </StyledServices>
    );
}

export default Payments;
