import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getPurchaseOrders,
  getServiceSplit,
} from "../../../services/quotation.services";

// Components
import Table from "../../../components/Table/Table";

import { useTranslation } from "react-i18next";
import Pagination from "../../../components/Pagination/Pagination";
import { ROLES } from "../../../constants";
import { getCountriesNew } from "../../../services/countries.services";
import SubscriptionModal from "./SubscriptionModal";
import { getQuoteData } from "../../../services/quotations.services";
import { getPreliquidations } from "../../../services/preliquidation.services";
import { getPreliquidationCosts } from "../../functions";
import TagsModal from "../../Quotes/Quotation/TableQuotation/TagsModal";
import TableCardService from "../../../components/Table/TableCardService";
import { getEmissions } from "../../../services/CO2.services";
import { Button, ButtonGroup, Skeleton } from "@mui/material";
import { FiDownload } from "react-icons/fi";

const TableServices = ({
  filterSearch,
  loading,
  setLoading,
  originCountries,
  destinationCountries,
  filterCategorySearch,
  selectedLoadTypes,
  filterMethod,
  filterDate,
  filterCost,
  refreshData
}) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const token = user.token;
  const { t: translate } = useTranslation("Services");

  const [open, setOpen] = useState(false);
  const [currentService, setCurrentService] = useState();
  const [openTags, setOpenTags] = useState(false);
  const [currentItem, setCurrentItem] = useState([]);
  const [cardView, setCardView] = useState(false);
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);

  let columns = [
    { name: translate("actions"), uid: "actions", type: "action" },
    {
      name: translate("radication_id"),
      uid: "idRadication",
      type: "text",
      style: { width: "auto" },
    },
    { name: translate("po"), uid: "purchaseOrder", type: "text" },
    { name: translate("load"), uid: "load", type: "text" },
    { name: translate("date"), uid: "date", type: "date" },
    // { name:translate("hour"), uid: "hour", type: "text" },
    { name: translate("origin"), uid: "origin", type: "country" },
    { name: translate("method"), uid: "method", type: "icon" },
    { name: translate("destination"), uid: "destination", type: "country" },
    { name: translate("cost"), uid: "cost", type: "text" },
    { name: translate("advisor"), uid: "advisor", type: "text" },
  ];

  let isClient = false;
  user.roles.forEach((role) => {
    if ([ROLES.User || ROLES.Client].includes(role)) {
      isClient = true;
    }
  });

  if (isClient) {
    columns = [
      { name: translate("actions"), uid: "actions", type: "action" },
      {
        name: "Referencia",
        uid: "ref",
        type: "text",
        style: { width: "auto" },
      },
      { name: translate("load"), uid: "load", type: "text" },
      { name: translate("date"), uid: "date", type: "date" },
      { name: translate("origin"), uid: "origin", type: "country" },
      { name: translate("destination"), uid: "destination", type: "country" },
      { name: translate("cost"), uid: "cost", type: "text" },
      { name: translate("advisor"), uid: "advisor", type: "text" },
    ];
  }

  const actions = [
    {
      name: translate("view_details"),
      icon: "View",
      handle: (item) =>
        navigate(
          `/services/detail/chat/?radication=${item.idRadication}&tab=chat`
        ),
    },
    {
      name: translate("tracking"),
      icon: "Tracking",
      handle: (item) =>
        navigate(`/tracking/service/?radication=${item.idRadication}&tab=chat`),
    },
    {
      name: translate("tags"),
      icon: "EditSquare",
      handle: (item) => {
        setCurrentItem(item);
        setOpenTags(true);
      },
    },
    {
      name: translate("subscribe"),
      icon: "Mail",
      handle: (item) => {
        setCurrentService(item), setOpen(true);
      },
    },
  ];

  const formatDate = (date) => {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };
  const formatMethods = (methods) => {
    if (methods && methods.length > 0) {
      return methods.map((method) => method.value);
    }
    return [];
  };

 

  const [startDate, endDate] = filterDate;
  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);
  const formattedMethods = formatMethods(filterMethod); 
  
  
  

  async function getData(
    currentPage,
    pageSize,
    filterSearch,
  ) {
    setLoading(true);
    try {
      const asyncTasks = [
        getServiceSplit(
          token,
          currentPage,
          pageSize,
          filterSearch, 
          false,
          formattedStartDate,
          formattedEndDate, 
          formattedMethods,
          originCountries,
          destinationCountries,

        ),
        getCountriesNew(token),
        getQuoteData(token),
        getPreliquidations(token),
        getEmissions(token),
      ];
      const res = await Promise.all(asyncTasks);
      const servicesData = await res[0].json();
      const countriesData = await res[1].json();
      const liquidationData = await res[2].json();
      const preliquidationData = await res[3].json();
      const emissionsData = await res[4].json();

      const filteredServices = servicesData?.results;
      const tableData = filteredServices?.map((service) => {
        const purchaseOrder = servicesData.results.find(
          (result) => result.id === service.id
        )?.purchase_order?.purchaseNumber;
        const methodId = service?.shipping?.shipping_method;

        const method =
          methodId === 1
            ? { name: "Aereo", text: "Aereo" }
            : methodId === 2
            ? { name: "Maritimo", text: "Maritimo" }
            : methodId === 3
            ? { name: "Terrestre", text: "Terrestre" }
            : methodId === 4
            ? { name: "Bbk", text: "Bbk" }
            : methodId === 5
            ? { name: "Roro", text: "Roro" }
            : methodId === 6
            ? { name: "Containers", text: "Contenedores especiales" }
            : { name: "Close", text: "No especificado" };

        const originCountry = countriesData.find(
          (country) =>
            country.name.toLowerCase() ===
            service?.shipping?.origin_boarding_terminal?.city?.country?.name.toLowerCase()
        );
        const destinationCountry = countriesData.find(
          (country) =>
            country.name.toLowerCase() ===
            service?.shipping?.destination_boarding_terminal?.city?.country?.name.toLowerCase()
        );

        const approvedLiquidations = liquidationData?.filter(
          (liquidation) => liquidation.customer_approval
        );
        const serviceLiquidation = approvedLiquidations.find(
          (liquidation) => liquidation.radication === service.radication
        );

        let cost = 0;
        if (serviceLiquidation?.preliquidation_ids) {
          const prelId = serviceLiquidation.preliquidation_ids[0];
          const prel = preliquidationData?.find((prel) => prel.id === prelId);
          const { totalCost } = getPreliquidationCosts(prel);
          cost = totalCost;
        }
        setLoading(false);
        return {
          ...service,
          originCountry,
          method,
          destinationCountry,
          purchaseOrder,
          cost,
        };
      });
      const dataTable = tableData.map((service) => {
        const user =
          service.users.find((user) => user.user?.groups?.includes("Asesores"))
            ?.user ||
          service.users.find((user) => user.user?.groups?.includes("Pricing"))
            ?.user ||
          service.users.find((user) =>
            user.user?.groups?.includes("Operations")
          )?.user;

        const purchaseOrder = service.purchaseOrder;

        let loadWeight = 0;
        let loadVolume = 0;
        service?.loads?.map((load) => {
          loadWeight += load.total_weight;
          loadVolume += load.long * load.width * load.height;
        });
        return {
          ...service,
          id: service.id,
          purchaseOrder,
          ref:
            purchaseOrder && purchaseOrder !== "Ninguna"
              ? purchaseOrder
              : service.radication,
          idRadication: service.radication,
          load: service.loads.length > 0 ? service.loads[0].load_type.name : "",
          loads: {
            amount: service?.loads?.length,
            weight: loadWeight,
            volume: loadVolume,
          },
          date: service.created_at,
          method: service.method,
          origin: service.originCountry,
          destination: service.destinationCountry,
          advisor: `${user?.first_name || ""} ${user?.last_name || ""}`,
          cost: service.cost ? service.cost?.toFixed(2) + " USD" : "",
        };
      });
      console.log("dataTable :", dataTable);
      setLoading(false);
      setItems({ data: dataTable, count: servicesData?.count });
      console.log("ITEMS DESOUES",items)
      return { data: dataTable, count: servicesData?.count };
    } catch (error) {
      console.error("Error fetching data", error);
      throw error;
    }
  }

  useEffect(() => {
    getData(page, 10);
    console.log("SE APLICO EL USE EFEECT")
  }, [page, refreshData]);

 

  return (
    <div className="h-100 p-0">
      <div className="flex justify-start p-0 m-0 ">
        <div className="flex justify-center items-center gap-1">
          <ButtonGroup aria-label="Vista de Resultados">
            <Button
              value="table"
              aria-label="Tabla"
              size="small"
              variant={cardView ? "outlined" : "contained"}
              onClick={() => setCardView(false)}
              color="primary"
              className="rounded-3xl"
              sx={{
                backgroundColor: cardView ? "transparent" : "#292E68",
                color: cardView ? "#292E68" : "#fff",
                borderColor: "#292E68",
                "&:hover": {
                  backgroundColor: cardView ? "#292E68" : "#303C90",
                  borderColor: "#292E68",
                },
                borderRadius: "9999px",
              }}
            >
              Tabla
            </Button>
            <Button
              value="card"
              aria-label="Gráfica"
              size="small"
              variant={cardView ? "contained" : "outlined"}
              onClick={() => setCardView(true)}
              color="primary"
              className="rounded-3xl"
              sx={{
                backgroundColor: cardView ? "#292E68" : "transparent",
                color: cardView ? "#fff" : "#292E68",
                borderColor: "#292E68",
                "&:hover": {
                  backgroundColor: cardView ? "#303C90" : "#ffff",
                  borderColor: "#292E68",
                },
                borderRadius: "9999px",
              }}
            >
              Cards
            </Button>
          </ButtonGroup>
          <div>
            <Button
              endIcon={<FiDownload />}
              variant="outlined"
              size="small"
              sx={{
                backgroundColor: "transparent",
                color: "#292E68",
                borderColor: "#292E68",
                "&:hover": {
                  backgroundColor: "#303C90",
                  borderColor: "#292E68",
                  color: "#fff",
                },
                borderRadius: "9999px",
              }}
            >
              Descargar
            </Button>
          </div>
        </div>
      </div>
      {/* <TableCardService/> */}
      <SubscriptionModal
        open={open}
        setOpen={setOpen}
        service={currentService}
      />
      {cardView ? (
        <div>
          {loading ? (
            <Skeleton variant="rectangular" width="100%" height={"50vh"} />
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "30px",
                marginTop: "20px",
              }}
            >
              {items?.data?.map((item) => {
                return <TableCardService item={item} actions={actions} />;
              })}
            </div>
          )}
          <Pagination
            page={page}
            setPage={setPage}
            itemsPerPage={10}
            totalItems={items.count}
          />
        </div>
      ) : (
        <Table
          columns={columns}
          items={items.data}
          actions={actions}
          serverPagination={true}
          searchBar={false}
          getData={getData}
          filterSearch={filterSearch}
          itemsCount={items.count}
        />
      )}

      <TagsModal
        open={openTags}
        setOpen={setOpenTags}
        serviceId={currentItem?.id}
      />
    </div>
  );
};

export default TableServices;
