import React from 'react'
import { Modal, styled } from '@nextui-org/react'
import Icon from '../../../../components/Icon/Icon'
import Button from '../../../../components/Button/Button'

const StyledModal = styled(Modal, {
    color: '$primary_500 !important',
    padding: '20px',
    display: 'flex',
    maxWidth:'400px',
})

export default function WarningModal({ open, setOpen }) {

    return (
        <StyledModal
            open={open}
            onClose={() => setOpen(false)}
            closeButton
            placement="left"
        >
            <Modal.Body justify='space-between'>
                <div style={{display:'flex', gap:'20px'}}>
                    <h3>Advertencia</h3>
                    <Icon name='WarnModal' size='30px'/>
                </div>
                <p>Revisar bien la información tanto de este paso como del siguiente antes de guardar</p>
            </Modal.Body>
            <Modal.Footer>
                <Button text="Aceptar" bgColor='primary_500' textColor="white" handler={()=>setOpen(false)}/>
            </Modal.Footer>
        </StyledModal>
    )
}
