import React, { useState } from "react";
import {
  InputAdornment,
  FormControl,
  Select,
  OutlinedInput,
  ListItemIcon,
  MenuItem,
} from "@mui/material";
import { FaTruckLoading } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const loadTypes = [
  { value: 1, text: "Carga general" },
  { value: 2, text: "Carga proyecto" },
];

const LoadFilter = ({ selectedLoadTypes, setSelectedLoadTypes }) => {
  const { t: translate } = useTranslation("Services");


  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    const selected = typeof value === "string" ? value.split(",") : value;

    const selectedWithDetails = selected.map((val) => {
      const loadType = loadTypes.find((type) => type.value === val);
      return { value: val, text: loadType?.text || "" };
    });

    setSelectedLoadTypes(selectedWithDetails);
  };

  return (
    <FormControl sx={{ width: "100%" }}>
      <Select
        multiple
        displayEmpty
        value={selectedLoadTypes.map(item => item.value)}
        onChange={handleChange}
        input={
          <OutlinedInput
            startAdornment={
              <InputAdornment position="start">
                <FaTruckLoading />
              </InputAdornment>
            }
          />
        }
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <p className="text-gray-500">{translate("select")} {translate("load")} </p>;;
          }
          return selected.map((code, index) => {
            const loadType = loadTypes.find((type) => type.value === code);
            if (loadType) {
              return (
                <span key={code}>
                  {loadType.text}
                  {index < selected.length - 1 ? ", " : ""}
                </span>
              );
            }
            return null;
          });
        }}
        MenuProps={MenuProps}
        inputProps={{ "aria-label": "Without label" }}
        sx={{ backgroundColor: "white" }}
        size="small"
      >
        {loadTypes.map((loadType) => (
          <MenuItem key={loadType.value} value={loadType.value}>
            <div className="flex justify-between w-[100%]">
              <div className="flex gap-1">
                {loadType.text}
              </div>
              <ListItemIcon>
              {selectedLoadTypes.map(item => item.value).includes(loadType.value) ? <FaCheck /> : null}
              
                            </ListItemIcon>
            </div>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default LoadFilter;
