import React, { useRef, useEffect, useState } from 'react';
import ColorThief from 'colorthief';
import theme from '../../theme';
import { NextUIProvider } from '@nextui-org/react';

const ImageColorExtractor = ({ src,dominantColor,setDominantColor }) => {
  const imgRef = useRef();

  useEffect(() => {
    const img = imgRef.current;

    if (img.complete) {
      extractColor();
    } else {
      img.addEventListener('load', extractColor);
    }

    function extractColor() {
      const colorThief = new ColorThief();
      const color = colorThief.getColor(img);
      const rgbColor = `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
      setDominantColor(rgbColor);
    }
  }, [src]);

  return (
      <div>
        <h1>Extracción de colores de una imagen</h1>
        <img ref={imgRef} src={src} alt="Imagen" crossOrigin="anonymous" />
        <p style={{color:dominantColor}}>Color dominante: {dominantColor}</p>
        
      </div>
  );
};

export default ImageColorExtractor;
