import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { styled } from "@nextui-org/react";
import theme from "../../../../../theme";
import { useSelector } from "react-redux";
import Pusher from "pusher-js";
import Button from "../../../../../components/Button/Button";
import Icon from "../../../../../components/Icon/Icon";
import Input from "../../../../../components/Input/Input";
import Bubble from "./Bubble";
import { useForm } from "react-hook-form";
import { URL } from "../../../../../constants";
import { useFetch } from "../../../../../hooks/useFetch";
import toast from "../../../../../components/Toast/ToastTypes";
import { useNavigate } from "react-router-dom";

const StyledChatContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  height: "70vh",
  justifyContent: "space-between",
  position: "relative",
});

const StyledDragnDrop = styled("div", {
  display: "none",
  background: "rgba(0, 0, 0, 0.8)",
  position: "absolute",
  width: "calc(100vw - 250px)",
  height: "100%",
  zIndex: "111",
  color: "rgba(255,255,255, 0.5)",
  ["&.dragging"]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
});

const StyledDocumentSend = styled("div", {
  display: "none",
  background: "rgba(0, 0, 0, 0.8)",
  position: "absolute",
  width: "calc(100vw - 250px)",
  height: "100%",
  zIndex: "222",
  color: "white",
  ["&.dropped"]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "1rem",
  },
  img: {
    width: 130,
  },
  [".buttons_dropped"]: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
  },
});

const StyledInputZone = styled("form", {
  [`.${theme} &`]: {
    display: "flex",
    padding: "1.75rem 2rem",
    background: "$neutral_50",
    height: "96px",
    gap: "1rem",

    ["& div:first-child"]: {
      width: "100%",
    },
  },
});

const StyledChat = styled("div", {
  [`.${theme} &`]: {
    padding: "2rem",
    background: "$primary_50",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    overflowY: "scroll",
    flex: "1",
  },
});

const ClipButton = styled("button", {
  [`.${theme} &`]: {
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "0.5rem",
    transition: "opacity 0.25s ease 0s, transform 0.25s ease 0s",
    "&:hover": {
      opacity: 0.8,
      transform: "scale(0.9)",
    },
    "&:active": {
      transform: "scale(0.95)",
    },
  },
});

const pusher = new Pusher("8f28c7342c752ebca446", {
  cluster: "us2",
});

const useFetchSendMessage = (message, token, radication) => {
  fetch(URL.get() + "send/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
    body: JSON.stringify({
      user: token,
      message: message,
      radication: radication,
    }),
  });

  return { userMessageInput, userName };
};

function Chat({ radication }) {
  const navigate = useNavigate();
  const chatRef = useRef();

  const { register, handleSubmit, reset } = useForm();

  const [classChat, setClassChat] = useState("");
  const [classDropped, setClassDropped] = useState("");
  const [file, setFile] = useState(null);

  const [messages, setMessages] = useState([]);
  const token = useSelector((state) => state.user.token);
  const idUser = useSelector((state) => state.user.idUser);
  const channel = pusher.subscribe("chat" + radication);
  const { data, loading } = useFetch(
    URL.get() + "chat/messages/" + radication + "/",
    {
      Authorization: "Token " + token,
    }
  );

  const useFetchSendDocument = async (file, token, radication) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("name", file.name);
      formData.append("radication", radication);

      const response = await fetch(URL.get() + "send-document/", {
        method: "POST",
        headers: {
          Authorization: "Token " + token,
        },
        body: formData,
      });

      if (response.ok) {
        setFile(null);
        setClassDropped("");
      } else {
        console.log("Error en la solicitud de fetch:", response.statusText);
      }
    } catch (error) {
      console.log("Error:", error);
    }

    return { userMessageInput, userName };
  };

  useEffect(() => {
    if (!loading && !Array.isArray(data)) {
      /* navigate("/services"); */
      // toast("warn", "Este servicio no existe");
    }
    if (!loading && Array.isArray(data)) {
      const mappedData = data?.map((item) => ({
        user: item.user,
        message: item.message,
        time: item.time,
        name: item.name,
        type: item.type,
      }));
      setMessages(mappedData);
    }
  }, [data, chatRef.current]);

  useEffect(() => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    channel.bind("chatting", function (response) {
      let newMessage = response.data;
      setMessages((prevMessages) => [...prevMessages, newMessage]);
    });
  }, []);

  const sendMessage = (data) => {
    reset((formValues) => ({
      ...formValues,
      chat: "",
    }));
    useFetchSendMessage(data.chat, token, radication);
  };

  const handleDrag = (e) => {
    if (!file) {
      if (e.type === "dragover") {
        e.preventDefault();
        setClassChat("dragging");
      } else if (e.type === "dragleave") {
        setClassChat("");
      } else if (e.type === "drop") {
        e.preventDefault();
        setClassChat("");
        const fileType = e.dataTransfer.files[0].type;
        console.log(fileType);
        if (
          fileType === "application/pdf" ||
          fileType === "application/msword" ||
          fileType ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
          fileType === "image/jpeg" ||
          fileType === "image/png" ||
          fileType === "application/vnd.ms-excel" ||
          fileType === "application/zip" ||
          fileType === "application/x-zip-compressed" ||
          fileType === "application/x-rar-compressed" ||
          fileType === "application/vnd.ms-powerpoint" ||
          fileType ===
            "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
          fileType ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
          setFile(e.dataTransfer.files[0]);
          setClassDropped("dropped");
          console.log(fileType);
        } else {
          setFile(null);
          toast(
            "error",
            "Lo sentimos, el formato del archivo debe ser PDF, Word, JPEG, PNG, Excel, ZIP o RAR"
          );
        }
      }
    }
  };

  const handleSetFile = (e) => {
    const fileType = e.target.files[0].type;
    console.log(fileType);
    if (
      fileType === "application/pdf" ||
      fileType === "application/msword" ||
      fileType ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      fileType === "image/jpeg" ||
      fileType === "image/png" ||
      fileType === "application/vnd.ms-excel" ||
      fileType === "application/zip" ||
      fileType === "application/x-zip-compressed" ||
      fileType === "application/x-rar-compressed" ||
      fileType === "application/vnd.ms-powerpoint" ||
      fileType ===
        "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
      fileType ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setFile(e.target.files[0]);
      setClassDropped("dropped");
      console.log(fileType);
    } else {
      setFile(null);
      toast(
        "error",
        "Lo sentimos, el formato del archivo debe ser PDF, Word, JPEG, PNG, Excel, ZIP o RAR"
      );
    }
  };

  return (
    <StyledChatContainer onDragOver={handleDrag}>
      <StyledDragnDrop
        onDragLeave={handleDrag}
        onDrop={handleDrag}
        className={classChat}
      >
        <h2>Arrastra tu documento aqui</h2>
      </StyledDragnDrop>
      <StyledDocumentSend className={classDropped}>
        <p>{file?.name || "unknown"}</p>
        <div className="buttons_dropped">
          <Button
            handler={() => {
              setFile(null);
              setClassDropped("");
            }}
            outline
            textColor="secondary_300"
            bgColor="transparent"
            endIcon={{ name: "Close", color: "#EB9D3A" }}
          />
          <Button
            handler={async () => {
              await useFetchSendDocument(file, token, radication);
            }}
            bgColor="primary_400"
            endIcon={{ name: "Send", color: "white" }}
          />
        </div>
      </StyledDocumentSend>
      <StyledChat ref={chatRef}>
        {messages.map((message, index) => {
          console.log("message :", message);
          return (
            <Bubble
              key={index}
              isUser={message.user === idUser}
              message={message.message}
              name={message.name}
              time={message.time}
              type={message.type}
            />
          );
        })}
      </StyledChat>
      <StyledInputZone onSubmit={handleSubmit(sendMessage)}>
        <Input
          register={register}
          name="chat"
          placeholder="Escribe un nuevo mensaje"
          icon={
            <ClipButton>
              <Icon name="Clip" />
            </ClipButton>
          }
          endIcon={
            <div style={{ position: "relative" }}>
              <Icon name="Clip" />
              <input
                type="file"
                onChange={handleSetFile}
                style={{
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  right: 0,
                  left: 0,
                  opacity: 0,
                }}
              ></input>
            </div>
          }
        />
        <Button
          type="submit"
          bgColor="primary_400"
          endIcon={{ name: "Send", color: "white" }}
        />
      </StyledInputZone>
    </StyledChatContainer>
  );
}

export default Chat;
