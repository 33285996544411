import React from 'react'
import InputTable from '../../../components/InputTable/InputTable'
import TotalsTable from './TotalsTable'



export default function FreightCostsTable() {
    const columns = [
        {name:"Flete", uid:"freight" , type:'number'},
        {name:"Seguro", uid:"insurance" , type:'number'},
        {name:"Otros", uid:"others" , type:'number'},
    ]
    return (
        <div>
            <InputTable tableName={'freight_costs'} columns={columns}/>
        </div>
    )
}