import React, { useState } from "react";
import {
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  SelectChangeEvent,
} from "@mui/material";
import { CiSearch } from "react-icons/ci";
import { useTranslation } from "react-i18next";

interface GeneralSearchProps {
  selectedOption: string;
  setSelectedOption: (value: string) => void;
  filterValue: string;
  setFilterValue: (value: string) => void;
}

const GeneralSearch: React.FC<GeneralSearchProps> = ({
  selectedOption,
  setSelectedOption,
  filterValue,
  setFilterValue,
}) => {
  const { t: translate } = useTranslation("Services");

  const handleOptionChange = (event: SelectChangeEvent<string>) => {
    setSelectedOption(event.target.value as string);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(event.target.value);
  };

  return (
    <div style={{ display: "flex", width: "100%" }}>
      <Select
        value={selectedOption}
        onChange={handleOptionChange}
        displayEmpty
        size="small"
        inputProps={{ "aria-label": "Select Filter" }}
        sx={{
          backgroundColor: "#f8f7f8",
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          height: "100%",
        }}
      >
        <MenuItem value="" disabled>
          {translate("category")}
        </MenuItem>
        <MenuItem value="opcion1">{translate("radication_id")}</MenuItem>
        <MenuItem value="opcion2">{translate("po")}</MenuItem>
        <MenuItem value="opcion3">{translate("advisor")}</MenuItem>
      </Select>
      <TextField
        value={filterValue}
        onChange={handleInputChange}
        placeholder="Buscar"
        size="small"
        variant="outlined"
        sx={{
          backgroundColor: "white",
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          width: "100%",
          borderLeft: 0,
          "& fieldset": {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderLeft: 0,
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CiSearch style={{ color: "gray" }} />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default GeneralSearch;
