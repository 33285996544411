import React, { useRef } from "react";
import { styled } from "@nextui-org/react";
import ColoredPieChart from "../../../components/Graphics/ColoredPieChart";
import useDraggableScroll from "use-draggable-scroll";
import { useTranslation } from "react-i18next";
const StyledContainer = styled("div", {
  display: "flex",
  gap: "15px",
  overflowX: "scroll",
  padding: "20px 0px",
  // backgroundColor: '$neutral_100',
  borderRadius: "8px",
  ["&::-webkit-scrollbar"]: {
    height: "5px",
  },
  ["&::-webkit-scrollbar-track"]: {
    borderRadius: "10px",
  },
  ["&::-webkit-scrollbar-thumb"]: {
    background: "#5E6EAB",
    borderRadius: "50px",
  },
  "&:hover": {
    borderBottom: "1px solid white",
    cursor: "pointer",
  },
});

const StyledCard = styled("div", {
  background: "white",
  borderRadius: "8px",
  margin: "auto",
  padding: "20px",
  width: "100%",
  minWidth: "280px",
  height: "400px",
  boxShadow: "$elevation_1",
  ".header": {
    display: "flex",
    justifyContent: "space-between",
    div: {
      color: "$primary_300",
      fontSize: "13px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      h3: { margin: "auto" },
    },
  },
});

const colors = ["#8295ec", "#3e60f7 ", "#001b91"];
export default function Summary() {
  const ref = useRef("div");
  const { onMouseDown } = useDraggableScroll(ref, { direction: "horizontal" });
  return (
    <StyledContainer ref={ref} onMouseDown={onMouseDown}>
      {[0, 0, 0, 0, 0, 0].map((e, i) => {
        return (
          <StyledCard key={i}>
            <div className="header">
              <div>
                <span>Early</span>
                <h3>2</h3>
              </div>
              <div>
                <span>Late</span>
                <h3>3</h3>
              </div>
            </div>
            <ColoredPieChart
              colors={colors}
              radius={70}
              legend={true}
              data={[
                { name: "Early", value: 2 },
                { name: "Late", value: 3 },
                { name: "On time", value: 2 },
              ]}
            />
          </StyledCard>
        );
      })}
    </StyledContainer>
  );
}
