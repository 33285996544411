import React, { useEffect } from 'react'
import { styled } from '@nextui-org/react';
import { Modal } from '@nextui-org/react';
import Input from '../../../components/Input/Input'
import InputSelect from '../../../components/Select/inputSelect';
import CreatableInputSelect from '../../../components/Select/CreatableSelect';
import InputFileCard from '../../../components/InputFileCard/InputFileCard'
import { Radio } from '@nextui-org/react';
import Button from '../../../components/Button/Button';
import TextEditor from '../../../components/TextEditor/TextEditor';
import { useForm, Controller } from 'react-hook-form'
import { toBase64 } from '../../../services/base64.services';
import { sendEmail, getOperations, getEmails, getSubject } from '../../../services/operations.services';
import { confirmacionDeArribo, notificacionLiberacion } from './mailTemplates';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import toast from '../../../components/Toast/ToastTypes';
import DropZone from '../../../components/DropZone/DropZone';

const StyledContainer = styled("form", {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    margin: '30px',
    gap: '15px',
    borderRadius: '8px',
    padding: '0 40px',
    "@media(max-width:1200px)":{
        margin: '5px',
        padding: '5px',
    },
    "@media(max-width:1000px)":{
        display:'flex',
        flexDirection:'column',
        gap:'10px',
    }
})

export default function MailForm({ selectedOperation }) {

    const { control, handleSubmit, setValue, reset,getValues,  trigger } = useForm({
        defaultValues: {
            print: 'active-registration',
            receiver_type: 'client',
            language: 'ES',
        }
    });

    const [body, setBody] = useState('')
    const [radication, setRadication] = useState()
    const [emails, setEmails] = useState()
    const [selectedEmails, setSelectedEmails] = useState()
    const [files, setFiles] = useState([]);

    const token = useSelector(state => state.user.token)

    const handleFiles = (newFiles) => {
        setFiles(newFiles);
    };
    const onSubmit = (formData) => {
        let submitData = formData;
        submitData.body = body
        submitData.radication = radication;
        submitData.to = selectedEmails.map(mail => { return mail.value })
        submitData.cc = formData.cc?.map(e => { return e.value })
        submitData.bcc = formData.bcc?.map(e => { return e.value })
        submitData.attached = files;
        if (submitData.cc?.length < 1) { submitData.cc = undefined }
        if (submitData.bcc?.length < 1) { submitData.bcc = undefined }
        console.log('submitData :',submitData)
        
        sendEmail(submitData, token).then(async res => {
            reset({})
            setSelectedEmails("")
            setFiles([])
            setBody("")
            toast('success', 'Correo enviado')
        }).catch(() => toast('error', 'Ha habido un error'))
    }
    

    useEffect(() => {
        getOperations({ token:token, id: selectedOperation }).then(async res => {
            const operation = await res.json();
            setRadication(operation.radication)

           

            getEmails(token, operation.radication).then(async res => {
                const emailsData = await res.json()

                if (emailsData[0]?.subject){
                    setValue("subject", emailsData[0]?.subject)
                } else {
                    getSubject(token, operation.radication).then(async res => {
                        const subject = await res.json()
                        setValue("subject", subject.subject)
                    })
                }

                let recipients = []
                console.log("emailsData :",emailsData)
                emailsData.forEach(email => {
                    if (!recipients.includes(email.recipient)) {
                        const cleanRecipient = email.recipient.replace(/[\[\]']+/g, '');
                        const cleanRecipientArray = cleanRecipient?.split(',')?.map(e=>e.trim())
                        console.log("cleanRecipientArray :",cleanRecipientArray)
                        recipients=[...recipients, ...cleanRecipientArray]
                    }
                })
                console.log("recipients :",recipients)
                setEmails(recipients)
                const formatRecipients = recipients?.map(recipient => {
                    return {
                        label: recipient,
                        value: recipient,
                    }
                })
                setSelectedEmails(formatRecipients)
                // setSelectedEmails(recipients[0]?.split(',')?.map(recipient => {
                //     return {
                //         label: recipient,
                //         value: recipient,
                //     }
                // }))
                trigger()
            })
        })
    }, [selectedOperation])
    console.log("selectedOperation :",selectedOperation)
    console.log("selectedEmails :",selectedEmails)
    return (
        <StyledContainer onSubmit={handleSubmit(onSubmit)}>
            <h3 style={{ gridColumn:'span 2' }}>Notificar via mail</h3>
            <div style={{ display: 'flex' }}>
                <div>
                    <label>Que desea imprimir :</label>
                    <Controller
                        name="print"
                        control={control}
                        render={({ field }) => (
                            <Radio.Group {...field} size="xs" orientation="horizontal" defaultValue='active-registration'>
                                <Radio value="active-registration"><label style={{ color: '#292E68' }}>Registro activo</label></Radio>
                                <Radio value="consolidated"><label style={{ color: '#292E68' }}>Todos consolidado</label></Radio>
                            </Radio.Group>
                        )}
                    />
                </div>
            </div>
            <div style={{ 'gridColumn': 'span 2', display: 'flex', flexDirection: 'column' }}>
                <label style={{ margin: '10px', fontWeight: 'bold' }}>Destinatarios :</label>
                <Controller
                    name="to"
                    control={control}
                    render={({ field }) => (
                        <CreatableInputSelect
                            {...field}
                            isMulti={true}
                            value={selectedEmails}
                            onChange={e => setSelectedEmails(e)}
                            options={emails?.map(email => {
                                return {
                                    label: email,
                                    value: email,
                                }
                            })}
                        />
                    )}
                />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <label style={{ margin: '10px', fontWeight: 'bold' }}>Copia correo :</label>
                <Controller
                    name="cc"
                    control={control}
                    render={({ field }) => (
                        <CreatableInputSelect
                            {...field}
                            isMulti={true}
                            options={emails?.map(email => {
                                return {
                                    label: email,
                                    value: email,
                                }
                            })}
                        />
                    )}
                />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <label style={{ margin: '10px', fontWeight: 'bold' }}>Copia oculta :</label>
                <Controller
                    name="bcc"
                    control={control}
                    render={({ field }) => (
                        <CreatableInputSelect
                            {...field}
                            isMulti={true}
                            options={emails?.map(email => {
                                return {
                                    label: email,
                                    value: email,
                                }
                            })}
                        />
                    )}
                />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <label style={{ margin: '10px', fontWeight: 'bold' }}>Asunto :</label>
                <Controller
                    name="subject"
                    control={control}
                    render={({ field }) => (
                        <Input {...field} />
                    )}
                />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <label style={{ margin: '10px' }}><b>Seleccione la plantilla :</b></label>
                <InputSelect options={[
                    { label: 'Ninguno', value: '' },
                    { label: 'Confirmacion de arribo', value: confirmacionDeArribo },
                    { label: 'Confirmacion liberacion', value: notificacionLiberacion },
                ]}
                    disableClear={true}
                    onChange={e => setBody(e.value)}
                />
            </div>
            <div style={{ 'gridColumn': 'span 2' }}>
                <TextEditor state={body} setState={setBody} />
            </div>
            <div style={{ 'gridColumn': 'span 2', display: 'flex', flexDirection: 'column', gap: '20px' }}>
                <label style={{ fontWeight: 'bold' }}>Adjuntos :</label>
                {/* <InputFileCard name="attachment" label='Sube o arrastra' onChange={handleFile} startIcon={{ name: 'Upload', color: '#FFF' }} width={'100%'} aspectRatio={'6/1'} /> */}
                <DropZone files={files} handleFiles={handleFiles}/>
                <Button 
                    disabled={!selectedOperation ||  !getValues('subject') || getValues('selectedEmails')?.length<1}
                    text='Enviar correo'
                    type="submit"
                    bold
                    bgColor={'primary_300'}
                    textColor='neutral_50' 
                    width={'100%'}
                    endIcon={{name:'Mail', color:'white'}}
                />
            </div>
            {!selectedOperation && <label>No hay una operacion seleccionada(!)</label>}
        </StyledContainer>
    )
}
