import React, { useState } from "react";
import { TextField, Button, Box, Typography } from "@mui/material";

function DataSelector() {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(`Selected date range: ${startDate} to ${endDate}`);
    // In a real implementation, this would trigger a data fetch or update
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Select Date Range
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Start Date"
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
          sx={{ mr: 2 }}
        />
        <TextField
          label="End Date"
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
          sx={{ mr: 2 }}
        />
        <Button type="submit" variant="contained" color="primary">
          Update Data
        </Button>
      </form>
    </Box>
  );
}

export default DataSelector;
