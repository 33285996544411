import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { styled } from "@nextui-org/react";
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const ChartContainer = styled('div', {
  width: '100%',
  height: 'auto',
  minHeight: '300px',
  maxHeight: '800px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const CustomTooltip = styled('div', {
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  border: '1px solid #ccc',
  padding: '10px',
  borderRadius: '4px',
});

const NoDataMessage = () => (
  <div style={{ textAlign: 'center', padding: '10px', color: '#777' }}>
    <FontAwesomeIcon icon={faExclamationCircle} style={{ fontSize: '1.2em', marginRight: '5px' }} />
    <p style={{ display: 'inline-block', verticalAlign: 'middle', fontSize: '0.8em', fontStyle: 'italic' }}>Sin datos disponibles</p>
  </div>
);

export default function DashboardHorizontalBarChart({
  dataKey,
  data,
  color = "#fd7c5c",
  barSize = 20,
  maxBars = 10
}) {
  const { t: translate } = useTranslation("Home");
  const [chartData, setChartData] = useState([]);
  const [chartHeight, setChartHeight] = useState(300);

  useEffect(() => {
    if (Array.isArray(data) && data.length > 0) {
      const filteredData = data
        .filter(e => e[dataKey] !== 0)
        .sort((a, b) => b[dataKey] - a[dataKey])
        .slice(0, maxBars);
      setChartData(filteredData);
     
      const itemHeight = 40;
      const minHeight = 300;
      const calculatedHeight = Math.max(filteredData.length * itemHeight, minHeight);
      setChartHeight(calculatedHeight);
    } else {
      setChartData([]);
    }
  }, [data, dataKey, maxBars]);

  const formatYAxisTick = (value) => {
    const maxLength = 20;
    return value.length > maxLength ? `${value.substring(0, maxLength)}...` : value;
  };

  const formatXAxisTick = (value) => {
    return value % 1 === 0 ? value : value.toFixed(1);
  };

  const CustomTooltipContent = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <CustomTooltip>
          <p><strong>{label}</strong></p>
          <p>{`${translate(dataKey)}: ${payload[0].value}`}</p>
        </CustomTooltip>
      );
    }
    return null;
  };

  if (chartData.length === 0) {
    return (
      <ChartContainer>
        <NoDataMessage>{translate("noDataAvailable")}</NoDataMessage>
      </ChartContainer>
    );
  }

  return (
    <ChartContainer style={{ height: `${chartHeight}px` }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          layout="vertical"
          data={chartData}
          margin={{
            top: 20,
            right: 30,
            bottom: 20,
            left: 20,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis
            type="number"
            tickFormatter={formatXAxisTick}
            domain={[0, 'dataMax']}
            tickCount={5}
          />
          <YAxis
            dataKey="name"
            type="category"
            width={120}
            tick={{ fontSize: 12 }}
            interval={0}
            tickFormatter={formatYAxisTick}
          />
          <Tooltip content={<CustomTooltipContent />} />
          <Bar
            dataKey={dataKey}
            fill={color}
            barSize={barSize}
            radius={[0, 4, 4, 0]}
          />
        </BarChart>
      </ResponsiveContainer>
    </ChartContainer>
  );
}