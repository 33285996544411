import React from 'react'
import { useEffect, useState } from 'react';
import { Modal, styled } from '@nextui-org/react';
import Input from '../../../components/Input/Input';
import InputSelect from '../../../components/Select/inputSelect';
import Button from '../../../components/Button/Button';
import toast from '../../../components/Toast/ToastTypes';
import { addOperationActivity, editOperationActivity } from '../../../services/operations.services';
import { useForm, Controller } from 'react-hook-form'
import { useSelector } from 'react-redux';
const StyledFormModal = styled(Modal, {
    padding: '1.5rem',
    color: '$primary_500 !important',
    minWidth: '500px',
})

export default function AddActivityModal({ activities, currentOperationData, operationId, openAddActivityModal, setOpenAddActivityModal, selectedActivity, setSelectedActivity }) {

    const { handleSubmit, control, reset } = useForm();

    const [stimatedDate, setStimatedDate] = useState(new Date().toISOString().split('T')[0]);

    const [confirmedDate, setConfirmedDate] = useState(new Date().toISOString().split('T')[0]);

    const token = useSelector(state => state.user.token)

    function formatDate(inputDate) {
        var dateParts = inputDate.split('/');
        var formattedDate = dateParts[2] + '-' + dateParts[1] + '-' + dateParts[0];
        return formattedDate;
    }

    const filteredActivities = activities.filter(activity => {
        return (activity.operation_type === currentOperationData.operation_type)
    })

    const onSubmit = (formData) => {
        const submitData = {
            ...currentOperationData,
            activities: [
                ...currentOperationData.activities,
                {
                    // id:formData.activity.value,
                    confirmed_date: confirmedDate,
                    estimated_date: stimatedDate,
                    operation: operationId,
                    activity: formData.activity.value,
                }
            ]
        }
        if (!selectedActivity) {
            addOperationActivity({ data: submitData, id: operationId, token:token }).then(res => {
                toast('success', 'Actividad agregada a la operacion')
                setOpenAddActivityModal(false)
            }).catch(err => console.log("ERR :", err))
        } else {
            editOperationActivity({ data: submitData, id: operationId, token:token }).then(res => {
                toast('success', 'Cambios guardados')
                setOpenAddActivityModal(false)
            }).catch(err => console.log("ERR :", err))
        }
    }

    useEffect(() => {
        if (selectedActivity) {
            setConfirmedDate(formatDate(selectedActivity.confirmed_date))
            setStimatedDate(formatDate(selectedActivity.estimated_date))
            reset({
                activity: { label: selectedActivity.activity, value: selectedActivity.id }
            })
        } else {
            reset({
                activity: '',
                confirmed_date: '',
                estimated_date: '',
            })
        }
    }, [selectedActivity])

    return (
        <StyledFormModal
            closeButton
            open={openAddActivityModal}
            onClose={() => { setOpenAddActivityModal(false); setSelectedActivity('') }}
            width="fit-content"
        >
            <Modal.Header justify='space-between'>
                <h3>{selectedActivity ? "Modificar actividad" : "Añadir actividad"}</h3>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column", gap: '15px' }}>
                    <Controller
                        name="activity"
                        control={control}
                        render={({ field }) => (
                            <InputSelect label="Actividad" {...field}
                                isDisabled={selectedActivity}
                                options={filteredActivities?.map(activity => {
                                    return {
                                        label: activity.name,
                                        value: activity.id,
                                    }
                                })} />
                        )}
                    />
                    <Controller
                        name="estimated_date"
                        control={control}
                        render={({ field }) => (
                            <Input type="date" label="Fecha estimada" {...field} value={stimatedDate} onChange={(e) => setStimatedDate(e.target.value)} />
                        )}
                    />
                    <Controller
                        name="confirmed_date"
                        control={control}
                        render={({ field }) => (
                            <Input type="date" label="Fecha confirmada" {...field} value={confirmedDate} onChange={(e) => setConfirmedDate(e.target.value)} />
                        )}
                    />
                    <div style={{ display: "flex", justifyContent: "end", width: "100%", margin: "15px" }}>
                        <Button text={selectedActivity ? 'Guardar cambios' : 'Agregar actividad'} bold bgColor={'primary_300'} textColor='neutral_50' type="submit" />
                    </div>

                </form>
            </Modal.Body>
        </StyledFormModal>
    )
}