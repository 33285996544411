import React, { useEffect } from "react";
import { useState } from "react";
import InputTable from "../../../../../components/InputTable/InputTable";
import { useFormContext } from "react-hook-form";
import { getTrms } from "../../../../../services/kpis.services";
import { useSelector } from "react-redux";

export default function CostsInputTable({tableName, id, conceptSelectOptions=false}){
    const token = useSelector(state=>state.user.token)
    const methods = useFormContext()
    const [tableTotal, setTableTotal] = useState(0)
    const [currencyOptions,setCurrencyOptions] = useState([])
    const [errors, setErrors] = useState({})

    useEffect(()=>{
        getTrms(token).then(async res => {
            const trmData = await res.json();
            setCurrencyOptions([
                {label:'USD', value:1},
                {label:'COP', value:1/methods.getValues('shipping_information.TRM')},
                // {label:'EUR', value:1/trmData.usd_eur},
                {label:'EUR', value:1/methods.getValues('shipping_information.usd_eur')},
            ])
        })
        const items = methods.getValues(id)
        calculateTableTotal(items)
    },[])

    const defaultData = {
        itemId:1,
        tableName,
        Factor: '',
        Unit: 'Kg',
        Currency: {label:'', value:0},
        Rate: '',
        Min: '',
        'Total in USD':'',
    }

    function calculateTotal(factor, trm, rate, unit, min, currency) {
        console.log("rate :",rate)
        let fact = factor || 0
        if (!unit?.value){
            unit = {
                value:unitOptions.find(option => option?.label === unit?.label)?.value
            }
        }
        let formatRate = rate
        if (rate && isNaN(Number(rate))){
            formatRate=formatRate.replace(",",".")
            if (formatRate.includes("%")){
                formatRate = formatRate.replace("%","")
                formatRate = formatRate/100
            }   
        }
        const value = formatRate * fact * unit.value;
        let minValue = min * fact;
        const result = Math.max(value, minValue)
        const convert = trm ? 1 / trm : currency? currency.value : 1
        if (trm == 0){return 0}
        return result * convert;
    }
    function calculateTableTotal(items){
        let tableTotalCount = 0;
        // items.map((row) => {
        //     const {Factor, Unit, Trm, Rate, Min } = row
        //     const rowTotal = calculateTotal(Factor, Trm, Rate, Unit, Min)
        //     tableTotalCount += rowTotal>0? rowTotal : 0
        // })
        items?.forEach((row) => {
            const rowTotal = Number(row['Total in USD'])
            tableTotalCount += rowTotal> 0? rowTotal : 0
        })
        setTableTotal(Number(tableTotalCount))
        methods.setValue(id + 'Total', tableTotalCount)
    }

    function getIndex(str){
        const regex = /\[(.*?)\]/;
        const match = regex.exec(str); 
        if (match && match.length > 1) {
        const valorEntreCorchetes = match[1];
        return valorEntreCorchetes;
        } else {
        return null
        }
    }

    const actualWeight = methods.getValues('shipping_information.weight') || 0
    const actualVolume = methods.getValues('shipping_information.volume') || 0
    const chargeableVol = methods.getValues('shipping_information.chargeable_vol') || 0
    const TotalGW = methods.getValues('total_weight_kg') || 0
    const pieces = methods.getValues('shipping_information.pieces') || 0
    const valueOfCargo = methods.getValues('shipping_information.cargo_value') || 0
    const freight = methods.getValues('air_freight.freight_total') || methods.getValues('ocean_freightTotal') || 0
    const freightTotal = methods.getValues('air_freight.total') || methods.getValues('ocean_freightTotal') || 0
    const originTotal = methods.getValues('origin_listTotal') || 0
    const destinationTotal = methods.getValues('destination_list_total') || 0
    const insuranceTotal = methods.getValues('insurance_list_total') || 0
    
    const unitOptions = [
        {label:'At cost', value:1, tooltip:'1'},
        {label:'HBL', value:1, tooltip:'1'},
        {label:'HAWB', value:1, tooltip:'1'},
        {label:'shpt', value:1, tooltip:'1'},
        {label:'ton', value: actualWeight/1000, tooltip:`${actualWeight/1000} (peso/1000)` , weight:actualWeight},
        {label:'cbm', value: actualVolume, tooltip:`${actualVolume} (cbm)`, volume:actualVolume},
        {label:'W/M', value: Math.max(actualVolume/1000000, actualWeight/1000), tooltip:`${Math.max(actualVolume/1000000, actualWeight/1000)} (Maximo entre Volumen/1000000 y peso/1000)`, weight:actualWeight, volume:actualVolume,},
        {label:'KG/Vol', value: Math.max(actualVolume/1000000, actualWeight/1000), tooltip:`${Math.max(actualVolume/1000000, actualWeight/1000)} (Maximo entre Volumen/1000000 y peso/1000)`, weight:actualWeight, volume:actualVolume},
        {label:'Kg', value: TotalGW, tooltip:`${TotalGW} (Peso total)`, weight:actualWeight,},
        {label:'Chargable Weight', value: Math.max(actualWeight, chargeableVol), tooltip:`${Math.max(actualWeight, chargeableVol)} (Maximo entre peso y volumen cargable)`, weight:actualWeight, chargeableVol:chargeableVol},
        {label:'lb', value: actualWeight/0.45, tooltip:`${actualWeight/0.45} (Peso/0,45)`, weight:actualWeight },
        {label:'Pallet', value: pieces ,tooltip:`${pieces} (Cantidad de piezas)` },
        {label:'Piece', value: pieces ,tooltip:`${pieces} (Cantidad de piezas)` },
        {label:'Box', value:1, tooltip:'1'},
        {label:'Unit', value:1, tooltip:'1'},
        {label:'Cntr', value:1, tooltip:'1'},
        {label:'Truck', value:1, tooltip:'1'},
        {label:'Forklift', value:1, tooltip:'1'},
        {label:'Crane', value:1, tooltip:'1'},
        {label:'Equipment', value:1, tooltip:'1'},
        {label:"Cntr 20'", value:1, tooltip:'1'},
        {label:"Cntr 40'", value:1, tooltip:'1'},
        {label:"Cntr 20' OT", value:1, tooltip:'1'},
        {label:"Cntr 40' OT", value:1, tooltip:'1'},
        {label:"Cntr 20' FR", value:1, tooltip:'1'},
        {label:"Cntr 40' FR", value:1, tooltip:'1'},
        {label:'TEU', value:1, tooltip:'1'},
        {label:'Day', value:1, tooltip:'1'},
        {label:'Hrs', value:1, tooltip:'1'},
        {label:'Day x Cntr', value:1, tooltip:'1'},
        {label:'Document', value:1, tooltip:'1'},
        {label:'Certificado', value:1, tooltip:'1'},
        {label:'UN', value:1, tooltip:'1'},
        {label:'Cargo Value', value: valueOfCargo, tooltip:`${valueOfCargo} (Valor de la carga)`},
        {label:'FOB Value', value: valueOfCargo, tooltip:`${valueOfCargo} (Valor de la carga)`},
        {label:'CFR Value', value: Number(valueOfCargo) + Number(originTotal) + Number(freightTotal), tooltip:`${Number(valueOfCargo) + Number(originTotal) + Number(freightTotal)} (Valor de la carga + total origen + total flete)`, valueOfCargo:valueOfCargo, originTotal:originTotal, freightTotal:freightTotal},
        {label:'CIF Value', value: Number(valueOfCargo) + Number(originTotal) + Number(freightTotal) + Number(insuranceTotal), tooltip:`${Number(valueOfCargo) + Number(originTotal) + Number(freightTotal) + Number(insuranceTotal)} (Valor de la carga + total origen + total flete + total seguro)`, valueOfCargo:valueOfCargo, originTotal:originTotal, freightTotal:freightTotal, insuranceTotal:insuranceTotal},
        {label:'Freight', value: freight, tooltip:`${freight} (Flete)`, freight:freight},
        {label:'Freight+Fuel', value: freightTotal, tooltip:`${freightTotal} (Total flete)`, freightTotal:freightTotal},
        {label:'Total Freight+Origin Charges', value: Number(freightTotal) + Number(originTotal), tooltip:`${Number(freightTotal) + Number(originTotal)} (Total flete + Total origen)`},
        {label:'Destination Charges', value: destinationTotal, tooltip:`${destinationTotal} (Total Destino)`},
        {label:'Inspection', value:1, tooltip:'1'},
        {label:'HS CODE', value:1, tooltip:'1'},
        {label:'Duty and tax outlays', value:1, tooltip:'1'},
        {label:'Service', value:1, tooltip:'1'},
        {label:'Tramite', value:1, tooltip:'1'},
        {label:'Per Day / Pro rata', value:1, tooltip:'1'},
        {label:'Short Ton', value: Math.max(actualWeight, chargeableVol)/900, tooltip:`${Math.max(actualWeight, chargeableVol)/900} (Maximo entre peso total y volumen cargable sobre 900)`,  weight:actualWeight, chargeableVol:chargeableVol},
        {label:'2000/lb', value:Math.max(actualWeight, chargeableVol)/900, tooltip:`${Math.max(actualWeight, chargeableVol)/900} (Maximo entre peso total y volumen cargable sobre 900)`,  weight:actualWeight, chargeableVol:chargeableVol},
    ]

    const columns = [
        {name:"Concepto", type:conceptSelectOptions? 'select' :'conceptAutocomplete', options:conceptSelectOptions},
        {name:"Factor", type:'number'},
        {name:"Unit", type:'select', options:unitOptions},
        {name:"Currency", type:'select', options:currencyOptions},
        {name:"Trm", type:'number'},
        {name:"Rate", type:'text', required:true},
        {name:"Min", type:'number'},
        {name:"Total in USD", type:'number', tooltip:true},
    ]

    function setUnit(unitName,itemId){
        const unit = unitOptions.find(unit => unit.label.toLowerCase() == unitName.toLowerCase())
        console.log("unit :",unit)
        console.log("setValue :",`${id}.[${itemId-1}].Unit`)
        methods.setValue(`${id}.[${itemId-1}].Unit`, unit)
    }

    React.useEffect(() => {
        const tableValues = methods.getValues(id)
        tableValues?.forEach((item,index) => {
            const fullUnit = unitOptions.find(unit => {
                return(
                    unit.label == item.Unit?.label
                )
            })
            methods.setValue(`${id}[${index}].Unit`, fullUnit)
        })
        
        const subscription = methods.watch((value, { name, type }) => {
            // console.log("name :",name)
            if (name?.startsWith(id)){
                const items = methods.getValues(id)
                const index = getIndex(name)
                //CALCULO DE TOTALES
                if(name === `${id}[${index}].Total in USD`){
                    calculateTableTotal(items)
                }
                if (
                    name === `${id}[${index}].Factor` ||
                    name === `${id}[${index}].Unit` ||
                    name === `${id}[${index}].Rate` ||
                    name === `${id}[${index}].Min` ||
                    name === `${id}[${index}].Currency` ||
                    name === `${id}[${index}].Trm`
                ){
                //TOTAL DE FILA :
                    const {Factor, Unit, Trm, Rate, Min, Currency } = items[index]
                    const rowTotal = calculateTotal(Factor, Trm, Rate, Unit, Min, Currency)
                    console.log("rowTotal :",rowTotal)
                    methods.setValue(`${id}[${index}].Total in USD`,rowTotal > 0? rowTotal.toFixed(2) : 0)
                //TOTAL DE TABLA :
                    calculateTableTotal(items)
                }
                //CURRENCY/TRM
                if (name === `${id}[${index}].Currency`){
                    const newTrm = 1 / value[id][index].Currency.value
                    methods.setValue(`${id}[${index}].Trm`, newTrm?.toFixed(2) )
                }
                //VALIDACIONES
                let errors = {}
                items.forEach(item=>{
                    if (item.Concepto && (!item.Rate || !item.Factor || isNaN(item.Rate))) {
                        const tableName = id;
                        const itemId = item.itemId;
                        if (!errors[tableName]) {
                            errors[tableName] = {};
                        }
                        if (!errors[tableName][itemId]) {
                            errors[tableName][itemId] = {};
                        }
                        if (item.Rate && isNaN(Number(item.Rate))){
                            let formatRate = item.Rate.replace(",",".")
                            if (formatRate.includes("%")){
                                formatRate = formatRate.replace("%","")
                                formatRate = formatRate/100
                            }
                            if (isNaN(formatRate)){
                                errors[tableName][itemId].Rate = 'Incluye caracteres no validos'
                            }
                            // else {
                            //     methods.setValue(`${id}[${index}].Rate`, formatRate )
                            // }
                        }
                        if (!item.Rate){errors[tableName][itemId].Rate = 'Campo requerido'}
                        if (!item.Factor){errors[tableName][itemId].Factor = 'Campo requerido'}
                    }
                })
                setErrors(errors)
            }
        });
        return () => subscription.unsubscribe();
    }, [methods.watch]);

    useEffect(()=>{
        const items = methods.getValues(id)
        calculateTableTotal(items)
    },[])

    return <div>
        <InputTable tableName={id} itemType={tableName} columns={columns} defaultData={defaultData} errors={errors} setUnit={setUnit}/>
        <h4>Total en USD : {tableTotal.toFixed(2)}</h4>
    </div>

}