import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUserAuth, logout } from '../services/auth.services';
import { useNavigate } from 'react-router-dom';

const AuthVerify = () => {
    const token = useSelector(state => state.user.token);
    const dispatch = useDispatch()
    const INACTIVITY_LIMIT = 12 * 60 * 60 * 1000;
    const navigate = useNavigate()

    useEffect(() => {
        const fetchUserAuth = async () => {
            try {
                await getUserAuth(token)
            } catch (error) {
                dispatch({ type: "LOG_OUT" })
                logout(token)
                localStorage.removeItem("storage");
                // location.reload()
            }
        }
        fetchUserAuth()
        // Logout the user by timeout
        let timeoutId;
        const events = ['mousemove', 'keydown', 'wheel', 'DOMMouseScroll', 'mouseWheel', 'mousedown', 'touchstart', 'touchmove', 'focus'];
        const resetTimeout = () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                logout(token)
                localStorage.removeItem("storage");
            }, INACTIVITY_LIMIT);
        };
        for (let event of events) {
            window.addEventListener(event, resetTimeout);
        }
        resetTimeout();
        return () => {
            for (let event of events) {
                window.removeEventListener(event, resetTimeout);
            }
            clearTimeout(timeoutId);
        };
    }, [navigate,token]);

    return (<></>);
}

export default AuthVerify;
