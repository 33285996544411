import { URL } from "../constants"

export const getCiiu = async (token) => {
    const response = await fetch(`${URL.get()}ciiu/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}