import { URL } from "../constants"

export const getQuoteData = async (token, id) => {
    //console.log('TOKEN :',token)
    const response = await fetch(`${URL.get()}quotation/quotations/${id ? id + '/'  : ''}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getOrganizations = async (token) => {
    const response = await fetch(`${URL.get()}organization/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getOffers = async (token, radication) => {
    const response = await fetch(`${URL.get()}quotation/offers/${radication}/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response.json();
}

export const getSubTypes = async (token) => {
    const response = await fetch(`${URL.get()}quotation/subtypes/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getSolutions = async (token) => {
    const response = await fetch(`${URL.get()}quotation/solutions/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token
        }
    })
    return response
}

export const getOffices = async (token) => {
    const response = await fetch(`${URL.get()}quotation/offices/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token
        }
    })
    return response
}
export const getClients = async (token) => {
    //console.log("clientes consultados")
    const response = await fetch(`${URL.get()}quotation/clients/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token
        }
    })
    return response
}
export const getQuotationByRadication = async (token, radication) => {
    const response = await fetch(`${URL.get()}quotation/quotationbyradication/${radication}/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token
        }
    })
    return response
}
export const getQuoteLanguages = async (token) => {
    const response = await fetch(`${URL.get()}quotation/quotationlanguages/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token
        }
    })
    return response
}
export const getStatuses = async (token) => {
    const response = await fetch(`${URL.get()}quotation/statuses/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token
        }
    })
    return response
}
export const getReasons = async (token) => {
    const response = await fetch(`${URL.get()}quotation/reasons/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token
        }
    })
    return response
}

export const getOrigins = async (token) => {
    const response = await fetch(`${URL.get()}quotation/origins/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token
        }
    })
    return response
}

export const getDestinations = async (token) => {
    const response = await fetch(`${URL.get()}quotation/destinations/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token
        }
    })
    return response
}

export const postQuotation = async (data, token) => {
    const response = await fetch(`${URL.get()}quotation/quotations/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token
        },
        body: JSON.stringify(data)
    });
    if (response.status === 201) {
        return response.json();
    } else if (response.status === 400) {
        const errorData = await response.json();
        throw new Error(errorData);
    } else {
        throw new Error("Ha habido un error");
    }
}

export const editQuotation = async (data, id, token) => {
    const response = await fetch(`${URL.get()}quotation/quotations/${id}/`, {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token
        },
        body: JSON.stringify(data)
    });
    if (response.status === 200) {
        return response.json();
    } else if (response.status === 400) {
        const errorData = await response.json();
        throw new Error(errorData);
    } else {
        throw new Error("Ha habido un error");
    }
}

export const deleteQuotation = async (id, token) => {
    const response = await fetch(`${URL.get()}quotation/quotations/${id}/`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token
        },
    });
    if (response.status === 200 || response.status === 204) {
        return response;
    } else if (response.status === 400) {
        const errorData = response;
        throw new Error(errorData);
    } else {
        throw new Error("Ha habido un error");
    }
}

export const getVersions = async (token, currentQuote) => {
    const response = await fetch(`${URL.get()}quotation/quotations/${currentQuote}/versions/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token
        }
    })
    return response
}

export const sendInstructions = async (data, token) => {
    const response = await fetch(`${URL.get()}quotation/delivery-order/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token
        },
        body: JSON.stringify(data)
    });
    if (response.status === 201) {
        return response.json();
    } else if (response.status === 400) {
        const errorData = await response.json();
        throw new Error(errorData);
    } else {
        throw new Error("Ha habido un error");
    }
}

export const getMovements = async (token) => {
    const response = await fetch(`${URL.get()}quotation/movements/`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
    })
    return response;
}

export const finishQuotation = async (token, id, base64data) => {
    const response = await fetch(`${URL.get()}quotation/quotations/${id}/set_approve/`, {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify({
            approve: true,
            base64data:base64data,
        })
    });
    if (response.status === 200) {
        return response.json();
    } else if (response.status === 400) {
        const errorData = await response.json();
        throw new Error(errorData);
    } else {
        throw new Error("Ha habido un error");
    }
}

export const approveQuotation = async (id, boolean, token, note) => {
    const response = await fetch(`${URL.get()}quotation/quotations/${id}/set_client_approve/`, {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify({
            customer_approval: boolean,
            notes:note || "",
        })
    });
    if (response.status === 200) {
        return response.json();
    } else if (response.status === 400) {
        const errorData = await response.json();
        throw new Error(errorData);
    } else {
        throw new Error("Ha habido un error");
    }
}

export const setSuggested = async (id, token) => {
    const response = await fetch(`${URL.get()}quotation/quotations/${id}/set_suggested/`, {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify({
            suggested: true
        })
    });
    if (response.status === 200) {
        return response.json();
    } else if (response.status === 400) {
        const errorData = await response.json();
        throw new Error(errorData);
    } else {
        throw new Error("Ha habido un error");
    }
}

export const getWaitingOp = async (token) => {
    //console.log('TOKEN :',token)
    const response = await fetch(`${URL.get()}quotation/waitingop/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getQuotesByRange = async (token, start, end, operation) => {
    const response = await fetch(`${URL.get()}all_quotation_bymonth/${start}/${end}/${operation}/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}
