import React, { useState } from 'react'
import PlacesAutocomplete from 'react-places-autocomplete'
import Input from '../Input/Input';
import Icon from '../Icon/Icon';

export default function SimpleAutocomplete({ setState, label }) {

    const [address, setAddress] = useState('')
    
    return (
        <div>
            <PlacesAutocomplete
                onChange={e => setAddress(e)}
                value={address}
                onSelect={e => { setAddress(e); setState(e) }}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                    <div className='p-relative'>
                        <Input
                            label={label}
                            className={`o_input`}
                            fullWidth={'100%'}
                            value={getInputProps().value}
                            onBlur={getInputProps().onBlur}
                            onChange={getInputProps().onChange}
                            onKeyDown={getInputProps().onKeyDown}
                        />
                        <div className="autocomplete-dropdown-container-bottom">
                            {
                                suggestions.map((suggestion, i) => {
                                    const style = suggestion.active
                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                    return (
                                        <div
                                            key={i}
                                            {...getSuggestionItemProps(suggestion, {
                                                className: 'suggestion',
                                                style,
                                            })}
                                        >
                                            <Icon name={'Rastreo'} size={'14'}></Icon><span>{suggestion.description}</span>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                )}
            </PlacesAutocomplete>
        </div>
    )
}
