import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@nextui-org/react';
import { Switch } from '@nextui-org/react';
import { getUserNotificationMethods, activateUserNotificationMethod, deactivateUserNotificationMethod } from '../../services/notifications.services';
import { registerDevice } from '../../firebaseFunctions';
import toast from '../../components/Toast/ToastTypes';

const StyledDiv = styled("div", {
    display: 'flex',
    flexDirection: 'column',
    background: "$neutral_50",
    borderRadius: '8px',
    margin: '50px',
});
const Row = styled("div", {
    width: '100%',
    padding: '15px',
    display: 'flex',
    justifyContent: 'space-between',
});
const Line = styled("div", {
    background: 'grey',
    height: '1px',
    padding: 0,
});

export default function Settings(user) {
    const [methods, setMethods] = useState([]);
    const token = useSelector(state => state.user.token);

    useEffect(() => {
        getUserNotificationMethods(token).then(async res => {
            const data = await res.json();
            setMethods(data.active_notification_methods);
        });
    }, []);

    function handleChangeNotification(e, id) {
        const checked = e.target.checked;
        if (checked) {
            activateUserNotificationMethod(token, id).then(async res => {
                const permissions = await registerDevice(token)
                console.log('res :',res)
                console.log('permissions :',permissions)
                if (res.status !== 200) {
                    toast("warn", "Error, puede que las notificaciones estan bloqueadas para este sitio")
                }
                setMethods(prevMethods => [...prevMethods, id]);
            }).catch(err => console.log('err :', err))
        } else {
            deactivateUserNotificationMethod(token, id).then(res => {
                setMethods(prevMethods => prevMethods.filter(methodId => methodId !== id));
            }).catch(err => toast('error', 'Error al desactivar el método de notificación'));
        }
    }

    return (
        <StyledDiv>
            <Row>
                <p>Activar notificaciones por mail</p>
                <Switch checked={methods.includes('Email')} onChange={(e) => handleChangeNotification(e, 1)} />
            </Row>
            <Line />
            <Row>
                <p>Activar notificaciones push</p>
                <Switch checked={methods.includes('Push')} onChange={(e) => handleChangeNotification(e, 2)} />
            </Row>
        </StyledDiv>
    );
}