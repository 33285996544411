// components/HelpIcon/HelpIcon.jsx
import React from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Tooltip, IconButton } from '@mui/material';

const HelpIcon = ({ message }) => {
    return (
        <Tooltip title={message}>
            <IconButton size="small">
                <HelpOutlineIcon fontSize="small" />
            </IconButton>
        </Tooltip>
    );
};

export default HelpIcon;
