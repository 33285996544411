import React from 'react';
import theme from '../../../theme';
import { styled } from '@nextui-org/react';

const Card4 = ({ title, children }) => {
  const StyledCard4 = styled('div', {
    [`.${theme} &`]: {
      backgroundColor: '$neutral_50',
      borderRadius: '8px',
      padding: '1.5rem',
      color: '$primary_300',
      width: '100%',
      height: 'fit-content',
      h5: {
        marginTop: '8px',
        marginBottom: '0px'
      },
      iframe: {
        borderRadius: '8px',
        width: '100%',
        height: '500px',
        border: 'none'
      }
    }
  });

  return (
    <StyledCard4>
      <h5>{title}</h5>
      {children}
    </StyledCard4>
  );
};

export default Card4;
