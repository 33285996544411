import React from "react";
import { styled } from "@nextui-org/react";
import theme from "../../../theme";

// Components
import Icon from "../../Icon/Icon";

function Card2({ index, icon, text, textSelected, handler }) {
    const StyledCard2 = styled("div", {
        [`.${theme} &`]: {
            backgroundColor: `${textSelected === text ? '$primary_300' : '$neutral_50'}`,
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "140px",
            height: "fit-content",
            color: `${textSelected === text ? '$neutral_50' : '$primary_300'}`,
            padding: "1rem 0",
            cursor: 'pointer',
            ['h4']: {
                marginBottom: '0rem'
            },
            "@media (max-width: 500px)":{
                ['h4']: {
                    fontSize:'10px'
                }
            }
        },
    });

    const Circle = styled("div", {
        [`.${theme} &`]: {
            backgroundColor: "$primary_50",
            borderRadius: "3rem",
            padding: "1.2rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: '0.5rem',
            "@media (max-width: 400px)":{
                padding:'5px'
            }
        },
    });

    return (
        <StyledCard2 onClick={handler ? () => handler(index) : undefined} className="theme">
            <Circle>
                <Icon name={icon} size={30} />
            </Circle>
            <h4 className="text-center">{text}</h4>
        </StyledCard2>
    );
}

export default Card2;