import React, { useEffect, useState } from "react";
import { styled } from "@nextui-org/react";
import theme from "../../theme";
import { useSearchParams } from "react-router-dom";
import toast from "../../components/Toast/ToastTypes";
import { debounce } from "lodash";
// Components
import PageTitle from "../../components/PageTitle/PageTitle";
import TableServices from "./TableServices/TableServices";
import src from "../../assets/img/serviceTable.png";
import { useTranslation } from "react-i18next";
import useMediaQueries from "../../hooks/useMediaQueries";
import { Button } from "@mui/material";
import DateFilter from "./TableServices/Filters/DateFilter";
import MethodFilter from "./TableServices/Filters/MethodFilter";
import CountryFilter from "./TableServices/Filters/CountryFilter";
import LoadFilter from "./TableServices/Filters/LoadFilter";
import CostFilter from "./TableServices/Filters/CostFilter";
import { FaFilter } from "react-icons/fa";
import GeneralSearch from "./TableServices/Filters/GeneralSearch";
import { subDays } from "date-fns";

const StyledServices = styled("article", {
  [`.${theme} &`]: {
    padding: "2rem 2rem",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    gap: "1.5rem",
    [".header"]: {
      display: "flex",
      gap: "1.5rem",
    },
  },
});

const onBoardingSteps = [
  {
    target: ".servicesPageTitle",
    disableScrolling: true,
    content: (
      <div>
        <h3>Servicios</h3>
        <p>
          Bienvenido al menu de cotizaciones, aqui puedes visualizar,
          administrar y crear cotizaciones
        </p>
      </div>
    ),
  },
  {
    target: ".servicesTable",
    content: (
      <div style={{ textAlign: "left" }}>
        <h3>Ver servicios</h3>
        <p>
          En esta tabla se muestran los servicios. Los servicios son operaciones
          en proceso que nacen a partir de las cotizaciones.
        </p>
      </div>
    ),
    disableScrolling: true,
  },
  {
    target: ".servicesTable",
    content: (
      <div style={{ textAlign: "left" }}>
        <h3>Ver detalles</h3>
        <p style={{ fontSize: "13px" }}>
          Asegurate de revisar la pestaña de detalles del servicio, donde podras
          acceder al chat, los registros de la operacion y mucha mas
          informacion.
        </p>
        <p style={{ fontSize: "13px" }}>
          Accede mediante el menu desplegable :
        </p>
        <img src={src} alt="" />
      </div>
    ),
    disableScrolling: true,
  },
];

const Services = () => {
  const { sm } = useMediaQueries();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [originCountries, setOriginCountries] = useState([]);
  const [destinationCountries, setDestinationCountries] = useState([]);
  const [selectedLoadTypes, setSelectedLoadTypes] = useState([]);
  const [filterCategorySearch, setFilterCategorySearch] = useState("");
  const [filterSearch, setFilterSearch] = useState("");
  const [filterMethod, setFilterMethod] = useState([]);
  const [filterDate, setFilterDate] = useState([
    subDays(new Date(), 230),
    new Date(),
  ]);
  const [filterCost, setFilterCost] = useState([0, 1000]);
  const [refreshData, setRefreshData] = useState(false); 
  


  const { t: translate } = useTranslation("Services");

  useEffect(() => {
    setTimeout(() => {
      let message;
      if (searchParams.get("created") === "liquidation") {
        message = "¡Se ha aprobado con éxito tu liquidación! ";
        toast("success", message);
      } else if (searchParams.get("created") === "pre-liquidation") {
        const title = "Tu pre-liquidación ha sido aprobada con éxito";
        message = (
          <>
            Recuerda que en las próximas <b>24 horas</b> recibiras tu
            liquidación final.
          </>
        );
        toast("success", message, title);
      }
    }, 1000);
  }, []);

  const handleChange = (value) => {
    setFilterSearch(value);
  };
  const debouncedSearch = debounce((value) => {
    handleChange(value);
  }, 300);

  

  const filterContainerClass = sm
    ? "flex flex-col w-[100%] gap-1"
    : "flex  gap-1 items-center";
  const filterIndivialClassThree = sm
    ? "flex flex-col w-[100%]"
    : "flex flex-col  w-[33%]";
  const filterIndivialClassFour = sm
    ? "flex flex-col w-[100%]"
    : "flex flex-col  w-[25%]";

    const handleSearch = () => {
      setRefreshData(!refreshData); 

    console.log("ORIGEN",originCountries)
    console.log("DESTINO", destinationCountries)
    };
  return (
    <StyledServices>
      <div className="servicesPageTitle">
        <PageTitle>{translate("title")}</PageTitle>
      </div>
      {/* <OnBoarding steps={onBoardingSteps} /> */}
      <div className="flex items-end justify-between">
        <div className=" w-[100%] flex flex-col gap-1">
          <div className={filterContainerClass}>
            <div className={filterIndivialClassThree}>
              <p className="font-semibold text-xs">{translate("search")}</p>
              <div className="flex">
                <GeneralSearch
                  selectedOption={filterCategorySearch}
                  setSelectedOption={setFilterCategorySearch}
                  filterValue={filterSearch}
                  setFilterValue={setFilterSearch}
                />
              </div>
            </div>
            <div className={filterIndivialClassThree}>
              <p className="font-semibold text-xs">{translate("date")}</p>
              <DateFilter
                filterDate={filterDate}
                setFilterDate={setFilterDate}
              />
            </div>
            <div className={filterIndivialClassThree}>
              <p className="font-semibold text-xs">{translate("method")}</p>
              <MethodFilter
                selectedMethods={filterMethod}
                setSelectedMethods={setFilterMethod}
              />
            </div>
          </div>
          <div className={filterContainerClass}>
            <div className={filterIndivialClassFour}>
              <p className="font-semibold text-xs">{translate("origin")}</p>
              <CountryFilter
                selectedCountries={originCountries}
                setSelectedCountries={setOriginCountries}
              />
            </div>
            <div className={filterIndivialClassFour}>
              <p className="font-semibold text-xs">
                {translate("destination")}
              </p>

              <CountryFilter
                selectedCountries={destinationCountries}
                setSelectedCountries={setDestinationCountries}
              />
            </div>

            <div className={filterIndivialClassFour}>
              <p className="font-semibold text-xs">{translate("load_type")}</p>
              <LoadFilter
                selectedLoadTypes={selectedLoadTypes}
                setSelectedLoadTypes={setSelectedLoadTypes}
              />
            </div>
            <div className={filterIndivialClassFour}>
              <p className="font-semibold text-xs">{translate("cost")}</p>
              <CostFilter
                filterCost={filterCost}
                setFilterCost={setFilterCost}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center ">
        <Button
          variant="outlined"
          endIcon={<FaFilter />}
          onClick={handleSearch}
          sx={{
            width: "100%",
            backgroundColor: theme.colors.primary_500.value,
            "&:hover": {
              backgroundColor: theme.colors.primary_400.value, // Color para el hover
            },
            "&:focus": {
              backgroundColor: theme.colors.primary_300.value, // Color para el focus
            },
            color: "#fff",
          }}
          size="small"
        >
          {translate("search")}
        </Button>
      </div>
      <div className="servicesTable">
        <TableServices
          filterSearch={filterSearch}
          loading={loading}
          setLoading={setLoading}
          originCountries={originCountries}
          destinationCountries={destinationCountries}
          filterCategorySearch={filterCategorySearch}
          selectedLoadTypes={selectedLoadTypes}
          filterMethod={filterMethod}
          filterDate={filterDate}
          filterCost={filterCost}
          refreshData={refreshData}

        />
      </div>
    </StyledServices>
  );
};



export default Services;
