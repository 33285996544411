import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "@nextui-org/react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Icon from "../../Icon/Icon";
import theme from "../../../theme";

const ChartContainer = styled("div", {
  padding: "20px",
  height:'500px',
});

const TitleIconContainer = styled("div", {
  display: "flex",
  gap: "10px",
  alignItems: "center",
  marginBottom: "20px",
});

const Title = styled("p", {
  color: theme.colors.primary_300,
  fontWeight: "800",
  fontSize: "1.2rem",
});

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          background: "#fff",
          padding: "10px",
          border: "1px solid #ccc",
          borderRadius: "5px",
        }}
      >
        <p>{label}</p>
        {payload.map((data, index) => (
          <p key={index} style={{ color: data.fill }}>{`${
            data.name
          }: $${data.value.toLocaleString()} USD`}</p>
        ))}
      </div>
    );
  }
  return null;
};

export default function SimpleBarChart({ data, title, icon }) {
  const { t: translate } = useTranslation("Home");

  const formatNumberWithCurrency = (value) => {
    return typeof value === "number" ? `$${value.toLocaleString()} USD` : value;
  };

  return (
    <ChartContainer>
      <TitleIconContainer>
        <Icon name={icon} color={theme.colors.secondary_200} />
        <Title>{title}</Title>
      </TitleIconContainer>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 40,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeOpacity={0.435} strokeDasharray="8 10"/>
          <XAxis dataKey="name" axisLine={false} tickLine={false}/>
          <YAxis tickFormatter={formatNumberWithCurrency} axisLine={false} tickLine={false}/>
          
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Bar dataKey={translate("land")} fill="url(#colorLand)" radius={[10, 10, 0, 0]}/>
          <Bar dataKey={translate("air")} fill="url(#colorAir)" radius={[10, 10, 0, 0]}/>
          <Bar dataKey={translate("ocean")} fill="url(#colorOcean)" radius={[10, 10, 0, 0]}/>
          <defs>
            <linearGradient id="colorLand" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#81D393" stopOpacity={0.9} />
              <stop offset="100%" stopColor="#81D393" stopOpacity={0.6} />
            </linearGradient>
            <linearGradient id="colorAir" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#1E90FF" stopOpacity={0.9} />
              <stop offset="100%" stopColor="#1E90FF" stopOpacity={0.6} />
            </linearGradient>
            <linearGradient id="colorOcean" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#3D509A" stopOpacity={0.9} />
              <stop offset="100%" stopColor="#3D509A" stopOpacity={0.6} />
            </linearGradient>
          </defs>
        </BarChart>
      </ResponsiveContainer>
    </ChartContainer>
  );
}
