import React, { useState } from 'react'
import { TwitterPicker } from 'react-color'
import { useFormContext } from 'react-hook-form'

export default function ColorPicker({name, itemIndex}) {
    const {setValue, watch} = useFormContext()
    const [showPicker, setShowPicker] = useState(false)

    const color = watch(`loads.${itemIndex}.color`)
    console.log("itemIndex :",itemIndex)
    return (
    <div style={{marginTop:'4px'}}>
        <span style={{fontSize:'12px', color:'#292E68'}}>Color </span>
        <div 
            style={{width:'100%', borderRadius:'20px', border:'1px solid grey', height:'30px', background: watch(`loads.${itemIndex}.color`) || "#5E6EAB" , cursor:'pointer'}}
            onClick={() => showPicker? setShowPicker(false) : setShowPicker(true)}    
        />
        {
            showPicker &&
            <div style={{position:'absolute', zIndex:999}}>
                <TwitterPicker onChange={(color)=>{
                    setValue(name, color.hex)
                    setShowPicker(false)
                }}/>
            </div>
        }
    </div>
  )
}
