import React, { useEffect, useState } from 'react';
import { styled } from '@nextui-org/react';
import Input from '../Input/Input';
import Button from '../Button/Button';
import { debounce } from 'lodash';

const Container = styled("div",{
  display:'flex',
  gap:'15px',
  flexDirection:'column',
  '.map':{
    borderRadius:'8px',
  },
  '.directions':{
    display:'flex',
    justifyContent:'space-between',
    alignItems:'end',
    gap:'40px',
  }
})

export const DirectionsMap = ({ mapId }) => {

  const [inputAddress, setInputAddress] = useState('');

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const userCoords = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          updateMapWithLocation(userCoords);
        },
        (error) => {
          console.error('Error getting user location:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };

  useEffect(() => {
    getCurrentLocation();
  }, [mapId]);

  const updateMapWithLocation = (coords, updateInput = true) => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: coords }, (results, status) => {
      if (status === 'OK') {
        const map = new google.maps.Map(document.getElementById(mapId), {
          zoom: 14,
          center: coords,
          disableDefaultUI: true,
          zoomControl: true,
          zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_BOTTOM,
          },
        });
        if (updateInput){
          setInputAddress(results[0].formatted_address);
        }
        new google.maps.Marker({
          position: coords,
          map: map,
          label: 'Mi Ubicación',
          title: results[0].formatted_address,
        });

        map.addListener('click', (e) => handleMapClick(e, map));
      } else {
        console.error('Geocoder failed due to: ' + status);
      }
    });
  };
  const handleMapClick = (e, map) => {
    const clickedCoords = {
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    };
    updateMapWithLocation(clickedCoords);
  };

  const handleAddressChange = async (address) => {
    console.log('address :',address)
    if (!inputAddress) {
      return;
    }
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address: address}, (results, status) => {
      if (status === 'OK') {
        const newLocation = results[0].geometry.location;
        updateMapWithLocation(newLocation, false);
      } else {
        console.error('Geocode was not successful for the following reason: ' + status);
      }
    });
  };
  const debounceAddressChange = debounce((address)=>{
    handleAddressChange(address)
  },300)

  return (
    <Container>
      <form onSubmit={handleAddressChange}>
        <div className='directions'>
          <Input
            label="Ingresa una dirección o haz click en el mapa :"
            type="text"
            value={inputAddress}
            fullWidth
            onChange={(e) => {setInputAddress(e.target.value); debounceAddressChange(e.target.value)}}
          />
          {/* <Button type="submit" text={"Actualizar"} outline/> */}
          <Button handler={() => getCurrentLocation()} endIcon={{name:"Tracking"}} outline/>
        </div>
      </form>
      <div id={mapId} style={{ height: '400px', width: '100%' }} className='map'/>
    </Container>
  );
};
