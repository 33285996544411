import React from 'react'
import './boat2.scss'

export default function Boat2({loadCount}) {

return (    
<section className="wrap">
	<div className="elmar">
		<div className="cielo">
		</div>
		<div className="barco">
			<div className="puente">
				<div className="chimeneal">
					<div className="linea"></div>
				</div>
				<div className="chimenear">
					<div className="linea"></div>
				</div>
				<div className="franja"></div>
				<div className="camarote1">
					<div className="antiskew">
						<div className="ventana"></div>
						<div className="ventana"></div>
						<div className="ventana"></div>
						<div className="ventana"></div>
						<div className="ventana"></div>
						<div className="puertita"></div>
					</div>
				</div>
				<div className="radar">
					<div className="cabeza"></div>
					<div className="soporte"></div>	
				</div>
				<div className="fantena"></div>
				<div className="santenea"></div>
				<div className="control">
					<div className="tapa">
						<div className="top-ventana"></div>
						<div className="top-ventana"></div>
						<div className="top-ventana"></div>
					</div>
					<div className="mid-ventana"></div>
					<div className="mid-ventana"></div>
					<div className="mid-ventana"></div>
					<div className="mid-ventana"></div>
					<div className="mid-ventana"></div>
					<div className="mid-ventana"></div>
					<div className="mid-ventana"></div>
					<div className="mid-ventana"></div>
					<div className="mid-ventana"></div>
					<div className="mid-ventana"></div>
					<div className="mid-ventana"></div>
					<div className="mid-ventana"></div>
					<div className="puertita"></div>
				</div>
				<div className="bajo-camarotes">
					<div className="ventanitas"></div>
					<div className="ventanitas"></div>
					<div className="ventanitas"></div>
					<div className="ventanitas"></div>
					<div className="ventanitas"></div>
					<div className="ventanitas"></div>
				</div>
			</div>
			<div className="base">
				<div className="atras"></div>
				<div className="popa"></div>
				<div className="tronco">
					<div className="marca">
						<div className="per"></div>
					</div>
				</div>
				<div className="proa"></div>
				<div className="anclacont"></div>
			</div>
			<div className="contenedores">
                {[...Array(loadCount)].map((_, index) => (
                    <div className="contenedor" key={index}>
                        <div className="cal"></div>
                        <div className="cal"></div>
                        <div className="cal"></div>
                        <div className="cal"></div>
                        <div className="cal"></div>
                        <div className="cal"></div>
                        <div className="cal"></div>
                        <div className="cal"></div>
                        <div className="cal"></div>
                        <div className="cal"></div>
                    </div>
                ))}
				{/* <div className="contenedor">
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
				</div>
				<div className="contenedor">
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
				</div>
				<div className="contenedor">
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
				</div>
				<div className="contenedor">
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
				</div>
				<div className="contenedor">
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
				</div>
				<div className="contenedor">
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
				</div>
				<div className="contenedor">
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
				</div>
				<div className="contenedor">
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
				</div>
				<div className="contenedor">
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
				</div>
				<div className="contenedor">
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
				</div>
				<div className="contenedor">
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
				</div>
				<div className="contenedor">
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
					<div className="cal"></div>
				</div> */}
			</div>
		</div>
        {/* style="enable-background:new 0 0 13685.8 298.5;" */}
        
		<div className="agua">
            <svg version="1.1" className="olacont" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 13685.8 298.5"  xml:space="preserve">
				<path className="olita" d="M13641.6,241.3c-325.4-8.8-403.9-213.3-535-213.3c-85.9,0-159.3,27.7-292.7,127.3c-93.3,69.7-372.3,86-372.3,86
				h-44.1c-325.4-8.8-403.9-213.3-535-213.3c-85.9,0-159.3,27.7-292.7,127.3c-93.3,69.7-372.3,86-372.3,86h-44.1
				c-325.4-8.8-403.9-213.3-535-213.3c-85.9,0-159.3,27.7-292.7,127.3c-93.3,69.7-372.3,86-372.3,86h-44.1
				c-325.4-8.8-661.3-147-827.7-86c-109.4,40.1-372.3,86-372.3,86H8665C8339.6,232.6,8261.1,28,8130,28c-85.9,0-159.3,27.7-292.7,127.3
				c-93.3,69.7-372.3,86-372.3,86h-44.2c-301.4-8.1-708.8-174.7-827.7-86c-78.8,58.8-289.7,79.6-353.6,84.7c-0.6,0-1.2,0.1-1.7,0.1
				c-1.1,0.1-2.2,0.2-3.2,0.2c-1.5,0.1-3,0.2-4.3,0.3c-0.9,0.1-1.7,0.1-2.4,0.2c-4.5,0.3-7,0.5-7,0.5h-44.1
				C5851.3,232.6,5772.8,8,5641.7,8c-85.9,0-159.3,47.7-292.7,147.3c-93.3,69.7-372.3,86-372.3,86h-44.1
				c-325.4-8.8-694.3-185.6-827.7-86c-93.3,69.7-372.3,86-372.3,86h-44.1C3363,232.6,3284.4,28,3153.4,28
				c-85.9,0-159.3,27.7-292.7,127.3c-93.3,69.7-372.3,86-372.3,86h-44.1c-325.4-8.8-403.9-179.3-535-179.3
				c-85.9,0-159.3-6.3-292.7,93.3c-93.3,69.7-372.3,86-372.3,86H1200C874.7,232.6,796.1,28,665,28c-85.9,0-159.3,27.7-292.7,127.3
				C278.9,225,0,241.3,0,241.3v57.2h0h1244.2h0h1244.2h0h1244.2h0h1244.2h0h1244.2h0H7465h0h1244.2l0,0h1244.2l0,0h1244.2l0,0h1244.2
				l0,0h1244.2v-57.2H13641.6z"/>
			</svg>
		</div>

	</div>
</section>
  )
}
