import { Modal, styled } from '@nextui-org/react'
import React from 'react'
import src from "../../../assets/img/containerDrawing.jpg"
import st20Img from "../img/20st.svg"
import st40Img from "../img/40st.svg"
import hq40Img from "../img/40hq.svg"
import hq45Img from "../img/45hq.svg"
import openTopImg from "../img/40op.svg"
import ref20Img from "../img/20ref.svg"
import ref40Img from "../img/40ref.svg"
import { useFormContext } from 'react-hook-form'

const StyledModal = styled(Modal, {
    padding: '1.5rem',
    display:'flex',
    gap:'20px',
    color: '$primary_500 !important',
})

const Container= styled("div",{
    display:'grid',
    gridTemplateColumns:'auto auto auto auto',
    gap:'20px',
})
const Card = styled("div",{
    fontSize:'13px',
    boxShadow:'$elevation_1',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    flexDirection:'column',
    paddingTop:'10px',
    borderRadius:'8px',
    border:'1px solid grey',
    cursor:'pointer',
    transition:'300ms',
    'img':{
        height:'150px',
        width:'170px'
    },
    '.info':{
        padding:'20px',
        display:'flex',
        flexDirection:'column',
    },
    '&:hover':{
        border:'2px solid $primary_300',
        transform:'scale(1.02)',
    }
})
export const ContainerOptions = [
    {
        name:"20' standard",
        img:st20Img,
        qty:1,
        length:589.5,
        width:235,
        height:239.2,
        maxWeight:28230,
    },
    {
        name:"40' standard",
        img:st40Img,
        qty:1,
        length:1202.9,
        width:235,
        height:239.2,
        maxWeight:26700,
    },
    {
        name:"40' HC",
        img:hq40Img,
        qty:1,
        length:589.5,
        width:235,
        height:269.7,
        maxWeight:26460,
    },
    {
        name:"45' HC",
        img:hq45Img,
        qty:1,
        length:1355.56,
        width:235,
        height:269.7,
        maxWeight:27700,
    },
    {
        name:"20' Open top",
        img:openTopImg,
        qty:1,
        length:588.8,
        width:234.5,
        height:218.4,
        maxWeight:30480,
    },
    {
        name:"40' Open top",
        img:openTopImg,
        qty:1,
        length:1202.9,
        width:234.2,
        height:232.6,
        maxWeight:26670,
    },
    {
        name:"20' Refrigerated",
        img:ref20Img,
        qty:1,
        length:572.4,
        width:228.6,
        height:201.4,
        maxWeight:21450,
    },
    {
        name:"40' Refrigerated",
        img:ref40Img,
        qty:1,
        length:1184,
        width:228.6,
        height:212.4,
        maxWeight:26630,
    },
    {
        name:"Custom",
        img:st20Img,
        qty:1,
        length:589.5,
        width:235,
        height:239.2,
        maxWeight:28230,
        editable:true,
    },
]

export default function AddContainerModal({ open, setOpen }) {
    const { getValues, setValue, reset, control, register, watch, trigger } = useFormContext();

    function handleClick(container){
        const formContainers = getValues('containers')
        console.log("formContainers :",formContainers)
        const key = Object.keys(formContainers)?.length
        setValue(`containers.${key}`,container)
        setOpen(false)
    }

    return (
    <StyledModal
        closeButton
        aria-label="modal-title"
        open={open}
        onClose={() => setOpen(false)}
        width="fit-content"
    >
        <Container>
            {
                ContainerOptions.map(container => (
                    <Card onClick={()=>handleClick(container)}>
                        <img src={container.img ||  src} alt=""/>
                        <div className='info'>
                            <b>{container?.name}</b>
                            {
                                container?.name !== "Custom" && 
                                <span>{`${container.length} x ${container.width} x ${container.height} (cm)`}</span>
                            }
                            {
                                container?.name !== "Custom" &&
                                <span>Max weight : {container.maxWeight} kg</span>
                            }
                        </div>
                    </Card>
                ))
            }
        </Container>
    </StyledModal>
  )
}
