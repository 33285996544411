import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material-UI imports
import {
  Button,
  CssBaseline,
  TextField,
  Link,
  Paper,
  Box,
  Grid,
  Typography,
  createTheme,
  ThemeProvider,
  IconButton,
  Tooltip,
} from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Copyright from "../../../components/Copyright/Copyright.jsx";
// Import your actions and services
import { loginAction } from "../../../store/sessionDuck.js";
import { setTenantAction } from "../../../store/tenant.js";
import { getTenant } from "../../../services/auth.services.js";

const defaultTheme = createTheme();

function LoginOne() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tenant = useSelector((state) => state.tenant);
  const user = useSelector((state) => state.user);
  const { register, handleSubmit } = useForm();
  const logo = useSelector((state) => state.tenant.logo);
  const [loadingMail, setLoadingMail] = useState(false);
  const { t: translate, i18n } = useTranslation("Login");

  useEffect(() => {
    const userLang = navigator.language || navigator.userLanguage;
    const langToUse = userLang.startsWith("es") ? "es_CO" : "en_US";
    i18n.changeLanguage(langToUse);
  }, [i18n]);

  useEffect(() => {
    const tenantName = localStorage.getItem("tenantName");
    if (tenantName) {
      if (user.loggedIn) {
        navigate(
          `/login-password/?tenant=${tenantName}&mail=${encodeURIComponent(
            user.mail
          )}`
        );
      } else {
        dispatch(setTenantAction("None"));
      }
    } else {
      dispatch(setTenantAction("None"));
    }
  }, [user, navigate, dispatch]);

  const onSubmit = async (data) => {
    setLoadingMail(true);
    try {
      const tenantData = await getTenant(data.email);
      navigate(
        `/login-password/?tenant=${tenantData.name}&mail=${encodeURIComponent(
          data.email
        )}`
      );
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoadingMail(false);
    }
  };

  const toggleLanguage = () => {
    const newLang = i18n.language === "en_US" ? "es_CO" : "en_US";
    i18n.changeLanguage(newLang);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            position: "relative",
            overflow: "hidden",
          }}
        >
          <Box
            component="video"
            autoPlay
            muted
            loop
            playsInline
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          >
            <source src="https://www.shutterstock.com/shutterstock/videos/1112122749/preview/stock-footage-aerial-front-view-cargo-ship-carrying-container-and-running-for-export-goods-from-cargo-yard-port.mp4" />
            Your browser does not support the video tag.
          </Box>
          {/* <Box
            sx={{
              position: "absolute",
              bottom: "10%",
              left: "10%",
              right: "10%",
              textAlign: "left",
              color: "white",
              zIndex: 1,
              padding: "20px",
              borderRadius: "10px",
              background: "rgba(0, 0, 0, 0.6)",
              backdropFilter: "blur(5px)",
              boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Typography
              variant="h4"
              component="h2"
              gutterBottom
              sx={{
                fontWeight: "light",
                letterSpacing: "2px",
                textTransform: "uppercase",
              }}
            >
              {translate("discover_future")}
            </Typography>
            <Typography
              variant="h2"
              component="h1"
              gutterBottom
              sx={{
                fontWeight: "bold",
                letterSpacing: "3px",
                textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
                marginBottom: "20px",
              }}
            >
              VAL LOGISTICS
            </Typography>
            <Typography
              variant="h6"
              component="p"
              gutterBottom
              sx={{
                fontWeight: "medium",
                marginBottom: "20px",
              }}
            >
              {translate("driving_global")}
            </Typography>
            <Link
              href="https://val.com.co"
              color="inherit"
              underline="none"
              sx={{
                display: "inline-block",
                padding: "10px 20px",
                border: "2px solid white",
                borderRadius: "25px",
                transition: "all 0.3s ease",
                "&:hover": {
                  backgroundColor: "white",
                  color: "black",
                },
              }}
            >
              {translate("know_more")}
            </Link>
          </Box> */}
          <Box
            sx={{
              position: "absolute",
              top: "20px",
              right: "20px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
           <IconButton
              component="a"
              href="https://www.facebook.com/profile.php?id=61551026647484"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ color: "white", mr: 1 }}
            >
              <FacebookIcon />
            </IconButton>
            <IconButton
              component="a"
              href="https://x.com/val_logistics"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ color: "white", mr: 1 }}
            >
              <TwitterIcon />
            </IconButton>
            <IconButton
              component="a"
              href="https://www.linkedin.com/company/val-logistics"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ color: "white" }}
            >
              <LinkedInIcon />
            </IconButton>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          square
          sx={{
            background: "linear-gradient(145deg, #f3f3f3 0%, #e6e6e6 100%)",
            position: "relative",
          }}
        >
          <Tooltip title={translate("toggle_language")} placement="left">
            <IconButton
              onClick={toggleLanguage}
              color="primary"
              sx={{
                position: "absolute",
                top: "20px",
                right: "20px",
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 1)",
                },
              }}
            >
              <LanguageIcon />
            </IconButton>
          </Tooltip>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {/* <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar> */}
            <Typography component="h1" variant="h4" gutterBottom>
              {translate("welcome_back")}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary" gutterBottom>
              {translate("login_subtitle")}
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit(onSubmit)}
              sx={{ mt: 3, width: "100%" }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label={translate("enter_email")}
                name="email"
                autoComplete="email"
                autoFocus
                {...register("email")}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={loadingMail}
              >
                {loadingMail ? translate("wait") : translate("continue")}
              </Button>
              <Grid container justifyContent="center">
                <Grid item>
                  <Link component={RouterLink} to="/register" variant="body2">
                    {translate("or_register")}
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
          <Box
            sx={{
              mx: 4,
              mb: 4,
              textAlign: "left",
              color: "white",
              padding: "15px",
              borderRadius: "10px",
              background: "rgba(0, 0, 0, 0.6)",
              backdropFilter: "blur(5px)",
              boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              sx={{
                fontWeight: "light",
                letterSpacing: "1px",
                textTransform: "uppercase",
                fontSize: "0.9rem",
              }}
            >
              {translate("discover_future")}
            </Typography>
            <Typography
              variant="h4"
              component="h1"
              gutterBottom
              sx={{
                fontWeight: "bold",
                letterSpacing: "2px",
                textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
                marginBottom: "10px",
                fontSize: "1.5rem",
              }}
            >
              VAL LOGISTICS
            </Typography>
            <Typography
              variant="body2"
              component="p"
              gutterBottom
              sx={{
                fontWeight: "medium",
                marginBottom: "10px",
                fontSize: "0.8rem",
              }}
            >
              {translate("driving_global")}
            </Typography>
            <Link
              href="https://val.com.co"
              color="inherit"
              underline="none"
              sx={{
                display: "inline-block",
                padding: "5px 10px",
                border: "1px solid white",
                borderRadius: "15px",
                transition: "all 0.3s ease",
                fontSize: "0.8rem",
                "&:hover": {
                  backgroundColor: "white",
                  color: "black",
                },
              }}
            >
              {translate("know_more")}
            </Link>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default LoginOne;
