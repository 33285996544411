import React from 'react';
import { Avatar, Loading, styled } from '@nextui-org/react';
import theme from '../../../theme';

const Summary = ({ user }) => {
    const StyledSummary = styled('div', {
        [`.${theme} &`]: {
            backgroundColor: '$neutral_50',
            borderRadius: '8px',
            width: '100%',
            padding: '2rem',
            gap: '1rem',
            ['&:nth-child(1)']: {
                ['section:nth-child(2)']: {
                    width: '40%'
                },
                ['section:nth-child(3)']: {
                    width: '20%'
                },
                ['section:nth-child(4)']: {
                    width: '20%'
                },
                ['section:nth-child(5)']: {
                    width: '15%'
                }
            },
            ['section']: {
                display: 'flex',
                flexDirection: 'column'
            },
            ['.activePoint']: {
                backgroundColor: '$secondary_2_300',
                borderRadius: '1rem',
                width: '12px',
                height: '12px',
                marginRight: '0.5rem'
            },
        }
    });
    const s3ImageUrl = `https://val-docs.s3.amazonaws.com/documents/${user.email}/userprofile/profile.jpeg`;
    return (
        <StyledSummary className='d-flex align-items-center justify-content-center'>
            {
                Object.keys(user).length > 0 ?
                    <>
                        <Avatar
                            src={user.s3_img ? s3ImageUrl : null} // Usa la URL S3 si está disponible
                            text={`${user?.first_name[0]?.toUpperCase()}${user?.last_name[0]?.toUpperCase()}`}
                            size='xl'
                        />
                        <section>
                            <span className='bold'>
                                {user.first_name && user.last_name ? `${user.first_name} ${user.last_name}` : ''}
                            </span>
                            {user.email}
                        </section>

                        <section>
                            <span className='bold'>Industria</span>
                            {user.industry}
                        </section>

                        <section>
                            <span className='bold'>NIT</span>
                            {user.document}
                        </section>

                        <section>
                            <div className='d-flex align-items-center'>
                                <div className='activePoint'></div>
                                <span className='bold'>Miembro activo</span>
                            </div>
                        </section>
                    </>
                    :
                    <Loading></Loading>
            }
        </StyledSummary>
    );
}

export default Summary;
