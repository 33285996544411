import video from '../../assets/tools/video.jpg'
const tutorials = [
    {
        title: '¿Cómo exportar?',
        image: video,
        url: '/tools/export'
    },
    {
        title: 'Crear cotización',
        image: video,
        url: '/tools/quote'
    },
    {
        title: 'Exportar a China',
        image: video,
        url: '/tools/export-to-china'
    },
    {
        title: 'Enfoque Sostenible',
        image: video,
        url: '/tools/co2-tutorial'
    },
    {
        title: 'Servicios',
        image: video,
        url: '/tools/service-tutorial'
    }
];

export default tutorials;