import { Loading, styled } from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import Input from "../../../../../components/Input/Input";
import Button from "../../../../../components/Button/Button";
import Icon from "../../../../../components/Icon/Icon";
import { useSelector } from "react-redux";
import Bubble from "../Chat/Bubble";
import Pusher from "pusher-js";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { useForm } from "react-hook-form";
import { useFetch } from "../../../../../hooks/useFetch";
import { URL } from "../../../../../constants";
import { format } from "date-fns";
import { transform } from "lodash";

const Container = styled("div",{
  position: "fixed",
  bottom: 0,
  gap:'49px',
  right: "50px" ,
  display:'flex',
})

const ChatContainer = styled("div", {
  border: "1px solid $primary_200",
  width: "600px",
  height: "600px",
  position: "relative",
  display:'flex',
  flexDirection:'column',
  bottom: 0,
  borderRadius: "8px 8px 0 0",
  right: "55px",
  ".header": {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    borderRadius: "0px 6px 0 0",
    background: "$primary_200",
    color: "white",
    padding: "2px 20px",
  },
});

const StyledChat = styled("div", {
  padding: "1rem",  
  background: "white",
  borderRadius:'8px 0 0 0',
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  overflowY: "auto",
  flex: "1",
  height: "100%",
  "&::-webkit-scrollbar": {
    width: "10px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#888",
    borderRadius: "10px",
  },
});

const Conversations = styled("div", {
  transition: '300ms',
  width: '270px',
  height: '600px',
  borderRadius: "6px 0 0 0",
  background: "$primary_200",
  position: 'relative',
  paddingTop: '30px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '15px',
  '.conversation': {
    transition: '300ms',
    opacity:1,
    background: 'white',
    padding: '10px',
    width: '90%',
    borderRadius: '16px',
    display: 'flex',
    alignItems: 'center',
    gap: '30px',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.01)',
    }
  },
  ".user-icon": {
    borderRadius: "50px",
    width: "2.5rem",
    height: "2.5rem",
    backgroundColor: "$primary_300",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "&.collapsed": {
    width: '70px',
    ".conversation": {
      width:0,
      opacity:0,
    },
  },
  "&.expanded": {
    width: '270px',
    ".conversation": {
      width: '90%',
      gap: '30px',
    }
  }
});

const StyledInputZone = styled("form", {
  background: "white",
  boxShadow:'$elevation_1',
  display: "flex",
  justifyContent: "space-between",
  alignItems:'center',
  width: "100%",
  height: "96px",
  gap: "10px",
  padding: "10px 20px",
  '.sendButton':{
    transition:'300ms',
    '&:hover':{
      transform:'scale(1.04)'
    }
  }
});

const pusher = new Pusher("8f28c7342c752ebca446", {
  cluster: "us2",
});

const ClipButton = styled("button", {
  background:'transparent',
  border: "none",
  cursor: "pointer",
  zIndex:'999',
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginRight: "0.5rem",
  transition: "opacity 0.25s ease 0s, transform 0.25s ease 0s",
  "&:hover": {
    opacity: 0.9,
    transform: "scale(1.1)",
    cursor: "pointer",
  },
  "&:active": {
    transform: "scale(0.95)",
  },
});

const Minimized = styled("div",{
  position:'fixed',
  bottom:0,
  right:'300px',
  display: "flex",
  justifyContent: "space-between",
  width: "400px",
  borderRadius: "6px 6px 0 0",
  background: "$primary_200",
  color: "white",
  padding: "2px 20px",
})

export default function ChatInterno({ open, setOpen, otherUser, setOtherUser, radication, minimize=false, setMinimize, users }) {
  
  const chatRef = useRef();
  const { register, handleSubmit, reset,watch } = useForm();
  
  const [classChat, setClassChat] = useState("");
  const [classDropped, setClassDropped] = useState("");
  const [file, setFile] = useState(null);
  const [collapse,setCollapse] = useState(true)

  const token = useSelector((state) => state.user.token);
  const idUser = useSelector((state) => state.user.idUser);
  const channel = pusher.subscribe("chat" + radication + "_" + otherUser?.id);

  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState([]);

  const { data, loading } = useFetch(
    URL.get() + "chat/messages/" + radication + "/?user_id=" + otherUser?.id,
    {
      Authorization: "Token " + token,
    }
  );
  console.log("file :",file)
  console.log("message :",message)
  const sendChatMessage = async (message, token, radication, userId) => {
    try {
      const response = await fetch(URL.get() + "send/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + token,
        },
        body: JSON.stringify({
          user: token,
          message: message,
          radication: radication,
          user_id: userId,
        }),
      });

      if (!response.ok) {
        throw new Error("Error al enviar el mensaje");
      }
    } catch (error) {
      console.error("Error al enviar el mensaje:", error);
    }
  };

  const handleSetFile = (e) => {
    const fileType = e.target.files[0].type;
    console.log(fileType);
    if (
      fileType === "application/pdf" ||
      fileType === "application/msword" ||
      fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      fileType === "image/jpeg" ||
      fileType === "image/png" ||
      fileType === "application/vnd.ms-excel" ||
      fileType === "application/zip" ||
      fileType === "application/x-zip-compressed" ||
      fileType === "application/x-rar-compressed" ||
      fileType === "application/vnd.ms-powerpoint" ||
      fileType === "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
      fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setFile(e.target.files[0]);
      setClassDropped("dropped");
      console.log(fileType);
    } else {
      setFile(null);
      toast(
        "error",
        "Lo sentimos, el formato del archivo debe ser PDF, Word, JPEG, PNG, Excel, ZIP o RAR"
      );
    }
  };

  useEffect(() => {
    const messages = data?.data
    if (!loading && !Array.isArray(messages)) {
    }
    if (!loading && Array.isArray(messages)) {
      const formatMessages = messages?.map((item,i) => ({
        key:i,
        user: item.user,
        message: item.message,
        time: item.time,
        name: item.name,
        type: item.type,
      }));
      setMessages(formatMessages);
    }
  }, [data, chatRef.current]);

  useEffect(() => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    channel.bind("chatting", function (response) {
      let newMessage = response.data;
      let newMessageTime = format(new Date(),'yyyy-MM-dd hh:mm:ss') 
      newMessage.time=newMessageTime
      setMessages((prevMessages) => [...prevMessages, newMessage]);
    });
  }, []);

  const sendMessage = (data) => {
    if (message){
      sendChatMessage(message, token, radication, otherUser.id);
    }
    setMessage('')
    reset({
      chat: "",
    });
  };

  return !open ? null : minimize? <Minimized onClick={()=>setMinimize(false)}>
      {otherUser?.first_name} {otherUser?.last_name}   💬
    </Minimized> :  (
    <Container>
      <Conversations className={collapse ? 'collapsed' : 'expanded'}>
        <div>
          <Button endIcon={{ name: collapse?'ArrowLeft':'ArrowRight' }} size={'sm'} handler={() => setCollapse(!collapse)} />
        </div>
        {users?.map(userData => {
          const user = userData?.user;
          return (
            <div className="conversation" onClick={() => { setOtherUser(userData) }} key={userData.id}>
              <div className="user-icon">
                <span>{`${user.first_name[0]?.toUpperCase()}${user.last_name[0]?.toUpperCase()}`}</span>
              </div>
              <span>{user.first_name} {user.last_name}</span>
            </div>
          )
        })}
      </Conversations>
      <ChatContainer>
        <div className="header">
          {otherUser?.first_name} {otherUser?.last_name}   💬
          <div style={{display:'flex',gap:'10px'}}>
            <Button
              handler={() => setMinimize(true)}
              text={'-'}
              size={"xs"}
            />
            <Button
              handler={() => setOpen(false)}
              endIcon={{ name: "Close", size: "15px" }}
              size={"xs"}
            />
          </div>
        </div>
        {loading?
          <div style={{height: "100%",display:'flex',justifyContent:'center',alignItems:'center', background: "#F3F5FF",}}>
            <Loading size="md"/>
          </div>:
          <StyledChat ref={chatRef}>
          {messages.map((message, index) => {
            return (
              <Bubble
                key={index}
                isUser={message.user === idUser}
                message={message.message}
                name={message.name}
                time={message.time}
                type={message.type}
              />
            );
          })}
        </StyledChat>}
          <StyledInputZone onSubmit={handleSubmit(sendMessage)}>
            <Input
              register={register}
              value={message}
              onChange={e=>setMessage(e.target.value)}
              name="chat"
              placeholder="Escribe un nuevo mensaje"
              fullWidth
              endIcon={
                <ClipButton style={{ position: "relative" }}>
                  <Icon name="Clip" />
                  <input
                    type="file"
                    onChange={handleSetFile}
                    style={{
                      position: "absolute",
                      top: 0,
                      bottom: 0,
                      right: 0,
                      left: 0,
                      opacity: 0,
                    }}
                  />
                </ClipButton>
              }
            />
            <div className="sendButton">
              <Button
                type="submit"
                endIcon={{name:"Send"}}
              />
            </div>
          </StyledInputZone>
      </ChatContainer>
    </Container>
  );
}
