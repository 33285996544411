import React from 'react'
import { useEffect, useState } from 'react'
import { getDOs, deleteDO } from '../../../services/operations.services'
import { useSelector } from 'react-redux'
import { styled, Modal } from '@nextui-org/react'
import Button from '../../../components/Button/Button'
import Input from '../../../components/Input/Input'
import toast from '../../../components/Toast/ToastTypes'
import InstructionsTable from './InstructionsTable'

const StyledContainer = styled("div", {
    borderRadius: '8px',
    "@media(max-width:1200px)":{
        margin: '5px',
    }
})

const StyledFormModal = styled(Modal, {
    padding: '1.5rem',
    color: '$primary_500'
})

function formatDate(dateString) {
    const originalDate = new Date(dateString);
    const day = originalDate.getDate();
    const month = originalDate.getMonth() + 1;
    const year = originalDate.getFullYear();

    const formattedDay = day.toString().padStart(2, '0');
    const formattedMonth = month.toString().padStart(2, '0');

    const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;
    return formattedDate;
}

export default function Instructions() {
    const token = useSelector(state => state.user.token)
    const [openModal, setOpenModal] = useState(false)
    const [selectedDO, setSelectedDo] = useState({})
    const [DOs, setDOs] = useState([])
    const [displayedDOs, setDisplayedDOs] = useState([])
    const [searchValue, setSearchValue] = useState('');
    const [deleteConfirmation, setDeleteConfirmation] = useState(false)

    useEffect(() => {
        getDOs(token).then(async res => {
            const data = await res.json();
            const tableData = data.map((item) => {
                return {
                    ...item,
                    created_at: formatDate(item.created_at),
                }
            })
            setDOs(tableData);
            setDisplayedDOs(tableData);
        })
    }, [deleteConfirmation])

    useEffect(() => {
        if (searchValue) {
            setDisplayedDOs(DOs.filter(DO => (
                DO.radication.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                DO.shipping_instructions.toLowerCase().includes(searchValue.toLowerCase())
            )));
        } else {
            setDisplayedDOs(DOs);
        }
    }, [searchValue]);

    const handleDelete = () => {
        deleteDO(selectedDO.id, token).then(res => {
            toast('success', 'Servicio borrado con exito')
            setOpenModal(false)
            setDeleteConfirmation(false)
            setSelectedDo('')
        }).catch(err => { console.log('ERROR :', err); toast('error', 'No se pudo borrar la instruccion') })
    }
    return (
        <StyledContainer>
            {/* <Input placeholder="Buscar..." onChange={(e)=>setSearchValue(e.target.value)}/> */}
            {displayedDOs.length && <InstructionsTable items={displayedDOs} />}
            {/* <Cards>
                {
                    displayedDOs && displayedDOs.map(DO => {
                        return(
                            <Card onClick={()=>{setSelectedDo(DO); setOpenModal(true)}}>
                                <p>{DO.radication}</p>
                                <div dangerouslySetInnerHTML={{__html:DO.shipping_instructions}} />
                            </Card>
                        )
                    })
                }
            </Cards> */}
            <StyledFormModal
                closeButton
                aria-label="modal-title"
                open={openModal}
                onClose={() => setOpenModal(false)}
                width="fit-content"
            >
                <Modal.Body>
                    <Button text='Borrar' bold bgColor={'delete'} textColor='neutral_50' handler={() => setDeleteConfirmation(true)} />
                    <div dangerouslySetInnerHTML={{ __html: selectedDO.shipping_instructions }} />
                </Modal.Body>
            </StyledFormModal>
            <StyledFormModal
                closeButton
                aria-label="modal-title"
                open={deleteConfirmation}
                onClose={() => setDeleteConfirmation(false)}
                width="fit-content"
            >
                <Modal.Header justify='space-between'>
                    <h3>Confirmar</h3>
                </Modal.Header>
                <Modal.Body justify='space-between'>
                    <p>Confirma que desea borrar la instruccion seleccionada</p>
                    <div style={{ display: 'flex', justifyContent: 'end', gap: '15px' }}>
                        <Button text='Cancelar' bold bgColor={'primary_300'} textColor='neutral_50' handler={() => setDeleteConfirmation(false)} />
                        <Button text='Borrar' bold bgColor={'delete'} textColor='neutral_50' handler={() => handleDelete()} />
                    </div>
                </Modal.Body>
            </StyledFormModal>
        </StyledContainer>
    )
}
