

import { useState } from 'react';
import { styled, Tooltip } from '@nextui-org/react';
import PreLiquidationTable from './PreLiquidationTable';
import Button from '../../../components/Button/Button';
import { useNavigate } from 'react-router-dom';
import { getQuoteData } from '../../../services/quotations.services';
import { getPreliquidations, formatLiquidation } from '../../../services/preliquidation.services';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import FinishQuotationModal from '../QuoteForm/FinishQuotationModal';
import { useTranslation } from "react-i18next";
import toast from '../../../components/Toast/ToastTypes';
import Icon from '../../../components/Icon/Icon';
import DuplicateModal from './DuplicateModal';
import PreliquidationTable2 from './CreatePreliquidation/PreliquidationTable2';

export default function PreLiquidation({ currentQuote, setShowTable }) {
    const navigate = useNavigate()

    const [quoteData, setQuoteData] = useState({})
    const [openModalParticipants, setOpenModalParticipants] = useState(false)
    const [openFinishModal, setOpenFinishModal] = useState(false)
    const [openDuplicateModal, setOpenDuplicateModal] = useState(false)

    const [currentQuoteData, setCurrentQuoteData] = useState('')
    const [tableData, setTableData] = useState([])

    const token = useSelector(state => state.user.token)

    const StyledDiv = styled('div', {
        bgColor: '$neutral_50',
        borderRadius: '8px',
        padding: '15px',
        '.buttons':{
            display: 'flex',
            justifyContent:'space-between',
            gap: '15px',
            margin: '10px',
            "@media(max-width:1000px)":{
                display:'flex',
                flexDirection:'column',
            }
        },
        '.leftButtons':{
            display:'flex',
            gap:'15px',
            "@media(max-width:1000px)":{
                display:'flex',
                flexDirection:'column',
            }
        }
    })

    useEffect(() => {
        if (currentQuote){
            getQuoteData(token, currentQuote).then(async res => {
                const currentQuoteData = await res.json();
                setCurrentQuoteData(currentQuoteData)
            })
        }
    }, [openModalParticipants, currentQuote])

    useEffect(() => {
        if (currentQuote) {
            getQuoteData(token, currentQuote).then(async res => {
                const data = await res.json();
                setQuoteData(data);
            })
        }
    }, [currentQuote]);

    function handleDuplicate(){
        setShowTable(true)
        toast('info',<div>Elija una cotizacion de la tabla y presione duplicar <Icon name='Versions' size='19px'/> en el menu de acciones (<Icon name='MenuVertical' size='14px'/>)</div>)
    }

    return (
        <StyledDiv>
            <DuplicateModal currentQuoteData={currentQuoteData} open={openDuplicateModal} setOpen={setOpenDuplicateModal}/>
            <div className='buttons'>
                <div className='leftButtons'>
                <Button
                    size="sm"
                    disabled={!currentQuoteData?.radication 
                    // || currentQuoteData?.preliquidation_ids?.length > 0
                    }
                    text={currentQuoteData?.preliquidation_ids?.length > 0 ? 'Editar preliquidacion' : 'Crear preliquidacion'}
                    
                    bgColor={'primary_300'}
                    textColor='neutral_50'
                    handler={() => navigate(
                        currentQuoteData?.preliquidation_ids?.length > 0 ?
                        `./create_preliquidation/?radication=${currentQuoteData.radication}&id=${currentQuoteData.id}&preliquidationId=${currentQuoteData?.preliquidation_ids[0]}`:
                        `./create_preliquidation/?radication=${currentQuoteData.radication}&id=${currentQuoteData.id}`
                    )}
                    endIcon={{
                        name:currentQuoteData?.preliquidation_ids?.length > 0 ? 'EditSquare' : 'Plus',
                        color:'white'
                    }}
                />
                <Button text={"Duplicar liquidación"}
                    size="sm"
                    
                    bgColor='primary_300'
                    textColor='neutral_50'
                    // disabled={!currentQuote}
                    handler={()=>setOpenDuplicateModal(true)}
                    endIcon={{name:'Versions', color:'white'}}
                />
                </div>
                
                <Tooltip content="Finaliza la liquidacion y la comparte con el cliente para su aprobación">
                    <Button text={quoteData?.approve? "liquidación ya finalizada" : 'Finalizar liquidación'}
                        size="sm"
                        
                        bgColor='primary_300'
                        textColor='neutral_50'
                        disabled={!currentQuote || quoteData?.approve}
                        handler={()=>setOpenFinishModal(true)}
                        endIcon={{name:'Cotizaciones', color:'white'}}
                    />
                </Tooltip>
            </div>
            <div style={{ margin: '20px' }}>
                {!currentQuoteData && <p> "Seleccione una cotizacion de la tabla ↑" </p>}
            </div>
            {/* <PreLiquidationTable
                tableData={tableData}
                currentQuote={currentQuote}
                radication={currentQuoteData.radication}
                setOpenModalParticipants={setOpenModalParticipants}
            /> */}
            <PreliquidationTable2 currentQuoteData={currentQuoteData}/>
            <FinishQuotationModal quoteData={quoteData} open={openFinishModal} setOpen={setOpenFinishModal} />
        </StyledDiv>
    )
}