import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

export default function BasicTimeline({items}) {
  console.log("timeline :p")
  return (
    <Timeline
      sx={{
        // background:'red',
        width:'100%',
        [`& .${timelineItemClasses.root}:before`]: {
          background:'red',
          flex: 0,
          padding: 0,
        },
      }}
    >
      {
        items.map((item, index)=>{
          return(
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot sx={{background:index===0?'#21a366':'#3D509A', scale:index===0?'1.5':'1.3'}}/>
                <TimelineConnector sx={{background:'#3D509A'}}/>
              </TimelineSeparator>
              <TimelineContent>
                <div style={{display:'flex', flexDirection:'column', gap:'15px'}}>
                  <h4 style={{color:'#292E68'}}>{item?.title}</h4>
                  {item?.content?.map(content => {
                    return content
                  })}
                </div>
              </TimelineContent>
            </TimelineItem>
          )
        })
      }
    </Timeline>
  );
}
