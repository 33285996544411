import React from "react";
export const onBoardingSteps = [
    {
      target: ".pageTitle",
      disableScrolling: true,
      content: (
        <div>
          <h3>Bienvenido a Val logistics</h3>
          <p>
            Esta es la pagina de inicio, donde puedes visualizar diferentes
            datos y graficas de tu interes.
          </p>
          <p>Presiona siguiente para ver las distintas funcionalidades </p>
        </div>
      ),
    },
    {
      target: ".datePicker",
      content:
        "Aqui puedes elegir el rango de fechas en el que se basaran todas las graficas",
      disableScrolling: true,
    },
    {
      target: ".download",
      content: (
        <div>
          <p>
            Y haciendo click aqui puedes descargar las graficas como pdf en el
            rango seleccionado.
          </p>
        </div>
      ),
      disableScrolling: true,
    },
    {
      target: ".quotationSubMenu",
      content: (
        <div>
          <p>
            <h3>Cotizaciones</h3>
            <p>
              Puedes seguir aprendiendo de la pagina dirigiendote al menu de
              cotizaciones haciendo click aqui cuando estes listo
            </p>
          </p>
        </div>
      ),
      disableScrolling: true,
    },
  ];