import React from 'react'
import { Calendar } from 'react-date-range'
import { Modal, styled } from '@nextui-org/react'

const StyledModal = styled(Modal, {
  position: 'relative',
  right: '250px',
  width: 'fit-content',
  height: '450px',
})

export default function DatePickerModal({ date, setDate, open, setOpen }) {
  return (
    <StyledModal
      closeButton
      aria-label="date-picker"
      open={open}
      onClose={() => { setOpen(false) }}
    >
      <Calendar
        onChange={e => { setDate(e); setOpen(false) }}
        date={date}
      />
    </StyledModal>
  )
}
