import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setTenantLogo } from '../../store/tenant';
import ImageColorExtractor from '../getImageColor/ImageColorExtractor';
import { editableTheme } from '../../themeClass';

export default function Logo() {
    const dispatch = useDispatch();
    const [base64Image, setBase64Image] = useState('');
    const [dominantColor, setDominantColor] = useState('#292E68'); // Estado en hexadecimal

    useEffect(() => {
        // Recuperar el color dominante desde localStorage
        const savedColor = localStorage.getItem('dominantColor');
        if (savedColor) {
            setDominantColor(savedColor);
        }
    }, []);

    useEffect(() => {
        if (base64Image) {
            dispatch(setTenantLogo(base64Image));
        }
    }, [base64Image, dispatch]);

    useEffect(() => {
        const theme = editableTheme.get();
        theme.colors.primary_500.value = dominantColor;
        theme.colors.primary_300.value = dominantColor;
        editableTheme.set(theme);

        // Guardar el color dominante en localStorage
        localStorage.setItem('dominantColor', dominantColor);
    }, [dominantColor]);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setBase64Image(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleColorChange = (event) => {
        setDominantColor(event.target.value);
    };

    return (
        <div style={{ padding: '20px', maxWidth: '600px', margin: '0 auto' }}>
            <h1>Configura tu Logo</h1>
            <p>Selecciona un color para el tema o sube una imagen para extraer su color dominante.</p>
            <div style={{ marginBottom: '20px' }}>
                <label>
                    <span style={{ marginRight: '10px' }}>Selecciona un color:</span>
                    <input
                        type="color"
                        value={dominantColor}
                        onChange={handleColorChange}
                        style={{ marginRight: '20px' }}
                    />
                </label>
                <label>
                    <span>O sube una imagen:</span>
                    <input
                        type="file"
                        accept=".jpg, .jpeg, .png, .gif"
                        onChange={handleImageUpload}
                        style={{ marginLeft: '10px' }}
                    />
                </label>
            </div>
            {base64Image && (
                <div>
                    <p>Imagen subida:</p>
                    <img src={base64Image} alt="Uploaded logo" style={{ maxWidth: '100%', height: 'auto' }} />
                    <ImageColorExtractor src={base64Image} dominantColor={dominantColor} setDominantColor={setDominantColor} />
                    <div style={{ width: '100px', height: '100px', background: dominantColor, marginTop: '10px' }}></div>
                </div>
            )}
        </div>
    );
}
