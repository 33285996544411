import React from "react";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";
import { styled } from "@nextui-org/react";
import Icon from "../../Icon/Icon";
import theme from "../../../theme";
import { useTranslation } from "react-i18next";

const ChartContainer = styled("div", {
  background: "#fff",
  padding: "20px",
  borderRadius: "10px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  margin: "30px 0",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: "relative",
});

const TitleIconContainer = styled("div", {
  display: "flex",
  gap: "10px",
  alignItems: "center",
  marginBottom: "20px",
  width: "100%",
  justifyContent: "space-between",
});

const Title = styled("p", {
  color: theme.colors.primary_300,
  fontWeight: "800",
  fontSize: "1.2rem",
});

const DataContainer = styled("div", {
  width: "100%",
  maxWidth: "400px",
  display: "flex",
  justifyContent: "space-around",
  fontSize: "12px",
  gap: "6px",
  alignItems: "end",
  div: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    b: { fontSize: "14px", margin: "auto" },
  },
});

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          background: "#fff",
          padding: "10px",
          border: "1px solid #ccc",
          borderRadius: "5px",
        }}
      >
        <p>{payload[0].name}</p>
        <p
          style={{ color: payload[0].fill }}
        >{`${payload[0].value.toLocaleString()} USD`}</p>
      </div>
    );
  }
  return null;
};

const noDataStyle = {
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: "1px solid #CCC",
  height: "232px",
  padding: "10px",
};

const COLORS = ["#292E68", "#6d80cc", "black", "#303C90", "#292E68"];

export default function PieRechart({ title, icon, data }) {
  const { t: translate } = useTranslation("Home");

  return (
    <ChartContainer>
      <TitleIconContainer>
        <Title>{title}</Title>
        <Icon name={icon} color={theme.colors.secondary_200} />
      </TitleIconContainer>
      {data?.length === 0 ? (
        <div style={noDataStyle}>
          <p>{translate("no-data")}</p>
        </div>
      ) : (
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={data}
              innerRadius={60}
              outerRadius={90}
              fill="#8884d8"
              paddingAngle={5}
              dataKey="value"
            >
              {data?.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltip />} />
          </PieChart>
        </ResponsiveContainer>
      )}
      <DataContainer>
        {data?.map((e, i) => (
          <div key={i}>
            <span style={{ color: COLORS[i] }}>{e.name}:</span>
            <b style={{ color: COLORS[i] }}>{e.value}</b>
          </div>
        ))}
      </DataContainer>
    </ChartContainer>
  );
}
