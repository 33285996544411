import React, { useEffect, useState } from 'react'
import { Modal, styled } from '@nextui-org/react';
import { useSelector } from 'react-redux';
import InputSelect from '../../../components/Select/inputSelect';
import { getQuoteData } from '../../../services/quotations.services';
import { getPreliquidations } from '../../../services/preliquidation.services';
import { getPreliquidationCosts } from '../../functions';
import Button from '../../../components/Button/Button';
import Icon from '../../../components/Icon/Icon';
import { useNavigate } from 'react-router-dom';

const StyledModal = styled(Modal, {
    padding: '1.5rem',
    display:'flex',
    gap:'20px',
    color: '$primary_500 !important',
    '.operation':{
        display:'flex',
        justifyContent:'space-between',
        padding:'20px',
        backgroundColor:'$primary_300',
        color:'white',
        borderRadius:'8px',
    },
    '.form':{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        gap:'30px',
    }
})

export default function DuplicateModal({ open, setOpen, currentQuoteData }) {
    const token = useSelector(state => state.user.token)
    const navigate = useNavigate()

    const [quotesFrom, setQuotesFrom] = useState([])
    const [quotesTo, setQuotesTo] = useState([])

    const [selectedFrom, setSelectedFrom] = useState('')
    const [selectedTo, setSelectedTo] = useState('')
    const [preliquidation, setPreliquidation] = useState()
    const [preliquidationTo, setPreliquidationTo] = useState()

    useEffect(()=>{
        getQuoteData(token).then(async res => {
            const quotesData = await res.json()
            let radicationsWithoutRFQ = []
            const quotesWithRFQ = quotesData.filter(quote => {
                if (!quote.customer_approval && !radicationsWithoutRFQ.includes(quote.radication)){
                    return true
                } else {
                    radicationsWithoutRFQ.push(quote.radication)
                    return false
                }
            })
            console.log("radicationsWithoutRFQ :",radicationsWithoutRFQ)
            console.log("quotesWithRFQ :",quotesWithRFQ)
            const quotesWithPrel = quotesWithRFQ.filter(quote => quote.preliquidation_ids?.length > 0)
            setQuotesFrom(quotesWithPrel)
            setQuotesTo(quotesWithRFQ)
        })
    },[])

    useEffect(()=>{
        if (currentQuoteData.preliquidation_ids?.length){
            setSelectedTo({
                label:`${currentQuoteData.radication} | id:${currentQuoteData.id}`,
                radication:currentQuoteData.radication,
                id:currentQuoteData.id,
                prelId:currentQuoteData.preliquidation_ids[0],
            })
        }
    },[currentQuoteData])

    useEffect(()=>{
        if (selectedFrom?.prelId){
            getPreliquidations(token, selectedFrom?.prelId).then(async res => {
                const prelData = await res.json();
                const costs = getPreliquidationCosts(prelData)
                setPreliquidation({
                    ...prelData,
                    costs,
                })
            })
        }
    },[selectedFrom])
    useEffect(()=>{
        if (selectedTo?.prelId){
            getPreliquidations(token, selectedTo?.prelId).then(async res => {
                const prelData = await res.json();
                const costs = getPreliquidationCosts(prelData)
                setPreliquidationTo({
                    ...prelData,
                    costs,
                })
            })
        } else {
            setPreliquidationTo(false)
        }
    },[selectedTo])

    function handleSwitch(){
        const auxSelectedFrom = selectedFrom
        setSelectedFrom(selectedTo)
        setSelectedTo(auxSelectedFrom)
    }
    function handleDuplicate(){
        if (selectedTo && selectedFrom){
            if (selectedTo?.prelId){
                navigate(`./create_preliquidation/?radication=${selectedTo.radication}&id=${selectedTo.id}&preliquidationId=${selectedTo?.prelId}&duplicate=${selectedFrom.id}`)
            } else {
                navigate(`./create_preliquidation/?radication=${selectedTo.radication}&id=${selectedTo.id}&duplicate=${selectedFrom.id}`)
            }
        } else {
            console.log("no")
        }
    }

    return (
        <StyledModal
            closeButton
            aria-label="modal-title"
            open={open}
            onClose={() => setOpen(false)}
            width="fit-content"
        >
            <h3>Duplicar preliquidacion</h3>
            <p>Carga una copia de la preliquidación de una cotización en otra</p>
            <div className='operation'>
                <b>{selectedFrom?.radication || '-----------'} | id:{selectedFrom?.id || '--'}</b>
                <span>{'===>'}</span>
                <b>{selectedTo?.radication || '-----------'} | id:{selectedTo?.id || '--'}</b>
            </div>
            <div className='form'>
                <InputSelect 
                    width='240px'
                    onChange={(e)=>setSelectedFrom(e.value)}
                    value={selectedFrom}
                    options={quotesFrom.map(quote => ({
                        label:`${quote.radication} | id:${quote.id}`,
                        value: {
                            label:`${quote.radication} | id:${quote.id}`,
                            radication:quote.radication,
                            id:quote.id,
                            prelId:quote.preliquidation_ids[0],
                        },
                    }))}
                />
                <Button 
                    bold 
                    textColor="white"
                    text={"↔"}
                    bgColor={'primary_300'}
                    disabled={!preliquidationTo}
                    handler={()=>handleSwitch()}
                />
                <InputSelect 
                    width='240px'
                    onChange={(e)=>setSelectedTo(e.value)}
                    options={quotesTo.map(quote => ({
                        label:`${quote.radication} | id:${quote.id}`,
                        value: {
                            label:`${quote.radication} | id:${quote.id}`,
                            radication:quote.radication,
                            id:quote.id,
                            prelId:quote.preliquidation_ids.length?quote.preliquidation_ids[0]:"",
                        }
                    }))}
                />
            </div>
            <div style={{display:'flex', justifyContent:'space-between', padding:'10px', alignItems:'center'}}>
                <div style={{display:'flex', flexDirection:'column', gap:'15px', width:'fit-content'}}>
                    <p>Preliquidación :</p>
                    <p>Costo : <b>{preliquidation?.costs?.totalCost?.toFixed(2)} USD</b> </p>
                    <p>Profit : <b>{preliquidation?.costs?.totalProfit?.toFixed(2)} USD</b></p>
                </div>
                <Icon name='SimpleArrowRight' size='50px'/>
                <div style={{display:'flex', flexDirection:'column', gap:'15px', width:'fit-content'}}>
                    <p>Preliquidación :</p>
                    {
                        preliquidationTo ? <>
                        <p>Costo : <b>{preliquidationTo?.costs?.totalCost?.toFixed(2)} USD</b> </p>
                        <p>Profit : <b>{preliquidationTo?.costs?.totalProfit?.toFixed(2)} USD</b></p>
                        </> : <p>No tiene</p>
                    }
                </div>
            </div>
            <Button 
                text="Duplicar"
                bold
                bgColor='primary_300'
                textColor="white"
                width='100%'
                handler={()=>handleDuplicate()}
                disabled={!selectedFrom || !selectedTo}
            />
        </StyledModal>
    );
}