import { URL } from "../constants"

export const getShippingMethods = async (token) => {
    const response = await fetch(`${URL.get()}shipping-methods/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getShippingAnalysisByMethod = async (token, startDate, endDate) => {

    const response = await fetch(`${URL.get()}monthly-shipping-analysis-by-method-for-user/${startDate}/${endDate}/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}
export const getShipmentsByShippingMethod = async (token, startDate, endDate) => {
    const response = await fetch(`${URL.get()}shipments-by-shipping-method/${startDate}/${endDate}/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getShippingMethodRatio = async (token, startDate, endDate) => {
    const response = await fetch(`${URL.get()}transport-mode-ratio-by-month/${startDate}/${endDate}/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}
export const getUserShippingMethodRatio = async (token, startDate, endDate) => {
    const response = await fetch(`${URL.get()}transport-mode-ratio-by-month-for-user/${startDate}/${endDate}/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

