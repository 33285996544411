// components/FormInput/FormInput.jsx
import React from "react";
import { styled, Input as inp } from "@nextui-org/react";
import theme from "../../theme";
import { useFormContext, Controller, useFormState } from 'react-hook-form';
import Icon from "../Icon/Icon";
import HelpIcon from "../HelpIcon/HelpIcon";

const StyledInput = styled(inp, {
  [`.${theme} &`]: {
    // height:'40px',
    borderRadius: "4px",
    border: "solid 1px $primary_300",
    transition: "border 0.25s ease",
    [".nextui-input-wrapper"]: {
      borderRadius: "6px",
      backgroundColor: "$neutral_50",
    },
    ["label"]: {
      color: "$primary_500",
    },
  },
  ["& + .nextui-input-helper-text-container .nextui-input-helper-text"]: {
    fontSize: "11px",
  },
});

const StyledLabel = styled("div", {
  [`.${theme} &`]: {
    fontSize: '12px',
    margin:'5px 0',
    color: `$primary_500`,
    ["span"]: {
      color: "#ff676c",
    },
  },
});

const FormInput = ({ name, label,readOnly=false, disabled = false, type = 'text', required = false, icon, helpMessage }) => {
  const { control } = useFormContext();
  const errors = useFormState()?.errors;
  const thisFieldError = errors[name[0]] ? errors[name[0]][name.slice(2)] : errors[name] || false;
  return (
    <Controller
      name={name}
      control={control}
      disabled={disabled}
      rules={required ? { required: "Este campo es obligatorio" } : {}}
      render={({ field }) => (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {label ? (
              <StyledLabel>
                {label} {required ? <span>*</span> : ""}
                {helpMessage && <HelpIcon message={helpMessage} />}
              </StyledLabel>
            ) : ""}
          </div>
          <StyledInput
            aria-label={label || "input"}
            contentRight={icon ? <Icon name={icon} /> : ""}
            type={type}
            step="any"
            read
            id={name}
            readOnly={readOnly}
            name={name}
            label=""
            css={{ "&:focus-within": { border: `solid 1px $primary_100` } }}
            {...field}
          />
          {thisFieldError && <span style={{ color: 'red', fontSize: '12px' }}>{thisFieldError.message}</span>}
        </div>
      )}
    />
  );
};

export default FormInput;
