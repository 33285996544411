import { Modal, styled } from '@nextui-org/react'
import React from 'react'
import src from "../../../assets/img/containerDrawing.jpg"
import { useFormContext } from 'react-hook-form'
import boxImg from '../img/box.svg'
import bagImg from '../img/bigbags.svg'
import sackImg from '../img/sacks.svg'
import barrelImg from '../img/barrels.svg'
import rollImg from '../img/roll.svg'

const StyledModal = styled(Modal, {
    padding: '1.5rem',
    display:'flex',
    gap:'20px',
    color: '$primary_500 !important',
})

const Container= styled("div",{
    display:'grid',
    gridTemplateColumns:'auto auto auto auto auto',
    gap:'20px',
})
const Card = styled("div",{
    fontSize:'13px',
    boxShadow:'$elevation_1',
    display:'flex',
    padding:'10px',
    justifyContent:'center',
    alignItems:'center',
    flexDirection:'column',
    paddingTop:'10px',
    borderRadius:'8px',
    border:'1px solid grey',
    cursor:'pointer',
    transition:'300ms',
    'img':{
        height:'150px',
        width:'170px'
    },
    '.info':{
        padding:'20px',
        display:'flex',
        flexDirection:'column',
    },
    '&:hover':{
        border:'2px solid $primary_300',
        transform:'scale(1.02)',
    }
})

const loadTypeOptions = [
    {
        name:"Box",
        img:boxImg,
        qty:1,
        length:1,
        width:1,
        height:1,
        weight_per_piece:100,
    },
    {
        name:"Big bags",
        img:bagImg,
        qty:1,
        length:1,
        width:1,
        height:1,
        weight_per_piece:100,
    },
    {
        name:"Sacks",
        img:sackImg,
        qty:1,
        length:1,
        width:1,
        height:1,
        weight_per_piece:100,
    },
    {
        name:"Barrels",
        img:barrelImg,
        cilinder:true,
        qty:1,
        radius:1,
        height:1,
        weight_per_piece:100,
    },
    {
        name:"Roll",
        img:rollImg,
        cilinder:true,
        qty:1,
        radius:1,
        height:1,
        weight_per_piece:100,
    },
]

export default function LoadTypesModal({ open, setOpen }) {
    const { getValues, setValue } = useFormContext();

    function handleClick(load){
        const formLoads = getValues('loads')
        const key = Object.keys(formLoads)?.length
        setValue(`loads.${key}`,load)
        
        setOpen(false)
    }

    return (
    <StyledModal
        closeButton
        aria-label="modal-title"
        open={open}
        onClose={() => setOpen(false)}
        width="fit-content"
    >
        <Container>
            {
                loadTypeOptions.map(load => (
                    <Card onClick={()=>handleClick(load)}>
                        <img src={load.img} alt=""/>
                        <div className='info'>
                            <b>{load?.name}</b>
                        </div>
                    </Card>
                ))
            }
        </Container>
    </StyledModal>
  )
}
