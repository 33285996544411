import React, { useEffect, useState } from 'react'
import { styled } from '@nextui-org/react'
import Icon from '../../../components/Icon/Icon'
import ViewsHandler from '../../../components/ViewsHandler/ViewsHandler'
import AddOceanShipment from './AddOceanShipment'
import AddAirShipment from './AddAirShipment'
import { getServiceSplitRadication } from '../../../services/quotation.services'
import { useSelector } from 'react-redux'
import { getShippingMethods } from '../../../services/shippingMethods.services'
import { getShipmentTracking } from '../../../services/cargoes.services'
import { getOperations } from '../../../services/operations.services';
import toast from '../../../components/Toast/ToastTypes'
import { useNavigate } from 'react-router-dom'

const Container = styled("div",{
    '.header':{
        display:'flex',
        justifyContent:'space-between',
        borderBottom:'4px solid $primary_300',
        margin:'20px 0'
    },
    boxShadow:'$elevation_1',
    background:'$neutral_50',
    margin:'1rem',
    padding:'1rem',
    borderRadius:'8px',
})
const ButtonsContainer = styled("div",{
    display:'flex',
    width:'100%',
    'button':{
        display:'flex',
        justifyContent:'center',
        gap:'10px',
        width:'100%',
        padding:'10px',
        fontSize:'20px',
        '&:hover':{
            cursor:'pointer',
        }
    },
    '.disabled':{
        // background:'red',
        color:'grey',
    },
    '.selected':{
        background:'$primary_400',
        color:'$neutral_50',
    }
})
const LeftRoundedButton = styled("button", {
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px',
})
const RightRoundedButton = styled("button", {
    borderTopRightRadius: '10px',
    borderBottomRightRadius: '10px',
})

export default function AddShipment({selectedOperation, shippingMethod, handleChange, optionalRadication = false, hasPurchaseOrder=false}) {

    const token = useSelector(state=>state.user.token)

    const [disableForm, setDisableForm] = useState(false)
    const [selectedMethod, setSelectedMethod] = useState('air')
    const [actualMethod, setActualMethod] = useState('air')
    const [allowChangeMethod, setAllowChangeMethod] = useState(true)
    const [rfq, setRFQ] = useState({})
    const [operation, setOperation] = useState({})
    
    useEffect(()=>{
        if (shippingMethod?.name=='ground'){
            handleChange('','events')
        }
    },[shippingMethod])

    useEffect(()=>{
        if (selectedOperation){
            const asyncTasks = [
                getServiceSplitRadication(selectedOperation?.radication, token),
                getShippingMethods(token),
                getOperations({token:token, id:selectedOperation.id})
            ]
            Promise.all (asyncTasks).then(async res => {
                const rfqData = await res[0].json();
                const shippingMethods = await res[1].json();
                const operationData = await res[2].json();

                setOperation(operationData)
                const methodId = rfqData.results[0].shipping.shipping_method;
                const method = shippingMethods.find(method=>method.id === methodId)

                setRFQ(rfqData)
                setActualMethod(method?.name)
                let shipmentType = "INTERMODAL_SHIPMENT"
                if (method.name === 'air'){
                    setSelectedMethod(method.name)
                    shipmentType="AIR_SHIPMENT"
                    setAllowChangeMethod(false)
                } else if (['ocean','bbk','ro-ro','special containers'].includes(method.name)){
                    setSelectedMethod('ocean')
                    setAllowChangeMethod(false)
                } else {setAllowChangeMethod(true)}

                getShipmentTracking(token, tags, selectedOperation?.radication, shipmentType).then(async res=>{
                    const shipmentData = await res.json();
                    console.log("shipmentData :",shipmentData)
                    if (shipmentData?.length){
                        toast('warn','Esta operacion ya tiene un seguimiento asignado')
                        setDisableForm(true)
                    }
                })
            })
        }
    },[selectedOperation])

    return (
    <Container>
        <div className='header'>
            <h3>Añadir rastreo</h3>
            <Icon
              size="30px"
              name={
                actualMethod == 'air'? "Aereo":
                actualMethod == 'ocean'? "Maritimo":
                actualMethod == 'ground'? "Terrestre":
                actualMethod == 'bbk'? "Bbk":
                actualMethod == 'ro-ro'? "Roro":
                actualMethod == 'special containers'? "Containers":
                "Close"
              }
            />
        </div>
        <ButtonsContainer>
            <LeftRoundedButton
                value="air"
                className={selectedMethod === 'air'? 'selected' : !allowChangeMethod? 'disabled' : ""}
                onClick={() => { if (allowChangeMethod){setSelectedMethod('air')}}}
            >
                <span>Aéreo</span>
                <Icon name='Aereo' size='30px' color={selectedMethod === 'air'? 'white': !allowChangeMethod?'grey' : "#303C90"}/>
            </LeftRoundedButton>
            <RightRoundedButton 
                value="ocean" 
                className={selectedMethod === 'ocean'? 'selected' : !allowChangeMethod? 'disabled' : ""}
                onClick={()=>{if (allowChangeMethod){setSelectedMethod('ocean')}}}
            >
                <span>Marítimo</span>
                <Icon name='Maritimo' size='30px' color={selectedMethod === 'ocean'? 'white' : !allowChangeMethod?'grey':"#303C90"}/>
            </RightRoundedButton>
        </ButtonsContainer>
        <ViewsHandler activeView={selectedMethod === 'air'? 0 : 1}>
            {[
                AddAirShipment,
                AddOceanShipment,
            ].map((FormComponent, i) => (
                <FormComponent
                    key={i}
                    selectedOperation={selectedOperation}
                    disableForm={disableForm}
                    operation={operation}
                    optionalRadication={optionalRadication}
                    hasPurchaseOrder={hasPurchaseOrder}
                />
            ))}
        </ViewsHandler>
    </Container>
  )
}
