import { URL } from "../constants"

const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export const getCountries = async (token) => {
    const response = await fetch(`${URL.get()}countries/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getCountriesNew = async (token) => {
    const response = await fetch(`${URL.get()}country-new-backend/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const GetShipmentsByCountryOrigin = async (token, startDate, endDate) => {
    const response = await fetch(`${URL.get()}shipments-by-country-origin/${startDate}/${endDate}/`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}
export const GetShipmentsByCountryDestination = async (token, startDate, endDate) => {
    const response = await fetch(`${URL.get()}shipments-by-country-destination/${startDate}/${endDate}/`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getAirportsByCountry = async (token, countryName) => {
    const response = await fetch(`${URL.get()}airports/?country=${countryName}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}
export const getPortsByCountry = async (token, country) => {
    const response = await fetch(`${URL.get()}ports/?country=${country}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}
export const getPortsByCode = async (token, countryCode) => {
    const response = await fetch(`${URL.get()}ports/?iso=${countryCode}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getPorts = async (token) => {
    const response = await fetch(`${URL.get()}allports/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}
export const getAirports = async (token) => {
    const response = await fetch(`${URL.get()}allairports/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}