import React, { useEffect } from "react";
import { styled } from "@nextui-org/react";
import theme from "../../../../theme";
import { useForm } from 'react-hook-form';
import { toBase64 } from '../../../../services/base64.services'
import { useTranslation } from "react-i18next";
//Componentes
import PageTitle from "../../../../components/PageTitle/PageTitle";
import InputFileCard from "../../../../components/InputFileCard/InputFileCard";

const StyledDocuments = styled("div", {
    [`.${theme} &`]: {
        margin: '3rem 8rem 0rem 8rem',
        padding: '3rem 8rem',
        backgroundColor: '$neutral_50',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        ['aside']: {
            color: "$primary_200"
        },
        ['main']: {
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem'
        }
    }
});

function Documents({ setDocuments }) {
    const nameForm = 'documents'
    const { register, getValues, setValue } = useForm({
        defaultValues: {
            [nameForm]: {
                scopeWork: '',
                plansLiftingPoints: '',
                photographicRecord: '',
            }
        }
    });

    const saveData = async (e, name) => {
        if (e?.target?.files.length > 0) {
            const file = await toBase64(e.target.files[0])
            setValue(name, file)
        } else {
            setValue(name, '')
        }
        setDocuments(getValues()[nameForm])
    }

    useEffect(() => {
        saveData()
    }, []);

    return (
        <StyledDocuments>
            <PageTitle>
                Documentos de tu carga
            </PageTitle>

            <aside>
                Adjunta los documentos de tu carga, este paso es opcional.
            </aside>

            <main>
                <section>
                    <h5>1 - Alcance de tu trabajo</h5>
                    <InputFileCard onChange={saveData} value={getValues()[nameForm].scopeWork} name={`${nameForm}.scopeWork`} label='Sube o arrastra' startIcon={{ name: 'Upload', color: '#FFF' }}></InputFileCard>
                </section>

                <section>
                    <h5>2 - Planos o puntos de izajes</h5>
                    <InputFileCard onChange={saveData} value={getValues()[nameForm].plansLiftingPoints} name={`${nameForm}.plansLiftingPoints`} label='Sube o arrastra' startIcon={{ name: 'Upload', color: '#FFF' }}></InputFileCard>
                </section>

                <section>
                    <h5>3 - Registro fotográfico</h5>
                    <InputFileCard onChange={saveData} value={getValues()[nameForm].photographicRecord} name={`${nameForm}.photographicRecord`} label='Sube o arrastra' startIcon={{ name: 'Upload', color: '#FFF' }}></InputFileCard>
                </section>
            </main>
        </StyledDocuments>
    );
}
export default Documents;