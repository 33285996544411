import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Input as Inp, styled } from "@nextui-org/react";
import theme from "../../../../theme";
import { useFormContext, Controller } from "react-hook-form";
import PlacesAutocomplete from "react-places-autocomplete";
//Components
import PageTitle from "../../../../components/PageTitle/PageTitle";
import Input from "../../../../components/Input/Input";
import InputSelect from "../../../../components/Select/inputSelect";
import Icon from "../../../../components/Icon/Icon";

import { getAirportsByCountry, getPortsByCode, } from "../../../../services/countries.services";

import { useTranslation } from "react-i18next";
import useMediaQueries from "../../../../hooks/useMediaQueries";

const StyledSend = styled("div", {
  [`.${theme} &`]: {
    margin: "3rem 5rem 0rem 5rem",
    boxShadow:'$elevation_1',
    padding: "3rem",
    backgroundColor: "$neutral_50",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    ".header": {
      display: "flex",
      justifyContent: "space-between",
      color: "$primary_300",
      fontSize: "18px",
      div: { display: "flex", gap: "10px", alignItems: "center" },
    },
    ["aside"]: {
      color: "$primary_200",
    },
    ["h5"]: {
      marginBottom: "0rem",
    },
    [".separate"]: {
      margin: "1rem 0",
    },
    [".form"]: {
      display: "grid",
      gridTemplateColumns: "auto auto",
      gap: "20px",
    },
    [".o_input"]: {
      borderRadius: "8px",
      backgroundColor: "white",
      border: "solid 1px $primary_300",
      transition: "border 0.25s ease",
      color: "blue",
      ["&:focus-within"]: {
        border: "solid 1px $primary_100",
      },
      [".nextui-input-wrapper"]: {
        borderRadius: "8px",
        backgroundColor: "$neutral_50",
      },
      [".nextui-input-label--right"]: {
        borderRadius: "0px 8px 8px 0px",
        backgroundColor: "$neutral_100",
      },
      [".nextui-input-label--left"]: {
        borderRadius: "8px 0px 0px 8px",
      },
    },
    [".autocomplete-dropdown-container-top, .autocomplete-dropdown-container-bottom"]:
      {
        position: "absolute",
        zIndex: "1",
        width: "100%",
        [".suggestion"]: {
          border: "solid 1px $neutral_100",
          padding: "0.4rem",
          fontSize: "0.9rem",
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        },
        [".suggestion:first-child"]: {
          borderTopRightRadius: "8px",
          borderTopLeftRadius: "8px",
        },
        [".suggestion:last-child"]: {
          borderBottomRightRadius: "8px",
          borderBottomLeftRadius: "8px",
        },
      },
    [".autocomplete-dropdown-container-top"]: {
      bottom: "100%",
    },
  },
  "@media (max-width: 1200px)":{
      [`.${theme} &`]:{
        margin:"1rem 2rem",
        padding:"2rem",
        fontSize:'12px',
      },
    },
    "@media (max-width: 700px)":{
      [`.${theme} &`]:{
        marginTop:"3rem",
        padding:"20px",
        fontSize:'10px',
        [".header"]:{
          flexDirection:'column',
          gap:'20px',
        }
      },
    },
    "@media (max-width: 592px)":{
      [`.${theme} &`]:{
        margin:0,
        marginTop:'30px',
        [".form"]: {
          display:'grid',
          gridTemplateColumns: "auto",
        },
      },
     
    }
});

const StyledLabel = styled("div", {
  [`.${theme} &`]: {
    color: `$primary_500`,
    ["span"]: {
      color: "#ff676c",
    },
  },
});

const Send = ({ countries, setDisabledContinue, shippingMethods }) => {
  const token = useSelector((state) => state.user.token);
  const { t: translate } = useTranslation("Quotation");
  const {sm, md} = useMediaQueries()

  const { control, getValues, setValue, watch } = useFormContext();

  const [_countries, _setCountries] = useState([]);

  const [originBoardings, setOriginBoardings] = useState([]);
  const [destinationBoardings, setDestinationBoardings] = useState([]);

  const [_shippingMethods, _setShippingMethods] = useState([]);
  const [selectedOriginCountry, setSelectedOriginCountry] = useState({});
  const [selectedDestinationCountry, setSelectedDestinationCountry] = useState(
    getValues("send.destinationCountry")
  );
  const [selectedOriginTerminal, setSelectedOriginTerminal] = useState(
    getValues("send.originBoardingTerminal") || {}
  );
  const [selectedDestinationTerminal, setSelectedDestinationTerminal] =
    useState(getValues("send.destinationBoardingTerminal") || {});
  const [originCountryISO, setOriginCountryISO] = useState("");
  const [destinationCountryISO, setDestinationCountryISO] = useState("");
  const nameForm = "send";

  const incoterm = getValues("negotiation.businessTerm");
  const commerceType = getValues("commerce.commerceType");

  const originAddressTerms = [1, 2];
  const destinationAddressTerms = [7, 17];

  let requireOriginAddress =
    originAddressTerms.includes(incoterm) && commerceType !== "customs";
  let requireDestinationAddress =
    destinationAddressTerms.includes(incoterm) && commerceType !== "customs";

  const getBoardings = async (token, shippingMethod, country) => {
    let res = [];
    switch (shippingMethod) {
      case 1: {
        //air
        res = await getAirportsByCountry(token, country?.label);
        break;
      }
      case 2:
      case 4:
      case 5:
      case 6: {
        //ocean
        res = await getPortsByCode(token, country?.iso);
        break;
      }
      default:
    }
    return res;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (getValues("commerce.shippingMethod.value") !== 3){ // 3 = TERRESTRE
        const res = await getBoardings( token, getValues("commerce.shippingMethod.value"), selectedOriginCountry);
        const boardingsData = await res.json();
        setOriginBoardings(boardingsData);
      }
    };
    fetchData();
  }, [token, getValues, selectedOriginCountry]);

  useEffect(() => {
    if (originBoardings.length > 0) {
      const origin = originBoardings.find(
        (dest) => dest.name === getValues("send.originBoardingTerminal")
      );
      if (origin) {
        const label =
          getValues("commerce.shippingMethod.value") === 1
            ? `${origin.iata_code || ""} - ${origin.name || ""} - ${
                origin.city || ""
              } - ${origin.country || ""}`
            : `${origin.unlocs[0] || ""} - ${origin.name || ""} - ${
                origin.province || ""
              } - ${origin.country || ""}`;

        setSelectedOriginTerminal({
          label,
          value: origin.name,
        });
      }
    }
  }, [originBoardings, getValues, setSelectedOriginTerminal]);

  useEffect(() => {
    const fetchData = async () => {
      if (getValues("commerce.shippingMethod.value") !== 3){ // 3 = TERRESTRE
        const res = await getBoardings(
          token,
          getValues("commerce.shippingMethod.value"),
          selectedDestinationCountry
        );
        const aux = await res.json();
        setDestinationBoardings(aux);
      }
    };

    fetchData();
  }, [token, getValues, selectedDestinationCountry]);

  useEffect(() => {
    if (destinationBoardings.length > 0) {
      const destination = destinationBoardings.find(
        (dest) => dest.name === getValues("send.destinationBoardingTerminal")
      );
      if (destination) {
        const label =
          getValues("commerce.shippingMethod.value") === 1
            ? `${destination.iata_code || ""} - ${destination.name || ""} - ${
                destination.city || ""
              } - ${destination.country || ""}`
            : `${destination.unlocs[0] || ""} - ${destination.name || ""} - ${
                destination.province || ""
              } - ${destination.country || ""}`;

        setSelectedDestinationTerminal({
          label,
          value: destination.name,
        });
      }
    }
  }, [destinationBoardings, getValues, setSelectedDestinationTerminal]);

  useEffect(() => {
    const data = countries.map((country) => {
      return {
        value: country.id,
        label: country.name,
        iso: country.code || country.iso,
      };
    });
    if (getValues("send.originCountry")) {
      const formatedOriginCountry = data.find(
        (country) => country.value == getValues("send.originCountry")
      );
      const formatedDestinationCountry = data.find(
        (country) => country.value == getValues("send.destinationCountry")
      );
      setSelectedOriginCountry(formatedOriginCountry);
      setSelectedDestinationCountry(formatedDestinationCountry);

      // setSelectedDestinationTerminal(getValues('send.destinationBoardingTerminal'))
    }
    _setCountries(data);
  }, [countries]);

  useEffect(() => {
    setSelectedOriginTerminal("");
  }, [selectedOriginCountry]);

  useEffect(() => {
    setSelectedDestinationTerminal("");
  }, [selectedDestinationCountry]);

  // useEffect(() => {
  //     setValue(`${nameForm}.originBoardingTerminal`, '')
  // }, [selectedOriginCountry])

  // useEffect(() => {
  //     setValue(`${nameForm}.destinationBoardingTerminal`, '')
  // }, [selectedDestinationCountry])

  useEffect(() => {
    //VALIDACIONES
    const sendFormValues = getValues("send");
    if (
      (!sendFormValues?.deliveryAddress && requireDestinationAddress) ||
      !sendFormValues?.destinationCountry ||
      !sendFormValues?.destinationBoardingTerminal ||
      !sendFormValues?.originCountry ||
      (!sendFormValues?.pickUpAddress && requireOriginAddress) ||
      !sendFormValues?.originBoardingTerminal
    ) {
      setDisabledContinue(true);
    } else {
      setDisabledContinue(false);
    }
  }, [watch()]);

  return (
    <StyledSend>
      <main className="d-flex flex-column gap-1">
        <div className="header">
          <PageTitle>{translate("shipment-information")}</PageTitle>
          <div>
            <span>{selectedOriginCountry?.label}</span>
            {selectedOriginCountry && selectedDestinationCountry && (
              <Icon name="SimpleArrowRight" size="30px" color="#3D509A" />
            )}
            <span>{selectedDestinationCountry?.label}</span>
            <Icon
              size="30px"
              name={
                getValues("commerce.shippingMethod.value") === 1
                  ? "Aereo"
                  : getValues("commerce.shippingMethod.value") === 2
                  ? "Maritimo"
                  : getValues("commerce.shippingMethod.value") === 3
                  ? "Terrestre"
                  : getValues("commerce.shippingMethod.value") === 4
                  ? "Bbk"
                  : getValues("commerce.shippingMethod.value") === 5
                  ? "Roro"
                  : getValues("commerce.shippingMethod.value") === 6
                  ? "Containers"
                  : "Terrestre"
              }
            />
          </div>
        </div>

        {getValues("liquidation.loadType.label") === "Carga General" ? (
          <aside>{translate("shipment-information-general")}</aside>
        ) : (
          <aside>{translate("shipment-information-project")}</aside>
        )}
        <h5>1 - {translate("origin_information")}</h5>
        <div className="form">
          <Controller
            control={control}
            name={`${nameForm}.originCountry`}
            rules={{ required: true }}
            render={({ field: { onChange, value, name } }) => (
              <InputSelect
                options={_countries?.map((e) => {
                  return { label: e.label, value: e.value, iso: e.iso };
                })}
                onChange={(value) => {
                  onChange(value?.value);
                  setSelectedOriginCountry(value);
                  setOriginCountryISO(value.iso);
                }}
                value={selectedOriginCountry}
                label={
                  <StyledLabel>
                    {translate("origin_country")} <span>*</span>
                  </StyledLabel>
                }
              />
            )}
          />
          <Controller
            name={`${nameForm}.pickUpAddress`}
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                onChange={(event) => {
                  field.onChange(event);
                }}
                label={
                  requireOriginAddress ? (
                    translate("pickup_address")
                  ) : (
                    <>
                      {translate("pickup_address")} <small>{translate("optional")}</small>
                      <small>{translate("optional")}</small>
                    </>
                  )
                }
                placeholder="Introduce dirección"
                required={requireOriginAddress}
              />
            )}
          />

          <Controller
            rules={{ required: true }}
            control={control}
            name={`${nameForm}.originBoardingTerminal`}
            render={({ field: { onChange, value } }) =>
              [1, 2, 4, 5, 6].includes(
                getValues("commerce.shippingMethod.value")
              ) ? (
                <InputSelect
                  height="10px"
                  options={originBoardings.map((e) => {
                    return {
                      label:
                        getValues("commerce.shippingMethod.value") === 1
                          ? `${e.iata_code || ""} - ${e.name || ""} - ${
                              e.city || ""
                            } - ${e.country || ""}`
                          : `${e.unlocs[0] || ""} - ${e.name || ""} - ${
                              e.province || ""
                            } - ${e.country || ""}`,
                      value: e.name,
                    };
                  })}
                  value={selectedOriginTerminal}
                  onChange={(value) => {
                    onChange(value?.value);
                    setSelectedOriginTerminal(value);
                  }}
                  label={
                    <StyledLabel>
                      {translate("boarding_terminal")} <small>{translate("optional")}</small>
                      <span>*</span>
                    </StyledLabel>
                  }
                />
              ) : (
                <PlacesAutocomplete
                  value={
                    `${
                      getValues("commerce.shippingMethod") ===
                      shippingMethods[0]?.id
                        ? "Aeropuerto "
                        : getValues("commerce.shippingMethod") ===
                          shippingMethods[1]?.id
                        ? "Puerto "
                        : ""
                    }` + value
                  }
                  searchOptions={{
                    componentRestrictions: { country: originCountryISO },
                  }}
                  onChange={(value) => {
                    onChange(value);
                  }}
                  onSelect={(value) => {
                    onChange(value);
                  }}
                >
                  {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                    <div className="p-relative">
                      {getValues("negotiation.businessTerm") !==
                        getValues("negotiation.businessTerm")[3]?.id &&
                        getValues("negotiation.businessTerm") !==
                          getValues("negotiation.businessTerm")[4]?.id && (
                          <Inp
                            rules={{ required: true }}
                            label={
                              <StyledLabel>
                                {translate("boarding_terminal")}{" "}
                                <small>{translate("optional")}</small>
                                <span>*</span>
                              </StyledLabel>
                            }
                            name={`${nameForm}.originBoardingTerminal`}
                            className={`o_input`}
                            animated={false}
                            placeholder="Desde"
                            fullWidth={"100%"}
                            value={value}
                            onBlur={getInputProps().onBlur}
                            onChange={getInputProps().onChange}
                            onKeyDown={getInputProps().onKeyDown}
                          />
                        )}
                      <div className="autocomplete-dropdown-container-bottom">
                        {suggestions.map((suggestion, i) => {
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? { backgroundColor: "#fafafa", cursor: "pointer" }
                            : { backgroundColor: "#ffffff", cursor: "pointer" };
                          return (
                            <div
                              key={i}
                              {...getSuggestionItemProps(suggestion, {
                                className: "suggestion",
                                style,
                              })}
                            >
                              <Icon name={"Rastreo"} size={"14"}></Icon>
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              )
            }
          ></Controller>
          <Controller
            name={`${nameForm}.pickUpComments`}
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                onChange={(event) => {
                  field.onChange(event);
                }}
                label={
                  <>
                    {translate("pickup_comments")}
                    <small>{translate("optional")}</small>
                  </>
                }
                placeholder="Introduce"
              />
            )}
          />
        </div>

        <hr className="separate" />

        <h5>2 - {translate("destination_info")}</h5>
        <div className="form">
          <Controller
            control={control}
            name={`${nameForm}.destinationCountry`}
            render={({ field: { onChange, value, name } }) => (
              // <Select onChange={(value) => { onChange(value); saveData(name, value); }} name={name} value={value} label='País de destino' options={_countries} required></Select>
              <InputSelect
                height={md?'40px':'30px'}
                label={
                  <StyledLabel>
                    {translate("destination_country")} <span>*</span>
                  </StyledLabel>
                }
                options={_countries?.map((e) => {
                  return {
                    label: e.label,
                    value: e.value,
                    iso: e.iso,
                  };
                })}
                value={selectedDestinationCountry}
                onChange={(value) => {
                  onChange(value.value);
                  setSelectedDestinationCountry(value);
                  setDestinationCountryISO(value.iso);
                }}
              />
            )}
          ></Controller>
          <Controller
            name={`${nameForm}.deliveryAddress`}
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                onChange={(event) => {
                  field.onChange(event);
                }}
                label={
                  requireDestinationAddress ? (
                    "Direccion de Entrega"
                  ) : (
                    <>
                      {translate("destination_address")}
                      <small>{translate("optional")}</small>
                    </>
                  )
                }
                placeholder="Introduce dirección"
                required={requireDestinationAddress}
              />
            )}
          />
          <Controller
            control={control}
            name={`${nameForm}.destinationBoardingTerminal`}
            render={({ field: { onChange, value } }) =>
              [1, 2, 4, 5, 6].includes(
                getValues("commerce.shippingMethod.value")
              ) ? (
                <InputSelect
                  height={md?'40px':'30px'}
                  value={selectedDestinationTerminal}
                  options={destinationBoardings.map((e) => {
                    return {
                      label:
                        getValues("commerce.shippingMethod.value") === 1
                          ? `${e.iata_code} - ${e.name} - ${e.city} - ${e.country}`
                          : `${e.unlocs[0]} - ${e.name} - ${e.province} - ${e.country}`,
                      value: e.name,
                    };
                  })}
                  onChange={(value) => {
                    onChange(value.value);
                    setSelectedDestinationTerminal(value);
                  }}
                  label={
                    <StyledLabel>
                      {translate("disembarkation")} <span>*</span>
                    </StyledLabel>
                  }
                />
              ) : (
                <PlacesAutocomplete
                  value={
                    `${
                      getValues("commerce.shippingMethod") ===
                      shippingMethods[0]?.id
                        ? "Aeropuerto "
                        : getValues("commerce.shippingMethod") ===
                          shippingMethods[1]?.id
                        ? "Puerto "
                        : ""
                    }` + value
                  }
                  onChange={(value) => {
                    onChange(value);
                  }}
                  onSelect={(value) => {
                    onChange(value);
                  }}
                  searchOptions={{
                    componentRestrictions: { country: destinationCountryISO },
                  }}
                >
                  {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                    <div className="p-relative">
                      <Inp
                        label={
                          <StyledLabel>
                            {translate("disembarkation")} <span>*</span>
                          </StyledLabel>
                        }
                        name={`${nameForm}.destinationBoardingTerminal`}
                        className={`o_input`}
                        animated={false}
                        placeholder="Hasta"
                        fullWidth={"100%"}
                        value={value}
                        onBlur={getInputProps().onBlur}
                        onChange={getInputProps().onChange}
                        onKeyDown={getInputProps().onKeyDown}
                      />
                      <div className="autocomplete-dropdown-container-top">
                        {suggestions.map((suggestion, i) => {
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? { backgroundColor: "#fafafa", cursor: "pointer" }
                            : { backgroundColor: "#ffffff", cursor: "pointer" };
                          return (
                            <div
                              key={i}
                              {...getSuggestionItemProps(suggestion, {
                                className: "suggestion",
                                style,
                              })}
                            >
                              <Icon name={"Rastreo"} size={"14"}></Icon>
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              )
            }
          ></Controller>
          <Controller
            name={`${nameForm}.deliveryComments`}
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                onChange={(event) => {
                  field.onChange(event);
                }}
                label={
                  <>
                    {translate("delivery_comments")} <small>{translate("optional")}</small>
                  </>
                }
                placeholder="Introduce"
              />
            )}
          />
          {/* <DirectionsMap mapId='asdasdasd'/> */}
        </div>
      </main>
    </StyledSend>
  );
};

export default Send;
