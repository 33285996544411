import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from "firebase/messaging";
import { addDevice } from "./services/notifications.services";

const firebaseConfig = {
    apiKey: import.meta.env.VITE_FIREBASE_KEY,
    authDomain: "val2023-34833.firebaseapp.com",
    projectId: "val2023-34833",
    storageBucket: "val2023-34833.appspot.com",
    messagingSenderId: "239406305046",
    appId: "1:239406305046:web:7b658ac565aba1d9490a37",
    measurementId: "G-0KJP7RQKLG"
};

const app = initializeApp(firebaseConfig);

const messaging = getMessaging(app);

export const registerDevice = async (token) => {
    return getToken(messaging)
        .then((currentToken) => {
            if (currentToken) {
                // console.log("FCM token:", currentToken);
                return addDevice(token, currentToken)
                    .then(async res => {
                        const data = await res.json()
                            ('res :', data);
                        return true;
                    })
                    .catch(err => {
                        console.log('err :', err);
                        return false;
                    });
            } else {
                return false;
            }
        })
        .catch((err) => {
            return false;
        });
}