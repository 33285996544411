import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import CostsInputTable from "./CostsInputTable";
import { styled } from "@nextui-org/react";
import { Collapse } from "@nextui-org/react";
import NetInputTable from "./NetTable";
import AirFreight from "./AirFreight";
import Icon from "../../../../../components/Icon/Icon";
import ProfitShareTable from "./ProfitShare";
import Button from "../../../../../components/Button/Button";
import { getProviders } from "../../../../../services/preliquidation.services";
import { useSelector } from "react-redux";

const Container = styled("div", {
  margin: "20px",
  h3: {
    color: "#3D509A",
  },
  ".subtitle": {
    width: "100%",
    borderBottom: "3px solid $primary_300",
    margin: "30px 0",
  },
  ".totals-container":{
    display:'flex',
    justifyContent:'space-between',
    marginTop:'30px',
    backgroundColor:"$primary_200",
    color:'white',
    borderRadius:'8px',
    padding:'20px',
  },
  "@media(max-width:700px)":{
    ".totals-container":{
      flexDirection:'column',
      'h2':{fontSize:'14px',}
    },
    ".subtitle":{
      'h3':{fontSize:'10px',}
    }
  }
});

const StyledCollapse = styled(Collapse, {
  marginTop: "20px",
  [".nextui-collapse-title"]: {
    color: "$primary_300",
  },
});

function calculateNetTotal(netArray){
  let total = 0
  netArray?.forEach(item=>{
    total += Number(item?.Total || 0)
  })
  if (isNaN(total)){return 0}
  return total;
}
function calculatePSTotal(PSArray){
  let total = 0
  PSArray?.forEach(item=>{
    total += Number(item?.Rate * item.Currency?.value)
  })
  if (isNaN(total)){return 0}
  return total;
}



export default function Costs({ rfqData }) {
  const user = useSelector(state=>state.user)
  const token = user.token
  const { getValues, ...methods } = useFormContext();
  const [total, setTotal] = useState(0)
  const [netTotal, setNetTotal] = useState(0)
  const [profit, setProfit] = useState(0)
  const [providers, setProviders] = useState([])

  function setTotals(value){
    let originTotal = value.origin_listTotal || 0
    let destinationTotal = value.destination_listTotal || 0
    let insuranceTotal = value.insurance_listTotal || 0
    let oceanFreightTotal = value.ocean_freightTotal || 0
    let groundFreightTotal = value.ground_freightTotal || 0
    let airFreightTotal =value.air_freight?.total || 0

    let netOriginTotal = calculateNetTotal(value.net_origin)
    let netDestinationTotal = calculateNetTotal(value.net_destination)
    let netInsuranceTotal = calculateNetTotal(value.net_insurance)
    let netAirFreightTotal = calculateNetTotal(value.net_air_freight)
    let netOceanFreightTotal = calculateNetTotal(value.net_ocean_freight)
    let netGroundFreightTotal = calculateNetTotal(value.net_ground_freight)
    let PSTotal = calculatePSTotal(value.PS)

    const tot = Number(originTotal) + Number(destinationTotal) + Number(insuranceTotal) + Number(airFreightTotal) + Number(oceanFreightTotal) + Number(groundFreightTotal)
    const netTot = netOriginTotal + netDestinationTotal + netInsuranceTotal + netAirFreightTotal + netOceanFreightTotal + netGroundFreightTotal + PSTotal
    const profit = Number(tot) - Number(netTot)

    setTotal(Number(tot)?.toFixed(2) || 0.00)
    setNetTotal(netTot.toFixed(2) || 0.00)
    setProfit(profit?.toFixed(2))
  }
  function getProvidersData(){
    getProviders(token).then(async res=>{
        const providersData = await res.json();
        setProviders(providersData)
    })
  }

  React.useEffect(() => {
    const subscription = methods.watch((value, { name, type }) => {
      console.log("value :",value)
      setTotals(value)
    });
    return () => subscription.unsubscribe();
  }, [methods.watch]);

  useEffect(()=>{
    getProvidersData()
    const values = getValues();
    setTotals(values)
  },[])

  

  return (
    <Container>
      <div
        className="subtitle"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <h3>Origen</h3>
        <h3>{rfqData?.radication}</h3>
        <div style={{ display: "flex", gap: "10px" }}>
          <h3>{`${rfqData?.shipping?.origin_boarding_terminal.city.country.name} -> ${rfqData?.shipping?.destination_boarding_terminal.city.country.name}`}</h3>
          <Icon
            name={
              getValues("shipping_method") === "air"
                ? "Aereo"
                : getValues("shipping_method") === "ocean"
                ? "Maritimo"
                : getValues("shipping_method") === "ground"
                ? "Terrestre"
                : getValues("shipping_method") === "bbk"
                ? "Bbk"
                : getValues("shipping_method") === "ro-ro"
                ? "Roro"
                : "Containers"
            }
            size="31"
            color="#3D509A"
          />
        </div>
      </div>
      <CostsInputTable tableName="ORIGIN" id="origin_list" />
      {user.roles[0] !== 8 && 
        <StyledCollapse title="Ver netas en origen" color="blue">
          <NetInputTable id="net_origin" relatedTableId={"origin_list"}  providers={providers} getProvidersData={getProvidersData}/>
        </StyledCollapse>
      }
      <div className="subtitle">
        {getValues("shipping_method") === "air" && <h3>Carga aérea</h3>}
        {getValues("shipping_method") === "ocean" && <h3>Carga marítima</h3>}
        {getValues("shipping_method") === "ground" && <h3>Carga terrestre</h3>}
        {/* <h3>
          {getValues("shipping_method") === "air"
            ? "Carga aérea"
            : "Carga marítima"}
        </h3> */}
      </div>
      {getValues("shipping_method") === "air" && <AirFreight />}
      {getValues("shipping_method") === "ground" && <div>
        <CostsInputTable tableName="GROUND" id="ground_freight"/>
        {user.roles[0] !== 8 && 
        <StyledCollapse title="Ver netas de carga">
            <NetInputTable
              id="net_ground_freight"
              relatedTableId={"ground_freight"}
              providers={providers} getProvidersData={getProvidersData}
            />
        </StyledCollapse>}
      </div>}
      {getValues("shipping_method") === "ocean" && <div>
          <CostsInputTable
            tableName="OCEAN"
            id="ocean_freight"
            conceptSelectOptions={[
              { label: "LCL", value: "LCL" },
              { label: "OW", value: "OW" },
              { label: "Cont 20", value: "Cont 20" },
              { label: "Cont 40", value: "Cont 40" },
              { label: "Cont 40'HQ", value: "Cont 40'HQ" },
              { label: "Cont 20'NOR", value: "Cont 20'NOR" },
              { label: "Cont 40'NOR", value: "Cont 40'NOR" },
              { label: "Cont 20'REEFER", value: "Cont 20'REEFER" },
              { label: "Cont 40'REEFER", value: "Cont 40'REEFER" },
              {
                label: "Cont 20' OT (In Gauge)",
                value: "Cont 20' OT (In Gauge)",
              },
              {
                label: "Cont 40' OT (In Gauge)",
                value: "Cont 40' OT (In Gauge)",
              },
              { label: "Cont 20' OT (OOG)", value: "Cont 20' OT (OOG)" },
              { label: "Cont 40' OT (OOG)", value: "Cont 40' OT (OOG)" },
              {
                label: "Cont 20' FR (In Gauge)",
                value: "Cont 20' FR (In Gauge)",
              },
              {
                label: "Cont 40' FR (In Gauge)",
                value: "Cont 40' FR (In Gauge)",
              },
              { label: "Cont 20' FR (OOG)", value: "Cont 20' FR (OOG)" },
              { label: "Cont 40' FR (OOG)", value: "Cont 40' FR (OOG)" },
              { label: "BAF", value: "BAF" },
              { label: "RECARGO IMO", value: "RECARGO IMO" },
            ]}
          />
          {user.roles[0] !== 8 &&
            <StyledCollapse title="Ver netas de carga">
              <NetInputTable
                id="net_ocean_freight"
                relatedTableId={"ocean_freight"}
                providers={providers} getProvidersData={getProvidersData}
              />
            </StyledCollapse>
          }
        </div>}
      <div className="subtitle">
        <h3>Seguro</h3>
      </div>
      <CostsInputTable tableName="INSURANCE" id="insurance_list" />
      {user.roles[0] !== 8 && 
        <StyledCollapse title="Ver netas de seguros">
          <NetInputTable id="net_insurance" relatedTableId={"insurance_list"} providers={providers} getProvidersData={getProvidersData}/>
        </StyledCollapse>
      }
      <div className="subtitle">
        <h3>Destino</h3>
      </div>
      <CostsInputTable tableName="DESTINATION" id="destination_list" />
      {user.roles[0] !== 8 &&
        <StyledCollapse title="Ver netas en destino">
          <NetInputTable
            id="net_destination"
            relatedTableId={"destination_list"}
            providers={providers} getProvidersData={getProvidersData}
          />
        </StyledCollapse>
      }
      <div className="subtitle">
        <h3>Profit Share</h3>
      </div>
      <ProfitShareTable tableName={'Profit Share'} id={'PS'} providers={providers}/>
      <div className="totals-container">
        <h2>Total : $ {total}  USD</h2>
        {user.roles[0] !== 8 && <h2>Net : $ {netTotal}  USD</h2>}
        {user.roles[0] !== 8 && <h2 style={{color:(total-netTotal)<1?"#FF6B6B":"white"}}>Profit : $ {profit}  USD</h2>}
      </div>
    </Container>
  );
}