import React, { useState } from "react";
import {
  InputAdornment,
  FormControl,
  Select,
  OutlinedInput,
  ListItemIcon,
  MenuItem,
} from "@mui/material";
import Icon from "../../../../components/Icon/Icon";
import { BiTransfer } from "react-icons/bi";
import { FaCheck } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const methods = [
  { value: "Todos", text: "Todos", icon: "" },
  { value: "air", text: "Aéreo", icon: "Aereo" },
  { value: "ocean", text: "Marítimo", icon: "Maritimo" },
  { value: "land", text: "Terrestre", icon: "Terrestre" },
  { value: "bbk", text: "Carga suelta BBK", icon: "Bbk" },
  { value: "roro", text: "Roll-on/roll-off", icon: "Roro" },
  { value: "containers", text: "Contenedores especiales", icon: "Containers" },
];

const MethodFilter = ({ selectedMethods, setSelectedMethods }) => {
  const { t: translate } = useTranslation("Services");

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    let selected = typeof value === "string" ? value.split(",") : value;

    if (selected.includes("Todos")) {
      if (selectedMethods.length === methods.length) {
        setSelectedMethods([]); // Deselecciona todos
      } else {
        setSelectedMethods(methods); // Selecciona todos
      }
    } else {
      selected = selected.map((val) => {
        const method = methods.find((m) => m.value === val);
        return { value: val, text: method?.text || "", icon: method?.icon || "" };
      });
      setSelectedMethods(selected);
    }
  };

  return (
    <FormControl sx={{ width: "100%" }}>
      <Select
        multiple
        displayEmpty
        value={selectedMethods.map((item) => item.value)}
        onChange={handleChange}
        input={
          <OutlinedInput
            startAdornment={
              <InputAdornment position="start">
                <BiTransfer />
              </InputAdornment>
            }
          />
        }
        renderValue={(selected) => {
          if (selected.length === 0) {
            return (
              <p className="text-gray-500">
                {translate("select")} {translate("method")}{" "}
              </p>
            );
          }
          return selected.map((code, index) => {
            const method = methods.find((method) => method.value === code);
            if (method) {
              return (
                <span key={code}>
                  {method.text}
                  {index < selected.length - 1 ? ", " : ""}
                </span>
              );
            }
            return null;
          });
        }}
        MenuProps={MenuProps}
        inputProps={{ "aria-label": "Without label" }}
        sx={{ backgroundColor: "white" }}
        size="small"
      >
        {methods.map((method) => (
          <MenuItem key={method.value} value={method.value}>
            <div className="flex justify-between w-[100%]">
              <div className="flex gap-1">
                <Icon name={method.icon}></Icon>

                {method.text}
              </div>

              <ListItemIcon>
                {selectedMethods
                  .map((item) => item.value)
                  .includes(method.value) ? (
                  <FaCheck />
                ) : null}{" "}
              </ListItemIcon>
            </div>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MethodFilter;
