import { URL } from "../constants"

export const getDocuments = async (data, token) => {
    const response = await fetch(`${URL.get()}services/documents/${data}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const deleteDocument = async (token, radication, documentId) => {
    const response = await fetch(`${URL.get()}services/documents/${radication}/${documentId}/`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const downloadDocument = async (data, token) => {
    const response = await fetch(`${URL.get()}get-document/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify({
            route: data
        })
    })

    return response
}
export const generateVal170 = async (token) => {
    const response = await fetch(`${URL.get()}document/val-170`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
    })
    return response
}

export const handleDownloadDocument = async (base64String, fileName, fileType) => {
    try {
        const binaryString = window.atob(base64String);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        const blob = new Blob([bytes], { type: fileType });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    } catch (error) {
        // console.log(error);
    }
};