import React from 'react'
import styles from './MapMarker.module.css'
import { Tooltip } from '@nextui-org/react'

export default function MapMarker({label, important}) {
  return (
      <div className={styles.ring_container}>
          
          <div className={important? styles.ringring2 : styles.ringring}/>
          <div className={important? styles.circle2 : styles.circle}/>
          {label && <span className={styles.label}>{label}</span>}
      </div>
  )
}
