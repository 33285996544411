import React, { useEffect } from "react";
import "./App.css";
import { connect, useDispatch, useSelector } from "react-redux";
import globalStyles from "./globalStyles";
import { Routes, Route, Navigate, useSearchParams } from "react-router-dom";
import { Toaster } from "sonner";
import { ENABLE_LOGS, ROLES, URL } from "./constants";
import useUserRoles from "./hooks/useUserRoles";
import CookieConsent from "react-cookie-consent";
import './index.css'

// ... (import statements)
import Missing from "./pages/Missing/Missing";
import Login from "./pages/Login/Login";
import LoginUrl from "./pages/LoginUrl/LoginUrl";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import MainSidebar from "./pages/Layout/MainSidebar/MainSidebar";
import Home from "./pages/Home/Home";
import Navbar from "./pages/Layout/Navbar/Navbar";
import Quotation from "./pages/Quotes/Quotation/Quotation";
import QuoteDetail from "./pages/Quotes/QuotationDetail/QuoteDetail";
import NewQuotation from "./pages/Quotes/NewQuotation/NewQuotation";
import ManageQuotes from "./pages/ManageQuotes/Index";
import ViewCO2 from "./pages/CO2/ViewCO2";
import ValGpt from "./pages/ValGpt/ValGpt";
import Register from "./pages/Register/Register";
import Tools from "./pages/Tools/Tools";
import Services from "./pages/Services/Services";
import History from "./pages/History/History";
import Support from "./pages/Support/Support";
import Profile from "./pages/Profile/Profile";
import ProfileForms from "./pages/Profile/ProfileForms/ProfileForms";
import Admin from "./pages/Admin/Admin";
import Calculator from "./pages/Calculator/Calculator";
import ServiceChat from "./pages/Services/ServiceDetail/ServiceChat/ServiceChat";
import ServiceLiquidation from "./pages/Services/ServiceDetail/ServiceLiquidation/ServiceLiquidation";
import RoleBasedRoute from "./HOCs/RoleBasedRoute";
import Tariff from "./pages/Dian/Tariff";
import AuthVerify from "./HOCs/AuthVerify";
import RenderDocument from "./pages/Profile/Documents/RenderDocument";
import TrackingResults from "./pages/Tracking/TrackingResults";
import OperationsTracking from "./pages/OperationsTracking/OperationsTracking";
import ExportTutorial from "./pages/Tools/ExportTutorial";
import QuotationTutorial from "./pages/Tools/QuotationTutorial";
import ExportToChina from "./pages/Tools/ExportToChina";
import CO2Dashboard from "./pages/CO2Dashboard/CO2Dashboard";
import EditQuotation from "./pages/Quotes/EditQuotation/EditQuotation";
import CO2Persons from "./pages/CO2Persons/CO2Persons";
import Payments from "./pages/CO2History/CO2History";
import Calculate from "./pages/CO2Persons/CalculateCO2/Calculate";
import PreliquidationDetails from "./pages/ManageQuotes/PreLiquidation/PreliquidationDetails";
import Co2Tutorial from "./pages/Tools/Co2Tutorial";
import ServiceTutorial from "./pages/Tools/ServiceTutorial";
import CO2Compensations from "./pages/CO2Compensations/CO2Compensations";
import { Co2 } from "@mui/icons-material";
import QuoteDetails from "./pages/ManageQuotes/quoteDetails/quoteDetails";
import CreatePreliquidation from "./pages/ManageQuotes/PreLiquidation/CreatePreliquidation/CreatePreliquidation";
import TrackingDashboard from "./pages/Trackingv2/Dashboard/TrackingDashboard";
import TrackingOcean from "./pages/Trackingv2/TrackingOcean";
import TrackingAir from "./pages/Trackingv2/TrackingAir";
import TrackingDetails from "./pages/Trackingv2/TrackingDetails/TrackingDetails";
import ServicesDashboard from "./pages/Services/Dashboard/ServicesDashboard"
import { Font } from "@react-pdf/renderer";
import OperationsDashboard from "./pages/OperationsTracking/OperationsDashboard";
import PricingDashboard from "./pages/ManageQuotes/PricingDashboard/PricingDashboard";
import Calculator3D from "./pages/Calculator3D/Calculator3D";
import { setTenantAction } from "./store/tenant";
import TwoStepLogin from "./pages/Admin/TwoStepLogin/LoginOne";
import LoginOne from "./pages/Admin/TwoStepLogin/LoginOne";
import LoginTwo from "./pages/Admin/TwoStepLogin/LoginTwo";
import RoutingSandbox from "./pages/Trackingv2/RoutingSandbox";
import { editableTheme } from "./themeClass";
import FreeTracking from "./pages/Trackingv2/FreeTracking";
import OperationInfo from "./pages/OperationInformation/OperationInfo";
import Forecast from "./pages/Forecast/Dashboard";
import ServiceInfo from "./pages/OperationInformation/ServiceInfo";
import CreditOffersDashboard from "./pages/Loans/Dashboard";
import CreditOffersModule from "./pages/Loans/LoansAuction";

function App({ loggedIn, token }) {
  // console.log('ENABLE_LOGS :',ENABLE_LOGS)
  // console.log('ENABLE_LOGS !== on :',ENABLE_LOGS !== 'on')

  const [searchParams] = useSearchParams();

  let tenantName = searchParams.get("tenant")
  if (tenantName){
    localStorage.setItem("tenantName",tenantName)
  } else {
    tenantName = localStorage.getItem("tenantName")
  }
  
  const dispatch = useDispatch();
  let colorTheme = useSelector(state=>state.colorTheme)
  // console.log("colorTheme :",colorTheme)
  const tenantState = useSelector(state=>state.tenant)
  const storageTenant = localStorage.getItem("tenantName")

  const theme = editableTheme.get()

  // theme.colors.primary_500.value='green',
  // console.log("theme :",theme)
  // editableTheme.set(theme)
  console.log("path :",window.location.pathname)
  console.log("window.location.pathname.startsWith('/operation-info') :",window.location.pathname.startsWith('/operation-info'))

  useEffect(()=>{
    console.log("app tenant name :",tenantName)
    if (tenantName){
      dispatch(setTenantAction(tenantName))
    }
  },[tenantName])

  // useEffect(()=>{
  //   if (tenantState?.url){
  //     URL.set(tenantState?.url)
  //   }
  // },[tenantState])

  if (ENABLE_LOGS !== 'on'){
    window.console.log= ()=>{}
    console.log= function(){}
  }
  
  const userRoles = useUserRoles();
  globalStyles(); // Global styles are being invoked here.
  if (!loggedIn) {
    const currentPath = window.location.pathname;
    const queryParams = window.location.search;
    if (currentPath !== "/" && !currentPath.startsWith('/login-password')) {
      localStorage.setItem("currentPath", currentPath + queryParams);
    }

    return (
      // ... (Login routes)
      <>
        <Routes>
          <Route exact element={<LoginOne />} path="/"></Route>
          <Route exact element={<LoginTwo />} path="/login-password"></Route>
          {/* <Route exact element={<Login />} path="/"></Route> */}
          <Route exact element={<LoginUrl />} path="/login"></Route>
          <Route element={<Register />} path="/register/:invitation?"></Route>
          <Route element={<ForgotPassword />} path="/forgot-password" />
          <Route element={<OperationInfo />} path="/operation-info" />
          <Route element={<ServiceInfo />} path="/service-info" />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <Toaster position="top-right" />
      </>
    );
  }
  
  return (
    <div style={{ display: "flex", backgroundColor: "#F3F5FF", maxWidth:'100%' }}>
      <AuthVerify />

      {/* <CookieConsent
        location="bottom"
        buttonText="Aceptar"
        cookieName="cookies"
        style={{ background: "#292E68", height: "100px" }}
        enableDeclineButton
        declineButtonText="Rechazar"
        declineButtonStyle={{
          color: "#FF6B6B",
          background: "white",
          fontSize: "20px",
          borderRadius: "8px",
        }}
        buttonStyle={{
          color: "#292E68",
          background: "white",
          fontSize: "20px",
          borderRadius: "8px",
        }}
        expires={150}
      >
        <span style={{ fontSize: "20px" }}>
          Este sitio web utiliza cookies para mejorar la experiencia de usuario
        </span>
      </CookieConsent> */}

      {!window.location.pathname.startsWith('/operation-info') && <MainSidebar userRoles={userRoles}></MainSidebar>}
      {userRoles.length > 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: "1",
            width: "calc(100% - 250px)",
            minHeight: "100vh",
          }}
        >
          {!window.location.pathname.startsWith('/operation-info') && <Navbar token={token} />}
          <Routes>
            <Route element={<Home />} path="/" />
            <Route element={<Home />} path="/home" />
            <Route element={<Tools />} path="/tools" />
            <Route element={<ExportTutorial />} path="/tools/export" />
            <Route element={<QuotationTutorial />} path="/tools/quote" />
            <Route element={<ExportToChina />} path="/tools/export-to-china" />
            <Route element={<Co2Tutorial />} path="/tools/co2-tutorial" />
            <Route element={<OperationInfo />} path="/operation-info" />
            <Route element={<ServiceInfo />} path="/service-info" />
            <Route
              element={<ServiceTutorial />}
              path="/tools/service-tutorial"
            />
            <Route element={<QuotationTutorial />} path="/tools/quote" />
            <Route element={<Calculator />} path="/calculator" />
            <Route element={<Calculator3D />} path="/loadCalc" />
            <Route element={<Tariff />} path="/tariff" />
            <Route element={<Profile token={token} />} path="/profile" />
            <Route
              element={<RenderDocument token={token} />}
              path="/profile/document"
            />
            <Route element={<ProfileForms />} path="/profile/forms" />

            <Route
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    ROLES.Admin,
                    ROLES.User,
                    ROLES.Advisor,
                    ROLES.Pricing,
                    ROLES.Commercial,
                    ROLES.Client,
                    ROLES.Provider,
                    ROLES.Outsource
                  ]}
                  userRoles={userRoles}
                />
              }
            >
              <Route element={<Quotation />} path="/quotation-requests" />
              <Route element={<QuoteDetail />} path="/quotation/detail" />
              <Route element={<NewQuotation />} path="/new-quotation" />
              <Route element={<EditQuotation />} path="/quotation/edit" />
            </Route>
            <Route
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    ROLES.Admin,
                    ROLES.Advisor,
                    ROLES.Pricing,
                    ROLES.Commercial,
                    ROLES.Operations,
                    ROLES.User,
                    ROLES.Provider,
                    ROLES.Client,
                    ROLES.Outsource
                  ]}
                  userRoles={userRoles}
                />
              }
            >
              <Route element={<Services />} path="/services" />
              <Route element={<ServiceChat />} path="/services/detail/chat" />
              <Route
                element={<ServiceLiquidation />}
                path="/services/detail/liquidation"
              />
            </Route>
            <Route
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    ROLES.Admin,
                    ROLES.User,
                    ROLES.Client,
                    ROLES.Advisor,
                    ROLES.Supplier,
                    ROLES.Pricing,
                    ROLES.Commercial,
                    ROLES.Operations,
                    ROLES.Co2,
                  ]}
                  userRoles={userRoles}
                />
              }
            >
              <Route element={<ViewCO2 />} path="/co2" />
            </Route>
            <Route
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    ROLES.Admin,
                    ROLES.User,
                    ROLES.Client,
                    ROLES.Advisor,
                    ROLES.Supplier,
                    ROLES.Pricing,
                    ROLES.Commercial,
                    ROLES.Operations,
                    ROLES.Co2,
                  ]}
                  userRoles={userRoles}
                />
              }
            >
              <Route element={<CO2Dashboard />} path="/co2/Dash" />
            </Route>

            <Route
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    ROLES.Admin,
                    ROLES.User,
                    ROLES.Client,
                    ROLES.Advisor,
                    ROLES.Supplier,
                    ROLES.Pricing,
                    ROLES.Commercial,
                    ROLES.Operations,
                    ROLES.Co2,
                  ]}
                  userRoles={userRoles}
                />
              }
            >
               <Route element={<ServicesDashboard />} path="/services/Dash" />
            </Route>

            <Route
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    ROLES.Admin,
                    ROLES.User,
                    ROLES.Client,
                    ROLES.Advisor,
                    ROLES.Supplier,
                    ROLES.Pricing,
                    ROLES.Commercial,
                    ROLES.Operations,
                    ROLES.Co2,
                  ]}
                  userRoles={userRoles}
                />
              }
            >
              <Route element={<CO2Compensations />} path="/co2/compensations" />
            </Route>
            <Route
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    ROLES.Admin,
                    ROLES.User,
                    ROLES.Client,
                    ROLES.Advisor,
                    ROLES.Supplier,
                    ROLES.Pricing,
                    ROLES.Commercial,
                    ROLES.Operations,
                    ROLES.Co2,
                  ]}
                  userRoles={userRoles}
                />
              }
            >
              <Route element={<Calculate />} path="/co2/persons/calculate" />
            </Route>
            <Route
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    ROLES.Admin,
                    ROLES.User,
                    ROLES.Client,
                    ROLES.Advisor,
                    ROLES.Supplier,
                    ROLES.Pricing,
                    ROLES.Commercial,
                    ROLES.Operations,
                    ROLES.Co2,
                  ]}
                  userRoles={userRoles}
                />
              }
            >
              <Route element={<Payments />} path="/co2/history" />
            </Route>
            <Route
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    ROLES.Admin,
                    ROLES.User,
                    ROLES.Client,
                    ROLES.Advisor,
                    ROLES.Supplier,
                    ROLES.Pricing,
                    ROLES.Commercial,
                    ROLES.Operations,
                    ROLES.Co2,
                  ]}
                  userRoles={userRoles}
                />
              }
            >
              <Route element={<ValGpt />} path="/ValGpt" />
            </Route>
            <Route
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    ROLES.Admin,
                    ROLES.User,
                    ROLES.Client,
                    ROLES.Advisor,
                    ROLES.Pricing,
                    ROLES.Commercial,
                    ROLES.Operations,
                  ]}
                  userRoles={userRoles}
                />
              }
            >
              <Route element={<History />} path="/history" />
            </Route>
            <Route
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    ROLES.Admin,
                    ROLES.User,
                    ROLES.Client,
                    ROLES.Advisor,
                    ROLES.Pricing,
                    ROLES.Commercial,
                    ROLES.Operations,
                  ]}
                  userRoles={userRoles}
                />
              }
            >
              <Route
                element={<TrackingDashboard />}
                path="/tracking/dashboard"
              />
              <Route
                element={<FreeTracking />}
                path="/tracking/free"
              />
              <Route
                element={<TrackingOcean />}
                path="/tracking/ocean-shipment"
              />
              <Route
                element={<TrackingAir/>}
                path="/tracking/air-shipment"
              />
              <Route
                element={<TrackingDetails/>}
                path="/tracking/service/"
              />
                            <Route
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    ROLES.Admin,               
                  ]}
                  userRoles={userRoles}
                />
              }
            >
              <Route element={<RoutingSandbox />} path="/tracking/routing/" />
            </Route>
            </Route>
            <Route
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    ROLES.Admin,
                    ROLES.User,
                    ROLES.Client,
                    ROLES.Advisor,
                    ROLES.Pricing,
                    ROLES.Commercial,
                    ROLES.Operations,
                  ]}
                  userRoles={userRoles}
                />
              }
            >
              <Route element={<Support />} path="/support" />
            </Route>
            <Route
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    ROLES.Admin,
                    ROLES.Advisor,
                    ROLES.Pricing,
                    ROLES.Operations,
                  ]}
                  userRoles={userRoles}
                />
              }
            >
              <Route element={<Admin />} path="/backstage" />
            </Route>

            <Route
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    ROLES.Admin,
                  ]}
                  userRoles={userRoles}
                />
              }
            >
              <Route element={<Forecast />} path="/forecast" />
            </Route>

            <Route
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    ROLES.Admin,
                    ROLES.Advisor,
                    ROLES.Pricing,
                    ROLES.Commercial,
                    ROLES.Provider,
                  ]}
                  userRoles={userRoles}
                />
              }
            >

            <Route element={<CreditOffersDashboard />} path="/loans/Dash" />
            </Route>
            <Route
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    ROLES.Admin,
                    ROLES.Advisor,
                    ROLES.Pricing,
                    ROLES.Commercial,
                    ROLES.Provider,
                  ]}
                  userRoles={userRoles}
                />
              }
            >


            <Route element={<CreditOffersModule />} path="/loans/Auction" />
            </Route>
            <Route
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    ROLES.Admin,
                    ROLES.Advisor,
                    ROLES.Pricing,
                    ROLES.Commercial,
                    ROLES.Provider,
                    ROLES.Outsource,
                  ]}
                  userRoles={userRoles}
                />
              }
            >
              <Route element={<ManageQuotes />} path="/manageQuotes" />
            </Route>
            <Route
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    ROLES.Admin,
                    ROLES.Advisor,
                    ROLES.Pricing,
                    ROLES.Commercial,
                    ROLES.Outsource
                  ]}
                  userRoles={userRoles}
                />
              }
            >
              <Route
                element={<CreatePreliquidation />}
                path="/manageQuotes/create_preliquidation/"
              />
            </Route>
            <Route
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    ROLES.Admin,
                    ROLES.Advisor,
                    ROLES.Pricing,
                    ROLES.Commercial,
                  ]}
                  userRoles={userRoles}
                />
              }
            >
              <Route
                element={<QuoteDetails />}
                path="/manageQuotes/quotation-details/"
              />
            </Route>
            <Route
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    ROLES.Admin,
                    ROLES.Advisor,
                    ROLES.Pricing,
                    ROLES.Commercial,
                    ROLES.Provider
                  ]}
                  userRoles={userRoles}
                />
              }
            >
            <Route
              element={<PreliquidationDetails />}
              path="/manageQuotes/preliquidation-details/"
            />
            <Route
                element={<PricingDashboard/>}
                path="/manageQuotes/dashboard"
            />
            </Route>
            
            <Route
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    ROLES.Admin,
                    ROLES.Advisor,
                    ROLES.Pricing,
                    ROLES.Commercial,
                    ROLES.Operations,
                    ROLES.Outsource,
                  ]}
                  userRoles={userRoles}
                />
              }
            >
              <Route
                element={<OperationsTracking />}
                path="/operations/tracking"
              />
            </Route>
            <Route
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    ROLES.Admin,
                    ROLES.Advisor,
                    ROLES.Pricing,
                    ROLES.Commercial,
                    ROLES.Operations,
                  ]}
                  userRoles={userRoles}
                />
              }
            >
              <Route
                element={<OperationsDashboard/>}
                path="/operations/dashboard"
              />
            </Route>
            <Route path="*" element={<Missing />} />
          </Routes>
        </div>
      )}
      <Toaster position="top-right" duration={5000} />
    </div>
  );
}

const mapState = ({ user: { fetching, loggedIn, token } }) => {
  return {
    fetching,
    loggedIn,
    token,
  };
};

export default connect(mapState)(App);
