import React, { useEffect, useState } from "react";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import styles from "./Register.module.scss";
import { styled, Tooltip } from "@nextui-org/react";
import theme from "../../theme";
import { Link, useNavigate } from "react-router-dom";
import Icon from "../../components/Icon/Icon";
import { getIndustries } from "../../services/industries.services";
import { register as regis } from "../../services/auth.services";
import { useForm } from "react-hook-form";
import { Toaster, toast as sonnerToast } from "sonner";
import toast from "../../components/Toast/ToastTypes";
import InputSelect from "../../components/Select/inputSelect";
function Register() {
  const { register, handleSubmit } = useForm();

  const documentTypes = [
    { label: "CC", value: "CC" },
    { label: "RFC", value: "RFC" },
    { label: "NIT", value: "NIT" }
  ]
  const [industries, setIndustries] = useState([])
  const [selectedIndustry, setSelectedIndustry] = useState()
  const [documentType, setDocumentType] = useState();
  const [loading, setLoading] = useState(false)

  const [errorMessages, setErrorMessages] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    password1: "",
    password2: "",
    document_type: "",
    industries: "",
  });

  const blockedDomains = [
    // "gmail.com",
    // "hotmail.com",
    "protonmail.com",
    "mailinator.com",
    "guerrillamail.com",
    "10minutemail.com",
    "tempmail.com",
    "temp-mail.org",
    "getairmail.com",
    "tempmailaddress.com",
    "throwawaymail.com",
    "yopmail.com",
    "tempail.com",
    "dispostable.com",
    "mailcatch.com",
    "tempinbox.com",
    "tempmailo.com",
    "maildrop.cc",
    "moakt.com",
    "mytemp.email",
    "mintemail.com",
    "mohmal.com",
    "mailnesia.com",
    "fakeinbox.com",
    "fakemail.fr",
    "dayrep.com",
    "spam4.me",
    "burnermail.io",
  ];

  useEffect(() => {
    getIndustries().then(async res => {
      console.log('res :', res)
      const data = await res.json();
      console.log("data :",data)
      setIndustries([
        { "id": 1, "name": "Farmacia" },
        { "id": 2, "name": "Comercio" },
        { "id": 3, "name": "Cosmética" },
        { "id": 4, "name": "Automotriz" },
        { "id": 5, "name": "Alta Tecnología" },
        { "id": 6, "name": "Alimentación" },
        { "id": 7, "name": "O&G" },
        { "id": 8, "name": "Industrial" },
        { "id": 9, "name": "Infraestructura" },
        { "id": 10, "name": "Mineria" },
        { "id": 11, "name": "Energía" },
        { "id": 12, "name": "Renovables" },
        { "id": 13, "name": "Petroquímicos" },
        { "id": 14, "name": "Ayuda benefica" }
      ])
    })
  }, [])
  const navigate = useNavigate();
  // Validación del tipo de documento
  const isValidDocument = (selectedDocumentType, document) => {
    switch (selectedDocumentType) {
      case 'CC':
        return /^[0-9]{6,10}$/.test(document);
      case 'RFC':
        return /^[A-ZÑ&]{3,4}\d{2}(0[1-9]|1[0-2])(0[1-9]|1\d|2\d|3[0-1])((?<=\D)|(?<=X))[A-Z\d]\d$/.test(document);
      case 'NIT':
        return /^[0-9]{9,10}$/.test(document);
      default:
        return false;
    }
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return emailRegex.test(email)
    // && !blockedDomains.includes(domain);
  };
  // Validación de la contraseña
  const isValidPassword = (password) => {
    const passwordRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*()-_=+[\]{}|;:'",.<>/?`~]{8,}$/;
    return passwordRegex.test(password);
  };

  const doRegister = (data) => {
    setLoading(true)
    if (data.password1 !== data.password2) {
      toast("error", "Las contraseñas no coinciden.");
      setLoading(false)
      return;
    }
    if (!isValidDocument(documentType, data.document)) {
      toast("error", "El documento ingresado no es válido.");
      setLoading(false)
      return;
    }
    const isAnyFieldEmpty = Object.values(data).some(value => value === '');
    try {
      const submitData = {
        first_name: data.first_name,
        last_name: data.last_name,
        document: data.document,
        phone: data.phone,
        email: data.email,
        password1: data.password1,
        password2: data.password2,
        document_type: documentType,
        industry: selectedIndustry.label,
        organization: {
          name: selectedIndustry.label,
          industry: selectedIndustry.value,
          website:'N',
        }
      }
      console.log("submitData :", submitData)
      if (Object.values(data).some(value => value === '')) {
        setLoading(false)
        toast("warn", "Debe rellenar todos los campos");
      } /* else if (!isValidEmail(data.email)) {
        toast("error", "Ingrese una dirección de correo electrónico válida.");
      }  */else if (isValidPassword(data.password1)) {
        regis(submitData).then((res) => {
          if (res.status === 204) {
            toast("success", "registro exitoso")
            navigate('/')
          } else {
            Object.keys(res).forEach(key => {
              let error = res[key]
              if (error[0] === "A user is already registered with this e-mail address.") {
                toast("error", "Este correo ya está en uso")
              } else {
                setLoading(false)
                toast("error", "Hubo un error en el registro")
              }
            })

          }

        }).catch(err => {
          setLoading(false)
          toast("error", "Hubo un error en el registro")
        })
      } else {
        const text = "Su contraseña debe cumplir con: Minimo 8 digitos, Minimo 1 letra mayuscula, Minimo 1 letra minuscula, Minimo 1 caracter especial, Minimo 1 numero";
        toast("error", text);
      }
      setLoading(false)
    } catch (E) {
      setLoading(false)
      toast("warn", "Debe rellenar todos los campos");
    }

  };

  const StyledRastreo = styled("div", {
    [`.${theme} &`]: {
      display: "flex",
      flexDirection: "column",
      padding: "2rem 4rem",
      h2: {
        color: "$primary_300",
      },
      label: { color: "$primary_500" },
      [".rastreo"]: {
        display: "grid",
        gridTemplateColumns: "3.5fr 1fr",
        alignItems: "end",
        gap: "1rem",
      },
    },
  });

  const InputsContainer = styled("div", {
    display: 'grid',
    gap: '20px',
    gridTemplateColumns: 'repeat(4, 1fr)',
    ['.medium_input']: {
      gridColumn: 'span 2'
    },
    ['.small_input']: {
      gridColumn: 'span 1'
    },
    ['.big_input']: {
      gridColumn: 'span 3'
    },
    ['.large_input']: {
      gridColumn: 'span 4'
    },
  })

  return (
    <>
      <Toaster position="top-right" />
      <section className={styles.login}>
        <div className={styles.login_form}>
          <Link to="/" className={styles.iconBack}>
            <Icon color="white" name="ArrowBack" />
          </Link>
          <h2 style={{ color: 'white' }}>Regístrate</h2>
          <InputsContainer>
            <div className="medium_input">
              <Input
                fullWidth
                register={register}
                label="Nombre*"
                labelColor="neutral_50"
                name="first_name"
                placeholder="Ingresar tu nombre"
                selectBorderColor="secondary_200"
              />
              {errorMessages.first_name && (
                <Tooltip text={errorMessages.first_name.message} placement="bottom" color="red">
                  <div className={styles.tooltip}>
                    <span className={styles.tooltiptext}>❌ Este campo es obligatorio</span>
                  </div>
                </Tooltip>
              )}
            </div>
            <div className="medium_input">
              <Input
                fullWidth
                register={register}
                label="Apellidos*"
                labelColor="neutral_50"
                name="last_name"
                placeholder="Ingresar tu apellido"
                selectBorderColor="secondary_200"
              />
              {errorMessages.last_name && (
                <Tooltip text={errorMessages.last_name.message} placement="bottom" color="red">
                  <div className={styles.tooltip}>
                    <span className={styles.tooltiptext}>❌ Este campo es obligatorio</span>
                  </div>
                </Tooltip>
              )}
            </div>
            <div className="medium_input">
              <InputSelect
                name="document_type"
                label='Tipo de Documento*'
                value={{ label: documentType, value: documentType }}
                onChange={(e) => setDocumentType(e?.value)}
                options={documentTypes}
              />
              {errorMessages.document_type && (
                <Tooltip text={errorMessages.document_type.message} placement="bottom" color="red">
                  <div className={styles.tooltip}>
                    <span className={styles.tooltiptext}>❌ Este campo es obligatorio</span>
                  </div>
                </Tooltip>
              )}
            </div>
            <div className="medium_input">
              <Input
                fullWidth
                register={register}
                label="Documento"
                labelColor="neutral_50"
                name="document"
                placeholder="Ingresar tu documento"
                selectBorderColor="secondary_200"
              />
              {errorMessages.document && (
                <Tooltip text={errorMessages.document.message} placement="bottom" color="red">
                  <div className={styles.tooltip}>
                    <span className={styles.tooltiptext}>❌ Este campo es obligatorio</span>
                  </div>
                </Tooltip>
              )}
            </div>
            <div className="medium_input">
              <Input
                fullWidth
                register={register}
                label="Teléfono"
                labelColor="neutral_50"
                name="phone"
                placeholder="Ingresar tu telefono"
                selectBorderColor="secondary_200"
                type="number"
              />
              {errorMessages.phone && (
                <Tooltip text={errorMessages.phone.message} placement="bottom" color="red">
                  <div className={styles.tooltip}>
                    <span className={styles.tooltiptext}>❌ Este campo es obligatorio</span>
                  </div>
                </Tooltip>
              )}
            </div>
            <div className="medium_input">
              <InputSelect
                fullWidth
                register={register}
                label="Industria"
                labelColor="neutral_50"
                name="industry"
                selectBorderColor="secondary_200"
                onChange={(e) => setSelectedIndustry(e)}
                value={selectedIndustry}
                options={industries?.map(industry => {
                  return {
                    label: industry.name,
                    value: industry.id,
                  }
                })}
              />
              {errorMessages.document_type && (
                <Tooltip text={errorMessages.document_type.message} placement="bottom" color="red">
                  <div className={styles.tooltip}>
                    <span className={styles.tooltiptext}>❌ Este campo es obligatorio</span>
                  </div>
                </Tooltip>
              )}
            </div>
            <div className="large_input">
              <Input
                fullWidth
                register={register}
                label="Correo"
                labelColor="neutral_50"
                name="email"
                placeholder="Ingresar tu correo"
                selectBorderColor="secondary_200"
              />
              {errorMessages.email && (
                <Tooltip text={errorMessages.email.message} placement="bottom" color="red">
                  <div className={styles.tooltip}>
                    <span className={styles.tooltiptext}>❌ Este campo es obligatorio</span>
                  </div>
                </Tooltip>
              )}
            </div>
            <div className="large_input">
              <Input
                fullWidth
                register={register}
                label="Contraseña"
                labelColor="neutral_50"
                name="password1"
                placeholder="Ingresar la contraseña"
                selectBorderColor="secondary_200"
                password //necesario para mostrar clave
              />
              {errorMessages.password1 && (
                <Tooltip text={errorMessages.password1.message} placement="bottom" color="red">
                  <div className={styles.tooltip}>
                    <span className={styles.tooltiptext}>❌ Este campo es obligatorio</span>
                  </div>
                </Tooltip>
              )}
            </div>
            <div className="large_input">
              <Input
                fullWidth
                register={register}
                label="Confirmar Contraseña"
                labelColor="neutral_50"
                name="password2"
                placeholder="Confirma la contraseña"
                password
                selectBorderColor="secondary_200"
              />
              {errorMessages.password2 && (
                <Tooltip text={errorMessages.password2.message} placement="bottom" color="red">
                  <div className={styles.tooltip}>
                    <span className={styles.tooltiptext}>❌ Este campo es obligatorio</span>
                  </div>
                </Tooltip>
              )}
            </div>
          </InputsContainer>
          <div style={{ color: 'white' }}>
            Al registrarte, aceptas nuestros <Link to="/terms-and-conditions">Términos y Condiciones</Link>.
          </div>

          <Button
            handler={handleSubmit(doRegister)}
            bgColor="secondary_200"
            disabled={loading}
            text="Registrate"
            textColor="white"
          />
          <div className={styles.provider_login}>
            <p style={{ color: 'white' }}>¿Eres proveedor?</p>
            <Link to="/provider-chat">Ingresa al chat</Link>
          </div>
        </div>
        <div className={styles.val_rastreo}>
          <img src="/home.svg" alt="" />
          <StyledRastreo>
            <h2>Sigue tu envío</h2>
            <div className="rastreo">
              <Input
                label="Ingresa tu número de radicado"
                placeholder="#00000"
                className={styles.o_input}
                animated={false}
                name="radicado"
              // onChange={handleChange}
              />
              <Link to="/rastreo">
                <Button
                  bgColor="primary_400"
                  text="Rastrear"
                  textColor="white"
                />
              </Link>
            </div>
          </StyledRastreo>
        </div>
      </section>
    </>
  );
}

export default Register;
