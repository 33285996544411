import React from 'react'
import { Modal, styled } from '@nextui-org/react'
import Button from '../Button/Button'

export default function DeleteConfirmationModal({ open, setOpen, text, handleDelete }) {

    const StyledFormModal = styled(Modal, {
        padding: '1.5rem',
        color: '$primary_500 !important',
    })

    return (
        <StyledFormModal
            aria-label="modal-title"
            open={open}
            onClose={() => setOpen(false)}
            width="fit-content"
        >
            <Modal.Header justify='space-between'>
                <h3>Confirmar</h3>
            </Modal.Header>
            <Modal.Body justify='space-between'>
                <p>{text}</p>
                <div style={{ display: 'flex', justifyContent: 'end', gap: '15px' }}>
                    <Button text='Cancelar' bold bgColor={'primary_300'} textColor='neutral_50' handler={() => setOpen(false)} />
                    <Button text='Borrar' bold bgColor={'delete'} textColor='neutral_50' handler={() => { handleDelete(); setOpen(false) }} />
                </div>
            </Modal.Body>
        </StyledFormModal>
    )
}
