import toast from "../components/Toast/ToastTypes";
import { logOutAction } from "../store/sessionDuck";
import { URL } from "../constants"

export const login_post = async (credentials) => {
    const response = await fetch(`${URL.get()}login/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(credentials)
    })

    const data = await response.json();
    if (data.non_field_errors && data.non_field_errors[0] == "Unable to log in with provided credentials."){
        toast('error', data.message ? data.message : 'Usuario o contraseña incorrectas')
    } else {
        !response.ok && toast('error', data.message ? data.message : 'Ha ocurrido un error')
    }
    return data;
}

export const register = async (data) => {
    const response = await fetch(`${URL.get()}dj-rest-auth/registration/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    });
    if (response.status === 200 ||response.status === 204 ) {
        return response; // Registro exitoso
    } else if (response.status === 400) {
        const errorData = await response.json();
        return errorData // Error de validación u otros errores
    } else {
        throw new Error("Hubo un error en el registro."); // Otros errores
    }
}


export const logout = async (token) => {
    const response = await fetch(`${URL.get()}logout/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
    })

    return response
}

export const getUserAuth = async (token) => {
    const response = await fetch(`${URL.get()}user/profile/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token  " + token,
        },
    })
    if (response.status === 200) {
        return response.json()
    } else {
        throw new Error("Hubo un error al obtener la autenticación del usuario");
    }
}

export const getProfile = async (token) => {
    const response = await fetch(`${URL.get()}userprofile/my_profile/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
    })
    return response
}

export const getAllProfiles = async (token) => {
    const response = await fetch(`${URL.get()}userprofile/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
    })
    return response
}

export const editProfile = async (token,data) => {
    const response = await fetch(`${URL.get()}userprofile/my_profile/`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify(data)
    })
    return response
}
export const getTenant = async (mail) => {
    // const response = await fetch(`${URL.get()}get_tenant/${mail}`, {
    //     method: "GET",
    //     headers: {
    //         "Content-Type": "application/json",
    //     },
    // })
    console.log("URL.get() :",URL.get())
    let response = {
        name:'QA',
        url:'https://apiqa.val.com.co/',
    }
    if (URL.get() == "https://apiv2.val.com.co/"){
         response = {
            name:'V2',
            url:"https://apiv2.val.com.co/",
        }
    }
    
    return response
}
