export const defaultValues = {
    shipping_method: '',
    general: {
      radication: '',
      date: '',
      preparedFor:'',
      clientEmail: '',
      clientNumber: '',
      preparedBy: '',
      preparedByPhone: '',
      preparedByEmail: '',
      salesContact: '',
      salesEmail: '',
      salesPhone: '',
      address: '',
      city: '',
      country: '',
    },
    service_details: {
      incoterm: '',
      destination_incoterm: '',
      loading_point: '',
      unloading_point: '',
      valid_until: '',
      move_type: '',
      load_type: '',
      transit_time: '',
      airline: '',
      transshipment_via: '',
    },
    shipping_information: {
      pieces: '',
      volume: '',
      weight: '',
      chargeable_vol: '',
      commodity: '',
      dangerous_goods: '',
      UN: '',
      cargo_value: '',
      TRM: '',
      currency: '',
      exchange: '',
    },
    packaging_list: '',
    origin_list: '',
    net_origin: '',
    net_destination: '',
    net_insurance: '',
    destination_list: '',
    insurance_list: '',
    ocean_freight: '',
    net_ocean_freight: '',
    net_air_freight: '',
  }
  
  export const steps = [
    { value: 'general', name: 'Informacion general' },
    { value: 'shipment', name: 'Envio y carga' },
    { value: 'costs', name: 'Costos' },
  ]

  export const servicesDefaultData = {
    itemId:1,
    Concepto: '',
    Factor: '',
    Unit: 'Kg',
    Currency: {label:'', value:0},
    Trm:'',
    Rate: '',
    Min: '',
    'Total in USD':'',
  }
  export const netDefaultData = {
    itemId:1,
    Concepto: '',
    Net: '',
    Mínima: '',
    Total: '',
    Profit: '',
    Proveedor: '',
  }
  export const netAirDefaultData = [{
    itemId:1,
    Concepto: 'Flete',
    Mínima: '',
    'Kg/CW':'',
    Total: '',
    Profit: '',
  },
  {
    itemId:2,
    Concepto: 'FS',
    Mínima: '',
    'Kg/CW':'',
    Total: '',
    Profit: '',
  }]