import React, { useEffect, useRef, useState } from 'react';
import { Input, styled } from '@nextui-org/react';
import theme from '../../theme';
import { Box } from '@mui/material';

// Components
import Icon from '../Icon/Icon';
import Button from '../Button/Button';

// Images
import Pdf from '../../assets/Files/pdf.png';

const InputFileCard = ({
    label,
    labelColor = 'neutral_50',
    bgColor = 'primary_400',
    startIcon,
    name,
    accept = 'application/pdf',
    onChange,
    value,
    width,
    aspectRatio,
    register = () => { },
}) => {
    const [file, setFile] = useState([])
    const inputFile = useRef(null)

    const StyledInputFileCard = styled(Box, {
        [`.${theme} &`]: {
            width: width || '286px',
            aspectRatio: aspectRatio || '5 / 3',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            backgroundColor: `${file.length > 0 ? '$neutral_50' : '$primary_50'}`,
            border: `1px ${file.length > 0 ? 'solid $primary_300' : 'dashed $primary_200'}`,
            borderRadius: '8px',
            ['.fullFile']: {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                position: `${file.length > 0 ? '' : 'absolute'}`,
                visibility: `${file.length > 0 ? 'visible' : 'hidden'}`,
                ['.content']: {
                    flexGrow: '1',
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    ['img']: {
                        width: '50px'
                    },
                    ['.close']: {
                        display: 'flex',
                        justifyContent: 'end',
                        width: '100%',
                        position: 'absolute',
                        top: '0'
                    }
                },
                ['.title']: {
                    backgroundColor: '$primary_50',
                    alignSelf: 'end',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    borderRadius: '0px 0px 8px 8px',
                    padding: '0.5rem 1rem',
                    fontSize: '0.9rem',
                    ['span']: {
                        width: '100%',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                    }
                }
            },
            [`.nextui-input-main-container`]: {
                width: `${file.length === 0 ? '100%' : '0rem'}`,
                height: `${file.length === 0 ? '100%' : '0rem'}`,
                position: `${file.length === 0 ? '' : 'absolute'}`,
                zIndex: `${file.length === 0 ? '1' : '-1'}`
            },
            ['.nextui-input-container']: {
                position: 'absolute',
                visibility: 'hidden'
            },
            ['label']: {
                color: `$${labelColor}`,
                backgroundColor: `$${bgColor}`,
                padding: '1rem 2rem',
                borderRadius: '8px',
                margin: 'auto',
                fontSize: '1rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '0.5rem',
                cursor: 'pointer',
            }
        }
    })

    const handleChange = (e) => {
        const files = e.target.files
        if (files.length > 0) {
            setFile(files)
        } else {
            setFile([])
        }
        onChange(e, name)
    }

    const remove = () => {
        handleChange({ target: inputFile.current.getElementsByTagName('input')[0] })
    }

    useEffect(() => {
        setFile(value || [])
    }, []);

    return (
        <StyledInputFileCard ref={inputFile} >
            <Input
                {...register(name)}
                type='file'
                onChange={handleChange}
                accept={accept}
                label={(
                    <>
                        <Icon
                            name={startIcon?.name || undefined}
                            color={startIcon?.color || undefined}
                            size={startIcon?.size || undefined}
                        ></Icon>
                        <span>{label}</span>
                    </>
                )}
            ></Input>
            <div className='fullFile'>
                <div className='content'>
                    <div className='close'>
                        <Button handler={remove} startIcon={{ name: 'CloseModal' }} bgColor={'transparent'} animated={false}></Button>
                    </div>
                    {/* {accept==='.xls,.xlsx'? <img src={Pdf} alt="pdf" /> : <h1>excel</h1>} */}
                </div>
                <div className='title'>
                    <span>{file[0]?.name}</span>
                    <span>PDF</span>
                </div>
            </div>
        </StyledInputFileCard>
    );
}

export default InputFileCard;
