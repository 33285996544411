import React from 'react';
import { styled } from '@nextui-org/react';
import { Helmet } from "react-helmet";

// Components
import PageTitle from '../../components/PageTitle/PageTitle'
import Card4 from '../../components/Cards/Card4/Card4';
import Card5 from '../../components/Cards/Card5/Card5';
import CarouselCards from '../../components/Cards/CarouselCards/CarouselCards';
import TutorialsData from './TutorialsData';

import calculator from '../../assets/tools/calculator.jpg';
import consulte from '../../assets/tools/consulte.jpg';

const StyledTools = styled('div', {
    padding: '2rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    '.content': {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: '1rem',
        '@md': {
            gridTemplateColumns: '3fr 1fr',
        },
    },
    section: {
        gridColumn: 'span 1',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        iframe: {
            width: '100%',
            height: '400px',
            border: 'none',
        },
    },
    aside: {
        gridColumn: 'span 1',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
    },
    '.tutorials': {
        gridColumn: 'span 1',
        marginTop: '2rem',
        '@md': {
            gridColumn: 'span 2',
        },
    },
});

const Tools = () => {
    return (
        <>
            <Helmet>
                <title>Herramientas</title>
                <meta name="description" content="Accede a herramientas útiles para tus operaciones logísticas, como tráfico marítimo, calculadora de peso, y consulta arancelaria." />
            </Helmet>
            
            <StyledTools>
                <PageTitle>
                    Herramientas
                </PageTitle>

                <div className='content'>
                    <section>
                        <Card4 title={'Tráfico marítimo'}>
                            <iframe
                                src="https://www.marinetraffic.com/en/ais/embed/zoom:4/centerx:-66.8846/centery:3.4242maptype:0/shownames:false/mmsi:0/shipid:0/fleet:/fleet_id:/vtypes:/showmenu:false/remember:false"
                                title="MarineTraffic Map"
                                allowFullScreen
                            ></iframe>
                        </Card4>
                    </section>

                    <aside>
                        <Card5 src="calculator" title={'Calculadora de peso'} image={calculator} description={'Utilice la opción ver más para calcular el peso de su carga.'} />
                        <Card5 src="tariff" title={'Consulta Arancelaria'} image={consulte} description={'Utilice la opción ver más para calcular los aranceles de su carga.'} />
                    </aside>

                    <div className='tutorials'>
                        <CarouselCards data={TutorialsData} />
                    </div>
                </div>
            </StyledTools>
        </>
    );
}

export default Tools;
