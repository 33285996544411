import React, { useEffect, useState } from 'react'
import './ViewsHandler.scss'

export default function ViewsHandler({ activeView, children }) {

    const [className, setClassName] = useState('final')

    useEffect(() => {
        setClassName('initial')
        setTimeout(() => {
            setClassName('final')
        }, "300");
    }, [activeView])

    return (

        <div className={className}>
            <div>
                {
                    children.map((child, index) => {
                        if (activeView === index) {
                            return (
                                <div key={index}>
                                    {child}
                                </div>
                            )
                        } else return null
                    })
                }
            </div>
        </div>
    )
}
