import React, { useEffect } from 'react';
import { styled } from '@nextui-org/react';
import theme from '../../../../theme';
import { useForm } from 'react-hook-form';
import { toBase64 } from '../../../../services/base64.services';

// Components
import PageTitle from '../../../../components/PageTitle/PageTitle';
import InputFileCard from '../../../../components/InputFileCard/InputFileCard';

const Documents = ({ setDocuments }) => {
    const nameForm = 'documents'
    const { register, getValues, setValue } = useForm({
        defaultValues: {
            [nameForm]: {
                rut: '',
                taxReturn: '',
                chamberCommerce: '',
            }
        }
    });

    const StyledExterior = styled('div', {
        [`.${theme} &`]: {
            margin: '3rem 8rem 0rem 8rem',
            padding: '3rem 8rem',
            backgroundColor: '$neutral_50',
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            ['main']: {
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem'
            }
        }
    })

    const saveData = async (e, name) => {
        if (e?.target?.files.length > 0) {
            const file = await toBase64(e.target.files[0])
            setValue(name, file)
        } else {
            setValue(name, '')
        }
        setDocuments(getValues()[nameForm])
    }

    useEffect(() => {
        saveData()
    }, []);

    return (
        <StyledExterior>
            <PageTitle>
                Documentos adicionales
            </PageTitle>

            <aside>
                Apellidos y nombres de las personas o entidades beneficiarias de las operaciones de comercio exterior.
            </aside>

            <main>
                <section>
                    <h5>1 - RUT</h5>
                    <InputFileCard onChange={saveData} value={getValues()[nameForm].rut} name={`${nameForm}.rut`} label='Sube o arrastra' startIcon={{ name: 'Upload', color: '#FFF' }}></InputFileCard>
                </section>

                <section>
                    <h5>2 - Declaración de renta</h5>
                    <InputFileCard onChange={saveData} value={getValues()[nameForm].taxReturn} name={`${nameForm}.taxReturn`} label='Sube o arrastra' startIcon={{ name: 'Upload', color: '#FFF' }}></InputFileCard>
                </section>

                <section>
                    <h5>3 - Cámara de comercio</h5>
                    <InputFileCard onChange={saveData} value={getValues()[nameForm].chamberCommerce} name={`${nameForm}.chamberCommerce`} label='Sube o arrastra' startIcon={{ name: 'Upload', color: '#FFF' }}></InputFileCard>
                </section>
            </main>
        </StyledExterior>
    );
}

export default Documents;
