import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import generateStore from "./store";
import { NextUIProvider } from "@nextui-org/react";
import { ProSidebarProvider } from "react-pro-sidebar";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

import * as es_CO from "./translations/es-CO";
import * as en_US from "./translations/en-US";
import { editableTheme } from "./themeClass";

const store = generateStore();

i18next.init({
  interpolation: { escapeValue: false },
  lng: "es",
  resources: {
    es: {
      Sidebar: es_CO.sidebar_es_CO,
      Home: es_CO.home_es_CO,
      History: es_CO.history_es_CO,
      Support: es_CO.support_es_CO,
      Quotation: es_CO.quotation_es_CO,
      Register: es_CO.register_es_CO,
      Profile: es_CO.profile_es_CO,
      Missing: es_CO.missing_es_CO,
      Tools: es_CO.tools_es_CO,
      Admin: es_CO.admin_es_CO,
      Calculator: es_CO.calculator_es_CO,
      Services: es_CO.services_es_CO,
      CO2: es_CO.co2_es_CO,
      ForgotPassword: es_CO.forgotPassword_es_CO,
      OperationsTracking: es_CO.operationsTracking_es_CO,
      ManageQuotes: es_CO.manageQuotes_es_CO,
      Tracking: es_CO.tracking_es_CO,
      CO2Dashboard: es_CO.co2Dashboard_es_CO,
      Components: es_CO.components_es_CO,
      Login: es_CO.login_es_CO,
    },
    en_US: {
      Sidebar: en_US.sidebar_en_US,
      Home: en_US.home_en_US,
      History: en_US.history_en_US,
      Support: en_US.support_en_US,
      Quotation: en_US.quotation_en_US,
      Register: en_US.register_en_US,
      Profile: en_US.profile_en_US,
      Missing: en_US.missing_en_US,
      Tools: en_US.tools_en_US,
      Admin: en_US.admin_en_US,
      Calculator: en_US.calculator_en_US,
      Services: en_US.services_en_US,
      CO2: en_US.co2_en_US,
      ForgotPassword: en_US.forgotPassword_en_US,
      ManageQuotes: en_US.manageQuotes_en_US,
      Tracking: en_US.tracking_en_US,
      CO2Dashboard: en_US.co2Dashboard_en_US,
      Components: en_US.components_en_US,
      OperationsTracking: en_US.operationsTracking_en_US,
      Login: en_US.login_en_US,
    },
  },
});

function Root() {
  const [theme, setTheme] = useState(editableTheme.get());

  useEffect(() => {
    const handleThemeChange = () => {
      setTheme(editableTheme.get());
    };

    // Supón que editableTheme tiene un mecanismo de suscripción
    editableTheme.subscribe(handleThemeChange);

    return () => {
      editableTheme.unsubscribe(handleThemeChange);
    };
  }, []);
  

  return (
    <Provider store={store}>
      <NextUIProvider theme={theme}>
        <BrowserRouter>
          <I18nextProvider i18n={i18next}>
            <ProSidebarProvider>
              <App />
            </ProSidebarProvider>
          </I18nextProvider>
        </BrowserRouter>
      </NextUIProvider>
    </Provider>
  );
}

// Crea el root solo una vez
const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

// Usa root.render() para renderizar o actualizar el contenido
root.render(<Root />);
