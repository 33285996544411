import React, { useEffect, useState } from "react";
import { styled, Modal, Loading } from "@nextui-org/react";
import Button from "../../components/Button/Button";
import { format } from "date-fns";
import Input from "../../components/Input/Input";
import ColoredPieChart from "../../components/Graphics/ColoredPieChart";
import ComposedBarChart from "../../components/Graphics/ComposedChart/ComposedChart";
import SmallTable from "./SmallTable";
import DateRangePickerModal from "../../components/DateRangePicker/DateRangePicker";
import {
  getCO2Stats,
  getCO2StatsCompensation,
} from "../../services/CO2.services";
import { useSelector } from "react-redux";
import { usePDF } from "react-to-pdf";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import CO2Persons from "../CO2Persons/CO2Persons";

import { useTranslation } from "react-i18next";

const StyledContainer = styled("div", {
  padding: "30px 50px",
  display: "flex",
  flexDirection:'column',
  gap:'25px',
  [".title"]: {
    width: "fit-content",
    color: "grey",
    margin: "0px auto",
  },
  [".total"]: {
    width: "fit-content",
    fontWeight: "bold",
    color: "grey",
    margin: "auto",
    marginTop: "5px",
  },
});
const WideDiv = styled("div", {
  background: "$neutral_50",
  width:'100%',
  boxShadow: "$elevation_1",
  padding:'10px',
  borderRadius: "8px",
});
const Pies = styled("div", {
  display: "grid",
  gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
  maxHeight: "350px",
});
const Legend = styled("div", {
  gridColumn: "span 4",
  display: "flex",
  width: "fit-content",
  margin: " 30px auto ",
});
const SmallChart = styled("div", {
  margin: "auto",
  width:'100%',
  height: "100%",
  minWidth: "200px",
});
const TableContainer = styled("div", {
  gridColumn: "span 2",
  backgroundColor: "$neutral_50",
  height: "420px",
  boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)",
  borderRadius: "8px",
});
const BigChart = styled("div", {
  gridColumn: "span 4",
  background: "$neutral_50",
  boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)",
  borderRadius: "8px",
});

const colors = ['#94ba7f','#3D509A','#5b658d', '#EB9D3A ']

export default function CO2Dashboard() {
  const { t: translate } = useTranslation("CO2Dashboard");
  const token = useSelector((state) => state.user.token);
  const user = useSelector((state) => state.user.idUser);
  const { toPDF, targetRef } = usePDF({ filename: "admin_dashboard.pdf" });

  const [open, setOpen] = useState(false);
  const [dates, setDates] = useState({});
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [totalEmision, settotalEmision] = useState(0);
  const [totalCompensation, settotalCompensation] = useState(0);
  const getChartTitle = (index) => {
    const labels = [translate("$co2"),translate("emmited-co2"),translate("moved-tons"),translate("km")];
    return labels[index];
  };

  const getTotal = (subData) => {
    return subData.reduce((total, item) => total + item.value, 0);
  };

  const getData = () => {
    setLoading(true)
    if (dates.startDate && dates.endDate) {
      const asyncTasks = [
        getCO2Stats(token, format(dates.startDate, "yyyy-MM-dd HH:mm:ss"), format(dates.endDate, "yyyy-MM-dd HH:mm:ss")),
        getCO2StatsCompensation(token, format(dates.startDate, "yyyy-MM-dd HH:mm:ss"), format(dates.endDate, "yyyy-MM-dd HH:mm:ss")),
      ];
      Promise.all(asyncTasks).then(async (responses) => {
        const shippingsData = await responses[0].json();
        const formatShippingsData = [];
        console.log("aaaaaaaaa")
        setLoading(false)
        const keysco2 = Object.keys(shippingsData.co2);
        keysco2.forEach((key) => {
          formatShippingsData.push({
            name: key,
            value: shippingsData.co2[key],
          });
        });
        const formatShippingsData2 = [];
        const keystons = Object.keys(shippingsData.tons);
        keystons.forEach((key) => {
          formatShippingsData2.push({
            name: key,
            value: shippingsData.tons[key],
          });
        });
  
        const formatShippingsData3 = [];
        const keysdistance = Object.keys(shippingsData.distance);
        keysdistance.forEach((key) => {
          formatShippingsData3.push({
            name: key,
            value: shippingsData.distance[key],
          });
        });
  
        let sumEmision = 0;
        formatShippingsData.forEach((emision) => {
          sumEmision += emision.value;
        });
        settotalEmision(sumEmision);
        let compensations = await responses[1].json();
        const formatShippingCompensation = [];
        const formatShippingPrice = [];
        const compensation = Object.keys(compensations.co2);
        compensation.forEach((key) => {
          formatShippingCompensation.push({
            name: key,
            value: compensations.co2[key],
          });
          formatShippingPrice.push({
            name: key,
            value: compensations.price[key],
          });
        });
        let sumCompensation = 0;
        formatShippingCompensation.forEach((emision) => {
          sumCompensation += emision.value;
        });
        settotalCompensation(sumCompensation);
        
        setData([
          formatShippingPrice,
          formatShippingsData,
          formatShippingsData2,
          formatShippingsData3,
        ]);
      })
    }
  };

  useEffect(() => {
    getData();
  }, [dates]);

  function downloadPDF() {
    setLoadingPdf(true);
    toPDF()
      .then(() => {
        setLoadingPdf(false);
      })
      .catch((err) => {
        console.log("error al descargar pdf :", err);
        setLoadingPdf(false);
      });
  }
  const NoDataMessage = () => (
    <div style={{ textAlign: "center", padding: "10px", color: "#777" }}>
      <FontAwesomeIcon
        icon={faExclamationCircle}
        style={{ fontSize: "1.2em", marginRight: "5px" }}
      />
      <p
        style={{
          display: "inline-block",
          verticalAlign: "middle",
          fontSize: "0.8em",
          fontStyle: "italic",
        }}
      >
        Sin datos disponibles
      </p>
    </div>
  );
  return (
    <div ref={targetRef}>
      <div
        style={{
          position: "relative",
          padding: "20px 50px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          onClick={() => setOpen(true)}
          style={{ display: "flex", gap: "20px", width: "fit-content" }}
        >
          {dates?.startDate ? (
            <Input value={format(dates.startDate, "dd/MM/yyyy")} />
          ) : (
            <Input value="" placeholder={"Desde..."} />
          )}
          {dates?.endDate ? (
            <Input value={format(dates.endDate, "dd/MM/yyyy")} />
          ) : (
            <Input value="" placeholder={"Hasta..."} />
          )}
        </div>
        <Button
          handler={() => downloadPDF()}
          text={loadingPdf ? "Cargando..." : "Descargar"}
          endIcon={{ name: "Download" }}
          outline
          disabled={loadingPdf}
        />
        <DateRangePickerModal
          setDates={setDates}
          open={open}
          setOpen={setOpen}
        />
      </div>
      { loading?<div style={{background:'white',borderRadius:'8px', width:'80%', margin:'auto', height:'60vh',display:'flex',alignItems:'center',justifyContent:'center'}}>
        <Loading size="lg"/>
      </div> :
        <StyledContainer>
        <CO2Persons
          dates={dates}
          totalEmmissions={totalEmision}
          totalCompensations={totalCompensation}
        />
        <WideDiv>
          <Pies>
            {data.map((subData, index) => (
              <SmallChart key={index}>
                <p className="title">{getChartTitle(index)}</p>
                {subData.some((item) => item.value !== 0) ? (
                  <>
                    <p className="total">
                      Total : {getTotal(subData)?.toFixed(2)}
                    </p>
                    <ColoredPieChart
                      data={subData}
                      colors={colors}
                      radius={70}
                    />
                  </>
                ) : (
                  <NoDataMessage />
                )}
              </SmallChart>
            ))}
          </Pies>
          <Legend>
            {data[0]?.map((item, i) => (
              <div
                style={{
                  display: "flex",
                  margin: "auto",
                  marginTop: "20px",
                  width: "100px",
                }}
                key={i}
              >
                <div
                  style={{
                    background: colors[i],
                    width: "20px",
                    height: "15px",
                    borderRadius: "2px",
                    margin: "5px",
                  }}
                />
                <span style={{ color: colors[i] }}>{item.name}</span>
              </div>
            ))}
          </Legend>
        </WideDiv>
        {/* <BigChart>
          <p className="title"> {translate("emited-co2")}</p>
          <ComposedBarChart
            barDataKey={"CO2"}
            lineDataKey={translate("tl-co2")}
            data={data[1]}
          />
        </BigChart> */}
      </StyledContainer>
      }
    </div>
  );
}
