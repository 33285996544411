import React from 'react';
import { styled, Image } from '@nextui-org/react';
import PageTitle from '../../components/PageTitle/PageTitle';
import Card3 from '../../components/Cards/Card3/Card3';
import YoutubeEmbed from '../../components/Youtube/EmbedYoutube';
import TutorialsData from './TutorialsData';

const StyledContainer = styled("div", {
  display: 'grid',
  gridTemplateColumns: '1fr', 
  gap: '40px',
  '@md': { 
    gridTemplateColumns: 'repeat(4, 1fr)',
  }
});

const StyledMain = styled("div", {
  backgroundColor: '$neutral_50',
  borderRadius: '8px',
  padding: '20px',
  margin: '20px',
  gridColumn: 'span 4',
  ['img']: {
    margin: '20px 0',
    borderRadius: '8px',
    maxWidth: '100%',
  },
  '@md': { 
    gridColumn: 'span 3',
    padding: '50px',
    ['img']: {
      margin: '40px 0',
    }
  },
  ['h3']: {
    margin: '40px 0 20px', 
  },
  ['p']: {
    marginBottom: '20px',
  }
});

const OtherTutorials = styled("div", {
  backgroundColor: '$neutral_50',
  padding: '3px',
  margin: '3px 0',
  display: 'grid',
  gap: '3px', 
  gridTemplateColumns: '1fr', 
  '@md': { 
    gridTemplateColumns: 'repeat(2, 1fr)', 
  },
  '@lg': {
    gridTemplateColumns: 'repeat(3, 1fr)', 
  }
});

const tutorials = TutorialsData;

export default function QuotationTutorial() {
  return (
    <StyledContainer>
      <StyledMain>
        <PageTitle>¿Cómo crear una cotización?</PageTitle>
        <YoutubeEmbed embedId="VPonsKSTgUY" />
        <h3>Pasos para generar una cotización</h3>
        <p>
          Aprende cómo crear una cotización de manera sencilla y efectiva con nuestros consejos y guía detallada. En el video adjunto, te mostraremos paso a paso el proceso para generar una cotización precisa y adaptada a tus necesidades.
        </p>
        <p>
          Además, abordaremos aspectos clave, como la consideración de las especificaciones del container, para garantizar que tu cotización sea transparente y cumpla con todos los requisitos. No te pierdas esta valiosa información que te facilitará la creación de cotizaciones exitosas.
        </p>
      </StyledMain>
      <OtherTutorials>
        <PageTitle>Explorar más</PageTitle>
        {tutorials.map((tutorial, i) => (
          <Card3 key={i} title={tutorial.title} image={tutorial.image} to={tutorial.url} iconVideo />
        ))}
      </OtherTutorials>
    </StyledContainer>
  );
}
