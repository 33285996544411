import React, {useState, useEffect } from 'react'
import { Modal, styled } from '@nextui-org/react'
import Table from '../../../components/Table/Table'
import { getOperations } from '../../../services/operations.services'
import { getUsers } from '../../../services/user.services'
import { useSelector } from 'react-redux'

const StyledModal = styled(Modal, {
    color: '$primary_500 !important',
    padding: '20px',
    minWidth:'900px',
})

const columns = [
    { name: 'Acciones', uid: 'actions', type: 'action' },
    { name: 'ID', uid: 'id', type: 'text' },
    { name: 'Operacion', uid: 'radication', type: 'text' },
    { name: 'Cliente', uid: 'client', type: 'text' },
    { name: 'ETD', uid: 'etd', type: 'text' },
    { name: 'ETA', uid: 'eta', type: 'text' },

]
function formatDate(dateString) {
    const originalDate = new Date(dateString);
    const day = originalDate.getDate();
    const month = originalDate.getMonth() + 1;
    const year = originalDate.getFullYear();

    const formattedDay = day.toString().padStart(2, '0');
    const formattedMonth = month.toString().padStart(2, '0');

    const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;

    return formattedDate;
}

export default function OperationsModal({open, setOpen, operationsOnCurrentStep, setSelectedOperation}) {

    const token = useSelector(state => state.user.token)
    const [items, setItems] = useState([])

    useEffect(()=>{
        const asyncTasks = [getOperations({token:token}), getUsers(token)]
        Promise.all(asyncTasks).then(async res => {
            const operationsData = await res[0].json();
            const usersData = await res[1].json();

            const filteredOperationsData =operationsData?.length && operationsData?.filter(op => operationsOnCurrentStep?.includes(op.id))
            console.log("filteredOperationsData :", filteredOperationsData)
            const tableData = filteredOperationsData?.map((operation, i) => {
                const client = usersData.find(user=>user.id===operation.client)
                return {
                    id: operation.id,
                    radication: operation.radication,
                    operation_type_id: operation.operation_type,
                    client:`${client.name} ${client.last_name}`,
                    etd: formatDate(operation.ETD),
                    eta: formatDate(operation.ETA),
                }
            });
            setItems(tableData)
        })
    },[operationsOnCurrentStep])

    const actions = [
        {
            name: 'Seleccionar',
            icon: 'View',
            handle: (item) => {
                setSelectedOperation({id:item.id, radication:item.radication})
                setOpen(false)
            }
        },
    ]

    return (
    <StyledModal
        open={open}
        onClose={()=>setOpen(false)}
        closeButton
    >
        <Modal.Header>
            <h3>Operaciones</h3>
        </Modal.Header>
        <Modal.Body>
        {
            items?.length > 0 ?
            <Table columns={columns} items={items} actions={actions} searchBar={false} excel={false}/> :
            <p>No hay operaciones</p>
        }
        </Modal.Body>
    </StyledModal>
  )
}
