import React, { useEffect, useState } from 'react'
import Input from '../../../components/Input/Input'
import { styled } from '@nextui-org/react'
import { useSelector } from 'react-redux'
import { format, formatISO, parse, parseISO } from 'date-fns'
import FormInput from '../../../components/FormInput/FormInput'
import { FormProvider, useForm } from 'react-hook-form'
import Button from '../../../components/Button/Button'
import toast from '../../../components/Toast/ToastTypes'
import { getLeadTimes, postOrEditLeadTimes } from '../../../services/operations.services'
import { getServiceSplitRadication } from '../../../services/quotation.services'
import Icon from '../../../components/Icon/Icon'

const Container = styled("div",{
    background:'white',
    borderRadius:'8px',
    boxShadow:'$elevation_1',
    padding:'30px',
    '.header':{
        display:'flex',
        justifyContent:'space-between',
        borderBottom:'4px solid $primary_500',
    },
    '.formContainer':{
        display:'grid',
        margin:'30px 0',
        gridTemplateColumns:"auto auto auto",
        gap:'20px',
    },
    "@media (min-width: 1900px)": {
        ".formContainer": {
          gridTemplateColumns:"auto auto auto auto",
        },
      },
})

function formatDate(date){
    const dateObject = parseISO(date)
    if (dateObject && !isNaN(dateObject)){
        return format(dateObject,"yyyy-MM-dd")
    } else return ""
}

export default function LeadTimes({selectedOperation}) {
    const token = useSelector(state => state.user.token)
    const { formState: { errors, isValid }, reset, handleSubmit, ...methods } = useForm();

    useEffect(()=>{
        getData()
    },[selectedOperation])
    console.log("FORM :",methods.watch())

    function getData(){
        const asyncTasks = [getLeadTimes(token), getServiceSplitRadication(selectedOperation?.radication, token)]
        Promise.all(asyncTasks).then(async res => {
            const leadTimesData = await res[0].json();
            const searchServiceData = await res[1].json()
            const serviceId = searchServiceData?.results[0]?.id
            const selectedLeadTimes = leadTimesData.find(item => item.radication == selectedOperation?.radication)
            console.log("selectedLeadTimes :",selectedLeadTimes)
            if (selectedLeadTimes){
                reset({
                    id:selectedLeadTimes.id,
                    estimated_pickup_date: formatDate(selectedLeadTimes?.estimated_pickup_date),
                    pickup_date: formatDate(selectedLeadTimes?.pickup_date),
                    cargo_received_date: formatDate(selectedLeadTimes?.cargo_received_date),
                    etd: formatDate(selectedLeadTimes?.etd),
                    atd: formatDate(selectedLeadTimes?.atd),
                    eta: formatDate(selectedLeadTimes?.eta),
                    ata: formatDate(selectedLeadTimes?.ata),
                    estimated_delivery_date: formatDate(selectedLeadTimes?.estimated_delivery_date),
                    actual_delivery_date: formatDate(selectedLeadTimes?.actual_delivery_date),
                    cargo_handover_date: formatDate(selectedLeadTimes?.cargo_handover_date),
                    shipment_eta: formatDate(selectedLeadTimes?.shipment_eta),
                    shipment_etd: formatDate(selectedLeadTimes?.shipment_etd),
                    customs_entry_date: formatDate(selectedLeadTimes?.customs_entry_date),
                    customs_cleared_date: formatDate(selectedLeadTimes?.customs_cleared_date),
                    service:serviceId,
                })
            } else reset({
                estimated_pickup_date: "",
                pickup_date: "",
                cargo_received_date: "",
                etd: "",
                atd: "",
                eta: "",
                ata: "",
                estimated_delivery_date: "",
                actual_delivery_date: "",
                cargo_handover_date: "",
                shipment_eta: "",
                shipment_etd: "",
                customs_entry_date: "",
                customs_cleared_date: "",
                service:serviceId,
            })
        })
    }

    function onSubmit(formData){
        function formatSubmitDate(date){
            if (date){
                const fechaString = date;
                const hora = "05:00:00";
                const fechaObjeto = parse(fechaString, 'yyyy-MM-dd', new Date());
                const fechaConHora = new Date(`${format(fechaObjeto, 'yyyy-MM-dd')}T${hora}`);
                const fechaISO = formatISO(fechaConHora);
                return fechaISO
            } else return null
        }

        const submitData = {
            estimated_pickup_date: formatSubmitDate(formData.estimated_pickup_date),
            pickup_date: formatSubmitDate(formData.pickup_date),
            cargo_received_date: formatSubmitDate(formData.cargo_received_date),
            etd: formatSubmitDate(formData.etd),
            atd: formatSubmitDate(formData.atd),
            eta: formatSubmitDate(formData.eta),
            ata: formatSubmitDate(formData.ata),
            estimated_delivery_date: formatSubmitDate(formData.estimated_delivery_date),
            actual_delivery_date: formatSubmitDate(formData.actual_delivery_date),
            cargo_handover_date: formatSubmitDate(formData.cargo_handover_date),
            shipment_eta: formatSubmitDate(formData.shipment_eta),
            shipment_etd: formatSubmitDate(formData.shipment_etd),
            customs_entry_date: formatSubmitDate(formData.customs_entry_date),
            customs_cleared_date: formatSubmitDate(formData.customs_cleared_date),
            service:formData.service,
            id:formData.id,
        }
        if (selectedOperation.id){
            postOrEditLeadTimes(token,submitData,selectedOperation.id).then(res=>{
                toast("success","Fechas guardadas")
                getData()
            }).catch(err => {toast('error',"No se pudieron guardar las fechas"); console.log("err :",err)})
        } else {
            postOrEditLeadTimes(token,submitData).then(res=>{
                toast("success","Fechas guardadas")
            }).catch(err => {toast('error',"No se pudieron guardar las fechas"); console.log("err :",err)})
        }
        
    }

    return (
    <FormProvider {...methods} >
        <form onSubmit={handleSubmit(onSubmit)}>
            <Container>
                <div className='header'>
                    <h3>Lead times</h3>
                    <Icon name='Calendar' size='30px'/>
                </div>
                <div className='formContainer'>
                    <FormInput name="estimated_pickup_date" type="date" label="Estimated pickup date" helpMessage="Fecha estimada de recogida" />
                    <FormInput name="pickup_date" type="date" label="Pickup date" helpMessage="Fecha de recogida" />
                    <FormInput name="cargo_received_date" type="date" label="Cargo received date" helpMessage="Fecha en la que se recibió la carga" />
                    {/* <FormInput name="shipment_etd" type="date" label="Shipment ETD" helpMessage="Fecha estimada de salida del envío" /> */}
                    <FormInput name="etd" type="date" label="ETD" helpMessage="Fecha estimada de salida" />
                    <FormInput name="atd" type="date" label="ATD" helpMessage="Fecha real de salida" />
                    {/* <FormInput name="shipment_eta" type="date" label="Shipment ETA" helpMessage="Fecha estimada de llegada del envío" /> */}
                    <FormInput name="eta" type="date" label="ETA" helpMessage="Fecha estimada de llegada" />
                    <FormInput name="ata" type="date" label="ATA" helpMessage="Fecha real de llegada" />
                    <FormInput name="customs_entry_date" type="date" label="Customs entry date" helpMessage="Fecha de entrada en aduana" />
                    <FormInput name="customs_cleared_date" type="date" label="Customs cleared date" helpMessage="Fecha de liberación de aduana" />
                </div>
                <div style={{margin:'20px', display:'flex',justifyContent:'right'}}>
                    <Button 
                        disabled={!selectedOperation}
                        text={selectedOperation?"Guardar fechas":"No hay operacion seleccionada"}
                        type="Submit"
                        textColor={'white'}
                        bgColor={'primary_300'} 
                        bold
                    />
                </div>
            </Container>
        </form>
    </FormProvider>
  )
}
