import React, { useState } from 'react';
import { styled } from '@nextui-org/react';
import { AutorizacionDatosEstudiosAterceros } from '../../../assets/Files/Documents/AutorizacionDatosEstudiosATerceros';
import items from './Items';

export default function RenderDocument() {

  // const [file,setFile] = useState('')

  const PDFContainer = styled('div', {
    height: '85vh',
    margin: '20px',
  });

  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  //console.log('id :',id)
  let file = '';
  items.forEach(document => {
    if (id == document.id) {
      file = document.Base64;
    }
  });
  return (
    <PDFContainer>
      <object
        data={"data:application/pdf;base64," + file}
        type="application/pdf"
        width="100%"
        height="100%"
      >
        <p>
          Tu navegador no puede mostrar este archivo PDF. Puedes
          descargarlo
          <a href={"data:application/pdf;base64," + AutorizacionDatosEstudiosAterceros} download>aquí.</a>

        </p>
      </object>
    </PDFContainer>
  );
}
