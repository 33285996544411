import React from 'react'
import InputTable from '../../../components/InputTable/InputTable'



export default function ProviderInputTable() {
    const columns = [
        {name:"Proveedor", type:'text'},
        {name:"V/R USD", type:'number'},
        {name:"Shipping", type:'number'},
        {name:"Total", type:'number'},
        {name:"FOB", type:'number'},
        {name:"Arancel", type:'number'},
        {name:"IVA", type:'number'},
        {name:"Declaracion", type:'number'},
    ]
    return (
        <div>
            <InputTable tableName={'provider_costs'} columns={columns}/>
        </div>
    )
}
