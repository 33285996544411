import React, { useState } from 'react'
import { styled } from '@nextui-org/react';
import { Radio } from '@nextui-org/react';

import MailForm from './MailForm';
import WhatsAppForm from './WhatsappForm';
import { useEffect } from 'react';
import { getOperations } from '../../../services/operations.services';
import { useSelector } from 'react-redux';

const StyledContainer = styled("div", {
    display: 'flex',
    flexDirection: 'column',
    margin: '30px',
    gap: '15px',
    backgroundColor: '$neutral_50',
    color: '$primary_300',
    borderRadius: '8px',
    boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)',
    padding: '40px',
    "@media(max-width:1200px)":{
        margin: '5px',
        padding: '5px',
    }
})



export default function Notificate({ selectedOperation }) {
    return (
        <StyledContainer>
            <MailForm selectedOperation={selectedOperation} />
        </StyledContainer>
    )
}
