import React, { useEffect, useState } from 'react';
import { styled } from '@nextui-org/react';
import theme from '../../theme';
import { FormControl, MenuItem, Select as Sel } from '@mui/material';

const Select = ({
    label,
    options,
    required = false,
    onChange,
    name,
    value = '',
    maxWidth,
    disabled = false,
}) => {
    const [selected, setSelected] = useState('')

    const StyledContainer = styled(FormControl, {
        [`.${theme} &`]: {
        }
    })

    const StyledSelect = styled(Sel, {
        [`.${theme} &`]: {
            borderRadius: '8px',
            border: "solid 1px $primary_300",
            transition: "border 0.25s ease",
            ['fieldset']: {
                border: '0rem'
            },
            ['.MuiSelect-select']: {
                fontFamily: 'Poppins, sans-serif'
            },
        }
    })

    const StyledMenuItem = styled(MenuItem, {
        [`.${theme} &`]: {
            fontFamily: 'Poppins, sans-serif',
            maxWidth: `${maxWidth}`,
            whiteSpace: 'pre-wrap'
        }
    })

    const StyledLabel = styled('div', {
        [`.${theme} &`]: {
            color: '$primary_500',
            fontSize: '0.875rem',
            paddingLeft: '0.25rem',
            marginBottom: '0.375rem',
            ['span']: {
                color: '#ff676c'
            }
        }
    })

    const handleChange = (event) => {
        setSelected(event.target.value)
        onChange(event)
    }

    useEffect(() => {
        setSelected(value)
    }, []);

    return (
        <StyledContainer fullWidth size='small'>
            <StyledLabel>
                {label} {required ? <span>*</span> : ''}
            </StyledLabel>
            <StyledSelect
                value={selected}
                onChange={onChange ? handleChange : undefined}
                name={name}
                disabled={disabled}

            >
                <StyledMenuItem disabled value=''>
                    <em>Seleccionar</em>
                </StyledMenuItem>
                {
                    options.map((option, i) =>
                        <StyledMenuItem key={i} value={option.value}>{option.name}</StyledMenuItem>
                    )
                }
            </StyledSelect>
        </StyledContainer >
    );
}

export default Select;
