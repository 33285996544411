import React, { useEffect } from "react";
import { useState } from "react";
import InputTable from "../../../../components/InputTable/InputTable";
import { useForm, useFormContext } from "react-hook-form";

export default function PackagingList({trigger}){

    const tableName = 'packaging_list'
    const methods = useFormContext()
    const {setError, clearErrors } = useFormContext()
    const [errors, setErrors] = useState({})
    const [totalQty, setTotalQty] = useState(0)
    const [totalCbm, setTotalCbm] = useState(0)
    const [totalWeight, setTotalWeight] = useState(0)

    const columns = [
        {name:"#", type:'text'},
        {name:"Descripcion", type:'text'},
        {name:"Qty", type:'number'},
        {name:"Largo(m)", type:'number'},
        {name:"Ancho(m)", type:'number'},
        {name:"Alto(m)", type:'number'},
        {name:"Volumen por pieza(m³)", type:'number'},
        {name:"CBM(m³)", type:'number'},
        {name:"Peso por pieza(Kg)", type:'number'},
        {name:"Peso total(Kg)", type:'number'},
        {name:"Estiba", type:'select', options:[{label:'SI',value:true},{label:'No',value:false}]},
      ]

    const defaultData = {
        itemId:9999,
        '#':'',
        Descripcion: '',
        Qty: '',
        Largo: '',
        Ancho: '',
        'Volumen por pieza': '',
        CBM: '',
        'Peso por pieza':'',
        'Peso total':'',
        Estiba:'',
    }

    function setTotals(){
        let tableTotalQty = 0;
        let tableTotalCbm = 0;
        let tableTotalWeight = 0;
        const items = methods.getValues(tableName)
        items?.map((row, index) => {
            const volume = row['Ancho(m)']*row['Largo(m)']*row['Alto(m)']
            const totalVolume = volume * row.Qty
            const totalWeight = row['Peso por pieza(Kg)']*row.Qty
            tableTotalQty += row.Qty
            tableTotalCbm += Number(row['CBM(m³)'])
            tableTotalWeight += totalWeight
            
        })
        console.log("tableTotalCbm :",tableTotalCbm)
        setTotalQty(tableTotalQty)
        setTotalCbm(tableTotalCbm)
        setTotalWeight(tableTotalWeight)
        methods.setValue('shipping_information.volume', tableTotalCbm)
        methods.setValue('total_weight_kg', tableTotalWeight)
    }
    React.useEffect(() => {
        const subscription = methods.watch((value, { name, type }) => {
            // console.log("name :",name)
            if (name?.startsWith(tableName)){
                let tableTotalQty = 0;
                let tableTotalCbm = 0;
                let tableTotalWeight = 0;
                const items = value[tableName]
                let errors = {}
                items?.map((row,index) => {
                    const volume = row['Ancho(m)']*row['Largo(m)']*row['Alto(m)']
                    const totalVolume = volume*row.Qty
                    const totalWeight = row['Peso por pieza(Kg)']*row.Qty
                    if (
                        name===`${tableName}[${index}].Ancho(m)`||
                        name===`${tableName}[${index}].Largo(m)`||
                        name===`${tableName}[${index}].Alto(m)`
                    ) { methods.setValue(`${tableName}[${index}].Volumen por pieza(m³)`, volume?.toFixed(2))}
                    if (
                        name === `${tableName}[${index}].Volumen por pieza(m³)`||
                        name===`${tableName}[${index}].Qty`
                    ) { methods.setValue(`${tableName}[${index}].CBM(m³)`, totalVolume?.toFixed(2))}
                    if (
                        name===`${tableName}[${index}].Peso por pieza(Kg)`||
                        name===`${tableName}[${index}].Qty`
                    ) { methods.setValue(`${tableName}[${index}].Peso total(Kg)`, totalWeight?.toFixed(2))}

                    tableTotalQty += row.Qty
                    tableTotalCbm += totalVolume
                    tableTotalWeight += totalWeight
                    
                    //validaciones
                    if ( row['Descripcion'] && (!row['Qty'] || !row['Ancho(m)'] || !row['Alto(m)'] || !row['Largo(m)'] || !row['Peso por pieza(Kg)'] || !row['Volumen por pieza(m³)'])){
                        if (!errors[tableName]) {
                            errors[tableName] = {};
                        }
                        if (!errors[tableName][row.itemId]) {
                            errors[tableName][row.itemId] = {};
                        }
                        setError("packagingList" + index, {
                            type: "manual",
                            message: "Valores vacios en packaging list, fila : " + index+1,
                        })
                        if (!row['Qty']){errors[tableName][row.itemId].Qty = 'Campo requerido';}
                        if (!row['Ancho(m)']){errors[tableName][row.itemId]['Ancho(m)'] = 'Campo requerido';}
                        if (!row['Alto(m)']){errors[tableName][row.itemId]['Alto(m)'] = 'Campo requerido';}
                        if (!row['Largo(m)']){errors[tableName][row.itemId]['Largo(m)'] = 'Campo requerido';}
                        if (!row['Peso por pieza(Kg)']){errors[tableName][row.itemId]['Peso por pieza(Kg)'] = 'Campo requerido';}
                        if (!row['Volumen por pieza(m³)']){errors[tableName][row.itemId]['Volumen por pieza(m³)'] = 'Campo requerido';}
                        console.log("Errors :",errors)
                        setErrors(errors)
                    } else {
                        setErrors({})
                        console.log("No hay error")
                        clearErrors("packagingList" + index)
                    }
                })
                setTotals()
                // setTotalQty(tableTotalQty)
                // setTotalCbm(tableTotalCbm)
                // setTotalWeight(tableTotalWeight)
                trigger()
            }
        });
        return () => subscription.unsubscribe();
    }, [methods.watch]);
    
    useEffect(()=>{
        setTotals()
    },[])

    return <div>
        <InputTable tableName={tableName} columns={columns} addRowText='Agregar carga +' defaultData={defaultData} errors={errors}/>
        <div style={{display:'flex', justifyContent:'space-around'}}>
            <h4>Total Qty : {totalQty}</h4>
            <h4>Total Cmb : {totalCbm}</h4>
            <h4>Total Weight : {totalWeight?.toFixed(2)}</h4>
        </div>
    </div>

}