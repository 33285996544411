import { styled } from "@nextui-org/react";
import { FormProvider, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import Button from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";
import { Step, StepLabel, Stepper } from "@mui/material";
import Loads from "./loads/Loads";
import ViewsHandler from "../../components/ViewsHandler/ViewsHandler";
import Containers from "./Containers/Containers";
import theme from "../../theme";
import Results from "./results/Results";

const Container = styled("div", {
  background: "white",
  margin: "20px",
  padding: "30px",
  borderRadius: "8px",
  boxShadow: "$elevation_1",
});
const loadDefaultValues = {
  name: "",
  length: "",
  width: "",
  height: "",
  qty: 1,
  color:"#5E6EAB",
  weight_per_piece: "",
  total_weight: "",
  volume: "",
};
const StyledStep = styled(Step, {
  [`.${theme} &`]: {
    [".MuiStepLabel-root"]: {
      position: "relative",
      zIndex: "1",
      [".MuiStepLabel-iconContainer"]: {
        backgroundColor: "$primary_50",
        ["svg"]: {
          borderRadius: "1rem",
          color: "$primary_300",
          ["circle"]: {
            color: "$primary_300",
          },
          ["text"]: {
            fill: "$neutral_50",
          },
        },
      },
      [".MuiStepLabel-iconContainer.Mui-disabled"]: {
        backgroundColor: "$primary_50",
        ["svg"]: {
          border: "solid 1px $primary_300",
          borderRadius: "1rem",
          ["circle"]: {
            color: "$primary_50",
          },
          ["text"]: {
            fill: "$primary_300",
          },
        },
      },
      [".MuiStepLabel-label, .MuiStepIcon-text"]: {
        fontFamily: "inherit",
      },
      [".MuiStepLabel-label.Mui-completed, .MuiStepLabel-label.Mui-active"]: {
        color: "$primary_300",
      },
      [".MuiStepLabel-label.Mui-disabled"]: {
        color: "$primary_100",
      },
    },
    [".MuiStepConnector-root"]: {
      zIndex: "0",
      left: "-50%",
      right: "50%",
      ["span"]: {
        border: "solid 1.5px $primary_300",
      },
    },
  },
});

export default function Calculator3D() {
  const {setValue, getValues, reset, ...methods } = useForm({
    defaultValues: {
      loads:{
        // 0: loadDefaultValues,
      },
      containers:{
        
      },
    },
  });
  methods.setValue = setValue;
  methods.getValues = getValues;
  methods.reset = reset;
  const [activeStep, setActiveStep] = useState(0);
  const formValues = methods.watch();

    useEffect(() => {
        const subscription = methods.watch((value, { name, type }) => {
            console.log("name :",name)
            Object.keys(value.loads).forEach((key) => {
              if ((name == "loads."+key+".qty") || name == "loads."+key+".weight_per_piece") {
                  const { qty, weight_per_piece } = value.loads[key];
                  const total_weight = qty * weight_per_piece;
                  if (!isNaN(total_weight)){
                    setValue(`loads.${key}.total_weight`, total_weight);
                  }
              }
              if ((name == "loads."+ key +".qty" || name =="loads."+key+".length" || name =="loads."+ key +".width" || name =="loads."+ key +".height")){
                const { length, width, height, qty } = value.loads[key];
                const volume = (length * width * height * qty) / 1000000;
                console.log("value.loads[key] :",value.loads[key])
                if (!isNaN(volume)){
                  setValue(`loads.${key}.volume`, volume);
                }
              }
        });
    });
        return () => subscription.unsubscribe();
    }, [methods.watch]);

  function calculate(){
    const form = getValues()
    Object.keys(form).forEach((key) => {
      const { length, width, height, qty, weight_per_piece } = form[key];
      const total_weight = qty * weight_per_piece;
      const volume = (length * width * height * qty) / 1000000;

      if (!isNaN(total_weight)) {
        setValue(`${key}.total_weight`, total_weight);
      }
      if (!isNaN(volume)) {
        setValue(`${key}.volume`, volume);
      }
    });
  }

  useEffect(() => {
    calculate()
  }, []);
  console.log("FORM :",formValues)
  const steps = [
    { value: 'loads', name: 'Loads' },
    { value: 'containers', name: 'Containers' },
    { value: 'results', name: 'Results' },
  ]
  const handleBack = () => {
    setActiveStep((preActiveStep) => preActiveStep - 1);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleNext = () => {
    setActiveStep((preActiveStep) => preActiveStep + 1);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
    <Container>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((step, i) => (
          <StyledStep key={i}>
            <StepLabel>{step.name}</StepLabel>
          </StyledStep>
        ))}
      </Stepper>
      <div
        style={{
          display: "flex",
          borderBottom: "3px solid #292E68",
          justifyContent: "space-between",
          marginBottom:'20px'
        }}
      >
        <h3>Calculadora de cargas 3d</h3>
        <Icon name="Containers" size="37px" />
      </div>
      <FormProvider {...methods}>
        <form>
          <ViewsHandler activeView={activeStep}>
            <Loads/>
            <Containers/>
            <Results/>
          </ViewsHandler>
        </form>
        
      </FormProvider>
    </Container>
    <div style={{margin:'20px', display:'flex', width:'100%', justifyContent:'center', gap:'40px'}}>
      <Button text="< Anterior" disabled={activeStep==0} bold bgColor="primary_200" textColor="white" width="200px" handler={()=>handleBack()}/>
      <Button text="Siguiente >" disabled={activeStep==2} bold bgColor="primary_200" textColor="white" width="200px" handler={()=>handleNext()}/>
    </div>
    </>
  );
}
