import React, { useState, useEffect } from 'react';
import { useFetch } from '../../../../hooks/useFetch';
import { URL } from '../../../../constants';
import { useSelector } from "react-redux";
import { bgColorStates, textColorStates } from './../../../../constants';
import { Loading } from '@nextui-org/react';
import { useNavigate } from 'react-router-dom';

// Components
import Table from '../../../../components/Table/Table';
const loading = false;

const mockData = {
    services: [
        {
            id: 1,
            radication: 12345,
            loads: [
                {
                    load_type: 'Carga General',
                },
            ],
            created_at: '2023-09-13T08:01:22Z',
            users: [
                {
                    role: ['Asesores'],
                    name: 'Juan',
                    last_name: 'Perez',
                },
            ],
            amount: 1000, // Valor
            payment: 'PSE', // Pago
            state: 'Pagado',

        },
        {
            id: 2,
            radication: 12346,
            loads: [
                {
                    load_type: 'Carga Proyecto',
                },
            ],
            created_at: '2023-09-14T08:01:22Z',
            users: [
                {
                    role: ['Asesores'],
                    name: 'Maria',
                    last_name: 'Gomez',
                },
            ],
            amount: 2000, // Valor
            payment: 'Tarjeta de crédito', // Pago
            state: 'Pendiente',
        },
        {
            id: 3,
            radication: 12347,
            loads: [
                {
                    load_type: 'Carga General',
                },
            ],
            created_at: '2023-09-15T08:01:22Z',
            users: [
                {
                    role: ['Asesores'],
                    name: 'Carlos',
                    last_name: 'Rodriguez',
                },
            ],
            amount: 3000, // Valor
            payment: 'Efectivo', // Pago
            state: 'Pagado',
        },
        {
            id: 4,
            radication: 12348,
            loads: [
                {
                    load_type: 'Carga Proyecto',
                },
            ],
            created_at: '2023-09-16T08:01:22Z',
            users: [
                {
                    role: ['Asesores'],
                    name: 'Ana',
                    last_name: 'Martinez',
                },
            ],
            amount: 4000, // Valor
            payment: 'PSE', // Pago
            state: 'Pendiente',
        },
        {
            id: 5,
            radication: 12349,
            loads: [
                {
                    load_type: 'Carga General',
                },
            ],
            created_at: '2023-09-17T08:01:22Z',
            users: [
                {
                    role: ['Asesores'],
                    name: 'Luis',
                    last_name: 'Hernandez'
                },
            ],
            amount: 5000, //Valor
            payment: 'Tarjeta de crédito', //Pago
            state: 'Pagado'
        }
    ],
};

const TablePayments = ({ filterType, filterSearch }) => {
    const data = mockData;
    const navigate = useNavigate()
    const token = useSelector((state) => state.user.token);

    //const { data, loading } = useFetch(URL + 'service/', { 'Authorization': 'Token ' + token });
    const [items, setItems] = useState([])

    const downloadQuotation = (item) => {
        //log('download')
    }

    const columns = [
        { name: 'Acciones', uid: 'actions', type: 'action' },
        { name: 'ID radicado', uid: 'idRadication', type: 'text' },
        { name: 'Carga', uid: 'load', type: 'text' },
        { name: 'Fecha', uid: 'date', type: 'date' },
        { name: 'Valor', uid: 'amount', type: 'text' },
        { name: 'Pago', uid: 'payment', type: 'text' },
        { name: 'Asesor', uid: 'advisor', type: 'text' },
        { name: 'Estado', uid: 'state', type: 'badge' }
    ]

    const actions = [
        {
            name: 'Ver detalle',
            icon: 'View',
            handle: (item) => navigate(`/services/detail/chat?radication=${item.idRadication}&tab=chat`)
        },
        {
            name: 'Descargar',
            icon: 'Download',
            handle: downloadQuotation
        }
    ]

    const getListAdvisors = (users) => {
        let list = [];
        users.forEach(user => { if (user.role[0] === 'Asesores') { list.push(` ${user.name} ${user.last_name}`) } })

        return list.toLocaleString()
    }

    useEffect(() => {
        if (!loading) {
            let dataTable;
            if (data?.services?.length > 0) {
                dataTable = data.services
                    .filter(service => {
                        if (service.state !== 'Cotizado' && filterType === "Seleccionar")
                            return true;
                        else if (service.state !== 'Cotizado' && filterType === "Carga general")
                            return service.loads[0].load_type === 'Carga General'
                        else if (service.state !== 'Cotizado' && filterType === "Carga proyecto")
                            return service.loads[0].load_type === 'Carga Proyecto'
                    })
                    .filter(service => {
                        if (filterSearch === '')
                            return true;
                        else
                            return service.radication.toString().startsWith(filterSearch)
                    })
                    .map(service => ({
                        id: service.id,
                        idRadication: service.radication,
                        load: service.loads.length > 0 ? service.loads[0].load_type : '',
                        date: new Date(service.created_at),
                        amount: service.amount,
                        payment: service.payment,
                        advisor: getListAdvisors(service.users),
                        state: {
                            name: service.state,
                            bgColor: bgColorStates[service.state],
                            textColor: textColorStates[service.state]
                        }
                    }));
            } else {
                dataTable = data
            }
            setItems(dataTable);
        }
    }, [loading, filterType, filterSearch]);
    //log('items :',items)
    return (
        <div className='h-100'>
            {
                loading ?
                    <div className='d-flex justify-content-center'>
                        <Loading></Loading>
                    </div>
                    :
                    items.length > 0 ?
                        <Table columns={columns} items={items} actions={actions}></Table>
                        :
                        <div className='d-flex justify-content-center'>No hay servicios finalizados.</div>
            }
        </div>
    );
}

export default TablePayments;
