import { URL } from "../constants"

export const getWeightByCountry = async (token) => {
    const response = await fetch(`${URL.get()}weight-by-country/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getWeightTrendByUser = async (token, startDate, endDate) => {
    const response = await fetch(`${URL.get()}weight-trend-by-user-per-month/${startDate}/${endDate}/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}


export const getWeightTrendByShippingMethod = async (token, startDate, endDate) => {
    const response = await fetch(`${URL.get()}weight-by-shipping-method/${startDate}/${endDate}/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}