import React, { useEffect, useState } from 'react'
import { Modal, styled } from '@nextui-org/react';
import { useSelector } from 'react-redux';
import { getUsers, impersonateUser } from '../../services/user.services';
import Table from '../Table/Table';
import toast from '../Toast/ToastTypes';

const StyledModal = styled(Modal, {
    padding: '1.5rem',
    display:'flex',
    gap:'20px',
    color: '$primary_500 !important',
})

const columns = [
    {name:'Nombre', uid:'name', type:'text'},
    {name:'Apellido', uid:'last_name', type:'text'},
    {name:'Mail', uid:'email', type:'text'},
    {name:'Rol', uid:'role', type:'text'},
    {name:'Activar', uid:'button', type:'button'},
]


export default function ImpersonateModal({ open, setOpen }) {

    const user = useSelector(state => state.user)
    const token = user.token
    const [items, setItems]= useState([])

    function impersonate(user){
        impersonateUser(token, user.id).then((res)=>{
            console.log("res :",res)
            if (res.ok){
                localStorage.setItem("impersonating",true)
                toast("success", `Actuando como usuario : ${user.name} ${user.last_name}`)
                setOpen(false)
            } else {toast("error",`Error : ${res.status}`)}
        })
    }

    useEffect(()=>{
        if (open){
            getUsers(token).then(async res => {
                const usersData = await res.json();
                const usersClients = usersData.filter(user => ['Usuario','Cliente','Client'].includes(user.role?.name))
                const tableData = usersClients.map((client)=>{
                    return {
                        id:client.id,
                        name:client.name,
                        last_name:client.last_name,
                        email:client.email,
                        role:client.role.name,
                        button:{
                            text:"Seleccionar",
                            handler:() => impersonate(client),
                        }
                    }
                })
                setItems(tableData)
            })
        }
    },[])

    return (
        <StyledModal
            closeButton
            aria-label="modal-title"
            open={open}
            onClose={() => setOpen(false)}
            width="fit-content"
        >  
            <h3>Actuar como usuario</h3>
            <Table items={items} columns={columns} excel={false}/>
        </StyledModal>
    );
}