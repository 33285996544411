import { createTheme } from "@nextui-org/react";

class ThemeConfig {
  constructor() {
    this.theme = createTheme({
      type: "light",
      theme: {
        colors: {
          primary_50: '#F3F5FF',
          primary_100: '#AFB7D6',
          primary_200: '#5E6EAB',
          primary_300: '#3D509A',
          primary_400: '#303C90',
          primary_500: '#292E68',
          secondary_50: '#FDF5EB',
          secondary_100: '#F7D7AE',
          secondary_200: '#EEAE5B',
          secondary_300: '#EB9D3A',
          secondary_400: '#A56E29',
          secondary_500: '#8F6023',
          secondary_2_50: '#F0FAF2',
          secondary_2_100: '#C1E9CA',
          secondary_2_200: '#A7E0B4',
          secondary_2_300: '#81D393',
          secondary_2_400: '#67CA7D',
          secondary_2_500: '#3F7B4C',
          neutral_50: '#FFFFFF',
          neutral_100: '#EBEDF0',
          neutral_200: '#DFE2E6',
          neutral_300: '#8993A4',
          neutral_400: '#7A8699',
          neutral_500: '#091E42',
          myColor: '#091E42',
          excel: '#21a366',
          delete: '#FF6B6B',
          gradient: 'linear-gradient(112deg, $blue100 -25%, $pink500 -10%, $purple500 80%)',
          link: '#5E1DAD',
          disabled: '#979dc7',
        },
        shadows: {
          elevation_1: '0px 4px 8px rgba(79, 94, 113, 0.1), 0px 2px 4px rgba(79, 94, 113, 0.11), 0px 0px 2px rgba(79, 94, 113, 0.12);',
          elevation_2: '0px 16px 32px rgba(79, 94, 113, 0.09), 0px 4px 8px rgba(79, 94, 113, 0.1), 0px 2px 4px rgba(79, 94, 113, 0.11), 0px 0px 2px rgba(79, 94, 113, 0.12);',
          elevation_3: '0px 16px 32px rgba(79, 94, 113, 0.08), 0px 8px 16px rgba(79, 94, 113, 0.09), 0px 4px 8px rgba(79, 94, 113, 0.1), 0px 2px 4px rgba(79, 94, 113, 0.11), 0px 0px 2px rgba(79, 94, 113, 0.12);',
          elevation_4: '0px 32px 64px rgba(79, 94, 113, 0.07), 0px 16px 32px rgba(79, 94, 113, 0.08), 0px 8px 16px rgba(79, 94, 113, 0.09), 0px 4px 8px rgba(79, 94, 113, 0.1), 0px 2px 4px rgba(79, 94, 113, 0.11), 0px 0px 2px rgba(79, 94, 113, 0.12);',
        },
        space: {},
        fonts: {},
        components: {
          Button: {
            css: {
              textTransform: 'none',  
            },
          },
        },
        
      },
    });
    this.listeners = [];
  }

  getTheme() {
    return this.theme;
  }

  setTheme(newTheme) {
    this.theme = newTheme;
    this.notifyListeners();
  }

  subscribe(listener) {
    this.listeners.push(listener);
  }

  unsubscribe(listener) {
    this.listeners = this.listeners.filter(l => l !== listener);
  }

  notifyListeners() {
    this.listeners.forEach(listener => listener());
  }
}

const themeConfig = new ThemeConfig();

export const editableTheme = {
  get: () => themeConfig.getTheme(),
  set: (newTheme) => themeConfig.setTheme(newTheme),
  subscribe: (listener) => themeConfig.subscribe(listener),
  unsubscribe: (listener) => themeConfig.unsubscribe(listener),
};
