import React, { useEffect, useState } from "react";
import Button from "../../../components/Button/Button";
import { startOfMonth, format } from "date-fns";
import Input from "../../../components/Input/Input";
import { useSelector } from "react-redux";
import { usePDF } from "react-to-pdf";
import CloropethContainer from "../../../components/Cloropeth/Container";
import { useTranslation } from "react-i18next";
import { styled } from "@nextui-org/react";
import styles from "../../Home/Home.module.scss";
import DatesRangePickerModal from "../../../components/DateRangePicker/DateRangePicker";
import { Modal } from "@mui/material";
import HomeConfig from "../../../components/HomeConfig/HomeConfig";
import DashboardHorizontalBarChart from "../../../components/Graphics/HorizontalChart/DashboardHorizontalChart";
import {getLeadTimes, getSpendByConcept, getSpendByCountry} from "../../../services/kpis.services";
import LeadTimesDash from "./leadTimesDash";

const Container = styled("div", {
  display: "flex",
  width: "90%",
  flexDirection: "column",
  padding: "30px",
  justifyContent: "center",
  gap: "20px",
  background: "white",
  margin: "20px auto",
  borderRadius: "8px",
  boxShadow: "$elevation_1",
});

export default function ServicesDashboard() {
    const { t: translate } = useTranslation("Services");
    const [loadingtop, SetLoadingtop] = useState(0)
    const [kpis, setKpis] = useState({});
    const [error, setError] = useState(false);
    const [loadingData, setLoadingData] = useState(false)
    const getKpis = async () => {
      try {            
        setLoadingData(true)
        SetLoadingtop(0);
        const kpiPromises = [
          getSpendByConcept( token, format(dates.startDate, "yyyy-MM-dd"), format(dates.endDate, "yyyy-MM-dd")),
          getSpendByCountry( token, format(dates.startDate, "yyyy-MM-dd"), format(dates.endDate, "yyyy-MM-dd")).finally(() => {
            SetLoadingtop(100);
            setLoadingData(false);
          }),
          getLeadTimes(token)
        ];
        const kpiResponses = await Promise.all(kpiPromises);
        const updatedKpis = {};
        await Promise.all(
          kpiResponses.map(async (res, index) => {
            const key = ["statistics", "country","lead_times"][index];
            updatedKpis[key] = await res?.json();
          })
        );
        setKpis(updatedKpis);
        setError(false);
      } catch (error) {
        setError(error.message);
        console.error(`Error fetching data: ${error.message}`);
      }
  };

  const [configOpen, setConfigOpen] = useState(false);
  const [openDates, setOpenDates] = useState(false);
  const [dates, setDates] = useState({
    startDate: startOfMonth(new Date()),
    endDate: new Date(),
  });
  const { toPDF, targetRef } = usePDF({ filename: "admin_dashboard.pdf" });

  const token = useSelector((state) => state.user.token);
  const user = useSelector((state) => state.user);
  console.warn(user);
  console.log(user);
  console.log("-------------------------------------------");

  const [loadingPdf, setLoadingPdf] = useState(false);

  useEffect(() => {
    getKpis();
  }, [dates]);

  function downloadPDF() {
    setLoadingPdf(true);
    toPDF().then(() => {
      setLoadingPdf(false);
    }).catch((err) => {
      console.log("error al descargar pdf :", err);
      setLoadingPdf(false);
    });
  }

  console.log("KPIS", kpis);
  const spendByConceptData = kpis?.statistics?.spendbyconcept && Object.keys(kpis?.statistics?.spendbyconcept).map((key) => {
    return {
      name: key,
      Profit: kpis.statistics.spendbyconcept[key],
    };
  }).sort((a,b)=>b.Profit - a.Profit)

  return (
    <Container>
      <div className={styles.dateclass}>
        <div className="dateFilter">
          <span>Fechas</span>
          <div
            className="datePicker"
            onClick={() => {setOpenDates(true)}}
            style={{ display: "flex", gap: "20px", width: "fit-content" }}
          >
            <Input label={"desde"} value={format(dates.startDate, "dd/MM/yyyy")}/>
            <Input label={"hasta"} value={format(dates.endDate, "dd/MM/yyyy")}/>
          </div>
          <DatesRangePickerModal
            aria-labelledby="modal-date-range"
            setDates={setDates}
            open={openDates}
            setOpen={setOpenDates}
          />
        </div>
        <div className={styles.btnContainer}>
          <div className="download">
            <Button
              handler={() => downloadPDF()}
              text={loadingPdf ? "Cargando..." : "download-button"}
              endIcon={{ name: "Download" }}
              outline
              disabled={loadingPdf}
            />
          </div>
          <Button
            text={"config"}
            endIcon={{ name: "Setting" }}
            outline
            handler={() => {configOpen ? setConfigOpen(false) : setConfigOpen(true)}}
          />
          <Modal
            open={configOpen}
            onClose={() => setConfigOpen(false)}
            aria-labelledby="modal-config"
          >
            <HomeConfig setConfigOpen={setConfigOpen} />
          </Modal>
        </div>
      </div>
      <LeadTimesDash/>

      {/* Se pone el id 4 que es andres.rivera y el id  108 que es de yconde enerflex*/}
      {[4,108,27].includes(user.idUser)? (
        <iframe
          src="https://docs.google.com/spreadsheets/d/e/2PACX-1vTFhiWmpEDI1mUzlf1BBUiL6SWobW_ceP1lf7VxpEWgc1eeTd0_SYW1nMgovqMb7GFiY-iE5Q1_azOH/pubhtml?widget=true&amp;headers=false"
          style={{
            width: "100%",
            height: "3000px",
            border: "none",
            overflow: "hidden",
          }}
          title="My Google Sheet"
        />
      ) : null}

      {/* Se pone el id 4 que es andres.rivera y el id  144 que es de olga.rincon altipal */}
      {[4,144,27].includes(user.idUser)? (
        <iframe
          src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQuDpkmbqWgzKxm3kRfHV5iKLddWzSu5QUa2zAajFtgxHE6NTYz_At-YZiubg7ZRqbsYomixYawOLD3/pubhtml?widget=true&amp;headers=false"
          style={{
            width: "100%",
            height: "3000px",
            border: "none",
            overflow: "hidden",
          }}
          title="My Google Sheet"
        />
      ) : null}
      <CloropethContainer title={"Mapa"} data={kpis.country} icon="Maritimo"/>
      <p style={{ margin: "15px", color: "grey" }}>Spend por servicio</p>
      <div style={{ width: "100%", minHeight:'600px', margin: "auto" }}>
        {spendByConceptData &&  <DashboardHorizontalBarChart dataKey={"Profit"} data={spendByConceptData}/>}
      </div>
    </Container>
  );
}
