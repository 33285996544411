import { useEffect, useState } from "react"
import { Modal, styled } from "@nextui-org/react"
import format from 'date-fns/format'
import TextEditor from '../../../components/TextEditor/TextEditor'
import { recalculateCO2 } from '../../../services/CO2.services'
import DropZone from '../../../components/DropZone/DropZone'
import { getEmails, getSubject, sendEmail, setSubState } from "../../../services/operations.services"
import Input from '../../../components/Input/Input'
import Button from '../../../components/Button/Button'
import DatePickerModal from '../../../components/DatePicker/DatePicker'
import { useSelector } from "react-redux"
import CreatableInputSelect from "../../../components/Select/CreatableSelect"
import toast from "../../../components/Toast/ToastTypes"



const StyledModal = styled(Modal,{
    background:'$neutral_50',
    color:'$primary_500 !important',
    padding:'10px',
})

export default function ChangeStatusModal({open, setOpen, subStatusesData, selectedStep, currentOperationData, getData}){

    const token = useSelector(state => state.user.token)

    const [date, setDate] = useState(new Date());
    const [time, setTime] = useState(format(new Date(), 'HH:mm'))
    const [note, setNote] = useState("")
    const [emails, setEmails] = useState()
    const [selectedEmails, setSelectedEmails] = useState([])
    const [selectedEmailsCopy, setSelectedEmailsCopy] = useState([])
    const [subject, setSubject] = useState("")
    const [files, setFiles] = useState([]);
    const [disableConfirm, setDisableConfirm] = useState(false)
    const [openDate, setOpenDate] = useState(false);

    useEffect(()=>{
        setDate(new Date())
        setTime(format(new Date(), 'HH:mm'))
    },[open])

    const handleSetSubState = () => {
        const combinedDateTime = new Date(
            date?.getFullYear(),
            date?.getMonth(),
            date?.getDate(),
            parseInt(time?.split(':')[0], 10),
            parseInt(time?.split(':')[1], 10),
        );
        if (currentOperationData.id) {
            const formatDateTime = format(combinedDateTime, "yyyy-MM-dd'T'HH:mm:ssxxx");
            setSubState(token, currentOperationData.id,currentOperationData.radication, selectedStep.id, formatDateTime, selectedEmails,selectedEmailsCopy, subject, note, files).then(async res => {
                setDate(new Date())
                setTime(format(new Date(), 'HH:mm'));
                getData()
                setOpen(false)
                // setUpdateLogs(Math.random())
                // const current=subStatusesData.filter((sub)=>sub.id===subStateRelation.operation_sub_status)
                // const excludedSteps = ['Confirm on board', 'Arrive at final dest', 'Delivery', 'Proof of delivery'];
                // if (!excludedSteps.includes(current[0].name) && excludedSteps.includes(selectedStep.name)) {
                //     await recalculateCO2(currentOperationData.radication);
                // }
                //EMAIL
                let emailSubmitData = {
                    body:note,
                    subject,
                    radication:currentOperationData.radication,
                    to:selectedEmails?.length && selectedEmails?.map(mail => { return mail.value }),
                    cc:selectedEmailsCopy?.length && selectedEmailsCopy?.map(mail => { return mail.value }),
                    attached:files,
                }
                if (emailSubmitData.cc?.length < 1) { emailSubmitData.cc = undefined }
                if (emailSubmitData.bcc?.length < 1) { emailSubmitData.bcc = undefined }
                sendEmail(emailSubmitData, token).then(async res => {
                    setSelectedEmails("")
                    setSelectedEmailsCopy("")
                    setFiles([])
                    setNote("")
                    toast('success', 'Correo enviado')
                }).catch(() => toast('warn', 'Ha habido un error al notificar al usuario'))

            }).catch(err => console.log("err :", err))
        } else toast("warn", "no hay una operacion seleccionada")
        setDisableConfirm(false)
    }
    const handleFiles = (newFiles) => {
        setFiles(newFiles);
    };

    useEffect(()=>{
        setNote(`El subestado de la operación ${currentOperationData?.radication} a cambiado a ${selectedStep?.name} `)
    },[selectedStep])
    console.log("emails :",emails)
    useEffect(() => {
        if(currentOperationData && open){
            getSubject(token, currentOperationData.radication).then(async res => {
                const subject = await res.json()
                setSubject(subject?.subject)
            })
            getEmails(token, currentOperationData.radication).then(async res => {
                const emailsData = await res.json()
                const recipients = []
                const cc = []
                emailsData?.forEach(email => {
                    if (!recipients.includes(email.recipient)) {
                        const cleanRecipient = email.recipient.replace(/[\[\]']+/g, '');
                        recipients.push(cleanRecipient)
                    }
                    if (!cc.includes(email.cc)) {
                        console.log("email.cc :",email.cc)
                        const cleanCC = email.cc.replace(/[\[\]']+/g, '');
                        cc.push(cleanCC)
                    }
                })
                const splitRecipients = recipients?.map(e=>e.split(','))
                const flattenedRecipients = splitRecipients.flat();
                const uniqueRecipients = [...new Set(flattenedRecipients)];

                const splitCC = cc?.map(e=>e.split(','))
                const flattenedCC = splitCC.flat().filter(e=>e);
                let uniqueCC = []
                uniqueCC = [...new Set(flattenedCC)];
                
                console.log("uniqueCC :",uniqueCC)
                setEmails([...uniqueRecipients, ...uniqueCC])
                setSelectedEmails(uniqueRecipients.map(e=>({
                    label:e,
                    value:e,
                })))
                setSelectedEmailsCopy(uniqueCC.map(e=>({
                    label:e,
                    value:e,
                })))
            })
        }
    }, [currentOperationData, open])
      
    return (
        <StyledModal
            closeButton
            open={open}
            onClose={() => {setOpen(false); setEmails([]); setSelectedEmails(); setSelectedEmailsCopy([]); setNote("")}}
            width="700px"
        >
            <Modal.Body>
                <h3>Cambiar estado</h3>
                <p style={{fontSize:'12px'}}>Cambiar subestado de "{currentOperationData.radication}" a "{selectedStep?.name}"</p>
                <div style={{ display: 'flex', gap: '10px' }}>
                    <div onClick={() => { setOpenDate(true) }} style={{ display: 'flex', width: 'fit-content' }}>
                        <label style={{ margin: '7px' }}>Fecha :</label>
                        <Input name="Fecha" value={format(date, "dd/MM/yyyy")} />
                    </div>
                    <Input name="Hora" type={'time'} value={time} onChange={(e) => setTime(e.target.value)} />
                </div>
                <CreatableInputSelect
                    isMulti={true}
                    label={"Destinatarios"}
                    value={selectedEmails}
                    onChange={e => setSelectedEmails(e)}
                    options={emails?.map(email => {
                        return {
                            label: email,
                            value: email,
                        }
                    })}
                />
                <CreatableInputSelect
                    isMulti={true}
                    label={"Copia"}
                    value={selectedEmailsCopy}
                    onChange={e => setSelectedEmailsCopy(e)}
                    options={emails?.map(email => {
                        return {
                            label: email,
                            value: email,
                        }
                    })}
                />
                <Input name="Asunto" label={"Asunto"} type={'text'} value={subject} onChange={(e) => setSubject(e.target.value)} />
                <TextEditor state={note} setState={setNote} />
                <DropZone files={files} handleFiles={handleFiles}/>
            </Modal.Body>
            <Modal.Footer>
                <Button text="Cancelar" handler={() => setOpen(false)} outline />
                <Button disabled={disableConfirm} text="Confirmar" bold outline bgColor={'primary_500'} textColor={'neutral_50'} handler={() => handleSetSubState()} />
            </Modal.Footer>
            <DatePickerModal open={openDate} setOpen={setOpenDate} date={date} setDate={setDate} />
        </StyledModal>
    )
}