import React, { useEffect, useState } from 'react'
import { createOperationStatus, deleteOperationStatus, getOperationStatuses, getSubStatuses } from '../../../services/operations.services';
import { useSelector } from 'react-redux';

//componentes
import Button from '../../../components/Button/Button';
import Table from '../../../components/Table/Table'
import ModifyStateModal from './ModifyStateModal';
import Input from '../../../components/Input/Input';
import toast from '../../../components/Toast/ToastTypes';
import DeleteConfirmationModal from '../../../components/DeleteConfirmationModal/DeleteConfirmationModal';
import { useTranslation } from "react-i18next";




export default function OperationStatesTable() {
   
    const { t: translate } = useTranslation("Admin");
    const columns = [
        {name:'', uid:'actions', type:'action'},
        {name:translate("status"), uid:'name', type:'text'},
        {name:translate("substatus"), uid:'subStatuses', type:'text'},
    ]
    const token = useSelector(state => state.user.token)
    const [items, setItems] = useState([])

    const [stateId, setStateId] = useState()
    const [openModal,setOpenModal] = useState(false);

    const [deleteId,setDeleteId] = useState()
    const [deleteModal,setDeleteModal] = useState(false)

    const [statusName, setStateName] = useState("")

    const actions = [
        {
            name: translate("view/edit"),
            icon: 'Edit',
            type: 'button',
            handle: (e)=>{
            setOpenModal(true)
            setStateId(e.id)
            }
        },
        {
            name:translate("delete"),
            icon: 'Trash',
            type: 'button',
            handle: (e)=>{
                setDeleteId(e.id)
                setDeleteModal(true)
            }
        },
    ];
    function getData(){
        const asyncTasks = [getOperationStatuses(token), getSubStatuses(token)]
        Promise.all(asyncTasks).then(async res=>{
            const statusesData = await res[0].json();
            const subStatusesData = await res[1].json();

            const tableData = statusesData.map(status => {
                const filteredSubStatuses = subStatusesData.filter(subStatus => subStatus.operation_status === status.id)
                let subStatusesNames = filteredSubStatuses.map(sub => sub.name);
                let subStatusesString = subStatusesNames.join(', ');
                return {
                    ...status,
                    subStatuses:subStatusesString,
                }
            })
            setItems(tableData)
        })
    }

    function createState(){
        if (statusName.length){
            createOperationStatus(token,{name:statusName}).then(()=>{
                getData();
            }).catch(err=>{console.log("err :",err)})
        } else {
            toast("warn","Ingresa un nombre para el nuevo estado")
        }
    }

    function deleteState(id){
        deleteOperationStatus(token,id).then(()=>{
            getData()
            setDeleteId()
            setDeleteModal(false)
        }).catch(err => console.log("err :",err))
    }

    useEffect(()=>{
        getData()
    },[])

    return (
        <div style={{margin:'0 100px'}}>
            <div style={{display:'flex',gap:'15px'}}>
                <Input onChange={(e)=>setStateName(e.target.value)}/>
                <Button text={translate("create_new_state")} outline handler={()=>{createState()}}/>
            </div>
            <Table columns={columns} items={items} actions={actions} excel={false} searchBar={false}/>
            <ModifyStateModal open={openModal} setOpen={setOpenModal} stateId={stateId}/>
            <DeleteConfirmationModal open={deleteModal} setOpen={setDeleteModal} handleDelete={()=>{deleteState(deleteId)}}
            text={"Confima que desea eliminar este estado"}
            />
        </div>
    )
}
