import React, { useEffect, useState } from 'react';
import { Collapse, Loading, Modal, Radio, styled, Textarea } from '@nextui-org/react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { getOffers } from '../../../services/quotations.services';
import { useSearchParams } from 'react-router-dom';
import { getLatLng } from '../../../services/map.services';
import { InitMap } from '../../../functions/mapFunctions';
// Components
import Button from '../../../components/Button/Button';
import { getPreliquidations } from '../../../services/preliquidation.services';
import OfferCard from './OfferCard';
import { getAirportsByCountry, getCountriesNew, getPortsByCode } from '../../../services/countries.services';
import { getServiceSplitQuote } from '../../../services/quotation.services';
import { getPreliquidationCosts } from '../../functions';
import Icon from '../../../components/Icon/Icon';
import { getUsers } from '../../../services/user.services';
import { getBusinessTerms } from '../../../services/businessTerms.services';
import { getShippingMethods } from '../../../services/shippingMethods.services';
import theme from '../../../theme';
import TagsModal from '../Quotation/TableQuotation/TagsModal';
import RejectModal from './RejectModal';
import { ROLES } from '../../../constants';

const Container = styled("div", {
    display: 'flex',
    position:'relative',
    flexDirection: 'column',
    background: 'white',
    alignItems: 'center',
    boxShadow:'$elevation_1',
    gap: '20px',
    width: '95%',
    height:'fit-content',
    margin: '3rem auto',
    padding: '20px',
    borderRadius: '8px',
    '.offers':{
        display:'grid',
        gridTemplateColumns:'auto auto',
        gap:'20px',
    },
    '.offersRow':{
        padding:'10px 30px 10px 30px',
        borderRadius:'8px',
        background:'#f9f9f9',
        'b':{
            color:'grey',
        },
    },
    '& .offersRow:nth-child(odd)': {
        background: '#f8f4f9',
    },
    '.cards':{
        marginTop:'10px',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '40px',
    },
    ['.labels']: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: '10px',

    },
    ['.info']: {
        display: 'flex',
        marginLeft: '30px',
        gap: '10px',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    '.tag':{
        color:'grey',
        background:'lightgray',
        fontSize:'14px',
        height:'25px',
        padding:'2px',
        borderRadius:'4px',
    },
    "@media (max-width:1000px)":{
        fontSize:'10px',
    },
    "@media(max-width:1200px)":{
        '.offers':{
            display:'flex',
            flexDirection:'column',
            gap:'20px',
        },
    }

})

const InfoCard = styled("div", {
    margin:'0 20px',
    padding: '10px',
    fontSize:'14px',
    '.title': {
        margin: '10px 0',
    },
    'div':{
        margin:'5px',
        display:'flex',
        borderBottom:'1px solid $neutral_300',
        justifyContent:'space-between',
    },
    "@media (max-width:1000px)":{
        fontSize:'10px',
    },
})

const DataContainer = styled("div", {
    display: 'grid',
    width:'100%',
    gridTemplateColumns: '40% 60%',
    gap: '10px',
    "@media (max-width:1000px)":{
        display:"block",
        fontSize:'10px',
    },
})
const TableContainer = styled("div", {
    gridColumn: 'span 2',
})
const StyledTable = styled("table",{
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: '20px',
    margin:'auto',
    'th, td': {
        'border': '1px solid #ddd',
        'padding': '4px',
        'text-align': 'left',
    },
    'th': {
        'background-color': '#f2f2f2',
    },
    'tr:nth-child(even)': {
        'background-color': '#f9f9f9',
    },
})

const CollapseTitle = styled("div", {
    display:'flex',
    gap:'15px',
    color:'$primary_300',
    fontSize:'20px',   
})
const StyledMap = styled("div",{
    "@media(max-width:1000px)":{
        height:'300px',
        width:'90%',
        margin:'auto'
    }
})
const StyledModal = styled(Modal, {
    display:'flex',
    padding: '20px',
    color: '$primary_500 !important',
    background: '$neutral_50',
})

export default function Costs() {

    const ShipmentContainer = styled("div", {
        width:'100%',
        gridColumn:'span 2',
        display:'flex',
        justifyContent:'space-around',
        alignItems:'center',
        background:theme.colors.primary_300.value,
        color:'white',
        padding:'20px',
        marginBottom:'25px',
        borderRadius:'6px',
        'h3':{margin:0, fontSize:'20px'},
        'span':{fontSize:'12px'},
        "@media(max-width:1000px)":{
            width:'90%',
            margin:'auto',
            padding:'5px',
            'h3':{margin:0, fontSize:'9px'},
            'span':{fontSize:'10px'},
        }
    })

    const [searchParams] = useSearchParams();
    const radication = searchParams.get('radication')
    const quoteId = searchParams.get('id')
    const finished = searchParams.get('finished')
    const user = useSelector((state) => state.user);
    const token = user.token

    const navigate = useNavigate()

    const [loading, setLoading] = useState(true)
    const [items, setItems] = useState([])
    const [clientChose, setClientChose] = useState(false)
    const [RFQData, setRFQData] = useState({})
    const [client, setClient] = useState({})
    const [shippingMethod, setShippingMethod] = useState()
    const [incoterms, setIncoterms] = useState([])
    const [openRejectModal, setOpenRejectModal] = useState(false)
    const [rejectReason, setRejectReason] = useState(false)
    const [note, setNote] = useState("")
    const [openTagsModal, setOpenTagsModal] = useState(false)

    function getData() {
        setLoading(true)
        const asyncTasks = [getOffers(token, radication), getPreliquidations(token), getServiceSplitQuote(quoteId, token), getUsers(token), getShippingMethods(token), getBusinessTerms(token)]
        Promise.all(asyncTasks).then(async res => {
            const offersData = res[0]
            const preliquidationsData = await res[1].json();
            const rfqData = await res[2].json();
            const usersData = await res[3].json();
            const shippingMethodsData = await res[4].json();
            const incotermsData = await res[5].json();
            setIncoterms(incotermsData)
            setRFQData(rfqData)

            const currentShippingMethod = shippingMethodsData.find(method=>method.id === rfqData.shipping.shipping_method)?.name

            setShippingMethod(currentShippingMethod)

            let clientId = rfqData.users.find(user => ['Usuario','Cliente'].includes(user.user?.groups[0]))?.user?.user
            if (!clientId){clientId = rfqData?.users[0]?.user?.user}

            const clientData = usersData.find(user => user.id === clientId)

            setClient(clientData)
            // console.log("offersData :",offersData)
            let formattedData = []
            if (Object.keys(offersData)?.length) {
                formattedData = Object.keys(offersData).map((providerMail) => {
                    const offerData = offersData[providerMail]
                    const offers = offerData.quotations.map((offer,i)=>{
                        // console.log("offer :",offer)
                        if (offer.customer_approval) {
                            setClientChose(true)
                        }
                        const lastPreliquidationId = offer.preliquidation_ids[offer.preliquidation_ids?.length - 1]
                        const lastPreliquidation = preliquidationsData.find(prel => prel.id === lastPreliquidationId)
                        console.log("lastPreliquidation :",lastPreliquidation)
                        const { totalCost } = getPreliquidationCosts(lastPreliquidation)
                        const totalsByItemType = {};
                        lastPreliquidation?.items?.forEach(item => {
                            const item_type = item.item_type;
                            const total_in_usd = item.total_in_usd;
                            if (!totalsByItemType[item_type]) {
                                totalsByItemType[item_type] = total_in_usd;
                            } else {
                                totalsByItemType[item_type] += total_in_usd;
                            }
                        });
                        return {
                            ...offer,
                            air_cargo: lastPreliquidation.air_cargo,
                            totalsByItemType: totalsByItemType,
                            option: i + 1,
                            cost: '$' + totalCost.toFixed(2),
                        }
                    })
                    return {
                        providerMail,
                        organization: offerData.organization,
                        offers,
                    }
                })
                setItems(formattedData)
            }
            switch (currentShippingMethod) {
                case 'ground':
                    const asyncTasksGround = [
                        getLatLng(rfqData.shipping.origin_boarding_terminal.city.country.name, token),
                        getLatLng(rfqData.shipping.destination_boarding_terminal.city.country.name, token)
                    ]
                    Promise.all(asyncTasksGround).then(async responses => {
                        const originCoordsData = {
                            lat: responses[0].location.lat,
                            lng: responses[0].location.lng,
                            label: 'Origen',
                        }
                        const destinationCoordsData = {
                            lat: responses[1].location.lat,
                            lng: responses[1].location.lng,
                            label: 'Destino',
                        }
                        InitMap([originCoordsData, destinationCoordsData] ,'rfqMap')
                    })
                    
                    break;
                case 'air':
                    const asyncTasksAir = [
                        getAirportsByCountry(token, rfqData.shipping.origin_boarding_terminal.city.country.name),
                        getAirportsByCountry(token, rfqData.shipping.destination_boarding_terminal.city.country.name)
                    ]
                    Promise.all(asyncTasksAir).then(async res => {
                        const originAirports = await res[0].json();
                        const destinationAirports = await res[1].json();
                        const originPort = originAirports?.find(port => port.name === rfqData.shipping.origin_boarding_terminal.city.name)
                        const destinationPort = destinationAirports?.find(port => port.name === rfqData.shipping.destination_boarding_terminal.city.name)
                        
                        const originCoordinates = originPort._geoloc
                        const destinationCoordinates = destinationPort._geoloc
                        const originCoordsData = {
                            lat: originCoordinates.lat,
                            lng: originCoordinates.lng,
                            label: 'Origen',
                        }
                        const destinationCoordsData = {
                            lat: destinationCoordinates.lat,
                            lng: destinationCoordinates.lng,
                            label: 'Destino',
                        }
                        InitMap([originCoordsData, destinationCoordsData],'rfqMap')
                    })
                    break;
                default:
                    getCountriesNew(token).then(async res => {
                        const countriesData = await res.json()
                        const originCountry = countriesData.find(country => country.name?.toLowerCase() === rfqData.shipping.origin_boarding_terminal.city.country.name?.toLowerCase())
                        const destinationCountry = countriesData.find(country => country.name?.toLowerCase() === rfqData.shipping.destination_boarding_terminal.city.country.name?.toLowerCase())

                        const asyncTasksOcean = [
                            getPortsByCode(token, originCountry?.code),
                            getPortsByCode(token, destinationCountry?.code),
                        ]
                        Promise.all(asyncTasksOcean).then(async res => {
                            const originPorts = await res[0].json();
                            const destinationPorts = await res[1].json();
                            
                            const originPort = originPorts.find(port => port.name === rfqData.shipping.origin_boarding_terminal.city.name)
                            const destinationPort = destinationPorts.find(port => port.name === rfqData.shipping.destination_boarding_terminal.city.name)
    
                            const originCoordinates = {
                                lng: originPort?.coordinates[0],
                                lat: originPort?.coordinates[1],
                            }
                            const destinationCoordinates = {
                                lng: destinationPort?.coordinates[0],
                                lat: destinationPort?.coordinates[1],
                            }
                            InitMap([originCoordinates, destinationCoordinates],'rfqMap')
                        })
                    })
                    
                    break;
            }
            setLoading(false)
        })
    }
    useEffect(() => {
        getData()
    }, [])
    useEffect(()=>{
        if (!openTagsModal){
            getData()
        }
    },[openTagsModal])

    const labels = {
        "temperature_control": {
            es: "Requiere control de Temperatura",
            en: "Temperature control required",
        },
        "dangerous": {
            es: "La carga es peligrosa",
            en: "Dangerous goods",
        },
        "data_logger": {
            es: "Requiere registro de datos",
            en: "Data logger required",
        },
        "remountable": {
            es: "La carga es remontable",
            en: "Remountable cargo",
        },
        "arancelary_position": {
            es: "Requiere posicion arancelaria",
            en: "Tariff position required",
        },
        "route_study": {
            es: "Requiere estudio de ruta",
            en: "Route study required",
        },
        "security_analysis": {
            es: "Requiere analisis de seguridad",
            en: "Security analysis required",
        },
        "additional_specifications": {
            es: "Especificaciones adicionales",
            en: "Additional specifications",
        },
    };

    return (
        <Container>
            <div style={{width:'100%', display:'flex', justifyContent:'space-between'}}>
                <h3>{`Pre - Liquidación #${radication}`}</h3>
                <div style={{display:'flex', gap:'10px'}}>
                    Tags :
                    {
                        RFQData?.tags?.map(e=>{
                            return <span className='tag'>{e}</span>
                        })
                    }
                    <Button startIcon={{name:'EditSquare'}} size={'xs'} handler={()=>setOpenTagsModal(true)}/>
                </div> 
            </div>
            {/* <PageTitle>{`Pre - Liquidación #${radication}`}</PageTitle> */}
            {clientChose && <Button text="Ir a detalles del servicio" handler={() => navigate(`/services/detail/chat/?radication=${RFQData.radication}&tab=chat`)} />}
            <div style={{position:'absolute', top:'20px', right:'20px'}}>
            </div>
            <div style={{ width:'100%' }}>
                {loading ? <Loading size='xl' style={{margin:'auto'}}/> :
                    <div>
                        <span style={{ margin: 'auto', textAlign:'center' }}>
                            {user.roles.includes(ROLES.Outsource) 
                                ? 'Pronto el cliente notificará si se acepta o no su propuesta.' 
                                : (items.length > 0 
                                    ? 'La cotización está lista para su aprobación, a continuación se presentan las opciones disponibles.' 
                                    : 'Pronto nuestro equipo atenderá su solicitud y se pondrá en contacto con usted para brindarle más información y ayudarle en lo que necesite.')
                            }
                        </span>
                        
                        {
    (user.roles.includes(ROLES.Admin) || user.roles.includes(ROLES.Pricing) || user.roles.includes(ROLES.Operations)) && (
        <Collapse 
            title={clientChose ?
            <CollapseTitle>Ya elegiste una oferta  <Icon color='green' size='35px' name='CheckModal'/></CollapseTitle> :
            <CollapseTitle>Elige una oferta  <Icon name='WarnModal' color='red' size='35px'/></CollapseTitle>}
        >
            <div className='offers'>
                {  
                    items?.map(item => {
                        return (
                            <div className='offersRow'>
                                <div style={{display:'flex',flexDirection:'column', margin:'20px 0'}}>
                                    <b>{item.organization}</b>
                                    <small style={{color:'grey'}}>{item.providerMail}</small>
                                </div>
                                <div className='cards'>
                                    {
                                        item.offers?.map(offer=>{
                                            return <OfferCard 
                                                key={offer.id}
                                                clientChose={clientChose}
                                                data={offer}
                                                getData={getData} 
                                                shippingMethod={RFQData.shipping?.shipping_method}
                                            />
                                        })
                                    }
                                </div>
                                <div style={{float:'right', margin:'20px 0'}}>
                                    <Button text={"Rechazar ofertas"}
                                        handler={() => setOpenRejectModal(true)}
                                        startIcon={{name:'CloseModal', color:'white'}}
                                        disabled={clientChose}
                                        bgColor='delete'
                                        size={'sm'}
                                        textColor='neutral_50'
                                    />
                                </div> 
                            </div>
                        )
                    })
                }
            </div>
        </Collapse>
    )
}
                        
                    </div>
                }
            </div>
            {RFQData.shipping && RFQData.service_options &&
                <DataContainer>
                    <ShipmentContainer>
                        <div>
                            <h3>{RFQData.shipping?.origin_boarding_terminal?.city?.name}, {RFQData.shipping?.origin_boarding_terminal?.city?.country?.name}</h3>
                            <span>Direccion : {RFQData.shipping?.pickup_address==="Ninguno"?'N/a':RFQData.shipping?.pickup_address}</span>
                        </div>
                        <Icon name='ArrowRight' color='white'/>
                        <div>
                            <Icon
                                name={
                                    shippingMethod === "air"
                                    ? "Aereo"
                                    :['ocean','bbk','ro-ro','special containers'].includes(shippingMethod)
                                    ? "Maritimo"
                                    : "Terrestre"
                                }
                                size="40"
                                color="white"
                            />
                            <h3>{RFQData?.business_terms}</h3>
                        </div>
                        
                        <Icon name='ArrowRight' color='white'/>
                        <div>
                            <h3>{RFQData.shipping?.destination_boarding_terminal?.city?.name}, {RFQData.shipping?.destination_boarding_terminal?.city?.country?.name}</h3>
                            <span>Direccion : {RFQData.shipping?.delivery_address === "Ninguno"?'N/a': RFQData.shipping?.delivery_address}</span>
                        </div>
                    </ShipmentContainer>
                    <InfoCard>
                        <p className='title'>General</p>
                            <div>
                                <b>Radicado : </b>
                                <span>{RFQData.radication}</span>
                            </div>
                            <div>
                                <b>Tipo de Negocio : </b>
                                <span>{RFQData.business_type}</span>
                            </div>
                            <div>
                                <b>Método de envio : </b>
                                <span>
                                    {shippingMethod}
                                </span>                              
                            </div>
                            <div>
                                <b>Incoterm : </b>
                                <span>
                                    {incoterms?.find(term => term.id === RFQData.business_term)?.name}
                                </span>
                            </div>
                    </InfoCard>
                    
                    <StyledMap 
                        id='rfqMap'
                        className='w-100 h-100 border-rounded'
                        style={{gridRow:'span 3'}}
                    />

                    <InfoCard>
                        <p className='title'>Cliente</p>
                        <div>
                            <b>Nombre : </b>
                            <span>{client?.name} {client?.last_name}</span>
                        </div>
                        <div>
                            <b>Email : </b>
                            <span>{client?.email}</span>
                        </div>
                        <div>
                            <b>Telefono : </b>
                            <span>{client?.phone}</span>
                        </div>
                    </InfoCard>
                    <InfoCard>
                        <p className='title'>Adicional</p>
                        {
                            Object.keys(RFQData.service_options).map(key => {
                                if (labels[key] && RFQData?.service_options[key] !== 0) {
                                    if (key === 'additional_specifications') {
                                        return (
                                            <div key={key}>
                                                <b>{labels[key].es} :</b>
                                                <span >
                                                    {RFQData?.service_options?.additional_specifications}
                                                </span>
                                            </div>
                                            
                                        );
                                    } else {
                                        return (
                                            <div key={key}>
                                                <b>{labels[key]?.es}</b>
                                                <span>Si</span>
                                            </div>
                                        );
                                    }
                                }
                            })
                        }
                    </InfoCard>
                    <InfoCard style={{ gridColumn: 'span 2' }}>
                        <p className='title'>Cargas</p>
                        <TableContainer>
                        <StyledTable>
                            <thead>
                                <tr>
                                <th>Tipo de Carga</th>
                                <th>Nombre</th>
                                <th>Valor (USD)</th>
                                <th>Dimensiones</th>
                                <th>Peso Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {RFQData.loads.map((load, index) => (
                                <tr key={index}>
                                    <td>{load.load_type.name}</td>
                                    <td>{load.name}</td>
                                    <td>{load.value} USD</td>
                                    <td>
                                    {load.long} {load.long_unit} x {load.width} {load.width_unit} x {load.height} {load.height_unit}
                                    </td>
                                    <td>
                                    {load.total_weight} {load.total_weight_unit}
                                    </td>
                                </tr>
                                ))}
                            </tbody>
                            </StyledTable>
                        </TableContainer>
                    </InfoCard>
                </DataContainer>
            }
            <RejectModal open={openRejectModal} setOpen={setOpenRejectModal}/>
            <TagsModal open={openTagsModal} setOpen={setOpenTagsModal} serviceId={RFQData.id}/>
        </Container>
    );
}