import React, { useEffect } from 'react';
import { styled } from '@nextui-org/react';
import theme from '../../../../theme';
import { useFieldArray, useForm, Controller } from 'react-hook-form';
import { GetDocumentData } from '../../../../services/user.services';
import { useSelector } from 'react-redux';
// Components
import PageTitle from '../../../../components/PageTitle/PageTitle';
import Input from '../../../../components/Input/Input';
import Select from '../../../../components/Select/Select';
import Button from '../../../../components/Button/Button';

const Representative = ({ setRepresentatives }) => {
    const user = useSelector(state => state.user);
    const documentTypes = [
        {
            value: 'RFC',
            name: 'RFC'
        },
        {
            value: 'NIT',
            name: 'NIT'
        },
        {
            value: 'C.C.',
            name: 'Cédula de ciudadania'
        }
    ]
    const nameForm = 'representative'
    const { register, control, setValue, getValues, watch } = useForm({
        defaultValues: {
            [nameForm]: [{
                name: '',
                lastName: '',
                documentType: '',
                documentNumber: '',
                position: ''
            }]
        }
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: nameForm
    });
    watch(nameForm)
    useEffect(() => {
        GetDocumentData([], user.token, user.idUser, 'representatives').then(async (res) => {
            let data = await res.json();
            // Formatear los datos obtenidos para que coincidan con el formato de useFieldArray
            const formattedData = data.map(item => ({
                name: item.name,
                lastName: item.last_name,
                documentType: item.document_type,
                documentNumber: item.document_number,
                position: item.position
            }));
            // Actualizar el estado del formulario con los datos formateados
            setValue(nameForm, formattedData);
        });
    }, [user, setValue, nameForm, control]);
    

    const StyledRepresentative = styled('div', {
        [`.${theme} &`]: {
            margin: '3rem 8rem 0rem 8rem',
            padding: '3rem 8rem',
            backgroundColor: '$neutral_50',
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            ['main']: {
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem'
            },
            ['main section.form']: {
                display: 'grid',
                gridTemplateColumns: 'repeat(2, minmax(200px, 1fr))',
                gap: '0.5rem',
                ['.colspan-2']: {
                    gridColumn: '1 / 3',
                    ['&.input > *']: {
                        display: 'flex'
                    }
                }
            },
        }
    })

    const saveData = () => {
        setRepresentatives(getValues()[nameForm])
    }

    useEffect(() => {
        saveData()
    }, []);

    return (
        <StyledRepresentative>
            <PageTitle>
                Representante legal
            </PageTitle>

            <aside>
                Puedes agregar opcional un representante legal suplente.
            </aside>

            <main>
                {
                    fields.map((field, i) =>
                        <div key={field.id}>
                            {
                                i === 0 ?
                                    <h5>Representante legal</h5>
                                    :
                                    <h5>Representante legal suplente</h5>
                            }
                            <section className='form'>
                                <Input register={register} onChange={saveData} name={`${nameForm}.${i}.name`} label='Nombre de representante' placeholder='Introducir' required></Input>
                                <Input register={register} onChange={saveData} name={`${nameForm}.${i}.lastName`} label='Apellido de representante' placeholder='Introducir' required></Input>
                                <Controller
                                    control={control}
                                    name={`${nameForm}.${i}.documentType`}
                                    render={({ field: { onChange, value } }) => (
                                        <Select onChange={(value) => { onChange(value); saveData() }} name={`${nameForm}.${i}.documentType`} value={value} label='Tipo de documento' options={documentTypes} required></Select>
                                    )}
                                ></Controller>
                                <Input register={register} onChange={saveData} name={`${nameForm}.${i}.documentNumber`} label='No. de documento' placeholder='Introducir' type='number' required></Input>
                                <Input register={register} onChange={saveData} name={`${nameForm}.${i}.position`} label='Cargo' placeholder='Introducir' required></Input>

                                <div className='d-flex align-items-end'>
                                    {
                                        i < fields.length - 1 ?
                                            <Button handler={() => { remove(i); saveData() }} text='Eliminar' outline></Button>
                                            :
                                            <Button handler={() => { append(); saveData() }} text='Agregar otro' startIcon={{ name: 'Plus', size: '16' }} outline></Button>
                                    }
                                </div>
                            </section>
                        </div>
                    )
                }
            </main>
        </StyledRepresentative>
    );
}

export default Representative;
