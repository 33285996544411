import React from 'react';
import { Textarea as ta, styled } from '@nextui-org/react';
import theme from '../../theme';

const StyledTextarea = styled(ta, {
    [`.${theme} &`]: {
        borderRadius: "8px",
        border: "solid 1px $primary_300",
        transition: "border 0.25s ease",
        [".nextui-input-wrapper"]: {
            borderRadius: "6px",
            backgroundColor: "$neutral_50",
        },
        ['label']: {
            color: '$primary_500'
        }
    },
    ["& + .nextui-input-helper-text-container .nextui-input-helper-text"]: {
        fontSize: "11px",
    },
})

const Textarea = ({
    placeholder,
    name,
    value,
    onChange,
    label,
    labelColor = 'primary_500',
    helperText,
    required = false,
    animated = false,
    selectBorderColor = "primary_100",
    register = () => { },
}) => {
    const StyledLabel = styled('div', {
        [`.${theme} &`]: {
            color: `$${labelColor}`,
            ['span']: {
                color: '#ff676c'
            }
        }
    })

    return (
        <StyledTextarea
            {...register(name, { required })}
            animated={animated}
            placeholder={placeholder}
            aria-label={placeholder}
            value={value}
            name={name}
            onChange={onChange}
            label={label ? <StyledLabel>{label} {required ? <span>*</span> : ''}</StyledLabel> : ''}
            helperText={helperText}
            css={{ "&:focus-within": { border: `solid 1px $${selectBorderColor}` } }}
        ></StyledTextarea>
    );
}

export default Textarea;
