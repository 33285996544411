import React, { useEffect, useState } from 'react'
import { styled, Switch } from '@nextui-org/react';
import SimpleAutocomplete from '../../../components/SimpleAutocomplete/SimpleAutocomplete'
import Button from '../../../components/Button/Button'
import Input from '../../../components/Input/Input'
import InputSelect from '../../../components/Select/inputSelect'
import { getCountries } from '../../../services/countries.services';
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {calculateCO2byPerson} from '../../../services/CO2.services'
import toast from '../../../components/Toast/ToastTypes';
import { useNavigate } from 'react-router-dom';
const StyledContainer = styled("div", {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '40px',
    marginBottom: '30px',
})

const vehicleOptions = [
    { label: 'Local', value: 'local' },
    { label: 'De transito/interurbano', value: 'transit' },
]
const fuelOptions = [
    { value: "unknown", label: "Desconocido" },
    { value: "diesel", label: "Diésel" },
    { value: "petrol", label: "Gasolina" },
    { value: "hybrid", label: "Híbrido" },
    { value: "cng", label: "Gas Natural Comprimido (CNG)" },
    { value: "lpg", label: "Gas Licuado de Petróleo (GLP)" },
    { value: "plug-in", label: "Híbrido Enchufable" },
    { value: "electric", label: "Eléctrico" }
]

export default function HotelForm() {
    const token = useSelector(state => state.user.token);
    const user = useSelector(state => state.user)
    const { control, handleSubmit, formState: { errors }, setValue } = useForm();
    const [countries, setCountries] = useState([]);

    useEffect(() => {
        getCountries(token).then(async res => {
            const data = await res.json();
            setCountries(data);
            console.log('paises :', data);
        });
    }, [token]);
    const navigate = useNavigate();
    const onSubmit = (data) => {
        const request={
            type:"hotel",
            country:data.country.value,
            nights:data.nights,
            guests: data.guests,
        }
        calculateCO2byPerson(token, {request,user:user.idUser}).then(async (res)=>{
            if(res.status==200){
                await toast("success", "Cálculo guardado exitosamente")
                navigate('/co2/history');
            }
            else{
                toast("error", "No se pudo guardar el cálculo")
            }
        }).catch(error=>{
            toast("error", "Ha ocurrido un error inesperado")
        })
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <StyledContainer>
                <Controller
                    name="country"
                    control={control}
                    render={({ field }) => (
                        <InputSelect
                            label="País"
                            options={countries.map(country => ({
                                value: country.iso,
                                label: country.name,
                            }))}
                            {...field}
                            onChange={(selectedCountry) => setValue('country', selectedCountry)}
                        />
                    )}
                    rules={{ required: true }}
                />
                <Controller
                    name="nights"
                    control={control}
                    render={({ field }) => (
                        <Input label="Noches de estadia" type="number" {...field} />
                    )}
                    rules={{ required: true, min: 1 }}
                />
                <Controller
                    name="guests"
                    control={control}
                    render={({ field }) => (
                        <Input label="Cantidad de personas" type="number" {...field} />
                    )}
                    rules={{ required: true, min: 1 }}
                />
                <Button text="Realizar calculo" bold outline type="submit" />
            </StyledContainer>
        </form>
    );
}
