import React, { useEffect, useState } from "react";
import { styled } from "@nextui-org/react";
import theme from "../../../../theme";
import { Controller, useFormContext } from "react-hook-form";
// Components
import PageTitle from "../../../../components/PageTitle/PageTitle";
import RadioGroup from "../../../../components/RadioGroup/RadioGroup";
import Card2 from "../../../../components/Cards/Card2/Card2";
//Constants
import { projectMethods, generalMethods } from "../../../../constants";
import { useTranslation } from "react-i18next";
import useMediaQueries from "../../../../hooks/useMediaQueries";

const StyledCommerce = styled("div", {
  [`.${theme} &`]: {
    boxShadow:'$elevation_1',
    margin: "3rem 8rem 0rem 8rem",
    padding: "3rem 8rem",
    backgroundColor: "$neutral_50",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    ["aside"]: {
      color: "$primary_200",
    },
    ["main"]: {
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
    },
    [".separate"]: {
      margin: "2rem 1rem",
    },
    [".containerIndustries"]: {
      display: "grid",
      gap: "0.5rem",
    },
  },
  "@media (max-width: 1200px)":{
    [`.${theme} &`]:{
      margin:"2rem 1rem",
      padding:"2rem",
      fontSize:'12px',
    },
  },
  "@media (max-width: 400px)":{
    // margin:"10px",
    marginTop:"3rem",
    padding:"5px",
    fontSize:'10px',
  }
});

const Commerce = ({ shippingMethods, commerceTypes, setDisabledContinue }) => {
  const nameForm = "commerce";
  const {md} = useMediaQueries()
  const { watch, setValue, getValues, control, formState: { errors } } = useFormContext();

  const [_shippingMethods, _setShippingMethods] = useState([]);
  const [selectedShipping, setSelectedShipping] = useState( getValues()[nameForm]?.shippingMethod || "" );
  const { t: translate } = useTranslation("Quotation");

  useEffect(() => {
    setValue(`${nameForm}.shippingMethod`, selectedShipping);
    if ( !getValues(`${nameForm}.shippingMethod`) || !getValues(`${nameForm}.commerceType`)) {
      setDisabledContinue(true);
    } else {
      setDisabledContinue(false);
    }
  }, [selectedShipping, watch(`${nameForm}.commerceType`)]);

  const selectShipping = (index) => {
    if (selectedShipping.text !== _shippingMethods[index].text) {
      setSelectedShipping(_shippingMethods[index]);
    }
  };

  useEffect(() => {
    if ( getValues(`liquidation.loadType`)?.value !== "" && shippingMethods?.length > 0) {
      let methods = [];
      if (getValues(`liquidation.loadType`)?.label === "Carga General") {
        methods = generalMethods;
      } else {
        methods = projectMethods;
      }
      _setShippingMethods(methods);
    }
  }, []);

  return (
    <StyledCommerce>
      <PageTitle>{translate("commerce-type-title")}</PageTitle>
      <aside>{translate("commerce-type")}</aside>
      <main>
        <Controller
          control={control}
          name={`${nameForm}.commerceType`}
          rules={{ required: "Campo obligario" }}
          render={({ field: { onChange, value } }) => (
            <RadioGroup
              size="xs"
              orientation={md? "horizontal" : 'vertical'}
              onChange={(value) => { onChange(value); }}
              name={`${nameForm}.commerceType`}
              value={value}
              options={commerceTypes}
            ></RadioGroup>
          )}
        ></Controller>
        <hr className="separate" />
        <div>
          <div>
            <PageTitle>{translate("shipment-method")}</PageTitle>
            {getValues(`liquidation.loadType`)?.label === "Carga General" ? (
              <aside>{translate("select-option")}</aside>
            ) : (
              <aside>{translate("maritime-tip")}</aside>
            )}
            <div style={{display:'flex',margin:'30px 0', justifyContent:'space-around', gap:'10px'}}>
              {_shippingMethods.map((shippingMethod, i) => {
                return (
                  <Card2
                    key={i}
                    index={i}
                    icon={shippingMethod.icon}
                    text={shippingMethod.text}
                    textSelected={selectedShipping?.text}
                    handler={selectShipping}
                  ></Card2>
                );
              })}
            </div>
          </div>
        </div>
      </main>
    </StyledCommerce>
  );
};

export default Commerce;
