import React from 'react';
import { styled } from '@nextui-org/react';
import theme from '../../theme';
import { toast } from 'sonner';

// Components
import Icon from '../Icon/Icon';

const Toast = ({ handlerClose, icon, title, description, descriptionColor }) => {
    const StyledToast = styled('div', {
        [`.${theme} &`]: {
            display: 'flex',
            gap: '1rem',
            alignItems: 'center',
            width: '100%',
            height: 'fit-content',
            ['.modal_icon']: {
                alignSelf: 'start',
                display: 'flex'
            },
            ['.content p.title']: {
                color: '$primary_500',
                fontWeight: 'bold',
                fontSize: '1rem'
            },
            ['.content']: {
                flex: '1',
                color: `${descriptionColor}`,
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem'
            },
            ['.content p']: {
                fontSize: '0.875rem'
            },
            ['.close_modal_icon']: {
                alignSelf: 'start',
                cursor: 'pointer',
                display: 'flex'
            }
        }
    })

    return (
        <StyledToast>
            <div className='modal_icon'>
                <Icon name={icon?.name} color={icon?.color} size={icon?.size} />
            </div>
            <div className='content'>
                {
                    title && <p className='title'>{title}</p>
                }
                <p>{description}</p>
            </div>
            <div
                className='close_modal_icon'
                onClick={() => handlerClose()}
            >
                <Icon name="Close" color="#8993A4" size="24" />
            </div>
        </StyledToast>
    );
}

export default Toast;
