import { Dropdown, styled } from '@nextui-org/react'
import { styled as styledMui } from '@mui/material/styles';
import React from 'react'
import Icon from '../Icon/Icon'
import ReactCountryFlag from 'react-country-flag'
import { Step, StepConnector, stepConnectorClasses, StepLabel, Stepper } from '@mui/material'
import { Check } from '@mui/icons-material'
import { useTranslation } from "react-i18next";


const Container = styled("div",{
  background:'white',
  minWidth:'1100px',
  boxShadow:'$elevation_1',
  width:'100%',
  borderRadius:'8px',
  height:'144px',
  display:'grid',
  paddingTop:'4px',
  gridTemplateColumns:'65% 30% 5%',
  transition:'100ms',
  '.big_cell':{
    width:'100%',
    borderRight:'1px solid lightgrey',
    display:'flex',
    gap:'10px',
    padding:'10px',
    flexDirection:'row',
    alignItems:'center',
    '.location':{
      display:"flex",
      flexDirection:'column',
    },
    'b':{fontSize:'10px'},
    'span':{fontSize:'10px'},
    '.MuiStepLabel-labelContainer span': {
      fontSize: "12px",
    }
  
  },
  '.label_data':{
    display:'flex',
    flexDirection:'column',
    'b':{fontSize:'11px'}
  },
  '.label':{
    color:'grey',
    fontSize:'10px'
  },
  "&:hover":{
    boxShadow:'$elevation_3',
  }
})

const SmallCells = styled("div",{
  display:'flex',
  flexDirection:'column',
  '.top_cells':{
    display:'grid',
    gridTemplateColumns:'20% 20% 20% 20% 20%',
    height:'115px',
    '.column':{
      borderRight:'1px solid lightgrey',
      display:'flex',
      flexDirection:'column',
      gap:'3px',
      padding:'5px',
      paddingLeft:'10px',
      height:'130px',
      paddingTop:'8px',
      fontSize:'12px',
    },
  },
  '.bottom_cell':{
    width:'100%',
    height:'25px',
    paddingLeft:'20px',
    background:'$primary_50',
    borderRadius:'4px',
    'a':{
      color:'darkblue',
      fontSize:'11px',
      marginRight:'20px',
    }
  }
})

const StyledActions = styled("div", {
  backgroundColor: "$primary_50",
  color: "$primary_400",
  display: "flex",
  cursor: "pointer",
  padding: "0.7rem",
  borderRadius: "30px",
});

const QontoConnector = styledMui(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#292E68',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#292E68',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#292E68',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styledMui('div')(({ theme, ownerState }) => ({
  color: '#292E68',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#292E68',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#292E68',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;
  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

export default function TableCardService({item, actions}) {

  const { t: translate } = useTranslation("Services");
  const handlerAction = (key, item) => {
    actions[key].handle(item);
  };
  return (
    <Container>
      <SmallCells>
        <div className='top_cells'>
          <div className='column'>
            <b className='label'>{translate("status")}</b>
            <b >1-Partida</b>
            <b style={{borderBottom:'3px solid green', width:"fit-content"}}>2-En camino</b>
            <b>3-</b>
          </div>
          <div className='column'>
            <div className='label_data'>
              <span className='label'>{translate("radicate")}</span>
              <b>{item?.idRadication}</b>
            </div>
            <div className='label_data'>
              <span className='label'>{translate("purchase_order")}</span>
              <b>{item?.purchaseOrder}</b>
            </div>
            <div className='label_data'>
              <span className='label'>mbl</span>
              <b>{item?.mbl}</b>
            </div>
          </div>
          <div className='column'>
            <div className='label_data'>
              <span className='label'>{translate("advisor")} </span>
              <b>{item?.advisor}</b>
            </div>
            <div className='label_data'>
              <span className='label'>Current carrier </span>
              {/* <b>Maersk</b> */}
            </div>
            <div className='label_data'>
              <span className='label'>ETA </span>
              {/* <b>08/09/2024</b> */}
            </div>
          </div>
          <div className='column'>
            <b className='label'>{translate("load")}</b>
            <div className='label_data' style={{display:'flex', flexDirection:'row', gap:'5px'}}>
              <span className='label'>{translate("load_type")} : </span>
              <b>Carga general</b>
            </div>
            <div className='label_data' style={{display:'flex', flexDirection:'row', gap:'5px'}}>
              <span className='label'>Cargas : </span>
              <b><b>{item?.load}</b></b>
            </div>
            <div className='label_data' style={{flexDirection:'row', gap:'5px'}}>
              <span className='label'>{translate("total_weight")} :</span>
              <b>{item?.loads?.weight} Kg</b>
            </div>
            <div className='label_data' style={{flexDirection:'row', gap:'5px'}}>
              <span className='label'>{translate("total_volumen")} :</span>
              <b>{item?.loads?.volume}cm³</b>
            </div>
          </div>
          <div className='column'>
            <b className='label'>{translate("operation_costs")}</b>
            <div className='label_data' style={{flexDirection:'row', gap:'5px'}}>
              <b>{item?.cost}</b>
            </div>
            <div className='label_data'>
              <span className='label'>{translate("co2_emissions")} :</span>
              {/* <b>1580 Kg</b> */}
            </div>
          </div>
        </div>
        <div className='bottom_cell'>
          <a>{translate("chat")} (0)</a>
          <a>{translate("documents")} (0)</a>
          {/* <a>{translate("notes")} (0)</a> */}
        </div>
      </SmallCells>
      <div className='big_cell'>
          <div className='location'>
            <ReactCountryFlag countryCode={item?.origin?.code} svg style={{ width: "2em", height: "1.8em", borderRadius: "6px", margin:'auto'}}/>
            <b>{item?.shipping?.origin_boarding_terminal?.city?.name}, {item?.origin?.name}</b>
            <span>{item?.shipping?.pickup_address}</span>
          </div>
            <div style={{ width:'100%', display:"flex", flexDirection:'column', alignItems:'center'}}>
              <Icon name='Aereo' size='22px'/>
              <Stepper activeStep={1} alternativeLabel orientation='horizontal' connector={<QontoConnector />} sx={{width:"100%"}}>
                  <Step key={1}>
                      <StepLabel sx={{fontSize:'10px'}} StepIconComponent={QontoStepIcon}>Origen</StepLabel>
                  </Step>
                  <Step key={2}>
                      <StepLabel sx={{fontSize:'10px'}} StepIconComponent={QontoStepIcon}>Actual</StepLabel>
                  </Step>
                  {/* <Step key={2}>
                      <StepLabel sx={{fontSize:'10px'}} StepIconComponent={QontoStepIcon}>Algo</StepLabel>
                  </Step> */}
                  <Step key={3}>
                      <StepLabel sx={{fontSize:'10px'}} StepIconComponent={QontoStepIcon}>Destino</StepLabel>
                  </Step>
              </Stepper>
            </div>
          <div className='location'>
            <ReactCountryFlag countryCode={item?.destination?.code} svg style={{ width: "2em", height: "1.8em", borderRadius: "6px", margin:'auto'}}/>
            <b>{item?.shipping?.destination_boarding_terminal?.city?.name}, {item?.destination?.name}</b>
            <span>{item?.shipping?.delivery_address}</span>
          </div>
      </div>
      <div style={{display:"flex",paddingBottom:"20px", justifyContent:'center',alignItems:'center',}}>
        <Dropdown placement="bottom-left">
          <Dropdown.Trigger>
              <StyledActions className="theme">
                  <Icon name="MenuVertical" size="14"></Icon>
              </StyledActions>
          </Dropdown.Trigger>
          <Dropdown.Menu
            onAction={(key) => handlerAction(key, item)}
            aria-label="Table actions"
            css={{ minWidth: "100px" }}
          >
            {actions.map((element, i) => (
              <Dropdown.Item
                key={i}
                onClick={() => element.handle(item)}
                icon={<Icon name={element.icon}></Icon>}
              >
              {element.name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </Container>
  )
}
