import React, { useEffect, useState } from "react";
import { styled, Dropdown as Dp } from "@nextui-org/react";
import theme from "../../theme";

function Dropdown({
  options,
  value,
  onChange,
  bgColor = "neutral_50",
  textColor = "primary_500",
  name, outline,
  selectionMode = "single"
}) {
  const [selected, setSelected] = useState('');

  const selectedValue = React.useMemo(
    () => Array.from(selected).join(", ").replaceAll("_", " "),
    [selected]
  );

  const StyledDropdownButton = styled(Dp.Button, {
    [`.${theme} &`]: {
      background: `$${bgColor}`,
      color: `$${textColor}`,
      borderRadius: 7,
      border: `${outline ? '1px solid $primary_300' : ''}`,
    }
  });

  const handleChange = (selected) => {
    setSelected(selected);
    onChange(Array.from(selected), name);
  };

  useEffect(() => {
    setSelected(value ? new Set([value]) : new Set([options[0].key]));
  }, [value]);

  return (
    <Dp>
      <StyledDropdownButton light>{selectedValue}</StyledDropdownButton>
      <Dp.Menu
        aria-label="Single selection actions"
        disallowEmptySelection
        selectionMode={selectionMode}
        selectedKeys={selected}
        onSelectionChange={handleChange}
        items={options}
        css={{ li: { color: `$${textColor}`, svg: { color: `$${textColor}` } } }}
      >
        {(item) => (
          <Dp.Item key={item.name} css={{ color: `$${textColor}` }}>
            {item.name}
          </Dp.Item>
        )}
      </Dp.Menu>
    </Dp>
  );
}

export default Dropdown;
