import { Height } from '@mui/icons-material'
import { styled } from '@nextui-org/react'
import React from 'react'


const Container = styled("div",{
    background:'white',
    boxShadow:'$elevation_1',
    borderRadius:'8px',
    margin:'30px',
    minHeight:'90vh',
    "iframe":{
        margin:'20px',
        height:'90vh',
        width:'95%',
        borderRadius:'8px',
    }
})

export default function RoutingSandbox() {
  return (
    <Container>
        <iframe src="https://demo.routing.ext.here.com" frameborder="0"></iframe>
    </Container>
  )
}
