import { URL } from "../constants";

export const sendRating = async (data, token) => {
    try {
        const response = await fetch(`${URL.get()}ratings/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Token " + token,
            },
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.detail || 'An error occurred');
        }

        return response.json();
    } catch (error) {
        console.error("Error posting rating:", error);
        throw error;
    }
};

export const getRating = async (token) => {
    try {
        const response = await fetch(`${URL.get()}ratings/`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Token " + token,
            },
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.detail || 'An error occurred');
        }

        return response.json();
    } catch (error) {
        console.error("Error posting rating:", error);
        throw error;
    }
};

