
import React from 'react';
import Icon from '../../Icon/Icon';
import { URL } from "../../../constants";
import toast from '../../Toast/ToastTypes';

const modalContainer = {
    backgroundColor: "#FFF",
    width: "400px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: "400",
    borderRadius: "10px",
    boxShadow: "1px 1px 7px #3D509A",
    border: "1px solid #3D509A"
};

const header = {
    backgroundColor: "#3D509A",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderTopRightRadius: "6px",
    borderTopLeftRadius: "6px",
    padding: "10px",
    color: "#FFF"
};

const title = {
    textAlign: "center",
    width: "100%"
};

const icon = {
    display: "contents",
    cursor: "pointer"
};

const form = {
    margin: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "20px"
};

const button = {
    width: "60%",
    margin: "auto",
    borderRadius: "5px",
    padding: "5px 0px",
    border: "none",
    backgroundColor: "#3D509A",
    cursor: "pointer",
    color: "#FFF"
};

const text = {
    textAlign: "center"
}

function ConfirmationModal(props) {

    const { titleText, buttonText, contentText, close, id, endpoint, methodType, headers } = props;

    const send = (form) => {
        form.preventDefault();
        fetch(`${URL}${endpoint}`, {
            method: methodType,
            headers: headers,
            body: JSON.stringify({ id: id })
        })
            .then(response => response.json())
            .then(response => toast('success', response.message));

        close();
    };

    return (
        <div style={modalContainer}>
            <div style={header}>
                <p style={title}>{titleText}</p>
                <div style={icon} onClick={close} title="Cerrar">
                    <Icon name="CloseModal" color="#FFF" />
                </div>
            </div>
            <form method="post" style={form} onSubmit={(form) => send(form)}>
                <p style={text}>{contentText}</p>
                <input type="submit" value={buttonText} style={button} />
            </form>
        </div>
    );
}

export default ConfirmationModal;