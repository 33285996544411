export default function camelCaseToSnakeCase(data) {
    if (typeof data === 'object') {
        if (Array.isArray(data)) {
            return data.map(camelCaseToSnakeCase);
        } else {
            const newObject = {};
            for (let key in data) {
                if (data.hasOwnProperty(key)) {
                    const newKey = key.replace(/([A-Z])/g, '_$1').toLowerCase();
                    newObject[newKey] = camelCaseToSnakeCase(data[key]);
                }
            }
            return newObject;
        }
    } else {
        return data;
    }
}