import React from 'react'
import { Loading, styled } from "@nextui-org/react";
import Icon from '../../../components/Icon/Icon';
import useMediaQueries from '../../../hooks/useMediaQueries';

const IconContainer = styled("div", {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.3);',
  width: '70px',
  height: '55px',
  borderRadius: '8px',
  '@media (max-width:1000px)':{
    display:'none',
  }
})

export default function Card({ color, title, number, aditional, loading }) {

  const {sm,md} = useMediaQueries()

  const StyledContainer = styled("div", {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    backgroundColor: color,
    color: 'white',
    boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)',
    borderRadius: '8px',
    transition: '300ms',
    'h2':{
      fontSize:'35px'
    },
    '.description':{
      fontSize: '12px', wrap: 'no-wrap'
    },
    ['&:hover']: {
      transform: 'translateY(-6px)'
    },
    "@media(max-width:1200px)":{
      padding: '10px',
      'h2':{
        fontSize:'25px'
      },
    },
    "@media(max-width:500px)":{
      padding: '5px',
      justifyContent:'space-between',
      'h2':{
        fontSize:'20px'
      },
      '.description':{
        fontSize: '8px', wrap: 'no-wrap',
      }
    }
  })

  console.log("number :",number)
  console.log("aditional :",number)
  return (
    <StyledContainer>
      <div style={{ display: 'flex', gap: '20px' }}>
        <IconContainer>
          <Icon name='weight' color='white' size='38px' />
        </IconContainer>
        {
          loading?
          <Loading style={{margin:'5px'}} size='xl' color='white'/>:
          <div>
            <p style={{fontSize:'12px'}}>{title}</p>
            <h2>{number}</h2>
          </div>
        }
      </div>
      <span className='description'>{aditional}</span>
    </StyledContainer>
  )
}
