import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {  getPurchaseOrders, getServiceSplit } from "../../../../services/quotation.services";
import { getMovements, getOrganizations } from "../../../../services/quotations.services";
import toast from "../../../../components/Toast/ToastTypes";
import { format } from "date-fns";
// Components
import Table from "../../../../components/Table/Table.jsx";
import { getPreliquidations } from "../../../../services/preliquidation.services";
import { getOffers } from "../../../../services/quotations.services";
import { ROLES } from "../../../../constants";
import { getBusinessTerms } from "../../../../services/businessTerms.services";
import { getPreliquidationCosts } from "../../../functions";
import { getCountriesNew } from "../../../../services/countries.services.js";
import { useTranslation } from "react-i18next";
import { getShippingMethods } from "../../../../services/shippingMethods.services.js";
import TagsModal from "./TagsModal.jsx";
import TableCardRFQ from "../../../../components/Table/TableCardRFQ.jsx";
import { Loading, Switch } from "@nextui-org/react";
import Pagination from "../../../../components/Pagination/Pagination.jsx";

const TableQuotation = ({ filterSearch }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const user = useSelector((state) => state.user);
  const token = user.token;

  const [items, setItems] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState([]);

  const [cardView, setCardView] = useState(false)
  const [page, setPage] = useState(1)
  const [count, setCount] = useState([]);
  const [loading, setLoading] = useState(false)

  const { t: translate } = useTranslation("Quotation");

  const tableStyle = {
    width: "100%",
  };
  let columns = [
    { name: "Acciones", uid: "actions", type: "action" },
    { name: translate("radicate"), uid: "idRadication", type: "text", style: { width: "auto" },},
    { name: translate("po"), uid: "purchaseOrder", type: "text" },
    { name: translate("customer"), uid: "client", type: "text" },
    { name: translate("load"),uid: "load",type: "text",style: { width: "auto" },},
    { name: translate("origin"), uid: "origin", type: "country" },
    { name: translate("method"), uid: "method", type: "icon" },
    { name: translate("destination"), uid: "destination", type: "country" },
    { name: translate("incoterm"), uid: "incoterm", type: "text" },
    // { name: translate("status-movements"), uid: "movements", type: "stepper", style: { width: "auto" },},
    { name: translate("date"), uid: "date", type: "text" },
    { name: translate("costs"), uid: "value", type: "text", style: { width: "auto" },},
    { name: translate("approval-status"), uid: "ready", type: "text" },
  ];

  let isClient = false;
  user.roles.forEach((role) => {
    if ([ROLES.User || ROLES.Client].includes(role)) {
      isClient = true;
    }
  });
  if (isClient) {
    columns = [
      { name: "Acciones", uid: "actions", type: "action" },
      { name: "Referencia", uid: "ref", type: "text", style: { width: "auto" },},
      { name: translate("customer"), uid: "client", type: "text" },
      { name: translate("load"), uid: "load", type: "text", style: { width: "auto" },},
      { name: translate("origin"), uid: "origin", type: "country" },
      { name: translate("destination"), uid: "destination", type: "country" },
      { name: translate("incoterm"), uid: "incoterm", type: "text" },
      { name: translate("status-movements"), uid: "movements", type: "stepper", style: { width: "auto" },},
      { name: translate("date"), uid: "date", type: "text" },
      { name: translate("costs"), uid: "value", type: "text", style: { width: "auto" },},
      { name: translate("approval-status"), uid: "ready", type: "text" },
    ];
  }
  useEffect(() => {
    if (searchParams.get("liquidate")) {
      toast(
        "info",
        "Selecciona desde la tabla la cotizacion que deseas liquidar"
      );
    }
    if (searchParams.get("operation")) {
      toast(
        "info",
        "Selecciona desde la tabla la cotizacion con la que deseas iniciar una operacion"
      );
    }
  }, [items]);

  const actions = [
    {
      name: translate("view_details"),
      icon: "View",
      handle: (item) => {
        if (!item.idRadication.startsWith("QRFQ")) {
          navigate(`/quotation/detail/?radication=${item.idRadication}&id=${item.id}&finished=${item.ready}`);
        } else {
          navigate(`/services/detail/chat/?radication=${item.idRadication}&tab=chat`);
        }
      },
    },
    {
      name: translate("tags"),
      icon: "EditSquare",
      handle: (item) => {
        setCurrentItem(item)
        setOpen(true)
      },
    },
    {
      name: translate("edit"),
      icon: "Edit",
      handle: (item) => {
        navigate(`/new-quotation/?radication=${item.idRadication}`);
      },
    },
    {
      name: translate("duplicate"),
      icon: "Versions",
      handle: (item) => {
        navigate(`/new-quotation/?radication=${item.idRadication}&duplicate=1`);
      },
    },
  ];

  if (user.roles.includes(1) || user.roles.includes(5) || user.roles.includes(9) || user.roles.includes(8)|| user.roles.includes(13)) {
    actions.push({
      name: translate("liquidate"),
      icon: "Cotizaciones",
      handle: (item) => {
        if (!item.idRadication.startsWith("QRFQ")) {
          !item.movements?.quotation
            ? navigate(
                `/manageQuotes/?tab=form&radication=${item.idRadication}&id=${item.id}`
              )
            : toast("warn", <div style={{display:'flex',flexDirection:'column', gap:'10px'}}>Ya existe liquidacion para este servicio
              <Link to={`/manageQuotes/?tab=pre-liquidation&selectId=${item.movements.quotation.id}`}>Ir a liquidación</Link></div>
            );
        } else {
          toast("warn", "Aun no hay detalles, edita la cotizacion primero");
        }
      },
    });
  }
  const redirectEdit = (item) => {
    navigate(`/new-quotation/?radication=${item.idRadication}`);
  };

  function getData(currentPage, pageSize) {
    setLoading(true)
    const asyncTasks = [
      getServiceSplit(token, currentPage, pageSize, filterSearch, true),
      getPreliquidations(token),
      getBusinessTerms(token),
      getPurchaseOrders(token),
      getCountriesNew(token),
      // getShippingMethods(token),
      getOrganizations(token)
    ];
    return Promise.all(asyncTasks).then(async (res) => {
        const RFQData = await res[0].json();
        const preliquidationsData = await res[1].json();
        const incotermsData = await res[2].json();
        const purchaseOrdersData = await res[3].json();
        const countriesData = await res[4].json();
        const organizationsData = await res[5].json();
        console.log("organizationsData :",organizationsData)
        const asyncTableData = RFQData.results.map(async (service) => {
          const providers = await getOffers(token, service.radication);
          let offers = []
          Object.keys(providers).map(key => {
            offers = offers.concat(providers[key]?.quotations)
          })
          const offerPrices = offers?.map((offer) => {
            const lastPreliquidationId = offer.preliquidation_ids[offer.preliquidation_ids?.length - 1];
            const lastPreliquidation = preliquidationsData.find(
              (prel) => prel.id === lastPreliquidationId
            );
            const { totalCost } = getPreliquidationCosts(lastPreliquidation);
            return "$ " + totalCost?.toFixed(2);
          });
  
          const costs = user.roles.includes(ROLES.Outsource) 
          ? "No disponible" 
          : (offerPrices.length > 0 ? offerPrices.join("/") : "No asignado");
          
            const date = format(
            new Date(service.created_at),
            "dd/MM/yyyy HH:mm:ss"
          );
          const load =
            service.loads && service.loads.length > 0
              ? service.loads[0].load_type
              : null;
          const purchaseOrder = purchaseOrdersData.find(
            (po) => po.service === service.id
          )?.purchaseNumber;
  
          const methodId = service?.shipping?.shipping_method;
          const method =
            methodId === 1
              ? { name: "Aereo", text: "Aereo" }
              : methodId === 2
              ? { name: "Maritimo", text: "Maritimo" }
              : methodId === 3
              ? { name: "Terrestre", text: "Terrestre" }
              : methodId === 4
              ? { name: "Bbk", text: "Bbk" }
              : methodId === 5
              ? { name: "Roro", text: "Roro" }
              : methodId === 6
              ? { name: "Containers", text: "Contenedores especiales" }
              : { name: "Close", text: "No especificado" };
          const client = service.users.find((user) => (user.user && user.user.groups?.includes("Usuario")))?.user;
          const organization = service.users.find((user) => (user.user && user.user.groups?.includes("Usuario")))?.organization?.name;
          const originCountry = countriesData.find(
            (country) =>
              country.name.toLowerCase() ===
              service?.shipping?.origin_boarding_terminal?.city?.country?.name.toLowerCase()
          );
          const destinationCountry = countriesData.find(
            (country) =>
              country.name.toLowerCase() ===
              service?.shipping?.destination_boarding_terminal?.city?.country?.name.toLowerCase()
          );
          let loadWeight = 0
          let loadVolume = 0
          service?.loads?.map(load=>{
            loadWeight += load.total_weight
            loadVolume += load.long * load.width * load.height
          })
          return {
            count:RFQData.count,
            tags:service.tags,
            key: service.id,
            id: service.id.toString(),
            purchaseOrder,
            ref: isClient? purchaseOrder !== "Ninguna" ? purchaseOrder : service.radication : service.radication,
            idRadication: service.radication,
            load: load?.name || "Sin carga",
            loads:{
              amount:service?.loads?.length,
              weight:loadWeight,
              volume:loadVolume,
            },
            expiration: format(new Date(service.expiration_date),"dd/MM/yyyy"),
            method,
            origin: originCountry,
            destination: destinationCountry,
            incoterm: incotermsData?.find((term) => term.id === service.business_term)?.name,
            value: costs,
            shipping:service.shipping,
            offerPrices,
            date,
            client:client?.first_name && client?.last_name? `${client?.first_name} ${client?.last_name}` : client?.username,
            clientData:client,
            clientOrganization:organization,
            // movements: movementsData?.find((movement) => movement.radication === service.radication),
            ready: offerPrices.length > 0 ? "Si" : "No",
          };
        });
        const tableData = await Promise.all(asyncTableData);
        setLoading(false)
        setItems(tableData);
        setCount(tableData[0]?.count)
        return { data: tableData, count: tableData[0]?.count };
      })
      .catch((err) => {
        console.log("error :", err);
        setLoading(false)
        // toast("error", "No se pudo acceder a las cotizaciones")
      });
  }
  console.log("items :",items)

  useEffect(()=>{
    getData(page,10)
  },[page])

  return (
    <div className="h-100">
      <div style={{display:'flex',gap:'20px', background:'lightgrey', borderRadius:'10px', width:'fit-content',padding:'10px'}}>
      <span style={{ fontSize: '12px', marginTop: '6px' }}>
        {cardView ? 'Table View' : 'Card View'}
      </span>
      <Switch
        checked={cardView}
        onChange={(e) => setCardView(e.target.checked)}
      />
      </div>
      
      {/* <TableCardRFQ/> */}
      {
        cardView?
        <div>
          {
            loading?<Loading size="lg"/>:
            <div style={{display:'flex',flexDirection:'column',gap:'30px', marginTop:'20px'}}>
              {
                items.map(item=>{
                  return <TableCardRFQ item={item} actions={actions}/>
                })
              }
            </div>
          }
          <Pagination page={page} setPage={setPage} itemsPerPage={10} totalItems={count}/>
        </div>:
        <div>
          <Table
            columns={columns}
            items={items}
            actions={actions}
            searchBar={false}
            serverPagination={true}
            getData={getData}
            filterSearch={filterSearch}
          />
        </div>
      }
      <TagsModal open={open} setOpen={setOpen} serviceId={currentItem?.id}/>
    </div>
  );
};

export default TableQuotation;
