import { Modal, Radio, styled, Textarea } from '@nextui-org/react'
import React, { useState } from 'react'
import Button from '../../../components/Button/Button'

const StyledModal = styled(Modal, {
    display:'flex',
    padding: '20px',
    color: '$primary_500 !important',
    background: '$neutral_50',
})

export default function RejectModal({open,setOpen}) {

    const [note, setNote] = useState("")
    const [rejectReason, setRejectReason] = useState("")

    function handleReject(){
        console.log("aaa")
    }
    return (
        <StyledModal
            open={open}
            onClose={() => setOpen(false)}
            closeButton
            aria-label="modal-title"
            width="fit-content"
        >
            <h3 style={{ margin: '20px' }}>Confirma que desea rechazar todas las ofertas</h3>
            <Radio.Group label="Motivo :" size='sm' value={rejectReason} onChange={(value) => setRejectReason(value)}>
                <Radio value="costs">Costo Elevado</Radio>
                <Radio value="budget">Cotización Presupuestaria</Radio>
                <Radio value="time">Tiempos de Transito Elevados </Radio>
                <Radio value="other">Otro </Radio>
            </Radio.Group>
            {rejectReason=='other' && <Textarea label="Otra razon :" value={note} onChange={(e)=>setNote(e.target.value)}/>}
            <Modal.Footer>
                <Button text="Cancelar" bold outline handler={() => { setOpen(false) }} />
                <Button text="Confirmar" textColor={'neutral_50'} disabled={true} bold outline bgColor={'delete'} handler={() => handleReject()} />
            </Modal.Footer>
        </StyledModal>
  )
}
