import { Progress, styled } from "@nextui-org/react";
import React, { useState } from "react";
import theme from "../../theme";
import Icon from "../Icon/Icon";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import Switch from '@mui/material/Switch';
import Cloropeth from "./Cloropeth";
import styles from "./cloropeth.module.scss";

const Container = styled("div", {
  width: '80%',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
});

const Header = styled("div", {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '1rem',
  borderBottom: '1px solid #ccc',
});

const TitleContainer = styled("div", {
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
});

const StyledIcon = styled(Icon, {
  color: theme.colors.secondary_200,
});

const SwitchContainer = styled("div", {
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
});

const MapContainer = styled("div", {
  border: '2px solid grey',
  borderRadius: '6px',
  overflow: 'hidden',
});

const NoDataMessage = styled("p", {
  textAlign: 'center',
  color: theme.colors.neutral_300,
});

function CloropethContainer({ data, title, icon }) {
  const [view, setView] = useState('origin');
  const [checked, setChecked] = useState(true);
  const [content, setContent] = useState("");
  const { t: translate } = useTranslation("Home");

  const toggleView = (event) => {
    setView(event.target.checked ? 'destination' : 'origin');
    setChecked(event.target.checked);
  };

  return (
    <Container>
      <Header>
        <TitleContainer>
          <StyledIcon name={icon} size="24px" />
          <p>{title}: {view}</p>
        </TitleContainer>
        <SwitchContainer>
          <span>Origen</span>
          <Switch checked={checked} onChange={toggleView} inputProps={{ 'aria-label': 'controlled' }} />
          <span>Destino</span>
        </SwitchContainer>
      </Header>
      <MapContainer>
        <Cloropeth setTooltipContent={setContent} data={data} view={view} />
        <Tooltip anchorId="mapclor" content={content} float />
      </MapContainer>
      <NoDataMessage>{translate("no-data")}</NoDataMessage>
    </Container>
  );
}

export default CloropethContainer;
