import React, { useEffect } from 'react';
import { styled } from '@nextui-org/react';
import theme from '../../../../theme';
import { useFieldArray, useForm, Controller } from 'react-hook-form';
import { GetDocumentData } from '../../../../services/user.services';
import { useSelector } from 'react-redux';
// Components
import PageTitle from '../../../../components/PageTitle/PageTitle';
import Input from '../../../../components/Input/Input';
import Select from '../../../../components/Select/Select';
import Button from '../../../../components/Button/Button';

const Banking = ({ setBankReferences }) => {
    const user = useSelector(state => state.user);
    const accountTypes = [
        {
            value: 'corriente',
            name: 'Cuenta Corriente'
        },
        {
            value: 'ahorro',
            name: 'Cuenta de Ahorro'
        },
        {
            value: 'nomina',
            name: 'Cuenta de Nómina'
        }
    ]
    const nameForm = 'banking'
    const { register, control, setValue, getValues, watch } = useForm({
        defaultValues: {
            [nameForm]: [{
                entity: '',
                branch: '',
                accountType: '',
                accountNumber: ''
            }]
        }
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: nameForm
    });
    watch(nameForm)
    useEffect(() => {
        GetDocumentData([], user.token, user.idUser, 'bankreferences').then(async (res) => {
            let data = await res.json();
            // Formatear los datos obtenidos para que coincidan con el formato de useFieldArray
            const formattedData = data.map(item => ({
                entity: item.entity,
                branch: item.branch,
                accountType: item.account_type.toLowerCase(),
                accountNumber: item.account_number
            }));
            // Actualizar el estado del formulario con los datos formateados
            setValue(nameForm, formattedData);
        });
    }, [user, setValue, nameForm, control]);
    const StyledBanking = styled('div', {
        [`.${theme} &`]: {
            margin: '3rem 8rem 0rem 8rem',
            padding: '3rem 8rem',
            backgroundColor: '$neutral_50',
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            ['main']: {
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem'
            },
            ['main section.form']: {
                display: 'grid',
                gridTemplateColumns: 'repeat(2, minmax(200px, 1fr))',
                gap: '0.5rem',
                ['.colspan-2']: {
                    gridColumn: '1 / 3',
                    ['&.input > *']: {
                        display: 'flex'
                    }
                }
            },
        }
    })

    const saveData = () => {
        setBankReferences(getValues()[nameForm])
    }

    useEffect(() => {
        saveData()
    }, []);

    return (
        <StyledBanking>
            <PageTitle>
                Referencia bancaria
            </PageTitle>

            <aside>
                Sí se trata de sociedad anónima no se requiere esta información.
            </aside>

            <main>
                {
                    fields.map((field, i) =>
                        <div key={field.id}>
                            <h5>Referencia No. {i + 1}</h5>
                            <section className='form'>
                                <Input register={register} onChange={saveData} name={`${nameForm}.${i}.entity`} label='Entidad' placeholder='Introducir' required></Input>
                                <Input register={register} onChange={saveData} name={`${nameForm}.${i}.branch`} label='Sucursal' placeholder='Introducir' required></Input>
                                <Controller
                                    control={control}
                                    name={`${nameForm}.${i}.accountType`}
                                    render={({ field: { onChange, value } }) => (
                                        <Select onChange={(value) => { onChange(value); saveData() }} name={`${nameForm}.${i}.accountType`} value={value} label='Tipo de cuenta' options={accountTypes} required></Select>
                                    )}
                                ></Controller>
                                <Input register={register} onChange={saveData} name={`${nameForm}.${i}.accountNumber`} label='No. de cuenta' placeholder='Introducir' type='text' required></Input>

                                <div className='d-flex align-items-end'>
                                    {
                                        i < fields.length - 1 ?
                                            <Button handler={() => { remove(i); saveData() }} text='Eliminar' outline></Button>
                                            :
                                            <Button handler={() => { append(); saveData() }} text='Agregar otro' startIcon={{ name: 'Plus', size: '16' }} outline></Button>
                                    }
                                </div>
                            </section>
                        </div>
                    )
                }
            </main>
        </StyledBanking>
    );
}

export default Banking;
