import React, { useEffect } from "react";
import { useState } from "react";
import InputTable from "../../../../../components/InputTable/InputTable";
import { useFormContext } from "react-hook-form";
import { getTrms } from "../../../../../services/kpis.services";
import { useSelector } from "react-redux";
import { getProviders } from "../../../../../services/preliquidation.services";

export default function ProfitShareTable({tableName, id, conceptSelectOptions=false, providers}){
    const token = useSelector(state=>state.user.token)
    const methods = useFormContext()
    const [currencyOptions,setCurrencyOptions] = useState([])
    // const [providers, setProviders] = useState([])
    const [tableTotal, setTableTotal] = useState(0)

    useEffect(()=>{
        setCurrencyOptions([
            {label:'USD', value:1},
            {label:'COP', value:1/methods.getValues('shipping_information.TRM')},
            {label:'EUR', value:1/methods.getValues('shipping_information.usd_eur')},
        ])
        const items = methods.getValues(id)
    },[])

    // useEffect(()=>{
    //     getProviders(token).then(async res=>{
    //         const providersData = await res.json();
    //         setProviders(providersData)
    //     })
    // },[])

    const defaultData = {
        itemId:1,
        PS:"",
        Concepto: "",
        Proveedor: "",
    }

    const columns = [
        {name:"PS", type:'text'},
        {name:"Rate", type:'number'},
        {name:"Currency", type:'select', options:currencyOptions},
        {name:"Proveedor", type:'select', options: providers.map(prov => {return {
            label:prov.name,
            value:prov.id,
        }})},
    ]
    
    React.useEffect(() => {
        const subscription = methods.watch((value, { name, type }) => {
            if (name?.startsWith(id)){
                const items = methods.getValues(id)
                // const index = getIndex(name)
                let total = 0;
                items.forEach(item => {
                    const rowTotalUSD = item.Rate * item.Currency.value
                    total += Number(rowTotalUSD)
                })
                setTableTotal(total)
            }
        });
        return () => subscription.unsubscribe();
    }, [methods.watch]);
    
    return <div>
        <InputTable tableName={'PS'} columns={columns} defaultData={defaultData}/>
        <h4>Total en USD : {tableTotal.toFixed(2)}</h4>
    </div>

}