import format from "date-fns/format"

export const example = `
<p><strong>PLANTILLA DE EJEMPLO CONDICIONES DE LA OFERTA</strong></p><p>&nbsp;</p>
<p>A continuaci&oacute;n, se detallan las condiciones de la oferta de env&iacute;o:</p>
<ol>
    <li>Descripci&oacute;n del Env&iacute;o: [Descripci&oacute;n detallada del paquete, peso, dimensiones, contenido, etc.].</li>
    <li>Origen: [Origen del env&iacute;o].</li><li>Destino: [Destino del env&iacute;o].</li>
    <li>Fecha de Recogida: [Fecha de Recogida programada].</li>
    <li>Fecha de Entrega Estimada: [Fecha estimada de entrega].</li>
    <li>Costo Total: $[Costo Total del env&iacute;o], que incluye tarifas de env&iacute;o, impuestos y otros cargos.</li>
    <li>M&eacute;todo de Env&iacute;o: [Especificar el m&eacute;todo de env&iacute;o, como terrestre, a&eacute;reo, mar&iacute;timo, etc.].</li>
    <li>Seguro: [Especificar si el env&iacute;o incluye seguro y la cobertura].</li>
    <li>Condiciones Especiales: [Cualquier condici&oacute;n especial o instrucci&oacute;n relacionada con el env&iacute;o].</li>
</ol>`

export default function CreateExample(data) {
    return `
	<p><strong>Salidas semanales / TT: días Aprox</strong></p>
	<ul>
		<li>Registro fotografico si requiere: USD 50</li>
		<li>Salidas sujetas a confirmación de reserva, disponibilidad de espacio y/o equipo. Sujeta a confirmación por parte de la naviera.</li>
		<li>Los itinerarios están sujetos a modificaciones sin previo aviso.</li>
		<li>Carga debe estar lista en puerto 24 horas antes al cierre documental estipulado por la Naviera</li>
		<li>La carga deberá entregarse en las condiciones mínimas y necesarias de empaque, embalaje y etiquetado, requeridas por la naviera para su embarque.</li>
		<li>Tarifa para carga general, no peligrosa, no sobredimensionada y remontable, sujetas a capacidad máxima de dimensiones y peso por pieza según Transportista.</li>
		<li>Si la carga es declarada como DG, debe contar con la documentación, empaque y etiquetado, establecidas por la regulación general de la OACI.</li>
		<li>Todos los valores mencionados en la presente cotización son estimados y se calculan con base en la información suministrada. Tarifas sujetas a dimensiones, pesos y condiciones finales de la carga al; momento de la recepción o entrega.</li>
		<li>La Pre-liquidación no constituye una factura; la misma está sujeta a validación y los valores reales se liquidarán al momento de facturación</li>
		<li>Los costos mencionados previamente no incluyen IVA 19%, ni recargos de VAT de terceros países.</li>
		<li>En caso de generarse Pagos a Terceros por operaciones adicionales no especificadas en la cotización serán facturados.</li>
		<li>Las tarifas no incluyen costos de cargue o descargues adicionales, no incluyen costos portuarios, movilizaciones para inspección y otros costos no especificados en la oferta.</li>
		<li>La cotización no incluye costos portuarios ni de aduana de importación o exportación. En caso de liquidarse, estos costos son estimados y no representan el valor definitivo. Se liquidan presupuestalmente.</li>
		<li>Tarifas y recargos basados en las actuales condiciones del mercado, sujeto a cambio sin previo aviso de acuerdo a las políticas y regulaciones del transporte internacional.</li>
		<li>Los valores finales están expresados en Dólares Americanos (USD) referencialmente.</li>
		<li>Los montos liquidados están sujetos a variaciones por la moneda de origen, tipo de cambio a la fecha de embarque o emisión de la factura. Para liquidaciones de valores en COP a USD se toma como referencia un tipo de cambio de COP $TRM$</li>
		<li>Servicio de transporte no incluye costos de Cargue ni descargue o movimientos adicionales.</li>
		<li>Las tarifas se pre-liquidan sobre el mayor peso entre el peso físico y el peso volumétrico.</li>
		<li>Si requiere Valor del Seguro depende del valor de la mercancía. 0.35% over CIF value Min USD 50.00.</li>
		<li>En caso de requerir transporte local, considerar que las tarifas no incluyen costos de cargue o descargue al o desde el vehículo.</li>
		<li>En caso de requerir costos de cargue y descargue, deberá de garantizar el ingreso de los equipos y del personal al lugar de la operación. De la misma manera de deberá informar de los requerimientos para el ingreso de los mimos.</li>
		<li>Deberá especificarse el valor de la mercancía, para el transporte terrestre; si este es superior a USD $125.000.00, se procede a generar una extensión sobre la póliza de la compañía transportadora para cubrir el monto restante.</li>
		<li>De acuerdo al tipo de carga y al valor de la misma se procederá a considerar el servicio de escolta adicional de acuerdo a solicitud del area de seguridad de la empresa transportadora.</li>
		<li>Se cuenta con 2 horas libres para cargue y descargue, tarifa de standby sujeta a tipo de vehículo y servicio.</li>
	</ul>
	<p><strong>FACTURACION:</strong></p>
	<ul>
		<li>Las facturas emitidas se deberán pagar a la TRM más alta, entre la TRM de fecha de referencia de esta factura + 60COP VS la TRM del día de pago + 60 COP, siempre y cuando la TRM no sea inferior a la de referencia de factura.</li>
	</ul>
	<p><strong>RESPONSABILIDADES:</strong></p>
	<ul>
		<li>VAL LOGISTICS tiene la responsabilidad de velar por la adecuada selección y contratación de transportadores y proveedores de servicios logísticos, así como la correcta celebración de los respectivos contratos, con base en la información suministrada por el cliente.</li>
		<li>VAL LOGISTICS limita su responsabilidad a las cláusulas legales de límite de responsabilidad y coberturas estipuladas por las aseguradoras de las empresas participantes en cada contrato de transporte y nunca será mayor a la asumida por los transportadores aéreos, marítimos y terrestres con nosotros.</li>
		<li>Es responsabilidad del cliente entregar la mercancía debidamente embalada para el transporte internacional según sea la modalidad, en caso de requerir embalaje o adecuación de la carga esta será cotizada puntualmente.</li>
	</ul>
	<p><strong>RECLAMACIONES:</strong></p>
	<ul>
		<li>En caso de generarse algún tipo de reclamación ya sea por faltantes o daños a la mercancía, se deberán tener en cuenta los siguientes parámetros de tiempo, de acuerdo a la modalidad de transporte:</li>
		<li>Para carga marítima: 5 días hábiles después del arribo de la motonave a puerto.</li>
		<li>Para carga Aérea: 5 días hábiles después de la llegada del vuelo.</li>
		<li>Para carga terrestre: 5 días hábiles después de la llegada de la mercancía al destino contratado.</li>
		<li>Cualquier reclamación que se genere por siniestro, pérdida, daño, retraso en la entrega de la mercancía no exime al cliente de su responsabilidad de cancelar a VAL LOGISTICS., el pago total de su factura, es condición fundamental para presentar reclamaciones por el servicio prestado ante el respectivo tercero.</li>
		<li>Las multas y sanciones aduaneras que se originen por información errónea por parte del proveedor, serán asumidas por el importador.</li>
	</ul>
	<p><strong>AUTORIZACIÓN DE DATOS:</strong></p>
	<ul>
		<li>Por medio del presente documento autorizo de manera expresa e irrevocable a VAL LOGISTICS con NIT 901.231.618-0 o a quien represente sus derechos u ostenten en el futuro a cualquier título o la calidad de acreedor, a consultar, solicitar, suministrar, reportar, procesar, divulgar, y mantener actualizada toda la información que se refiera a mi comportamiento crediticio, financiero y comercial a cualquier base de datos y o a las centrales de información financiero y/o riesgos o a quien represente sus derechos.</li>
		<li>Usted podrá en cualquier momento, ejercer su derecho de conocer, actualizar, rectificar, solicitar, ser informado, revocar y/o solicitar la supresión de sus datos, así como acceder a sus datos personales que han sido objeto de tratamiento, en Bogotá: Carrera 55 # 152b-68 Torre A Oficina 1204 Bogotá Colombia – Oficial de Cumplimiento.</li>
		<li>VAL LOGISTICS´S.A.S, se compromete a no utilizar sus datos para un uso distinto a aquel por el que fueron recaudados, de acuerdo con la ley.</li>
	</ul>
    `
}