import { toast as banner } from "sonner";
import Toast from "./Toast";

const types = {
    info: {
        icon: 'InfoModal',
        iconColor: '#6195FC',
        descriptionColor: '#303C90',
        background: '#E8EFFE',
        borderColor: '#6195FC'
    },
    error: {
        icon: 'CloseModal',
        iconColor: '#FF676C',
        descriptionColor: '#CD5D61',
        background: '#FCEBEC',
        borderColor: '#FF676C'
    },
    success: {
        icon: 'CheckModal',
        iconColor: '#67CA7D',
        descriptionColor: '#3F7B4C',
        background: '#E4FFEA',
        borderColor: '#67CA7D'
    },
    warn: {
        icon: 'WarnModal',
        iconColor: '#F1B254',
        descriptionColor: '#D99135',
        background: '#FDF5EB',
        borderColor: '#F1B254'
    },
}

const toast = (type, description, title, moveLeft="0px") => {
    const toastId = banner(
        <Toast duration={10000} dismissible handlerClose={() => banner.dismiss(toastId)} icon={{ name: types[type].icon, color: types[type].iconColor }} title={title} description={description} descriptionColor={types[type].descriptionColor}/>,
        {
            style: {
                background: types[type].background,
                border: `1px solid ${types[type].borderColor}`,
                position:'relative',
                display:'flex',
                flexDirection:'column',
                right:moveLeft,
            },
        }
    );
    return toastId;
}

export default toast;