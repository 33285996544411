import React, { useEffect, useState } from 'react';
import { styled } from '@nextui-org/react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Icon from '../../components/Icon/Icon';
import theme from '../../theme';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import toast from '../../components/Toast/ToastTypes';

import PageTitle from '../../components/PageTitle/PageTitle';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';

import { getTrackingDetails } from '../../services/tracking.services';

//Libreria Traduccion
import { useTranslation } from "react-i18next";

const StyledServices = styled('article', {
    [`.${theme} &`]: {
        padding: '3rem 2rem',
        display: "flex",
        height: "100%",
        flexDirection: "column",
        gap: '1.5rem',
        ['.header']: {
            display: 'flex',
            gap: '3rem',
        },
        ['.container']: {
            padding: '15px',
        },
    }
});

const StyledCard = styled("div", {
    backgroundColor: '$neutral_50',
    padding: '40px',
    borderRadius: '8px',
    display: 'flex !important',
    flexDirection: 'row !important',
    justifyContent: 'space-between',
    ['.text']: {
        width: '48%',
    },
    h1: {
        width: 'fit-content',
        color: '$secondary_300',
        fontSize: '36px',
        fontWeight: '$semibold'
    },
    h3: {
        fontSize: '20px',
        fontWeight: '$semibold',
    }
});

const StyledInfo = styled("div", {
    display: 'flex',
    justifyContent: 'space-around',
    margin: '10px',
    width: '600px',
    ["div"]: {
        display: 'flex',
        gap: '20px',
        ["div"]: {
            flexDirection: 'column',
            gap: '5px',
        }
    }
})
const StyledLocation = styled("div", {
    border: '1px solid $primary_100',
    margin: '10px',
    borderRadius: '8px',
    padding: '10px',
    ['&:hover']: {
        bgColor: '$neutral_100',
        cursor: 'pointer',
    }
})
const StyledEvent = styled("div", {
    display: 'flex',
    justifyContent: 'space-between',
    border: '1px solid $primary_100',
    borderRadius: '8px',
    padding: '10px',
    ['&:hover']: {
        bgColor: '$neutral_100',
        cursor: 'pointer',
    }
})


const steps = [
    { label: 'Recolectado', completado: true, description: '2023-09-21 10:00' },
    { label: 'Confirmado abordo', completado: true, description: '2023-09-21 11:00' },
    { label: 'Estimado arribo', completado: false, description: '2023-09-21 12:00' },
    { label: 'Arribo a destino', completado: false, description: '' },
    { label: 'Liberación de documentos', completado: false, description: '' },
    { label: 'Entrega final', completado: false, description: '' }
];

const StepCard = ({ imgSrc, index, title, description }) => (
    <div className='container'>
        <StyledCard>
            <img src={imgSrc} alt={"paso" + (index + 1)} />
            <div className='text'>
                <h1>0{index + 1}</h1>
                <h3>{title}</h3>
                <p>{description}</p>
            </div>
        </StyledCard>
    </div>
);

const TrackingResults = ({ data }) => {

    const [mapLocation, setMapLocation] = useState(data.origin.address.addressLocality)

    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
    const handleReset = () => {
        setActiveStep(0);
    };

    return (
        <StyledServices>
            <div style={{ display: 'flex', gap: '20px' }}>
                <Icon name='Aereo' color='#EB9D3A' size='40' />
                <PageTitle showIcon={false}>Servicio # {data.id}</PageTitle>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <div style={{ marginRight: '50px' }}>
                    <iframe
                        width="600"
                        height="500"
                        style={{ border: '1px solid #BDBDBD', borderBottom: '2px solid #BDBDBD' }}
                        loading="lazy"
                        allowFullScreen
                        referrerPolicy="no-referrer-when-downgrade"
                        src={`https://www.google.com/maps/embed/v1/place?key=${import.meta.env.VITE_GOOGLE_KEY}=${mapLocation}`}
                    />
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <StyledInfo>
                            <StyledLocation>
                                <Icon name='Rastreo' size='35' />
                                <div onClick={() => setMapLocation(data.origin.address.addressLocality)}>
                                    <p>Origen : {data.origin.address.addressLocality}</p>
                                    {/* <p>01 sep 2022, 12:00 am</p> */}
                                </div>
                            </StyledLocation>
                            <StyledLocation>
                                <Icon name='RastreoFill' size='35' />
                                <div onClick={() => setMapLocation(data.destination.address.addressLocality)}>
                                    <p>Destino: {data.destination.address?.addressLocality}</p>
                                    {/* <p>05 sep 2022, 08:00 am</p> */}
                                </div>
                            </StyledLocation>
                        </StyledInfo>
                    </div>
                </div>
                <div>
                    <PageTitle>Estados</PageTitle>
                    <Box sx={{ maxWidth: 400 }}>
                        <Stepper activeStep={activeStep} orientation="vertical">
                            {steps.map((step, index) => (
                                <Step key={step.label}>
                                    <StepLabel
                                        optional={
                                            index === 6 ? (
                                                <Typography variant="caption">Last step</Typography>
                                            ) : null
                                        }
                                    >
                                        {step.label}
                                    </StepLabel>
                                    <StepContent>
                                        <Typography>{step.description}</Typography>
                                        <Box sx={{ mb: 2 }}>
                                            <div>
                                                <Button
                                                    variant="contained"
                                                    onClick={handleNext}
                                                    sx={{ mt: 1, mr: 1 }}
                                                >
                                                    {index === steps.length - 1 ? 'Finish' : 'Continue'}
                                                </Button>
                                                <Button
                                                    disabled={index === 0}
                                                    onClick={handleBack}
                                                    sx={{ mt: 1, mr: 1 }}
                                                >
                                                    Back
                                                </Button>
                                            </div>
                                        </Box>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                </div>
            </div>
            <h2>Eventos</h2>
            {
                data.events.map((event, index) => {
                    return (
                        <StyledEvent onClick={() => {
                            setMapLocation(event.location.address.addressLocality)
                            window.scrollTo({ top: 60, behavior: 'smooth' })
                        }}>
                            <p>{data.events.length - index} - {event.description}</p>
                            <p>{event.timestamp}</p>
                        </StyledEvent>
                    )
                })
            }
        </StyledServices >
    );
};
export default TrackingResults;
