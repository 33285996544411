import React, { useState } from "react";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import styles from "./Login.module.scss";
import toast from "../../components/Toast/ToastTypes"
import { connect } from "react-redux";
import { loginAction } from "../../store/sessionDuck";
import { styled } from "@nextui-org/react";
import theme from "../../theme";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import shipImg from '../../assets/sendMethods/land.jpg'

import { useTranslation } from "react-i18next";


function Login({ loginAction }) {
  const { register, handleSubmit, setValue, watch, getValues } = useForm();

  const doLogin = (credentials) => {
    loginAction(credentials);
  };

  const StyledForm = styled("form", {
    [`.${theme} &`]: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem'
    }
  })

  const StyledRastreo = styled("div", {
    [`.${theme} &`]: {
      display: "flex",
      flexDirection: "column",
      padding: "0rem 4rem 1rem 4rem",
      h2: {
        color: "$primary_300",
      },
      label: { color: "$primary_500" },
      [".rastreo"]: {
        display: "grid",
        gridTemplateColumns: "3.5fr 1fr",
        alignItems: "end",
        gap: "1rem",
      },
    },
  });
  //console.info("API BACKEND:", import.meta.env.VITE_BACKEND_URL)
  return (
    <section className={styles.login}>
      <div className={styles.login_form}>
        <h2>¡Bienvenido de nuevo!</h2>
        <p>Ingresa tus datos para iniciar sesión</p>
        <StyledForm onSubmit={handleSubmit(doLogin)}>
          <Input
            register={register}
            name="email"
            label="Correo"
            labelColor="neutral_50"
            placeholder="Ingresar el correo"
            selectBorderColor="secondary_200"
            /*Email on change */
            onChange={e => setValue('email', e.target.value)}
            value={getValues().email}
          />
          <Input
            register={register}
            password
            name="password"
            label="Contraseña"
            labelColor="neutral_50"
            placeholder="Ingresar la contraseña"
            selectBorderColor="secondary_200"
            /*Password onchange */
            onChange={e => setValue('password', e.target.value)}
            value={getValues().password}
          />
          <Link to="/forgot-password">Olvidé mi contraseña</Link>
          <Button
            type="submit"
            bgColor="secondary_200"
            text="Iniciar sesión"
            textColor="white"
          />
        </StyledForm>
        <div className={styles.provider_login}>
          <p>¿No tienes una cuenta con nosotros?</p>
          <Link to="/register">Registrate</Link>
        </div>
        <div className={styles.provider_login}>
          <p>¿Eres proveedor?</p>
          <Link to="/provider-chat">Ingresa al chat</Link>
        </div>
      </div>
      <div className={styles.val_rastreo}>
        <img src="/home.svg" alt="" />
        {/* <div style={{display:'flex',flexDirection:'column', padding:'25px'}}>
          <img src="/rodatech_logo.png" alt="" width='200px'/>
          <img src={shipImg} alt="" style={{margin:'auto', width:'90%', borderRadius:'8px'}}/>
        </div> */}
        
        <StyledRastreo>
          <h2>Sigue tu envío</h2>
          <div className="rastreo">
            <Input
              label="Ingresa tu número de radicado"
              placeholder="#00000"
              className={styles.o_input}
              animated={false}
              name="radicado"
            // onChange={handleChange}
            />
            <Link to="/rastreo">
              <Button bgColor="primary_400" text="Rastrear" textColor="white" />
            </Link>
          </div>
        </StyledRastreo>
      </div>
    </section>
  );
}

const mapState = ({ user: { } }) => {
  return {};
};

export default connect(mapState, { loginAction })(Login);
