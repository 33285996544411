import { styled } from '@nextui-org/react'
import React, { useState } from 'react'
import Input from '../../components/Input/Input'
import PageTitle from '../../components/PageTitle/PageTitle'
import Button from '../../components/Button/Button'
import { getTrackTrace } from '../../services/tracking.services'
import { useSelector } from 'react-redux'

const Container = styled("div",{
    display:'flex',
    flexDirection:'column',
    gap:'20px',
    background:'white',
    boxShadow:'$elevation_1',
    margin:'30px',
    padding:'30px',
    borderRadius:'8px',
})

export default function FreeTracking() {
    const token = useSelector(state=>state.user.token)
    const [search, setSearch] = useState("")
    const [html, setHtml] = useState("")

    function handleSearch(){
        getTrackTrace(token, search).then(async res => {
            const trackingData = await res.json();
            setHtml(trackingData.html)
        })
    }

    return (
        <Container>
            <PageTitle>Tracking</PageTitle>
            <div style={{display:'flex', width:'80%', gap:'20px', alignItems:'end'}}>
                <Input label="Nro del contenedor :" fullWidth value={search} onChange={(e)=>setSearch(e.target.value)}/>
                <Button text='Buscar' bgColor='primary_500' textColor='white' handler={()=>handleSearch()}/>
            </div>
            <div dangerouslySetInnerHTML={{__html: html}} style={{marginTop: '20px'}} />
        </Container>
    )
}
