"use client";
import React, { useState } from "react";
import { Theme, useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ReactCountryFlag from "react-country-flag";
import {
  InputAdornment,
  ListItemIcon,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { FaCheck } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { useTranslation } from "react-i18next";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface CountryFilterProps {
  selectedCountries: string[];
  setSelectedCountries: (countries: string[]) => void;
}

const countries = [
  { value: "UNITEDSTATES", label: "United States", code: "US" },
  { value: "FRANCE", label: "France", code: "FR" },
  { value: "GERMANY", label: "Germany", code: "DE" },
  { value: "SPAIN", label: "Spain", code: "ES" },
  { value: "ARGENTINA", label: "Argentina", code: "AR" },
  { value: "COLOMBIA", label: "Colombia", code: "CO" },
  { value: "AUSTRALIA", label: "Australia", code: "AU" },
  { value: "AFGHANISTAN", label: "Afghanistan", code: "AF" },
  { value: "PORTUGAL", label: "Portugal", code: "PT" },
  { value: "CHINA", label: "China", code: "CN" },
  { value: "ROMANIA", label: "Romania", code: "RO" },
  { value: "ALBANIA", label: "Albania", code: "AL" },
];


function getStyles(name: string, personName: readonly string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const CountryFilter: React.FC<CountryFilterProps> = ({
  selectedCountries,
  setSelectedCountries,
}) => {
  const theme = useTheme();
  const { t: translate } = useTranslation("Services");


  const handleChange = (event: SelectChangeEvent<typeof selectedCountries>) => {
    const {
      target: { value },
    } = event;
    setSelectedCountries(typeof value === "string" ? value.split(",") : value);
  };

  const sortedCountries = [
    ...countries.filter((country) => selectedCountries.includes(country.value)),
    ...countries.filter((country) => !selectedCountries.includes(country.value)),
  ];



  return (
    <FormControl sx={{width:"100%"}}>
      <Select
        multiple
        displayEmpty
        value={selectedCountries}
        onChange={handleChange}
        input={
          <OutlinedInput
            startAdornment={
              <InputAdornment position="start">
                <FaLocationDot />
              </InputAdornment>
            }
          />
        }
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <p className="text-gray-500">{translate("select")} {translate("country")} </p>;
          }
          return selected.map((value, index) => {
            const country = countries.find((country) => country.value === value);
            if (country) {
              return (
                <span key={value}>
                  <ReactCountryFlag
                    countryCode={country.code}
                    svg
                    style={{ marginRight: 8 }}
                  />
                  {country.label}
                  {index < selected.length - 1 ? ", " : ""}
                </span>
              );
            }
            return null;
          });
        }}
        MenuProps={MenuProps}
        inputProps={{ "aria-label": "Without label" }}
        sx={{ backgroundColor: "white" }}
        size="small"
      >
        {sortedCountries.map((country) => (
          <MenuItem
            key={country.value}
            value={country.value}
            style={getStyles(country.label, selectedCountries, theme)}
          >
            <div className="flex justify-between w-[100%] items-center">
              <div>
                <ReactCountryFlag
                  countryCode={country.code}
                  svg
                  style={{ marginRight: 8 }}
                />
                {country.label}
              </div>

              <ListItemIcon>
                {selectedCountries.includes(country.value) ? <FaCheck /> : null}
              </ListItemIcon>
            </div>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default CountryFilter;
