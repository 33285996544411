import { Dropdown, styled } from '@nextui-org/react'
import React from 'react'
import Icon from '../Icon/Icon'
import ReactCountryFlag from 'react-country-flag'
import { wrap } from 'lodash'
import { useTranslation } from "react-i18next";

const Container = styled("div",{
  background:'white',
  minWidth:'1100px',
  boxShadow:'$elevation_1',
  width:'100%',
  borderRadius:'8px',
  height:'144px',
  display:'grid',
  paddingTop:'4px',
  gridTemplateColumns:'70% 25% 5%',
  transition:'100ms',
  '.big_cell':{
    borderRight:'1px solid lightgrey',
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    '.departure':{
      width:'90%',
      display:'flex',
      justifyContent:'end',
      fontSize:'10px',
    }
  },
  '.label_data': {
    display: 'flex',
    flexDirection: 'column',
    'b': {
      fontSize: '9px',
      wordWrap: 'break-word',
    }
  },
  '.label':{
    color:'grey',
    fontSize:'10px'
  },
  "&:hover":{
    boxShadow:'$elevation_3',
  }
})

const SmallCells = styled("div",{
  display:'flex',
  flexDirection:'column',
  '.top_cells':{
    display:'grid',
    gridTemplateColumns:'20% 20% 20% 20% 20%',
    height:'115px',
    '.column':{
      borderRight:'1px solid lightgrey',
      display:'flex',
      flexDirection:'column',
      gap:'3px',
      padding:'5px',
      paddingLeft:'10px',
      height:'130px',
      paddingTop:'8px',
      fontSize:'12px',
    },
  },
  '.bottom_cell':{
    width:'100%',
    height:'25px',
    paddingLeft:'20px',
    background:'$primary_50',
    borderRadius:'4px',
    'a':{
      color:'darkblue',
      fontSize:'11px',
      marginRight:'20px',
    }
  }
})

const ShipmentContainer = styled("div", {
  width:'100%',
  gridColumn:'span 2',
  display:'flex',
  justifyContent:'space-around',
  alignItems:'center',
  padding:'20px 10px',
  marginTop:'10px',
  borderRadius:'6px',
  'span':{fontSize:'8px'},
  'b':{fontSize:'8px'},
  'div':{
    display:"flex",
    flexDirection:'column',
    justifyContent:'end',
  },
})

const StyledActions = styled("div", {
  backgroundColor: "$primary_50",
  color: "$primary_400",
  display: "flex",
  cursor: "pointer",
  padding: "0.7rem",
  borderRadius: "30px",
  // [".nextui-button-text"]: {
  // gap: "0.4rem",
  // },
});

export default function TableCardRFQ({item,actions}) {
  let status = 1
  console.log("item.ready :",item.ready)
  if (item.ready=="Si"){
    status = 3
  }
  const { t: translate } = useTranslation("Quotation");

  const handlerAction = (key, item) => {
    actions[key].handle(item);
  };

  return (
    <Container>
      <SmallCells>
        <div className='top_cells'>
          <div className='column'>
            <b className='label'>{translate("status")}</b>
            <b style={status == 1 ? {borderBottom:'3px solid green', width:"fit-content"} : {}}>1 - {translate("request")}</b>
            <b style={status == 2 ? {borderBottom:'3px solid green', width:"fit-content"} : {}}>2 - {translate("liquidation")}</b>
            <b style={status == 3 ? {borderBottom:'3px solid green', width:"fit-content"} : {}}>3 - {translate("ready_for_approval")}</b>
          </div>
          <div className='column'>
            <div className='label_data'>
              <b span className='label'>{translate("radicate")}</b>
              <b>{item?.idRadication}</b>
            </div>
            <div className='label_data'>
              <span className='label'>{translate("purchase_order")}</span>
              <b>{item?.purchaseOrder}</b>
            </div>
          </div>
          <div className='column'>
            <b className='label'>{translate("client")}</b>
            <div className='label_data' style={{display:'flex', flexDirection:'row', gap:'3px'}}>
              <span className='label'>{translate("organization")} :</span>
              <b>{item?.clientOrganization}</b>
            </div>
            <div className='label_data' style={{display:'flex', flexDirection:'row', gap:'3px'}}>
              <span className='label'>{translate("name")}: </span>
              <b>{item?.client}</b>
            </div>
            <div className='label_data'>
              <span className='label'>{translate("email")} :</span>
              <b>{item?.clientData?.email}</b>
            </div>
          </div>
          <div className='column'>
            <b className='label'>{translate("load")}</b>
            <div className='label_data' style={{display:'flex', flexDirection:'row', gap:'5px'}}>
              <span className='label'>Tipo : </span>
              <b>{item?.load}</b>
            </div>
            <div className='label_data' style={{display:'flex', flexDirection:'row', gap:'5px'}}>
              <span className='label'>{translate("loads")} : </span>
              <b>{item?.loads?.amount}</b>
            </div>
            <div className='label_data' style={{flexDirection:'row', gap:'5px'}}>
              <span className='label'>{translate("total_weight")} :</span>
              <b>{item?.loads?.weight} Kg</b>
            </div>
            <div className='label_data' style={{flexDirection:'row', gap:'5px'}}>
              <span className='label'>{translate("total_volume")} :</span>
              <b>{item?.loads?.volume}cm³</b>
            </div>
          </div>
          <div className='column'>
            <b className='label'>{translate("offers")}</b>
            {
              item.offerPrices.length?
              item.offerPrices?.map((offer,i)=>(
                <div className='label_data' style={{flexDirection:'row', gap:'5px'}}>
                  <span className='label'>{translate("option")} {i+1} : </span>
                  <b>{offer}</b>
                </div>
              )):
              <b>{translate("no_offers")}</b>
            }
          </div>
        </div>
        <div className='bottom_cell'>
          <a>{translate("documents")} (0)</a>
        </div>
      </SmallCells>
      <div className='big_cell'>
        <ShipmentContainer>
          <div>
            <ReactCountryFlag countryCode={item?.origin?.code} svg style={{ width: "2em", height: "1.8em", borderRadius: "6px", margin:'auto'}}/>
            <b>{item?.shipping?.origin_boarding_terminal?.city?.name}, {item?.origin?.name}</b>
            <span>{item?.shipping?.pickup_address}</span>
          </div>
          <Icon name='ArrowRight' size='23'/>
          <div>
              <Icon 
                name={
                  item?.method.name == "Aereo" ?"Aereo":
                  item?.method.name == "Terrestre" ?"Terrestre":
                  "Maritimo"
                } 
                size="25"
              />
              <h4>{item?.incoterm}</h4>
          </div>
          <Icon name='ArrowRight' size='23'/>
          <div>
            <ReactCountryFlag countryCode={item?.destination?.code} svg style={{ width: "2em", height: "1.8em", borderRadius: "6px", margin:'auto'}}/>
            <b>{item?.shipping?.destination_boarding_terminal?.city?.name}, {item?.destination?.name}</b>
            <span>{item?.shipping?.delivery_address}</span>
          </div>
        </ShipmentContainer>
        <span className='departure'>{translate("creation_date")} • {item?.date}</span>
      </div>
      <div style={{display:"flex",paddingBottom:"20px", justifyContent:'center',alignItems:'center',}}>
        <Dropdown placement="bottom-left">
            <Dropdown.Trigger>
                <StyledActions className="theme">
                    <Icon name="MenuVertical" size="14"></Icon>
                </StyledActions>
            </Dropdown.Trigger>
            <Dropdown.Menu
              onAction={(key) => handlerAction(key, item)}
              aria-label="Table actions"
              css={{ minWidth: "100px" }}
            >
              {actions.map((element, i) => (
                <Dropdown.Item
                  key={i}
                  onClick={() => element.handle(item)}
                  icon={<Icon name={element.icon}></Icon>}
                >
                {element.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
        </Dropdown>
      </div>
    </Container>
  )
}
