import React from "react";
import { Typography } from "@mui/material";
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

function ScatterPlot({ data }) {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Containers Plot of Variables
      </Typography>
      <ResponsiveContainer width="100%" height={300}>
        <ScatterChart>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" dataKey="x" name="X Value" unit="TEU" />
          <YAxis type="number" dataKey="y" name="Y Value" unit="USD" />
          <Tooltip cursor={{ strokeDasharray: "3 3" }} />
          <Scatter name="Data Points" data={data} fill="#8884d8" />
        </ScatterChart>
      </ResponsiveContainer>
    </>
  );
}

export default ScatterPlot;
