import React, { useEffect, useState } from 'react'
import { styled } from '@nextui-org/react'
import { usePDF } from 'react-to-pdf';
import ColoredPieChart from '../../components/Graphics/ColoredPieChart'
import DashboardHorizontalBarChart from '../../components/Graphics/HorizontalChart/DashboardHorizontalChart'
import Table from '../../components/Table/Table'
import Button from '../../components/Button/Button';
import { getQuotesByRange } from '../../services/quotations.services'
import { useSelector } from "react-redux";
import { getServicesSubType, getQuotationsBysubtype,getUsersOperationReport,getOperationKpis } from '../../services/kpis.services';
import { startOfMonth, format } from "date-fns";
import DatesRangePickerModal from '../../components/DateRangePicker/DateRangePicker';
import Input from '../../components/Input/Input';
import UltraHorizontalBarChart from '../../components/Graphics/HorizontalChart/UltraHorizontalBarChart';
import theme from '../../theme';
import useMediaQueries from '../../hooks/useMediaQueries';


const Container = styled("div",{
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: '20px',
    margin:'30px',
    "@media(max-width:1000px)":{
        display:'flex',
        flexDirection:'column',
        gap:'10px',
        'span':{
            fontSize:'10px'
        }
    },
})

const ChartContainer = styled("div", {
    background: 'white',
    borderRadius: '8px',
    padding: '20px',
    width: '100%',
    boxShadow:'$elevation_1',
})



export default function OperationsDashboard() {
    const {sm,md} = useMediaQueries()
    const [loadingPdf, setLoadingPdf] = useState(false)
    const [dataMethod, setdataMethod] = useState([]);
    const [kpis, setKpis] = useState(false)
    const [openDates, setOpenDates] = useState(false)
    const [dates, setDates] = useState({
        startDate: startOfMonth(new Date()),
        endDate: new Date(),
    });
    
    const colors = [theme.colors.primary_500.value, '#EB9D3A ', '#81D393','#5b658d']
    console.log("kpis :",kpis)

    const { toPDF, targetRef } = usePDF({filename: 'operations_dashboard.pdf'});
    const user = useSelector(state => state.user);
    function downloadPDF(){
        setLoadingPdf(true)
        toPDF().then(()=>{
            setLoadingPdf(false)
        }).catch((err)=>{
            console.log("error al descargar pdf :",err)
            setLoadingPdf(false)
        })
    }
    useEffect(() => {
        Promise.all([
            getQuotesByRange(user.token, format(dates.startDate, "yyyy-MM-dd"), format(dates.endDate, "yyyy-MM-dd"), "true"),
            getServicesSubType(user.token, format(dates.startDate, "yyyy-MM-dd"), format(dates.endDate, "yyyy-MM-dd"), "true"),
            getQuotationsBysubtype(user.token, format(dates.startDate, "yyyy-MM-dd"), format(dates.endDate, "yyyy-MM-dd"), "true"),
            getUsersOperationReport(user.token, format(dates.startDate, "yyyy-MM-dd"), format(dates.endDate, "yyyy-MM-dd"), "true"),
            getOperationKpis(user.token, format(dates.startDate, "yyyy-MM-dd"), format(dates.endDate, "yyyy-MM-dd"), "true"),
        ]).then(async res => {
            
            const requestData = await res[0].json()
            const servicesSubTypeData=await res[1].json()
            const quotationBySubtype=await res[2].json()
            const operationReportData=await res[3].json()
            const kpisData=await res[4].json()

            let importacionTotal = 0;
            let exportacionTotal = 0;
            let otrosTotal = 0;
            let aereosTotal=0;
            let maritimosTotal=0;
            let otrosTotalMethod=0;

            servicesSubTypeData.forEach(item => {
                if (item.name.toLowerCase().includes('importacion')) {
                    importacionTotal += item.value;
                } else if (item.name.toLowerCase().includes('exportacion')) {
                    exportacionTotal += item.value;
                } else {
                    otrosTotal += item.value;
                }
                if(item.name.toLowerCase().includes("maritima")){
                    maritimosTotal += item.value;
                }
                else if(item.name.toLowerCase().includes('aerea')){
                    aereosTotal+= item.value;
                }else{
                    otrosTotalMethod+= item.value;
                }
            });
            const agrupado = [
                { "name": "Importacion", "value": importacionTotal },
                { "name": "Exportacion", "value": exportacionTotal },
                { "name": "Otros", "value": otrosTotal }
            ];
            const agrupadoMethod = [
                { "name": "Maritimo", "value": maritimosTotal },
                { "name": "Aéreo", "value": aereosTotal },
                { "name": "Otros", "value": otrosTotalMethod }
            ];
            let mesesImportacion=[]
            let mesesExportacion=[]
            quotationBySubtype.forEach((item)=>{
                if(item.name.toLowerCase().includes('importacion')){
                    item.value.forEach((month)=>{
                        mesesImportacion.push(month)
                    })
                }
                else if(item.name.toLowerCase().includes('exportacion')){
                    item.value.forEach((month)=>{
                        mesesExportacion.push(month)
                    })
                }
            })
            const mergedDataImports = mesesImportacion.reduce((acc, item) => {
                const existingItem = acc.find(element => element.name === item.name);
                if (existingItem) {
                    existingItem.impos += item.solicitudes;
                } else {
                    acc.push({ name: item.name, impos: item.solicitudes });
                }
                return acc;
            }, []);
            const mergedDataExports = mesesExportacion.reduce((acc, item) => {
                const existingItem = acc.find(element => element.name === item.name);
                if (existingItem) {
                    existingItem.expos += item.solicitudes;
                } else {
                    acc.push({ name: item.name, expos: item.solicitudes });
                }
                return acc;
            }, []);
            setKpis({
                requests:requestData,
                operationsType:agrupado,
                method:agrupadoMethod,
                expo:mergedDataExports,
                impo:mergedDataImports,
                operationReport:operationReportData,
                ...kpisData,
            })
            setdataMethod(agrupadoMethod)
        })

    }, [user.token, dates]);

    const transformData = (kpis) => {
        const months = kpis.requests.map(req => req.name);
        const data = months.map(month => {
          const requestData = kpis.requests.find(req => req.name === month);
          const impoData = kpis.impo.find(impo => impo.name === month) || { impos: 0 };
          const expoData = kpis.expo.find(expo => expo.name === month) || { expo: 0 };
          return {
            label: month,
            expo: expoData.expo,
            impo: impoData.impos,
            requests: requestData.solicitudes,
          };
        });
        return data;
    };
    function formatKpi(data){
        const formated = Object.keys(data).map(key=>{
            return {
                name:key,
                operations:data[key],
            }
        })
        return formated;
    }
    function formatKpiPie(data){
        const formated = Object.keys(data).map(key=>{
            return {
                name:key,
                value:data[key],
            }
        })
        return formated;
    }

    const data = kpis && transformData(kpis)
    const operationsByClient =  kpis?.operations_by_client && formatKpi(kpis.operations_by_client)?.sort((a,b)=>b.operations - a.operations)?.slice(0,15)
    const operationsByAgent = kpis?.operations_by_agent && formatKpiPie(kpis.operations_by_agent)
    const operationsByType = kpis?.operations_by_type && Object.keys(kpis?.operations_by_type).map(key=>{
        return {
            label:key,
            operations : kpis?.operations_by_type[key],
        }
    })

    return (
    <>
        <div style={{ margin: '20px', display:'flex', justifyContent:'space-between', alignItems:'center' }}>
            <div onClick={() => {setOpenDates(true)}} style={{ display: "flex", gap: "20px", width: "fit-content" }}>
                <Input label={"desde"} value={format(dates.startDate, "dd/MM/yyyy")}/>
                <Input label={"hasta"} value={format(dates.endDate, "dd/MM/yyyy")}/>
            </div>
            <DatesRangePickerModal
                aria-labelledby="modal-date-range"
                setDates={setDates}
                open={openDates}
                setOpen={setOpenDates}
            />
           {md && <Button text={loadingPdf?'Cargando...':'Descargar pdf'} disabled={loadingPdf} handler={() => downloadPDF()} outline/>}
        </div>
        <Container ref={targetRef}>
            <ChartContainer style={{gridColumn:'span 4', minHeight:'500px'}}>
                <span>Importaciones, exportaciones y solicitudes</span>
                <UltraHorizontalBarChart
                    data={data}
                    barDataKeys={[
                        { key: 'expo', color: '#EB9D3A' },
                        { key: 'impo', color: theme.colors.primary_500.value },
                    ]}
                    lineDataKeys={[{ key: 'requests', color: '#81D393' }]}
                />
            </ChartContainer>
            <ChartContainer style={{gridColumn:'span 2'}}>
                <span>Operaciones por cliente (top 15 clientes)</span>
                <DashboardHorizontalBarChart height={200} color={theme.colors.primary_500.value} dataKey={"operations"} data={operationsByClient} marginLeft={md?210:50}/>
            </ChartContainer>
            <ChartContainer style={{gridColumn:'span 2'}}>
                <span>Operaciones por agente</span>
                <ColoredPieChart colors={colors} data={operationsByAgent} radius={md?100:70} legend={true}/>
            </ChartContainer >
            <ChartContainer >
                <span>Modalidad</span>
                <ColoredPieChart colors={colors} data={dataMethod} radius={md?100:70} legend={true}/>
            </ChartContainer >
            <ChartContainer>
                <span>Operaciones por tipo</span>
                <ColoredPieChart colors={colors} data={kpis?.operationsType} radius={md?100:70} legend={true}/>
            </ChartContainer>
            <ChartContainer style={{gridColumn:'span 2'}}>
                    <span>Operaciones por subtipo</span>
                    <UltraHorizontalBarChart
                        data={operationsByType}
                        barDataKeys={[{ key: 'operations', color: '#EB9D3A' }]}
                        lineDataKeys={[]}
                        angle={-45}
                    />
            </ChartContainer>
            {/* <ChartContainer >
                <Table columns={columns} items={items} excel={false} searchBar={false}/>
            </ChartContainer > */}
        </Container>
    </>    
  )
}