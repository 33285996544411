import React from 'react'
import { styled } from '@nextui-org/react'
import Icon from '../Icon/Icon'
import { debounce, isNaN } from 'lodash'

const Container = styled("div",{
    marginTop:'25px',
    border:'1px solid $neutral_100',
    background:'$neutral_50',
    boxShadow:'$elevation_1',
    width:'100%',
    height:'100px',
    padding:'30px',
    borderRadius:'6px',
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    'div':{
        display:'flex',
        gap:'10px',
    },
    'input':{
        width:'55px',
        border:'1px solid grey',
        borderRadius:'8px',
        paddingLeft:'10px',
    },
    'button':{
        width:'28px',
        background:'$neutral_200',
        border:'1px solid grey',
        fontWeight:'$bold',
        fontSize:'18px',
        borderRadius:'6px',
        transition:'300ms',
        '&:hover':{
            cursor:'pointer',
            transform:'scale(1.02)'
        }
    }
})

export default function Pagination({page, setPage, itemsPerPage, setItemsPerPage, totalItems}) {

    function goFirst(){setPage(1)}
    
    function goLast(){setPage(Math.ceil(totalItems/itemsPerPage))}

    function goBack(){
        if (page>1){
            setPage(page-1)
        }
    }
    function goNext(){
        if (page < Math.ceil(totalItems/itemsPerPage)){
            setPage(page+1)
        }
    }

    function handlePageChange(value){
        if (value < 1){
            setPage(1)
        } else
        if (value > Math.ceil(totalItems/itemsPerPage)){
            const maxPage = Math.ceil(totalItems/itemsPerPage)
            if (isNaN(maxPage)){
                setPage(1)
            } else {
                setPage(maxPage)
            }
        } else {
            setPage(value)
        }
    }
    const debouncedSearch = debounce((value) => {
        handlePageChange(value)
    }, 300);

    return (
    <Container>
        <div>
            Total:<b>{totalItems}</b>
        </div>
        <div>
            <button onClick={()=>goFirst()}>{'<<'}</button>
            <button onClick={()=>goBack()}>{'<'}</button>
            <input type="number" value={page} onChange={(e)=>debouncedSearch(e.target.value)}/>/{Math.ceil(totalItems/itemsPerPage)}
            <button onClick={()=>goNext()}>{'>'}</button>
            <button onClick={()=>goLast()}>{'>>'}</button>
        </div>
        <div>
            <label>Mostrar :</label>
            <input type="number" disabled value={itemsPerPage} onChange={(e)=>{setItemsPerPage(e.target.value)}}/>
        </div>
    </Container>
  )
}