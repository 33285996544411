import { Navigate, Outlet } from 'react-router-dom';

const RoleBasedRoute = ({ allowedRoles, userRoles }) => {
    return (
        userRoles.find(role => allowedRoles?.includes(role))
            ? <Outlet />
            : <Navigate to="/missing" />
    );
};

export default RoleBasedRoute;