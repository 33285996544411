import React from "react";
import PropTypes from "prop-types";
import styles from "./EmbedYoutube.module.scss";

const YoutubeEmbed = ({ embedId, title = "YouTube video" }) => (
  <div className={styles.videoResponsive}>
    <iframe
      src={`https://www.youtube.com/embed/${embedId}`}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title={title}
      loading="lazy"
      className={styles.iframe}
    />
  </div>
);

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default YoutubeEmbed;
