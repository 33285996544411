import { styled, Text } from "@nextui-org/react";
import React from 'react'
import theme from '../../theme';
import CheckBox from "../CheckBox/CheckBox";
import Button from "../Button/Button";

export default function HomeConfig({ setConfigOpen }) {

    const StyledConfig = styled("div", {
        [`.${theme} &`]: {
            position: 'absolute',
            top: '300px',
            right: '50px',
            width: '568px',
            backgroundColor: "$neutral_50",
            boxShadow: "$elevation_1",
            borderRadius: "8px",
            padding: "32px 24px",
            zIndex: 1,
        }
    })
    const CheckBoxContainer = styled("div", {
        width: '500px',
        display: 'flex',
        flexWrap: 'wrap',
        margin: '30px 0'
    })
    const BottomButtons = styled("div", {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px'
    })
    const DuoButtons = styled("div", {
        display: 'flex',
        gap: '10px',
    })

    return (
        <StyledConfig className="theme">
            <h3>Configuración vista :</h3>
            <Text color='#7A8699' textAling="left">
                Podras configurar la vista de tu tablero , debes <br></br> seleccionar mínimo 4 indicadores.
            </Text>
            <CheckBoxContainer>
                <CheckBox>Kg movidos</CheckBox>
                <CheckBox>M. cúbicos</CheckBox>
                <CheckBox>DIM</CheckBox>
                <CheckBox>Cotizaciones</CheckBox>
                <CheckBox>Servicios</CheckBox>
                <CheckBox>Gastos</CheckBox>
            </CheckBoxContainer>
            <BottomButtons>
                <Button text={"Limpiar selección"} bold={true} size='lg' />
                <DuoButtons>
                    <Button text={"Cancelar"} bold={true} size='lg' handler={() => setConfigOpen(false)} outline={true} />
                    <Button text={"Guardar"} bgColor={"primary_400"} textColor={"neutral_50"} bold={true} size='lg' />
                </DuoButtons>
            </BottomButtons>
        </StyledConfig>
    )
}
