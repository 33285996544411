import React, { useState } from "react";
import { styled } from "@nextui-org/react";
import theme from "../../theme";

// Components
import TreeChart from "../../components/Graphics/TreeChart/TreeChart";
import Steps from "../../components/StepsFormCO2/Steps";
import Card3 from "../../components/Cards/Card3/Card3";

// Images
import aerial from "../../assets/sendMethods/aerial.jpg";
import maritime from "../../assets/sendMethods/maritime.jpg";
import land from "../../assets/sendMethods/land.jpg";

import { useTranslation } from "react-i18next";

const ViewCO2 = () => {
  const { t: translate } = useTranslation("CO2");
  const [cards, setCards] = useState([
    {
      title: "Aéreo",
      image: aerial,
    },
    {
      title: "Marítimo",
      image: maritime,
    },
    {
      title: "Terrestre",
      image: land,
    },
  ]);

  const StyledViewCO2 = styled("section", {
    [`.${theme} &`]: {
      backgroundColor: "$primary_50",
      height: "100%",
      width: "100%",
      padding: "3rem 2rem",
      paddingBottom: "4rem",
      display: "flex",
      flexDirection: "column",
      gap: "2rem",
    },
    [".nextui-checkbox-container"]: {
      "--nextui--checkboxColor": "#3D509A",
      "--nextui--checkboxColorHover": "#3D509A",
      "--nextui--checkboxBorderColor": "#3D509A",
    },
    [".cards"]: {
      display: "flex",
      gap: "2rem",
    },
  });

  return (
    <StyledViewCO2>
      <Steps></Steps>
      <TreeChart></TreeChart>
      <div className="cards">
        {cards.map((element, i) => (
          <Card3 key={i} title={element.title} image={element.image}></Card3>
        ))}
      </div>
    </StyledViewCO2>
  );
};

export default ViewCO2;
