import React, {useEffect, useMemo, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import '@pqina/pintura/pintura.css';
import { openDefaultEditor } from '@pqina/pintura';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";


const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
  padding: 20
};
const thumb = {
  position: "relative",
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box"
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden"
};

const img = {
  display: "block",
  width: "auto",
  height: "100%"
};

const thumbButton = {
  position: "absolute",
  right: 10,
  bottom: 10,
  "border-radius": "5%",
};

const CloseButton = {
  position: "absolute",
  right: "10px",
  width: "1.5rem",
  height: "1.5rem",
  "border-radius": "50%",


};

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderColor: '#8d8d8d',
  borderStyle: 'dashed',
  borderRadius:'8px',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};
const focusedStyle = {
  borderColor: '#2196f3'
};
const acceptStyle = {
  borderColor: '#00e676'
};
const rejectStyle = {
  borderColor: '#ff1744'
};

const editImage = (image, done) => {
  const imageFile = image.pintura ? image.pintura.file : image;
  const imageState = image.pintura ? image.pintura.data : {};

  const editor = openDefaultEditor({
    src: imageFile,
    imageState
  });

  editor.on("close", () => {
    // the user cancelled editing the image
  });

  editor.on("process", ({ dest, imageState }) => {
    Object.assign(dest, {
      pintura: { file: imageFile, data: imageState }
    });
    done(dest);
  });
};

export default function DropZone({handleFiles, files}) {

  const {getRootProps,getInputProps,acceptedFiles,isFocused,isDragAccept,isDragReject,} = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      handleFiles([
        ...files,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      ]);
    }
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);

/*   const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  )); */

  const [activeDocument, setActiveDocument] = useState(null);

  const thumbs = files.map((file, index) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        {file.type.startsWith('image/') ? (
          <img src={file.preview} style={img} alt="" />
        ) : (
          <img src={file.preview} style={img} alt="" />
        )}
      </div>
      {file.type.startsWith('image/') ? (
        <button
          style={thumbButton}
          onClick={() =>
            editImage(file, (output) => {
              const updatedFiles = [...files];
  
              // replace original image with new image
              updatedFiles[index] = output;
  
              // revoke preview URL for old image
              if (file.preview) URL.revokeObjectURL(file.preview);
  
              // set new preview URL
              Object.assign(output, {
                preview: URL.createObjectURL(output)
              });
  
              // update view
              handleFiles(updatedFiles);
            })
          }
        >
          Editar
        </button>
      ) : (
        <button
          style={thumbButton}
          onClick={() => {
            setActiveDocument(file);
          }}
        >
          Abrir
        </button>
      )}
      <button
        style={CloseButton}
        onClick={() => {
          const updatedFiles = [...files];
          updatedFiles.splice(index, 1);
          handleFiles(updatedFiles);
        }}
      >
        X
      </button>
    </div>
  ));
/*   useEffect(()=>{
    handleFiles(acceptedFiles)
  },[acceptedFiles])
 */
  return (
    
    <div className="container">
      {activeDocument && (
        <DocViewer
          documents={[{ uri: activeDocument.preview }]}
          pluginRenderers={DocViewerRenderers}
        />
      )}
      <div {...getRootProps({style})}>
        <input {...getInputProps()} aria-label='drag-and-drop'/>
        <p>Arrastra archivos adjuntos o haz click para seleccionar</p>
      </div>
      <aside>
        <h4>Files</h4>
       {/*  <ul>{files}</ul> */}
        <aside style={thumbsContainer}>{thumbs}</aside>
      </aside>
    </div>
  );
}
