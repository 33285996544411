import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useNavigate, useSearchParams } from "react-router-dom";
import { styled } from "@nextui-org/react";
import ViewsHandler from "../../components/ViewsHandler/ViewsHandler";
import AirShipmentsTable from "./AirShipmentsTable";
import { useTranslation } from "react-i18next";
const tabs = [
  {
    id: "tracking-tab",
    value: "tracking",
    label: "Tracking",
  },
  // {
  //   id: "tracking-group-tab",
  //   value: "tracking-group",
  //   label: "Tracking group",
  // },
];

const Container = styled("div", {
  // background: "$neutral_50",
  borderRadius: "8px",
  padding: "30px",
  ".title": {
    margin: "20px 0",
    color: "$primary_300",
  },
  ".views": { margin: "40px" },
  [".css-1a4cg4j-MuiButtonBase-root-MuiTab-root"]: {
    fontFamily: "inherit",
    color: "$primary_300",
    textTransform: "none",
  },
  [".Mui-selected"]: {
    fontWeight: "600",
    color: "$primary_300 !important",
  },
  [".MuiTabs-indicator"]: {
    backgroundColor: "$secondary_300",
  },
});

export default function TrackingAir() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [tabId, setTabId] = useState(0);
  const [tabValue, setTabValue] = useState(searchParams.get("tab"));

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    setTabId(tabs.findIndex((tab) => tab.value === newValue));
    navigate(`/tracking/air-shipment/?tab=${newValue}`);
  };

  useEffect(() => {
    setTabValue(tabValue);
    setTabId(tabs.findIndex((tab) => tab.value === tabValue));
  }, []);

  return (
    <Container>
      <h2 className="title">Air Shipments</h2>
      <Tabs
        value={tabValue}
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="service"
      >
        {tabs.map((tab, i) => (
          <Tab key={i} value={tab.value} label={tab.label} />
        ))}
      </Tabs>
      <div className="views">
        <ViewsHandler activeView={tabId}>
          <AirShipmentsTable />
          <h1>Tracking Group</h1>
        </ViewsHandler>
      </div>
    </Container>
  );
}
