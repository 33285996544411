import React, {useState} from 'react';
import { Loading, Modal } from '@nextui-org/react';
import { useNavigate, useSearchParams } from 'react-router-dom';
// Components
import Table from '../../components/Table/Table';
import VersionsTable from './VersionControl/VersionsTable';
import { useTranslation } from "react-i18next";
import toast from '../../components/Toast/ToastTypes';
import Icon from '../../components/Icon/Icon';

export default function QuotationsTable({ items, setShowTable, currentQuote, setCurrentQuote, loading }) {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const [versions, setVersions] = useState([])
    const [openModal, setOpenModal] = useState(false)

    const columns = [
        { name: 'Acciones', uid: 'actions', type: 'action' },
        { name: 'ID', uid: 'id', type: 'id' },
        { name: 'Cotización', uid: 'radication', type: 'text' },
        { name: 'Cliente', uid: 'client', type: 'text' },
        { name: 'Servicio', uid: 'service', type: 'text' },
        { name: 'Subtipo', uid: 'subtype', type: 'text' },
        { name: 'Oficina', uid: 'office', type: 'text' },
        { name: 'Razon', uid: 'reason', type: 'text' },
        { name: 'Nro de versiones', uid: 'versionsAmount', type: 'textCenter' },
        { name: 'Costo', uid: 'cost', type: 'text' },
        { name: 'Profit', uid: 'profit', type: 'text' },
        { name: 'Tiene preliquidacion', uid: 'prel', type: 'icon' },
        { name: 'Estado', uid: 'status', type: 'text' },
    ]

    const actions = [
        {
            name: 'Seleccionar',
            icon: 'View',
            handle: (item) => {
                setCurrentQuote({
                    id:item.id,
                    radication:item.radication,
                })
                setShowTable(false)
            }
        },
        {
            name: 'Ver detalles',
            icon: 'File',
            handle: (item) => {
                navigate(`/manageQuotes/quotation-details/?id=${item.id}&radication=${item.radication}`)
            },
        },
        {
            name: 'Ver versiones',
            icon: 'Versions',
            handle: (item) => {
                setVersions(item.versions)
                setOpenModal(true)
            },
        }
    ]

    return (
        <div style={{ background: '#EBEDF0', padding: '20px', borderRadius: '8px'}}>
            {
                loading ?
                    <div className='d-flex justify-content-center'>
                        <Loading></Loading>
                    </div>
                    :
                    items?.length > 0 ?
                        <Table columns={columns} items={items} actions={actions}/>
                        :
                        <div className='d-flex justify-content-center'>No hay preliquidaciones</div>
            }
            <Modal
                width='fit-content'
                open={openModal}
                onClose={()=>setOpenModal(false)}
                closeButton
                style={{padding:'50px'}}
            >
                <VersionsTable data={versions} currentQuote={currentQuote} setCurrentQuote={setCurrentQuote}/>
            </Modal>
        </div>
    );
}