import React, { useState } from 'react'
import { styled, Switch } from '@nextui-org/react';
import SimpleAutocomplete from '../../../components/SimpleAutocomplete/SimpleAutocomplete'
import Button from '../../../components/Button/Button'
import Input from '../../../components/Input/Input'
import InputSelect from '../../../components/Select/inputSelect'
import { useForm, Controller } from 'react-hook-form';
import {calculateCO2byPerson} from '../../../services/CO2.services'
import toast from '../../../components/Toast/ToastTypes';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
const StyledContainer = styled("div", {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '40px',
    marginBottom: '30px',
})

const weightOptions = [
    { label: 'Kilograms', value: 'kilograms' },
    { label: 'Grams', value: 'grams' },
    { label: 'Metric Tons', value: 'metric tons' },
    { label: 'Imperial Tons', value: 'imperial tons' },
    { label: 'Pounds', value: 'pounds' },
    { label: 'Ounces', value: 'ounces' },
];


const specification = {
    "type": {
        "description": "Specification",
        "type": "string",
        "default": "average",
        "enum": [
            "average",
            "refrigerated",
        ],
    }
};


export default function RoadFreightForm() {
    const token = useSelector(state => state.user.token)
    const user = useSelector(state => state.user)
    const { control, handleSubmit, watch, formState: { errors } } = useForm();
    const [origin, setOrigin] = useState('')
    const [destination, setDestination] = useState('')
    const navigate = useNavigate();
    function onSubmit(data) {
        const request={
            type:"road_freight",
            departure:origin,
            destination,
            specification: 'average',
            weight: parseInt(data.weight),
            weight_unit: data.fuel.value
        }
        calculateCO2byPerson(token, {request,user:user.idUser}).then((res)=>{
            if(res.status==200){
                toast("success", "Cálculo guardado exitosamente")
                navigate('/co2/history');
            }
            else{
                toast("error", "No se pudo guardar el cálculo")
            }
        }).catch(error=>{
            toast("error", "Ha ocurrido un error inesperado")
        })
    } 

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <StyledContainer>
                <SimpleAutocomplete label={'departure'} setState={setOrigin} />
                <SimpleAutocomplete label={'destination'} setState={setDestination} />
                <Controller
                    name="weight"
                    control={control}
                    render={({ field }) => (
                        <Input label="Peso de la carga" type="number" {...field} />
                    )}
                    rules={{ required: true }}
                />
                <Controller
                    name="fuel"
                    control={control}
                    render={({ field }) => (
                        <InputSelect label="Unidad" options={weightOptions} {...field} />
                    )}
                    rules={{ required: true }}
                />

                <div style={{ display: 'flex', paddingTop: '30px', justifyContent: 'space-between' }}>

                    <Button text="Realizar calculo" bold outline type="submit" />
                </div>
            </StyledContainer>
        </form>
    )
}
