import React, { useEffect, useState } from 'react'
import { Modal,styled } from '@nextui-org/react'
import { createSubStatus, deleteSubStatus, getOperationStatuses, getSubStatuses, editState } from '../../../services/operations.services'
import { useSelector } from 'react-redux'
import Button from '../../../components/Button/Button'
import Input from '../../../components/Input/Input'
import Table from '../../../components/Table/Table'
import { getDate } from 'date-fns'

const StyledModal = styled(Modal, {
    color: '$primary_500 !important',
    padding: '20px',
    display:'flex',
    minWidth:'600px',
})

const columns = [   
    {name:'Nombre', uid:'name', type:'text'},
    {name:'', uid:'actions', type:'action'},
]

export default function ModifyStateModal({open, setOpen, stateId}) {
    const token = useSelector(state => state.user.token)
    const [status, setStatus] = useState()
    const [disableModify, setDisableModify] = useState(true)
    const [statusName, setStatusName] = useState()

    const [subStatuses, setSubStatuses] = useState()

    const [subStatusName,setSubStatusName] = useState("")

    function getData(){
        const asyncTasks = [getOperationStatuses(token,stateId), getSubStatuses(token)]
        if (stateId){
            Promise.all(asyncTasks).then(async res=>{
                const statusData = await res[0].json();
                const subStatusesData = await res[1].json();
                setStatus(statusData)

                setStatusName(statusData.name)
                const filteredSubStatuses = subStatusesData.filter(subStatus => subStatus.operation_status === statusData.id)
                setSubStatuses(filteredSubStatuses)
            })
        }
    }

    useEffect(()=>{
        getData()
    },[stateId])

    function handleDeleteSubState(id){
        deleteSubStatus(token,id).then(()=>{
            getData()
        }).catch(err=>{
            console.log("err :",err)
            getData()
        })
    }

    function handleCreateSubState(){
        const submitData = {
            name:subStatusName,
            operation_status:stateId,
        }
        createSubStatus(token,submitData).then(() => {
            setSubStatusName("")
            getData()
        }).catch(err=>{
            console.log("err :",err)
            getData()
        })
    }

    function handleEditStateName(){
        editState(token, stateId, {name:statusName}).then(()=>{
            getData()
        }).catch(err=>console.log("err :",err))
    }

    const actions = [
        {
            name: 'Borrar',
            icon: 'Trash',
            handle: (item) => {
                handleDeleteSubState(item.id)
            }
        },
    ]

    return (
    <StyledModal
        open={open}
        onClose={()=>setOpen(false)}
        width='fit-content'
        closeButton   
        placement="left"
        aria-label='modal'
    >
        <Modal.Body justify='space-between'>
            <h3>Administrar estado : {status?.name}</h3>
            <p>Modificar nombre</p>
            <div style={{display:'flex',width:'100%',gap:'15px'}}>
                <Input fullWidth disabled={disableModify} value={statusName} onChange={(e)=>setStatusName(e.target.value)}/>
                <Button 
                    text={disableModify? "Editar" : "Cancelar" } 
                    handler={() => {disableModify ? setDisableModify(false) : setDisableModify(true); setStatusName(status?.name)}}
                    outline
                />
                <Button disabled={disableModify || !statusName} text={"Confirmar"} handler={()=>handleEditStateName()} outline/>
            </div>
            <p>Administar subestados</p>
            <div style={{display:'flex',width:'100%',gap:'15px'}}>
                <Input placeholder={"Nombre del subestado..."} fullWidth value={subStatusName} onChange={e => setSubStatusName(e.target.value)}/>
                <Button text={"Añadir"} handler={()=>handleCreateSubState()} outline/>
            </div>
            {subStatuses && <Table columns={columns} items={subStatuses} actions={actions} searchBar={false} excel={false}/>}
        </Modal.Body>
    </StyledModal>
  )
}
