import React from 'react'
import styles from './Airplane.module.scss'

export default function AirPlane() {
  return (
    <div className={styles.container}>
      <div  className={styles.airplane}>
        <div className={styles.head}></div>
        <div className={styles.body}>
        <div className={styles.window}></div>
        <div className={styles.window}></div>
        <div className={styles.window}></div>
        </div>
        <div className={styles.lwing}></div>
        <div className={styles.rwing}></div>
        <div className={styles.tale}></div>
      </div >
    </div>
  )
}
