import { styled } from '@nextui-org/react';
import React, { useState } from 'react';
import src from "../../../assets/img/containerDrawing.jpg";
import Button from '../../../components/Button/Button';
import AddContainerModal from './AddContainerModal';
import { useFormContext } from 'react-hook-form';
import FormInput from '../../../components/FormInput/FormInput';
// import Cube from '../loads/Cube';

const Container = styled("div", {
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  ".formItem": {
    display: "grid",
    gap: "10%",
    gridTemplateColumns: "50% 40%",
    borderBottom: "2px solid $primary_500",
    padding: "10px",
  },
  ".inputs": {
    display: "grid",
    gridTemplateColumns: "auto auto auto",
    gap: "0px 10px",
    margin: "25px 0",
  },
});

export default function Containers() {
  const [open, setOpen] = useState(false);
  const { setValue, watch } = useFormContext();
  const containersFormValues = watch("containers");

  const handleDelete = (key) => {
    const updatedContainers = { ...containersFormValues };
    delete updatedContainers[key];
    setValue("containers", updatedContainers);
  };

  return (
    <Container>
      <AddContainerModal open={open} setOpen={setOpen} />
      <div>
        {
          Object.keys(containersFormValues).map(key => {
            const editable = containersFormValues[key]?.editable
            return (
              <div className="formItem" key={key}>
                <div className="inputs">
                  <FormInput readOnly={!editable} label="Nombre" name={`containers.${key}.name`} />
                  <FormInput readOnly={!editable} label="Cantidad" name={`containers.${key}.qty`} type="number" />
                  <FormInput readOnly={!editable} label="Largo (cm)" name={`containers.${key}.length`} icon="Largo" type="number" />
                  <FormInput readOnly={!editable} label="Ancho (cm)" name={`containers.${key}.width`} icon="Ancho" type="number" />
                  <FormInput readOnly={!editable} label="Alto (cm)" name={`containers.${key}.height`} icon="Alto" type="number" />
                  <FormInput readOnly={!editable} label="Peso maximo" name={`containers.${key}.maxWeight`} icon="Cube" type="number" />
                  <div style={{ display: "flex", gridColumn:'span 3', margin:'15px 0', justifyContent: "end", alignItems: "end" }}>
                    <Button
                      handler={() => handleDelete(key)}
                      size="sm"
                      text="Borrar contenedor"
                      textColor="white"
                      endIcon={{ name: "Trash", color: "white", size: "20px" }}
                      bgColor="delete"
                    />
                  </div>
                </div>
                {/* <Cube
                  height={containersFormValues[key]?.height}
                  width={containersFormValues[key]?.width}
                  depth={containersFormValues[key]?.length}
                  name='Contenedor'
                  onlyEdges
                /> */}
              </div>
            )
          })
        }
      </div>
      <div style={{ display: "flex", justifyContent: "end", marginTop: "20px" }}>
        <Button
          text="Agregar contenedor"
          endIcon={{ name: "Plus", color: "white" }}
          handler={() => setOpen(true)}
          textColor="white"
          bgColor="primary_300"
        />
      </div>
    </Container>
  )
}
