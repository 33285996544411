import React, { useEffect, useState } from 'react'
import Button from '../Button/Button'
import { Loading, Modal, styled } from '@nextui-org/react'
import { createPurchaseOrder, editPurchaseOrderByRadication, getServiceSplitRadication } from '../../services/quotation.services'
import Input from '../Input/Input'
import { useSelector } from 'react-redux'
import toast from '../Toast/ToastTypes'
import Icon from '../Icon/Icon'

const StyledModal = styled(Modal, {
    padding: '1.5rem',
    display:'flex',
    gap:'20px',
    color: '$primary_500 !important',
    '.header':{
        margin:'0 25px',
        paddingBottom:'10px',
        display:'flex',
        justifyContent:'space-between',
        alignItems:'end',
        borderBottom:'3px solid $primary_500'
    }
})

export default function EditPurchaseOrderButton({radication}) {
    const token = useSelector(state=>state.user.token)

    const [open, setOpen] = useState(false)
    const [oldPurchaseOrder, setOldPurchaseOrder] = useState("")
    const [purchaseOrder, setPurchaseOrder] = useState("")
    const [service,setService] = useState()
    const [loading, setLoading] = useState(false)
    function getData(){
        getServiceSplitRadication(radication,token).then(async res =>{
            setLoading(true)
            const serviceData = await res.json()
            console.log("serviceData :",serviceData)
            if (serviceData?.results && serviceData?.results[0]){
                setService(serviceData.results[0])
                setPurchaseOrder(serviceData?.results[0]?.purchase_order)
                setOldPurchaseOrder(serviceData?.results[0]?.purchase_order)
                setLoading(false)
            } else {
                setPurchaseOrder(false)
                setLoading(false)
            }
        })
    }

    useEffect(()=>{
        if (open){
            getData()
        }
    },[radication, open])

    function handleSubmit(){
        console.log("purchaseOrder :",purchaseOrder)
        console.log("service :",service)
        if (oldPurchaseOrder){
            editPurchaseOrderByRadication({
                "purchaseNumber": purchaseOrder,
                "service": service.id,
            }, radication, token).then(res=>{
                toast("success","PO modificado")
                getData()
            }).catch((err)=>{console.log(err);toast("error","No pudo modificarse")})
        } else {
            createPurchaseOrder(purchaseOrder, service.id, token).then(res => {
                toast("success","PO creada con exito")
                getData()
            }).catch(err=>{console.log(err);toast("error","No se pudo crear PO")})
        }
        
    }
    console.log("loading :",loading)
    return (
    <div>
        <Button 
            disabled={!radication}
            size='sm'
            text='Editar PO'
            bgColor='primary_300'
            textColor="white"
            handler={()=>setOpen(true)}
            endIcon={{name:"Edit", color:'white'}}  
        />
        <StyledModal
            open={open}
            setOpen={setOpen}
            onClose={()=>setOpen(false)}
        >
            <div className='header'>
                <b>{oldPurchaseOrder?"Editar PO":"Crear PO"}</b>
                <Icon name='EditSquare' size='30px'/>
            </div>
            <Modal.Body>
                {radication && <p>Radicado : {radication}</p>}
                <p>PO actual : <b>{oldPurchaseOrder?.purchaseNumber}</b></p>
                <Input
                    value={purchaseOrder?.purchaseNumber}
                    onChange={(e)=>setPurchaseOrder(e.target.value)}
                    label={"Nuevo PO :"}
                />
                <div style={{marginTop:'20px'}}>
                    {loading?<Loading/>:
                    <Button
                        text='Guardar'
                        width={'100%'}
                        bgColor='primary_300'
                        textColor='white'
                        disabled={!purchaseOrder || loading}
                        handler={()=>handleSubmit()}
                    />}
                </div>
            </Modal.Body>
        </StyledModal>
    </div>
  )
}
