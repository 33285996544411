import React, { useEffect } from "react";
import { styled } from "@nextui-org/react";
import { InitMap } from "../../../../functions/mapFunctions";
import { useTranslation } from "react-i18next";
import DashboardHorizontalBarChart from "../../../../components/Graphics/HorizontalChart/DashboardHorizontalChart";
import MilestoneTable from "./MilestoneTable";
import ExceptionsTable from "./ExceptionsTable";
import CalendarTable from "./CalendarTable";

const Container = styled("div", {
  display: "grid",
  gridTemplateColumns: "60% 40%",
  gap: "15px",
});
const BigDiv = styled("div", {
  gridColumn: "span 2",
  background: "$neutral_50",
  // height:'fit-content',
  padding: "20px",
  minHeight: "300px",
  width: "100%",
  borderRadius: "8px",
  boxShadow: "$elevation_2",
});
const SmallDiv = styled("div", {
  background: "$neutral_50",
  height: "400px",
  padding: "20px",
  width: "100%",
  borderRadius: "8px",
  boxShadow: "$elevation_2",
});
const coordinatesArray = [
  {
    lat: -34.603738,
    lng: -58.377223,
    label: "Origen",
  },
  {
    lat: -34.612869,
    lng: -58.381431,
  },
  {
    lat: -34.619133,
    lng: -58.387333,
  },
  {
    lat: -34.625828,
    lng: -58.394339,
  },
  {
    lat: -34.629387,
    lng: -58.399122,
    label: "Destino",
  },
];

export default function OceanicShipmentDashboard() {
  useEffect(() => {
    InitMap(coordinatesArray, "ocean_shipments_map");
  }, []);

  return (
    <Container>
      <BigDiv style={{ minHeight: "500px" }}>
        <h3>Live shipment locations</h3>
        <div
          id={"ocean_shipments_map"}
          className="w-100 h-100 border-rounded"
          style={{ width: "100%", height: "100%", maxHeight: "400px" }}
        />
      </BigDiv>
      <BigDiv>
        <h3>Demurrage Days</h3>
        <DashboardHorizontalBarChart width={1000} height={300} color={'#3D509A'} dataKey={"Demurrage Days"} data={[
          {name: '> 10', "Demurrage Days": 35},
          {name: '8 - 10', "Demurrage Days": 20},
          {name: '4 - 7', "Demurrage Days": 5},
          {name: '0 - 3', "Demurrage Days": 8},
        ]}/>
      </BigDiv>
      <BigDiv>
        <h3>Detention Days</h3>
        <DashboardHorizontalBarChart width={1000} height={300} color={'#5E6EAB'} dataKey={"Detention Days"} data={[
          {name: '> 10', "Detention Days": 35},
          {name: '8 - 10', "Detention Days": 20},
          {name: '4 - 7', "Detention Days": 5},
          {name: '0 - 3', "Detention Days": 8},
        ]}/>
      </BigDiv>
      <BigDiv>
        <h3>Latest key milestone updates</h3>
        <MilestoneTable/>
      </BigDiv>
      <SmallDiv>
        <h3>Exception updates</h3>
        <ExceptionsTable/>
      </SmallDiv>
      <SmallDiv>
        <h3>Calendar</h3>
        <CalendarTable/>
      </SmallDiv>
    </Container>
  );
}
