import React, { useState } from 'react'
import { styled } from '@nextui-org/react';
import { getOperations, editOperation, getActivities, postLogEntries, editOperationActivity} from '../../../services/operations.services';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Modal } from '@nextui-org/react';
import format from 'date-fns/format';
//components
import Button from '../../../components/Button/Button';
import Input from '../../../components/Input/Input';
import toast from '../../../components/Toast/ToastTypes'
import AddActivityModal from './AddActivityModal'
import DatePickerModal from '../../../components/DatePicker/DatePicker';
import TrackingCards from './TrackingCards';
import TrackingModal from './TrackingModal';
import TrackingLogs from './TrackingLogs';
import { useNavigate } from 'react-router-dom';
import EditPurchaseOrderButton from '../../../components/EditPurchaseOrderButton/EditPurchaseOrderButton';

const StyledContainer = styled("div", {
    backgroundColor: '$neutral_50',
    borderRadius: '8px',
    boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)',
    padding: '10px',
    '.controls':{
        display:'flex',
        margin:'20px',
        gap:'20px',
        justifyContent:'space-between' ,
        alignItems:'end'
    },
    '.dates':{
        display:'flex',
        gap:'15px',
        alignItems:'end',
        'div':{
            display:"flex",
        }
    },
    "@media(max-width:1200px)":{
        '.controls':{
            display:'flex',
            flexDirection:'column',
        },
        '.dates':{
            display:'flex',
            flexDirection:'column',
            alignItems:'start',
            'div':{
                display:"flex",
                flexDirection:'column',
            }
        },
    }
})

const StyledModal = styled(Modal, {
    color: '$primary_500 !important',
    minWidth: '400px',
    padding: '20px',
})

const convertDate = date => {
    const dateParts = date.split('/');
    return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
};

export default function Events({ selectedOperation,setSelectedOperation, setTabId, setTabValue }) {
    const navigate = useNavigate()
    const token = useSelector(state => state.user.token)
    const user = useSelector(state => state.user)

    const [items, setItems] = useState([])
    const [currentOperationData, setCurrentOperationData] = useState({})
    const [openLogModal, setOpenLogModal] = useState(false)
    const [activities, setActivities] = useState([])
    const [selectedActivity, setSelectedActivity] = useState('')
    const [logEntry, setLogEntry] = useState('')

    const [openAddActivityModal, setOpenAddActivityModal] = useState(false)
    const [openTrackingModal, setOpenTrackingModal] = useState(false)
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false)

    const [updateLogs, setUpdateLogs] = useState(0)

    const [openETD, setOpenETD] = useState(false)
    const [openETA, setOpenETA] = useState(false)

    const [ETD, setETD] = useState(new Date());
    const [ETA, setETA] = useState(new Date());

    const [disableDates, setDisableDates] = useState(true)

    function formatDate(inputDate) {
        const date = new Date(inputDate);
        const day = date.getUTCDate().toString().padStart(2, '0');
        const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
        const year = date.getUTCFullYear();
        return `${day}/${month}/${year}`;
    }

    function handleDelete() {
        const editedOperation = currentOperationData;
        const newActivities = editedOperation.activities.filter(activity => activity.activity !== selectedActivity.id)
        editedOperation.activities = newActivities;
        editOperationActivity({ data: editedOperation, id: currentOperationData.id, token:token }).then(() => {
            setOpenConfirmationModal(false)
            toast("success", "Actividad borrada")
        }).catch(() => toast("error", "Error, no se pudo borrar la actividad"))
    }

    function handleSubmitLogEntry() {
        const entryData = {
            text_field: logEntry,
            operation: selectedOperation,
            activity: selectedActivity.id,
            recipient: user.idUser,
        }
        postLogEntries(entryData, token).then(res => {
            toast("success", "Entrada agregada con exito")
        }).catch(err => console.log('err :', err))
    }

    function saveDates(){
        setDisableDates(true)
        console.log('selectedOperation :',selectedOperation)
        editOperation(selectedOperation,{
            ETD:ETD,
            ETA:ETA,
        }, token).then(res=>{
            console.log("res :",res)
        }).catch(err=>{
            console.log("err :",err)
        })
    }

    useEffect(() => {
        Promise.all([getOperations({ token:token, id: selectedOperation }), getActivities(token)]).then(async responses => {
            const operationData = await responses[0].json();
            if (!Array.isArray(operationData)){
                setCurrentOperationData(operationData);
            }
            setETD(new Date(operationData.ETD))
            setETA(new Date(operationData.ETA))

            const activitiesData = await responses[1].json();
            setActivities(activitiesData)
            const tableData = operationData.activities?.map((operationActivity) => {
                return {
                    id: operationActivity.activity,
                    activity: activitiesData.find(activity => activity.id === operationActivity.activity)?.name,
                    confirmed_date: formatDate(operationActivity.confirmed_date),
                    estimated_date: formatDate(operationActivity.estimated_date),
                    operation: operationActivity.operation,
                }
            })
            const sortedData = tableData?.sort((a, b) => {
                const dateA = convertDate(a.confirmed_date);
                const dateB = convertDate(b.confirmed_date);
                return new Date(dateB) - new Date(dateA);
            });
            setItems(sortedData)
        })
    }, [selectedOperation, openAddActivityModal, openConfirmationModal])

    return (
        <StyledContainer>
            <div className='controls'>
                <Button size={"sm"} text='Asignar numero de rastreo' disabled={!selectedOperation} bgColor={'primary_300'} textColor='neutral_50'
                    endIcon={{name:'Rastreo', color:'white'}}
                    handler={() => { 
                        navigate('/operations/tracking/?tab=trackingForm') 
                        setTabValue('trackingForm')
                        setTabId(6)
                    }} 
                />
                <EditPurchaseOrderButton radication={currentOperationData?.radication}/>
                <div className='dates'>
                    <div style={{display:'flex', gap:'10px'}}>
                        <div onClick={() => { !disableDates && setOpenETD(true) }}>
                            <Input disabled={disableDates} value={(ETD instanceof Date && !isNaN(ETD.valueOf())) && format(ETD, "dd/MM/yyyy")} label='ETD' />
                        </div>
                        <div onClick={() => { !disableDates && setOpenETA(true) }}>
                            <Input disabled={disableDates} value={(ETA instanceof Date && !isNaN(ETA.valueOf())) && format(ETA, "dd/MM/yyyy")} label='ETA' />
                        </div>
                    </div>
                    <div style={{display:'flex', gap:'10px'}}>
                        <Button text='Modificar fechas'
                            disabled={!selectedOperation || !disableDates}
                            size='sm' 
                            bgColor={'primary_300'}
                            textColor='neutral_50' 
                            handler={() => { setDisableDates(false)}}
                            endIcon={{name:'EditSquare', color:'white'}}
                        />
                        <Button text='Guardar cambios'
                            disabled={disableDates}
                            size='sm'
                            bgColor={'primary_300'} 
                            textColor='neutral_50'
                            handler={() => {saveDates()}} 
                            endIcon={{name:'CheckSquare', color:'white'}}
                        />
                    </div>
                    <DatePickerModal open={openETD} setOpen={setOpenETD} date={new Date()} setDate={setETD} />
                    <DatePickerModal open={openETA} setOpen={setOpenETA} date={new Date()} setDate={setETA} />
                </div>
            </div>

            <TrackingModal open={openTrackingModal} setOpen={setOpenTrackingModal} radication={currentOperationData.radication}/>

            <StyledModal
                closeButton
                aria-label="modal-title"
                open={openLogModal}
                onClose={() => { setOpenLogModal(false); setSelectedActivity('') }}
                width="fit-content"
            >
                <Modal.Body>
                    <p>Agregar entrada de registro a:</p>
                    <p> {selectedActivity.name} (id : {selectedActivity.id})</p>
                    <textarea label={"Log entry:"} onChange={(e) => setLogEntry(e.target.value)} style={{ border: "1px solid #292E68", borderRadius: "8px" }} />
                    <Button text='Agregar' bold bgColor={'primary_300'} textColor='neutral_50' handler={() => { handleSubmitLogEntry() }} />
                </Modal.Body>
            </StyledModal>
            <AddActivityModal
                activities={activities}
                currentOperationData={currentOperationData}
                operationId={selectedOperation}
                openAddActivityModal={openAddActivityModal}
                setOpenAddActivityModal={setOpenAddActivityModal}
                selectedActivity={selectedActivity}
                setSelectedActivity={setSelectedActivity}
            />

            <StyledModal
                aria-label="modal-title"
                open={openConfirmationModal}
                onClose={() => setOpenConfirmationModal(false)}
                width="fit-content"
            >
                <Modal.Header justify='space-between'>
                    <h3>Confirmar</h3>
                </Modal.Header>
                <Modal.Body justify='space-between'>
                    <p>Confirma que desea borrar la actividad seleccionada</p>
                    <div style={{ display: 'flex', justifyContent: 'end', gap: '15px' }}>
                        <Button text='Cancelar' bold bgColor={'primary_300'} textColor='neutral_50' handler={() => setOpenConfirmationModal(false)} />
                        <Button text='Borrar' bold bgColor={'delete'} textColor='neutral_50' handler={() => handleDelete()} />
                    </div>
                </Modal.Body>
            </StyledModal>
            <TrackingCards setUpdateLogs={setUpdateLogs} currentOperationData={currentOperationData} setSelectedOperation={setSelectedOperation}/>
            <div style={{marginTop:'20px'}}>
                <TrackingLogs currentOperation={selectedOperation} updateLogs={updateLogs} allowEditing={true}/>
            </div>
        </StyledContainer>
    )
}
