import { styled } from "@nextui-org/react";
import Button from "../../../components/Button/Button";
import FormInput from "../../../components/FormInput/FormInput";
// import Cube from "./Cube";
import { useFormContext } from "react-hook-form";
import { SketchPicker, TwitterPicker } from "react-color";
import ColorPicker from "./ColorPicker";
import LoadTypesModal from "./LoadTypesModal";
import { useState } from "react";
// import CylinderComponent from "./Cilinder";

const Container = styled("div", {
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  ".formItem": {
    display: "grid",
    gap: "10%",
    gridTemplateColumns: "50% 40%",
    borderBottom: "2px solid $primary_500",
    padding: "10px",
  },
  ".inputs": {
    display: "grid",
    gridTemplateColumns: "33% 33% 33%",
    gap: "0px 30px",
    margin: "25px 0",
  },
});

const defaultValues = {
  name: "",
  length: "",
  width: "",
  height: "",
  qty: 1,
  weight_per_piece: "",
  total_weight: "",
  volume: "",
};

export default function Loads() {
  const { getValues, setValue, reset, control, register, watch, trigger } = useFormContext();
  const [open, setOpen] = useState(false);

  const formValues = watch();
  const loadsFormValues = watch("loads");

  function handleDelete(key) {
    console.log("key:", key);
    let newFormValues = getValues("loads");
    console.log("newFormValues:", newFormValues);
    delete newFormValues[key];

    const sortedLoads = {};
    const loadsArray = Object.keys(newFormValues).map((key) => newFormValues[key]);
    loadsArray.forEach((load, index) => {
      sortedLoads[index] = load;
    });

    setValue("loads", sortedLoads);
  }

  function addLoad() {
    const key = Object.keys(loadsFormValues).length;
    reset({ ...formValues, loads: { ...loadsFormValues, [key]: defaultValues } });
  }

  console.log("formValues:", formValues);

  return (
    <Container>
      {Object.keys(loadsFormValues).map((key, index) => {
        console.log("key :",key)
        return (
          <div className="formItem" key={index}>
            <div className="inputs">
              <FormInput label="Nombre" name={`loads.${index}.name`} />
              {loadsFormValues[key].cilinder && <FormInput label="Radio (cm)" name={`loads.${index}.radius`} type="number" />}
              {!loadsFormValues[key].cilinder && <FormInput label="Largo (cm)" name={`loads.${index}.length`} icon="Largo" type="number" />}
              {!loadsFormValues[key].cilinder && <FormInput label="Ancho (cm)" name={`loads.${index}.width`} icon="Ancho" type="number" />}
              <FormInput label="Alto (cm)" name={`loads.${index}.height`} icon="Alto" type="number" />
              <FormInput label="Cantidad" name={`loads.${index}.qty`} type="number" />
              <FormInput label="Peso por pieza (Kg)" name={`loads.${index}.weight_per_piece`} icon="Cube" type="number" />
              {loadsFormValues[key].cilinder && <div/>}
              <FormInput label="Peso total (Kg)" name={`loads.${index}.total_weight`} icon="Cube" type="number" />
              <FormInput label="Volumen (m³)" name={`loads.${index}.volume`} icon="Volumen" type="number" />
              <ColorPicker name={`loads.${index}.color`} itemIndex={index}/>
              <div style={{ display: "flex", gridColumn:'3',marginTop:'30px', justifyContent: "end", alignItems: "end" }}>
                <Button
                  handler={() => handleDelete(index)}
                  size="sm"
                  text="Borrar carga"
                  textColor="white"
                  endIcon={{ name: "Trash", color: "white", size: "20px" }}
                  bgColor="delete"
                />
              </div>
            </div>
            {/* {
              loadsFormValues[index]?.cilinder?
              <CylinderComponent
                height={loadsFormValues[index]?.height}
                radius={loadsFormValues[index]?.radius}
                name={loadsFormValues[index]?.name}
                color={loadsFormValues[index]?.color}
              />:
              <Cube
                height={loadsFormValues[index]?.height}
                width={loadsFormValues[index]?.width}
                depth={loadsFormValues[index]?.length}
                name={loadsFormValues[index]?.name}
                color={loadsFormValues[index]?.color}
              />
            } */}
          </div>
        );
      })}
      <div style={{ display: "flex", justifyContent: "end", marginTop: "20px" }}>
        <Button
          text="Agregar carga"
          endIcon={{ name: "Plus", color: "white" }}
          handler={() => setOpen(true)}
          textColor="white"
          bgColor="primary_300"
        />
      </div>
      <LoadTypesModal open={open} setOpen={setOpen}/>
    </Container>
  );
}
