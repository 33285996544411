import { React, useState } from 'react';
import theme from '../../theme';
import { styled } from "@nextui-org/react";


export default function CheckBox({ children }) {

    const [isSelected, setIsSelected] = useState(true);

    const StyledContainer = styled("div", {
        [`.${theme} &`]: {
            display: "flex",
            transition: '0.5s',
            margin: "5px",
            h4: {
                margin: '2px',
                marginLeft: '10px',
            },
            backgroundColor: isSelected ? '$primary_300' : "$neutral_50",
            color: isSelected ? "$neutral_50" : "$primary_300",
            borderRadius: '50px',
            width: "fit-content",
            height: "56px",
            padding: "15px",
            cursor: "pointer",
        },
    })

    const Icon = styled("img", {
        width: '24px',
        height: '24px',
        margin: 0,
        padding: 0,
        backgroundColor: 'white',
        borderRadius: '50%',
    })

    return (
        <StyledContainer className={"theme"} onClick={() => isSelected ? setIsSelected(false) : setIsSelected(true)}>
            {isSelected ? <Icon src='/checkSelected.svg' /> : null}
            <h4>{children}</h4>
        </StyledContainer>
    )
}
