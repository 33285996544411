// export const URL = process.env.NODE_ENV === "production" ? `${process.env.BASE_URL}/` : "https://apiv2.val.com.co/"
// api de QA o backend QA

class Config {
    constructor() {
      this.backendUrl = import.meta.env.VITE_BACKEND_URL;
    }
    getBackendUrl() {
      return this.backendUrl;
    }
    setBackendUrl(newUrl) {
      this.backendUrl = newUrl;
    }
}
  
const configURL = new Config();

export const URL = {
    get: () => configURL.getBackendUrl(),
    set: (newUrl) => configURL.setBackendUrl(newUrl)
};
  
// export const URL = "https://apiclient.val.com.co/"

// export const URL = "https://apiv2.val.com.co/"

// export const URL = "https://apiqa.val.com.co/"

export const CARGOES_API_KEY = import.meta.env.VITE_CARGOES_KEY
export const CARGOES_TOKEN = import.meta.env.VITE_CARGOES_TOKEN
export const ENABLE_LOGS = import.meta.env.VITE_ENABLE_LOGS
// export const ENABLE_LOGS = 'on'

export const containerSizes = [
    {
      value: "20",
      measurements: { long: 5.9, height: 2.435, width: 2.4 },
      name: "20 pies",
    },
    {
      value: "40",
      measurements: { long: 12.03, height: 2.435, width: 2.4 },
      name: "40 pies",
    },
    {
      value: "40HC",
      measurements: { long: 12.03, height: 2.4, width: 2.71 },
      name: "40 HC pies",
    },
];

export const bgColorStates = {
    'Estimado arribo': '#FDF5EB',
    'Entrega final': '#DAF2DF',
    'Documentos': '#F5E5AC',
    'Recoleccion': '#DEE5FF',
    'Arribo a destino': '#F7D7AE',
    'Pagado': '#F3F5FF',
    'Cotizado': '#AFB7D6',
}

export const textColorStates = {
  'Estimado arribo': '#EEAE5B',
  'Entrega final': '#3F7B4C',
  'Documentos': '#8F6023',
  'Recoleccion': '#55649B',
  'Arribo a destino': '#A56E29',
  'Pagado': '#5E6EAB',
  'Cotizado': '#3D509A',
}

export const ROLES = {
  'Admin': 1,
  'User': 4,
  'Advisor': 5,
  'Guest': 6,
  'Co2': 7,
  'Supplier': 8,
  'Provider': 8,
  'Pricing': 9,
  'Commercial': 10,
  'Operations': 11,
  'Client': 12,
  'Outsource':13
}

export const liquidationFormSteps = {
    General: [
        { value: 'liquidation', name: 'Liquidación' },
        { value: 'commerce', name: 'Comercio/Envio' },
        { value: 'negotation', name: 'Negociación' },
        { value: 'shipping', name: 'Ruta' },
        { value: 'load', name: 'Carga' },
        { value: 'Additional', name: 'Adicional' }
    ],
    Project: [
        { value: 'liquidation', name: 'Liquidación' },
        { value: 'commerce', name: 'Comercio' },
        { value: 'shipping', name: 'Envío' },
        { value: 'negotation', name: 'Negociación' },
        { value: 'load', name: 'Carga' },
        { value: 'Additional', name: 'Adicional' },
        { value: 'Documents', name: 'Documentos' },
    ],
}

export const industryIcons = {
    'Farmacia': 'Pharmacy',
    'Comercio': 'Commerce',
    'Cosmética': 'Cosmetic',
    'Automotriz': 'Automotive',
    'Alta Tecnología': 'Technology',
    'Alimentación': 'Food',
    'O&G': 'OYG',
    'Industrial': 'Industrie',
    'Infraestructura': 'Infrastructure',
    'Mineria': 'Mining',
    'Energía': 'GreenEnergy',
    'Renovables': 'WindPower',
    'Petroquímicos': 'Nuclear',
    'Ayuda benefica': 'Help',
}

export const generalMethods = [
    { value: 1, text: 'Aéreo', icon: 'Aereo' },
    { value: 2, text: 'Marítimo', icon: 'Maritimo' },
    { value: 3, text: 'Terrestre', icon: 'Terrestre' },
]
export const projectMethods = [
    { value: 3, text: 'Terrestre', icon: 'Terrestre' },
    { value: 4, text: 'Carga suelta BBK', icon: 'Bbk' },
    { value: 5, text: 'Ro-Ro Roll-on/roll-off', icon: 'Roro' },
    { value: 6, text: 'Contenedores especiales', icon: 'Containers' }
]
