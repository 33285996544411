import React, { useEffect, useState } from 'react'

import { useSelector } from 'react-redux'

import DetailsPdf from './DetailsPdf';
import { getRoles } from '../../../services/operations.services';

export default function QuoteDetails() {
    const [showPurchase,setShowPurchase] = useState(false)
    const user = useSelector(state => state.user)
    console.log("user :",user)
    useEffect(()=>{
        getRoles(user?.token).then(async res => {
            const rolesData = await res.json();
            const userRoles = user.roles.map(rolId => {
                const rol = rolesData.find(rol=>rol.id == rolId)
                return rol?.name
            })
            console.log("rolesData :",rolesData)
            if (userRoles.includes("Admin") || userRoles.includes("Pricing") || userRoles.includes("Operations")){
                setShowPurchase(true)
            } 
        })
    })

    return (
        <>
            <DetailsPdf 
                // showPurchase={showPurchase}
                showSelectVersion={true}
                showPurchase={false}
            />
        </>
    )
}
