import React, { useState } from 'react'
import { styled } from '@nextui-org/react'
import { useNavigate } from 'react-router-dom'
import Card2 from '../../../components/Cards/Card2/Card2'
import PageTitle from '../../../components/PageTitle/PageTitle'
import Icon from '../../../components/Icon/Icon'
import Button from '../../../components/Button/Button'
import CarForm from './CarForm'
import BusForm from './BusForm'
import HotelForm from './HotelForm'
import AirFreight from './AirFreight'
import RoadFreightForm from './RoadFreightForm'
import ShipFreightForm from './ShipFreightForm'
import ViewsHandler from '../../../components/ViewsHandler/ViewsHandler'
import InputFileCard from '../../../components/InputFileCard/InputFileCard'
import { Radio } from '@nextui-org/react'
import * as XLSX from 'xlsx';
import { calculateCO2Massive } from '../../../services/CO2.services'
import { useSelector } from 'react-redux';
import toast from '../../../components/Toast/ToastTypes';
import SimpleMap from '../../../components/Map/Map'
const Container = styled("div", {
    background: '$neutral_50',
    margin: '40px',
    padding: '40px',
    borderRadius: '8px',
    boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)',
})
const FormContainer = styled("div",{
    display:'grid',
    gridTemplateColumns:'40% 54%',
    gap: '6%'
})
const Cards = styled("div", {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop:'20px',
})
const ExcelButton = styled("button", {
    display: 'flex',
    margin: '30px',
    background: '$excel',
    color: 'white',
    gap: '5px',
    borderRadius: '8px',
    padding: '10px',
    transition: '300ms',
    ['&:hover']: {
        cursor: 'pointer',
        background: '#2c8059',
    }
})

const types = [
   /*  { index: 0, value: 'car', name: 'Auto', icon: 'Car' },
    { index: 1, value: 'bus', name: 'Bus', icon: 'Bus', },
    { index: 2, value: 'hotel', name: 'Estadia en hotel', icon: 'Hotel', }, */
    { index: 3, value: 'air', name: 'Air Freight', icon: 'Aereo', },
    { index: 4, value: 'Ship', name: 'Ship Freight', icon: 'Maritimo', },
    { index: 5, value: 'Road', name: 'Road Freight', icon: 'Terrestre', },
]

export default function Calculate() {
    const navigate = useNavigate()
    const token = useSelector(state => state.user.token);
    const user = useSelector(state => state.user)
    const [selectedType, setSelectedType] = useState()
    const [method, setMethod] = useState("form")
    const [coordinatesArray, setCoordinatesArray] = useState([])
    const [file, setFile] = useState()

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
        console.log('selectedFile :', selectedFile)
    };
    const handleUpload = async () => {
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                // Convertir el workbook a JSON
                const jsonData = {};
                let sheetNameG = ""
                workbook.SheetNames.forEach(sheetName => {
                    const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
                    jsonData[sheetName] = sheetData;
                    sheetNameG = sheetName
                });
                let request = {
                    calculations: jsonData[sheetNameG],
                    user: user.idUser
                }
                console.log(request)
                calculateCO2Massive(token, request).then((res) => {
                    if (res.status == 200) {
                        toast("success", "Cálculos guardado exitosamente")
                        navigate('/co2/history');
                    }
                    else {
                        toast("error", "No se pudo guardar los cálculos")
                    }
                }).catch(error => {
                    toast("error", "Ha ocurrido un error inesperado")
                })
                console.log(jsonData);
            };

            reader.readAsArrayBuffer(file);
        }
    };


    return (
        <Container>
            <PageTitle>Calcular emisiones de CO₂</PageTitle>
            <div style={{ margin: '15px' }}>
                <Radio.Group label="Metodo" size='sm' value={method} onChange={(value) => setMethod(value)} orientation="horizontal">
                    <Radio value="form">Llenar formulario</Radio>
                    <Radio value="excel">Importar archivo </Radio>
                </Radio.Group>
            </div>
            {
                method === 'excel' ?
                <div>
                    <p>¡Optimizamos tu experiencia con cálculos masivos para carga terrestre, aérea y marítima!</p>
                    <p>Actualmente, nuestro sistema está diseñado para manejar eficientemente grandes volúmenes de datos en estos modos de carga.</p>
                    <InputFileCard label='Sube un archivo .xls o .xlsx'
                        name='co2 xls'
                        startIcon={{ name: 'Upload', color: '#FFF' }}
                        width={'100%'}
                        aspectRatio={'6/1'}
                        onChange={(e) => handleFileChange(e)}
                        accept='.xls,.xlsx'
                    />
                    <ExcelButton onClick={() => handleUpload()}>Importar excel <Icon name='Excel' size='26' /></ExcelButton>
                    <p>Descarga nuestra <a href='https://github.com/ricoemanuel/docs/raw/master/template.xlsx'>plantilla de importación</a> en formato Excel.</p>
                </div> :
                <FormContainer>
                    <Cards>
                        {
                            types.map((type) =>
                                <Card2
                                    key={type.index}
                                    index={type.index}
                                    icon={type.icon}
                                    text={type.name}
                                    textSelected={selectedType?.name}
                                    handler={() => setSelectedType(type)}
                                />
                            )
                        }
                    </Cards>
                    <div style={{gridRow:'span 2', borderRadius:'20px', overflow:'hidden'}}>
                        <SimpleMap coordinatesArray={coordinatesArray}/>
                    </div>
                    <ViewsHandler activeView={selectedType?.index}>
                        <CarForm />
                        <BusForm />
                        <HotelForm />
                        <AirFreight setCoordinatesArray={setCoordinatesArray}/>
                        <ShipFreightForm setCoordinatesArray={setCoordinatesArray}/>
                        <RoadFreightForm setCoordinatesArray={setCoordinatesArray}/>
                    </ViewsHandler>
                </FormContainer>
            }
        </Container>
    )
}
