import React from "react";
import i404 from "../../assets/img/404.png"


function Missing(props) {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
      {/* <h1>Error</h1> */}
      <img src={i404} alt="" />
    </div>
  );
}

export default Missing;
