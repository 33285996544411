import React, { useEffect, useState } from "react";
import {
  getOperationStatuses,
  getSubStatuses,
  getSubStatusesOperations,
  editSubState,
} from "../../../services/operations.services";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { Modal, styled } from "@nextui-org/react";

//components
import TextEditor from "../../../components/TextEditor/TextEditor";
import DatePickerModal from "../../../components/DatePicker/DatePicker";
import Table from "../../../components/Table/Table";
import Input from "../../../components/Input/Input";
import Button from "../../../components/Button/Button";
import toast from "../../../components/Toast/ToastTypes";
import { useTranslation } from "react-i18next";

const StyledModal = styled(Modal, {
  minWidth: "700px",
  display: "flex",
  alignItems: "left",
  gap: "20px",
  color: "$primary_500 !important",
  padding: "20px",
});

export default function TrackingLogs({
  currentOperation,
  updateLogs,
  allowEditing = false,
}) {
  const token = useSelector((state) => state.user.token);
  const [items, setItems] = useState([]);

  const [selectedSubStatusId, setSelectedSubStatusId] = useState();
  const [notesModal, setNotesModal] = useState(false);
  const [selectedNote, setSelectedNote] = useState("");

  const [openModal, setOpenModal] = useState();
  const [openDate, setOpenDate] = useState(false);
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState(format(new Date(), "HH:mm"));
  const [note, setNote] = useState("");
  const { t: translate } = useTranslation("OperationsTracking");

  function handleModifySubState() {
    const combinedDateTime = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      parseInt(time.split(":")[0], 10),
      parseInt(time.split(":")[1], 10)
    );
    const editData = {
      date: combinedDateTime,
      notes: note,
    };
    console.log("editData :",editData)
    editSubState(token, selectedSubStatusId, editData)
      .then((res) => {
        toast("success", "Log modificado");
        setOpenModal(false);
        setNote("");
        setDate(new Date());
        setTime(format(new Date(), "HH:mm"));
        setSelectedSubStatusId();
        getData();
      })
      .catch((err) => {
        console.log("err :", err);
        toast("error", "Error, no se pudo modificar");
      });
  }

  function getData() {
    
    const asyncTasks = [
      getSubStatuses(token),
      getSubStatusesOperations(token),
      getOperationStatuses(token),
    ];
    Promise.all(asyncTasks).then(async (res) => {
      const subStatusesData = await res[0].json();
      const relations = await res[1].json();
      const operationStatusesData = await res[2].json();
      console.log("currentOperation :",currentOperation)
      const currentRelations = relations.filter(
        (relation) => relation.operation === currentOperation
      );
      const sortedRelations = currentRelations.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );
      const logData = sortedRelations.map((relation, index) => {
        const currentSubStatus = subStatusesData.find(
          (sub) => sub.id === relation.operation_sub_status
        );
        const bgColor = index % 2 === 0 ? "#f2f2f2" : "#ffffff"; // Alternar colores

        return {
          id: relation.id,
          subStatus: currentSubStatus?.name,
          status: operationStatusesData.find(
            (status) => status.id === currentSubStatus?.operation_status
          )?.name,
          notes: {
            text: relation?.notes ? translate("view_notes") : translate("no_notes"),
            disabled: !relation?.notes,
            handler: () => {
              setSelectedNote(relation?.notes);
              setNotesModal(true);
            },
          },
          date: relation?.date
            ? format(new Date(relation.date), "dd/MM/yyyy : HH:mm")
            : "",
          bgColor: bgColor, // Nuevo campo para el color de fondo
        };
      });

      setItems(logData);
    });
  }

  useEffect(() => {
    if (selectedSubStatusId) {
      getSubStatusesOperations(token, selectedSubStatusId).then(async (res) => {
        const relationData = await res.json();
        console.log("relationData :", relationData);
        const date = format(new Date(relationData.date), "dd/MM/yyyy");
        const time = format(new Date(relationData.date), "HH:mm");
        setDate(new Date(relationData.date));
        setTime(time);
        setNote(relationData.notes);
      });
    }
  }, [selectedSubStatusId]);

  useEffect(() => {
    getData();
  }, [currentOperation, updateLogs]);

  const columns = [
    { name: translate("status"), uid: "status", type: "text" },
    { name: translate("substatus"), uid: "subStatus", type: "text" },
    { name: translate("date"), uid: "date", type: "text" },
    { name: translate("notes"), uid: "notes", type: "button" },
  ];

  if (allowEditing) {
    columns.unshift({ name: "", uid: "actions", type: "action" });
  }
  const actions = [
    {
      name: translate("modify"),
      icon: "EditSquare",
      type: "button",
      handle: (e) => {
        setSelectedSubStatusId(e.id);
        setOpenModal(true);
      },
    },
  ];

  return (
    <div
      style={{ margin: "30px 70px", display: "flex", flexDirection: "column" }}
    >
      <p>Registro de estado de la operación</p>
      {items.length ? (
        <Table
          columns={columns}
          items={items}
          style={{ width: "auto", margin: "auto" }}
          actions={actions}
          excel={false}
        />
      ) : (
        <p>Aun no hay registros de esta operación o no hay una seleccionada</p>
      )}
      <Modal open={notesModal} onClose={() => setNotesModal(false)}>
        <TextEditor state={selectedNote} setState={setSelectedNote} readOnly />
      </Modal>

      <StyledModal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
      >
        <Modal.Header>
          <h3>Modificar estado</h3>
        </Modal.Header>
        <div style={{ display: "flex", gap: "10px" }}>
          <div
            onClick={() => {
              setOpenDate(true);
            }}
            style={{ display: "flex", width: "fit-content" }}
          >
            <label style={{ margin: "7px" }}>Fecha :</label>
            <Input value={format(date, "dd/MM/yyyy")} />
          </div>
          <Input
            type={"time"}
            value={time}
            onChange={(e) => setTime(e.target.value)}
          />
        </div>
        <TextEditor state={note} setState={setNote} />
        <Modal.Footer>
          <Button text="Cancelar" handler={() => setOpenModal(false)} outline />
          <Button
            text="Confirmar"
            bold
            outline
            bgColor={"primary_500"}
            textColor={"neutral_50"}
            handler={() => handleModifySubState()}
          />
        </Modal.Footer>
      </StyledModal>
      <DatePickerModal
        open={openDate}
        setOpen={setOpenDate}
        date={date}
        setDate={setDate}
      />
    </div>
  );
}
