import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  getCompensations,
  approveCompensation,
} from "../../services/CO2.services";

import { useTranslation } from "react-i18next";

const cardStyles = {
  container: {
    border: "1px solid #ccc",
    padding: "20px",
    margin: "20px",
    width: "350px",
    height: "650px",
    borderRadius: "20px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    transition: "transform 0.3s ease",
    position: "relative",
  },
  title: {
    lineHeight: "1.2",
    position: "fixed",
    bottom: "343px",
    right: "5px",
    zIndex: "1",
    color: "#fff",
    textAlign: "right",
  },
  description: {
    marginBottom: "100px",
    fontSize: "16px",
    color: "#333",
    zIndex: "1",
  },
  imageContainer: {
    position: "relative",
    flex: "1",
    right: "21px",
    top: "-21px",
  },
  image: {
    maxWidth: "350px",
    height: "300px",
    borderRadius: "20px 20px 4px 4px",
    objectFit: "cover",
    filter: "brightness(0.7)",
  },
  details: {
    fontWeight: "bold",
    fontSize: "24px",
  },
  button: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#12C909",
    background: "linear-gradient(45deg, #39509c, #5a6acc)",
    color: "#fff",
    padding: "12px 24px",
    borderRadius: "10px",
    border: "none",
    fontWeight: "bold",
    fontSize: "16px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    cursor: "pointer",
  },
  buttonCert: {
    position: "absolute",
    top: "78%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#12C909",
    background: "linear-gradient(45deg, #39509c, #5a6acc)",
    color: "#fff",
    padding: "12px 24px",
    borderRadius: "10px",
    border: "none",
    fontWeight: "bold",
    fontSize: "16px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    cursor: "pointer",
  },
  footer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    marginBottom: "40px",
  },
};
function calculatePrice(kgAmount) {
  if (kgAmount >= 0 && kgAmount < 5000) {
    return 80;
  } else if (kgAmount >= 5000 && kgAmount < 10000) {
    return 250;
  } else if (kgAmount >= 10000 && kgAmount < 45000) {
    return 420;
  } else {
    return 950;
  }
}
function openCertification(certificate_pdf) {
  console.log(certificate_pdf);
  window.open(certificate_pdf, "_blank");
}

function Card({ compensation, token, isSelected }) {
  const { t: translate } = useTranslation("CO2Compensations");
  const [isHovered, setIsHovered] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const user = useSelector((state) => state.user);
  const chooseOption = async () => {
    let response = await approveCompensation(
      token,
      compensation.id,
      user.idUser
    );
    if (compensation.order_id != "val option") {
      const responseString = response.join("");
      const normalizedResponse = JSON.parse(responseString);
      window.open(normalizedResponse.certificate_pdf, "_blank");
      window.location.reload();
    } else {
      window.location.reload();
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex(
        (prevIndex) => (prevIndex + 1) % compensation.project.images.length
      );
    }, 3000);

    return () => clearInterval(interval);
  }, [compensation.project.images]);

  useEffect(() => {
    if (isSelected) {
      setIsHovered(false);
    }
  }, [isSelected]);

  const handleMouseEnter = () => {
    if (!isSelected) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const maxDescriptionLength = 200;
  const truncatedSummary =
    compensation.project.summary.length > maxDescriptionLength
      ? `${compensation.project.summary.slice(0, maxDescriptionLength)}...`
      : compensation.project.summary;

  const maxtitleLength = 108;
  const truncatedTitle =
    compensation.project.title.length > maxtitleLength
      ? `${compensation.project.title.slice(0, maxtitleLength)}...`
      : compensation.project.title;

  return (
    <div
      style={{
        ...cardStyles.container,
        transform:
          isHovered || compensation.status ? "scale(1.05)" : "scale(1)",
        backgroundColor: isHovered ? "rgba(0, 0, 0, 0.5)" : "white",
        position: "relative",
        boxShadow: compensation.status ? "0 0 35px #3c529c" : "none",
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div style={cardStyles.imageContainer}>
        <h2 style={{ ...cardStyles.title }}>{truncatedTitle}</h2>
        <img
          src={compensation.project.images[currentImageIndex]}
          alt="Project"
          style={{
            ...cardStyles.image,
            filter: isHovered ? "brightness(1)" : "brightness(0.7)",
          }}
        />
      </div>
      <div>
        <p style={{ ...cardStyles.description }}>{truncatedSummary}</p>
      </div>
      {isHovered && !isSelected && (
        <button onClick={chooseOption} style={{ ...cardStyles.buttonCert }}>
          Seleccionar
        </button>
      )}

      <div style={cardStyles.footer}>
        <div>
          <p style={{ ...cardStyles.details }}>
            CO₂ a compensar:
            <br /> {compensation.kg_amount} kg <br />
            {compensation.order_id === "val option" ? null : (
              <>Precio: {calculatePrice(compensation.kg_amount)} USD</>
            )}
          </p>
        </div>
      </div>
      {compensation.status && compensation.order_id != "val option" ? (
        <button
          onClick={openCertification(compensation.certificate_pdf)}
          style={{ ...cardStyles.button }}
        >
          Certificado
        </button>
      ) : null}
    </div>
  );
}

export default function CO2Compensations() {
  const token = useSelector((state) => state.user.token);
  const [compensations, setCompensations] = useState([]);
  const [isSelected, setSelected] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const radication = searchParams.get("radication");
  const id = searchParams.get("id");

  useEffect(() => {
    if (radication) {
      getCompensations(token, radication, "radication")
        .then((compensations) => {
          setCompensations(compensations);
          compensations.forEach((compensation) => {
            if (compensation.status == true) {
              setSelected(true);
            }
          });
        })
        .catch((error) => {
          console.error("Error al obtener compensaciones:", error);
        });
    }
    if (id) {
      getCompensations(token, id, "id")
        .then((compensations) => {
          setCompensations(compensations);
          compensations.forEach((compensation) => {
            if (compensation.status == true) {
              setSelected(true);
            }
          });
        })
        .catch((error) => {
          console.error("Error al obtener compensaciones:", error);
        });
    }
  }, [radication, token]);

  return (
    <div>
      <br />
      <h2 style={{ textAlign: "center" }}>Opciones de compensación</h2>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          marginTop: "20px",
        }}
      >
        {compensations.map((compensation) => (
          <Card
            key={compensation.id}
            compensation={compensation}
            token={token}
            isSelected={isSelected}
          />
        ))}
      </div>
    </div>
  );
}
