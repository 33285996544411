import React from 'react'
import { styled } from '@nextui-org/react';
import Input from '../../../components/Input/Input'
import InputSelect from '../../../components/Select/inputSelect';
import InputFileCard from '../../../components/InputFileCard/InputFileCard'
import { Radio } from '@nextui-org/react';
import Button from '../../../components/Button/Button';

const StyledContainer = styled("div", {
    display: 'flex',
    flexDirection: 'column',
    margin: '30px',
    gap: '15px',
    borderRadius: '8px',
    padding: '40px',
})

export default function Wha() {
    return (
        <StyledContainer>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                <div>
                    <p style={{ margin: '10px' }}><b>Seleccione la plantilla :</b></p>
                    <InputSelect options={[
                        {
                            label: 'Plantilla'
                        }
                    ]} />
                </div>
                <div>
                    <p style={{ margin: '10px' }}><b>Tipo de mensaje :</b></p>
                    <InputSelect options={[
                        {
                            label: 'Link a portal de tracking'
                        },
                        {
                            label: 'Link a portal de clientes'
                        },
                        {
                            label: 'Status'
                        },
                    ]} />
                </div>
            </div>
            <p style={{ margin: '10px', fontWeight: 'bold' }}>Enviar a :</p>
            <Input />
            <Button text='Enviar' type="submit" bold bgColor={'primary_300'} textColor='neutral_50' />
        </StyledContainer>
    )
}

