import React, { useState, useEffect } from 'react'
import Input from '../../../components/Input/Input'
import InputSelect from '../../../components/Select/inputSelect';
import Button from '../../../components/Button/Button';
import { Loading, Modal, Tooltip } from '@nextui-org/react';
import { styled } from '@nextui-org/react';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form'
import Icon from '../../../components/Icon/Icon';
import { getSubTypes, getSolutions, getOffices, getQuoteLanguages, getStatuses, getReasons, postQuotation, deleteQuotation, editQuotation, getQuoteData } from '../../../services/quotations.services';
import { getBusinessTerms } from '../../../services/businessTerms.services';
import { getUsers } from '../../../services/user.services';
import { useSearchParams, useNavigate } from 'react-router-dom';
import PlacesAutocomplete from 'react-places-autocomplete';
import toast from '../../../components/Toast/ToastTypes';
import { getServiceSplitRadication } from '../../../services/quotation.services';
import DisplayData from './DisplayData';
import FinishQuotationModal from './FinishQuotationModal';
import { ROLES } from '../../../constants';
import { useTranslation } from "react-i18next";
import CreateExample from '../OfferRequirements/templates';
import EditPurchaseOrderButton from '../../../components/EditPurchaseOrderButton/EditPurchaseOrderButton';
import { getAgents, getOperationStatuses } from '../../../services/operations.services';
import { getProviders, getProviderTypes } from '../../../services/preliquidation.services';

const StyledFormModal = styled(Modal, {
    padding: '1.5rem',
    color: '$primary_500 !important',
})

const FormContainer = styled("fieldset", {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    gap: '30px',
    "@media(max-width:1000px)":{
        display:'flex',
        flexDirection:'column',
    }
})

const InputsContainer = styled("div", {
    position: 'relative',
    backgroundColor: '$neutral_50',
    gridColumn: 'span 3',
    display: 'grid',
    maxHeight: '600px',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '30px',
    padding: '30px',
    borderRadius: '8px',
    boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)',
    ['.autocomplete-dropdown-container-top, .autocomplete-dropdown-container-bottom']: {
        position: 'absolute',
        zIndex: '1',
        width: '100%',
        ['.suggestion']: {
            border: 'solid 1px $neutral_100',
            padding: '0.4rem',
            fontSize: '0.9rem',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        },
        ['.suggestion:first-child']: {
            borderTopRightRadius: '8px',
            borderTopLeftRadius: '8px',
        },
        ['.suggestion:last-child']: {
            borderBottomRightRadius: '8px',
            borderBottomLeftRadius: '8px'
        },
    },
    ['.autocomplete-dropdown-container-top']: {
        bottom: '100%'
    }
})
const RFQDataContainer = styled("div", {
    gridColumn: 'span 2',
    gridRow: 'span 2',
    background: '$neutral_50',
    borderRadius: '8px',
    boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)',
    padding: '20px',
    overflowX:'scroll',
})
const ButtonsContainer = styled("div",{
    display:'flex',
    gap:'10px',
    margin:'20px 0',
    "@media(max-width:1075px)":{
        display:'flex',
        flexDirection:'column',
    }
})

export default function QuoteForm({ currentQuote, setCurrentQuote, items, setItems }) {

    const [searchParams] = useSearchParams()
    const [subtypes, setSubtypes] = useState([])
    const [services, setServices] = useState([])
    const [offices, setOffices] = useState([])
    const [incoterms, setIncoterms] = useState([])
    const [clients, setClients] = useState([])
    const [status, setStatus] = useState([])
    const [quoteLanguages, setQuoteLanguages] = useState([])
    const [disableSubmit, setDisableSubmit] = useState(false)
    const [reasons, setReasons] = useState([])
    const [quoteData, setQuoteData] = useState({})
    const [disableForm, setDisableForm] = useState(true)
    const [deleteConfirmation, setDeleteConfirmation] = useState(false)
    const [agents, setAgents] = useState([])
    const [operationStatuses, setOperationStatuses] = useState([])
    const [filteredProviders, setFilteredProviders] = useState([])
    
    const [filteredServices, setFilteredServices] = useState([]);
    const [RFQ, setRFQ] = useState()
    const [radication, setRadication] = useState('')

    const token = useSelector(state => state.user.token)

    const navigate = useNavigate()

    const { control, handleSubmit, watch, getValues, setValue, reset, formState: { errors } } = useForm();
    
    useEffect(() => {
        const radication = searchParams.get("radication")
        if (radication) {
            setRadication(searchParams.get("radication"))
        }
    }, [])

    useEffect(() => {
        if (subtypes.length > 0) {
            const selectedSubtype = watch("subtype");
            const filteredServices = services?.filter(service => service.subtype.includes(selectedSubtype?.value));
            setFilteredServices(filteredServices);
        }
    }, [subtypes, services, watch("subtype")])

    const currentSubtype = watch("subtype")
    useEffect(() => {
        if (subtypes.length > 0) {
            const filteredServices = services?.filter(service => service.subtype.includes(currentSubtype?.value));
            setFilteredServices(filteredServices);
        }
    }, [currentSubtype]);

    useEffect(() => {
        const asyncTasks = [
            getSubTypes(token),
            getSolutions(token),
            getOffices(token),
            getBusinessTerms(token),
            getUsers(token),
            getQuoteLanguages(token),
            getStatuses(token),
            getReasons(token),
            getServiceSplitRadication(radication || currentQuote?.radication, token),
            getAgents(token),
            getOperationStatuses(token),
            getProviders(token),
            getProviderTypes(token)
        ];
        // if (radication || currentQuote?.radication) {
        //     asyncTasks.push(getServiceSplitRadication(radication || currentQuote?.radication, token))
        // }
        if (currentQuote.id){
            asyncTasks.push(getQuoteData(token, currentQuote.id))
        }
        Promise.all(asyncTasks)
            .then(async responses => {
                const subtypesData = await responses[0].json(); setSubtypes(subtypesData);
                const servicesData = await responses[1].json(); setServices(servicesData)
                const officesData = await responses[2].json(); setOffices(officesData)
                const incotermsData = await responses[3].json(); setIncoterms(incotermsData)
                const clientsData = await responses[4].json();
                const quoteLanguagesData = await responses[5].json(); setQuoteLanguages(quoteLanguagesData)
                const statusesData = await responses[6].json(); setStatus(statusesData)
                const reasonsData = await responses[7].json(); setReasons(reasonsData)
                const agentsData = await responses[9]?.json();
                const operationStatusesData = await responses[10]?.json();
                const providersData = await responses[11]?.json();
                const providerTypesData = await responses[12]?.json();
                const liquidationData = await responses[13]?.json();
                
                setAgents(agentsData)
                setOperationStatuses(operationStatusesData)
                console.log("providersData :",providersData)
                console.log("providerTypesData :",providerTypesData)
                let filteredProviders = providersData.filter(prov => {
                    const type = providerTypesData?.find(type => type.id == prov.provider_type)
                    if (['COURIER','FFWW'].includes(type.name)){
                      return true
                    } else return false
                })
                setFilteredProviders(filteredProviders)
                setQuoteData(liquidationData)

                setClients(clientsData.filter(user => [ROLES.User, ROLES.Client, ROLES.Admin].includes(user.role_id)))

                if (radication || currentQuote?.id) {
                    const RFQData = await responses[8].json(); 
                    setRFQ(RFQData.results[0])
                    if (currentQuote?.id) {
                        reset({
                            subtype: formatData(subtypesData.find(e => e.id === liquidationData.subtype)),
                            service: formatData(servicesData.find(e => e.id === liquidationData.service)),
                            office: formatData(officesData.find(e => e.id === liquidationData.office)),
                            client: formatData(clientsData.find(e => e.id === liquidationData.client)),
                            origin: liquidationData?.shipping?.origin_boarding_terminal,
                            destination: liquidationData?.shipping?.destination_boarding_terminal,
                            reason: formatData(reasonsData.find(e => e.id === liquidationData.reason)),
                            incoterm: formatData(incotermsData.find(e => e.id === liquidationData.incoterm)),
                            language: formatData(quoteLanguagesData.find(e => e.id === liquidationData.language)),
                            status: formatData(statusesData.find(e => e.id === liquidationData.status)),
                            notes: liquidationData.observations,
                        });
                    }

                    if (RFQData) {
                        setValue("origin", `${RFQData.results[0].shipping?.origin_boarding_terminal.city.name}, ${RFQData.results[0].shipping?.origin_boarding_terminal.city?.country.name}`)
                        setValue("destination", `${RFQData.results[0].shipping?.destination_boarding_terminal.city.name}, ${RFQData.results[0].shipping?.destination_boarding_terminal.city.country.name}`)
                        setValue("office", formatData(officesData[0]))
                        const client = RFQData.results[0].users.find(user=> (user.user?.groups.includes("Usuario") || user.user?.groups.includes("Cliente")))?.user
                        if (client){
                        setValue("client", {
                            label:`${client.first_name} ${client.last_name} - ${client.email}`,
                            value:client.user,
                        })}
                        const incoterm = formatData(incotermsData.find(e => e.id === RFQData.results[0].business_term))
                        setValue("incoterm", incoterm)
                        if (!currentQuote){
                            setDisableForm(false)
                        }
                    }
                }
            })
            .catch(error => console.error('Error:', error));
    }, [radication]);

    useEffect(() => {
        if (currentQuote.radication){
            setRadication(currentQuote.radication)
        }
    }, [currentQuote])

    const formatData = (data) => {
        return {
            label: data?.name,
            value: data?.value || data?.id,
        }
    }

    const onSubmit = () => {
        setDisableSubmit(true)
        const formData = getValues()
        const submitData = {
            origin_terminal: formData.origin,
            destination_terminal: formData.destination,
            observations: formData.notes,
            client: formData.client?.value,
            service: formData.service?.value,
            subtype: formData.subtype?.value,
            agent: formData.agent?.value,
            operation_status: formData.operationStatus?.value,
            tercerizado: formData.tercerizado?.value,
            office: formData.office?.value,
            language: formData.language?.value,
            incoterm: formData.incoterm?.value,
            status: formData.status?.value,
            reason: formData.reason?.value,
            terms: CreateExample(quoteData),
            radication,
        }
        if (currentQuote && !disableForm) {
            editQuotation(submitData, currentQuote.id, token).then(res => {
                toast("success", "Cotizacion modificada")
                setDisableSubmit(false)
                setDisableForm(true)
            }).catch(err => { toast("error", "No se pudo modificar la cotizacion");setDisableSubmit(false) })
        } else {
            postQuotation(submitData, token).then(res => {
                setCurrentQuote({
                    id:res?.id,
                    radication:res?.radication,
                })
                setDisableForm(true)
                toast("success", "Cotizacion creada con exito")
                setDisableSubmit(false)
                setDisableForm(true)
            }).catch(err => {console.log("err :",err) ;toast("error", "No se pudo crear la cotizacion"); setDisableSubmit(false)})
        }
    };

    const handleDelete = () => {
        if (currentQuote) {
            deleteQuotation(currentQuote.id, token).then((res) => {
                setItems(items.filter(item => item.id !== currentQuote.id))
                setDeleteConfirmation(false)
                setCurrentQuote('')
                toast("success", "Cotizacion eliminada")
            }).catch((err) => {
                setDeleteConfirmation(false)
                toast("error", "No se pudo eliminar la cotizacion")
            })
        }
    }

    const [openFinishModal, setOpenFinishModal] = useState(false)

    const CustomInputSelect = React.forwardRef((props, ref) => (
        <InputSelect ref={ref} {...props} />
    ));

    console.log("currentQuote :",currentQuote)
    console.log("radication :",radication)
    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <ButtonsContainer>
                    <Button text='Nueva cotización' size={"sm"} bgColor={'primary_300'} textColor='neutral_50' endIcon={{name:'Plus', color:'white'}} handler={() => {
                        navigate('/quotation-requests/?liquidate=true')
                    }} />
                    <Button size={"sm"} text='Modificar' disabled={!currentQuote} bgColor={'primary_300'} textColor='neutral_50' handler={() => { currentQuote && setDisableForm(false) }} endIcon={{name:'EditSquare', color:'white'}}/>
                    <Button size={"sm"} text='Guardar' disabled={Object.keys(errors).length || (disableSubmit || disableForm)} handler={()=>onSubmit()} bgColor={'primary_300'} textColor='neutral_50' type="submit" endIcon={{name:'CheckSquare', color:'white'}}/>
                    <Button size={"sm"} text='Borrar' disabled={!currentQuote} bgColor={'delete'} textColor='neutral_50' handler={() => setDeleteConfirmation(true)} endIcon={{name:'Trash', color:'white'}}/>
                    <EditPurchaseOrderButton radication={currentQuote?.radication}/>
                    <Tooltip content="Finaliza la liquidacion y la comparte con el cliente para su aprobación">
                        <Button size={"sm"} text={quoteData?.approve? "liquidación ya finalizada" : 'Finalizar liquidación'}
                            bgColor='primary_300'
                            textColor='neutral_50'
                            disabled={!currentQuote || quoteData?.approve}
                            handler={()=>setOpenFinishModal(true)}
                            endIcon={{name:'Cotizaciones', color:'white'}}
                        />
                    </Tooltip>
                </ButtonsContainer>
            </div>
            {(radication || currentQuote) && <form onSubmit={handleSubmit(onSubmit)} errors={errors}>
                <FormContainer disabled={disableForm}>
                    <InputsContainer>
                        <div>
                            <Controller
                                name="subtype"
                                defaultValue={undefined}
                                rules={{ required: "Este campo es obligatorio" }}
                                control={control}
                                render={({ field }) => (
                                    <CustomInputSelect label="Subtipo * :" {...field}
                                        isDisabled={disableForm}    
                                        options={subtypes?.map(subtype => {
                                            return {
                                                label: subtype.name,
                                                value: subtype.id,
                                            }
                                        })}
                                    />
                                )}
                            />
                            {errors.subtype && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors.subtype.message}</span>}
                        </div>
                        <div>
                            <Controller
                                name="service"
                                control={control}
                                render={({ field }) => (
                                    <CustomInputSelect label='Servicio *' {...field}
                                        isDisabled={disableForm}
                                        options={filteredServices?.map(service => {
                                            return {
                                                label: service.name,
                                                value: service.id,
                                            }
                                        })}
                                    />
                                )}
                                rules={{ required: "Este campo es obligatorio" }}
                            />
                            {errors.service && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors.service.message}</span>}
                        </div>
                        <div>
                            <Controller
                                name="office"
                                control={control}
                                render={({ field }) => (
                                    <CustomInputSelect label='Oficina *' {...field}
                                        isDisabled={disableForm}
                                        options={offices?.map(office => {
                                            return {
                                                label: office.name,
                                                value: office.id,
                                            }
                                        })}
                                    />
                                )}
                                rules={{ required: "Este campo es obligatorio" }}
                            />
                            {errors.office && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors.office.message}</span>}
                        </div>
                        <div>
                            <Controller
                                name="agent"
                                control={control}
                                render={({ field }) => (
                                    <CustomInputSelect label='Agente *' {...field}
                                        isDisabled={disableForm}
                                        options={agents?.map(agent => {
                                            return {
                                                label: agent.name,
                                                value: agent.id,
                                            }
                                        })}
                                    />
                                )}
                                rules={{ required: "Este campo es obligatorio" }}
                            />
                            {errors.service && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors.service.message}</span>}
                        </div>
                        <div>
                            <Controller
                                name="tercerizado"
                                control={control}
                                render={({ field }) => (
                                    <CustomInputSelect label='Tercerizado' {...field}
                                        isDisabled={disableForm}
                                        options={[
                                            {label:'No',value:null},
                                            ...filteredProviders?.map(provider => {
                                            return {
                                                label: provider.name,
                                                value: provider.id,
                                            }
                                        })]}
                                    />
                                )}
                                rules={{ required: "Este campo es obligatorio" }}
                            />
                            {errors.office && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors.office.message}</span>}
                        </div>
                        <div style={{gridColumn:'span 2'}}>
                            <Controller
                                name="client"
                                control={control}
                                render={({ field }) => (
                                    <CustomInputSelect label='Cliente *' {...field}
                                        isDisabled={disableForm}
                                        options={clients.map(client => {
                                            return {
                                                label: `${client.name} ${client.last_name} - ${client.email}`,
                                                value: client.id,
                                            }
                                        })}
                                    />
                                )}
                                rules={{ required: "Este campo es obligatorio" }}
                            />
                            {errors.client && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors.client.message}</span>}
                        </div>
                        <div>
                            <Controller
                                name="incoterm"
                                control={control}
                                render={({ field }) => (
                                    <CustomInputSelect label='Incoterm' {...field}
                                        isDisabled={disableForm}
                                        options={incoterms?.map(incoterm => {
                                            return {
                                                label: incoterm.name,
                                                value: incoterm.id,
                                            }
                                        })}
                                    />
                                )}
                                rules={{ required: "Este campo es obligatorio" }}
                            />
                            {errors.incoterm && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors.incoterm.message}</span>}
                        </div>
                        <div>
                            <Controller
                                name="origin"
                                control={control}
                                render={({ field }) => (
                                    <PlacesAutocomplete
                                        {...field}
                                    // searchOptions={{
                                    //     componentRestrictions: { country: destinationCountryISO }
                                    // }}
                                    // onSelect={(e)=> {console.log('onSelect :',e)}}
                                    >
                                        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                                            <div className='p-relative'>
                                                <Input
                                                    isDisabled={disableForm}
                                                    label='Origen'
                                                    name={`origin`}
                                                    className={`o_input`}
                                                    animated={false}
                                                    placeholder='Hasta'
                                                    fullWidth={'100%'}
                                                    value={watch('origin') || ''}
                                                    onBlur={getInputProps().onBlur}
                                                    onChange={getInputProps().onChange}
                                                    onKeyDown={getInputProps().onKeyDown}
                                                />
                                                <div className="autocomplete-dropdown-container-bottom">
                                                    {
                                                        suggestions.map((suggestion, i) => {
                                                            const style = suggestion.active
                                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                            return (
                                                                <div
                                                                    key={i}
                                                                    {...getSuggestionItemProps(suggestion, {
                                                                        className: 'suggestion',
                                                                        style,
                                                                    })}
                                                                >
                                                                    <Icon name={'Rastreo'} size={'14'}></Icon><span>{suggestion.description}</span>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )}
                                    </PlacesAutocomplete>
                                )}
                                rules={{ required: "Este campo es obligatorio" }}
                            />
                            {errors.origin && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors.origin.message}</span>}
                        </div>
                        <div>
                            <Controller
                                name="destination"
                                control={control}
                                render={({ field }) => (
                                    <PlacesAutocomplete
                                        {...field}
                                    // searchOptions={{
                                    //     componentRestrictions: { country: destinationCountryISO }
                                    // }}
                                    >
                                        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                                            <div className='p-relative'>
                                                <Input
                                                    isDisabled={disableForm}
                                                    label='Destino'
                                                    value={watch('destination') || ''}
                                                    name={`destination`}
                                                    className={`o_input`}
                                                    animated={false}
                                                    placeholder='Hasta'
                                                    fullWidth={'100%'}
                                                    onBlur={getInputProps().onBlur}
                                                    onChange={getInputProps().onChange}
                                                    onKeyDown={getInputProps().onKeyDown}
                                                />
                                                <div className="autocomplete-dropdown-container-bottom">
                                                    {
                                                        suggestions.map((suggestion, i) => {
                                                            const style = suggestion.active
                                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                            return (
                                                                <div
                                                                    key={i}
                                                                    {...getSuggestionItemProps(suggestion, {
                                                                        className: 'suggestion',
                                                                        style,
                                                                    })}
                                                                >
                                                                    <Icon name={'Rastreo'} size={'14'}></Icon><span>{suggestion.description}</span>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )}
                                    </PlacesAutocomplete>
                                )}
                                rules={{ required: "Este campo es obligatorio" }}
                            />
                            {errors.destination && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors.destination.message}</span>}
                        </div>
                        <div>
                            <Controller
                                name="status"
                                control={control}
                                defaultValue={{label:'En proceso', value:2}}
                                render={({ field }) => (
                                    <CustomInputSelect label='Estado *' {...field}
                                        isDisabled={disableForm}
                                        rules={{ required: "Este campo es obligatorio" }}
                                        options={status.map(state => {
                                            return {
                                                label: state.name,
                                                value: state.id,
                                            }
                                        })}
                                    />
                                )}
                                rules={{ required: "Este campo es obligatorio" }}
                            />
                            {errors.status && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors.status.message}</span>}
                        </div>
                        <div>
                            <Controller
                                name="reason"
                                control={control}
                                render={({ field }) => (
                                    <CustomInputSelect label='Motivo' {...field} isDisabled={disableForm}
                                        options={reasons.map(reason => {
                                            return {
                                                label: reason.name,
                                                value: reason.id,
                                            }
                                        })}
                                    />
                                )}
                                rules={{ required: "Este campo es obligatorio" }}
                            />
                            {errors.reason && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors.reason.message}</span>}
                        </div>
                        <div>
                            <Controller
                                name="language"
                                defaultValue={{label:'Español', value:1}}
                                control={control}
                                render={({ field }) => (
                                    <CustomInputSelect label='Idioma' {...field}
                                        isDisabled={disableForm}
                                        options={quoteLanguages.map(language => {
                                            return {
                                                label: language.name,
                                                value: language.id,
                                            }
                                        })}
                                    />
                                )}
                                rules={{ required: "Este campo es obligatorio" }}
                            />
                            {errors.subtype && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors.subtype.message}</span>}
                        </div>
                        <div>
                            <Controller
                                name="travel_time"
                                defaultValue={{label:'Un dia', value: 1}}
                                rules={{ required: "Este campo es obligatorio" }}
                                control={control}
                                render={({field }) => (
                                    <CustomInputSelect label="Tiempo en transito *" {...field}
                                        isDisabled={disableForm}       
                                        options={[
                                            {label:'Un dia', value: 1},
                                            {label:'Una semana', value: 7},
                                            {label:'15 dias',value: 15},
                                            {label:'30 dias',value: 30},
                                            {label:'abierto',value: 1},
                                        ]}
                                    />
                                )}
                            />
                            {
                            watch('travel_time')?.label == 'abierto' &&
                                <Input label="Días *" type="number" onChange={(e)=>{setValue("travel_time.value",e.target.value)}} isDisabled={disableForm}/>
                            }
                            {errors.travel_time && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors.travel_time.message}</span>}
                        </div>
                        <div>
                            <Controller
                                name="expiration"
                                defaultValue={{label:'Un dia', value: 1}}
                                rules={{ required: "Este campo es obligatorio" }}
                                control={control}
                                render={({ field }) => (
                                    <CustomInputSelect label="Vencimiento * " {...field}
                                        isDisabled={disableForm}      
                                        options={[
                                            {label:'Un dia', value: 1},
                                            {label:'Una semana', value: 7},
                                            {label:'15 dias',value: 15},
                                            {label:'30 dias',value: 30},
                                            {label:'abierto',value: 0},
                                        ]}
                                    />
                                )}
                            />
                            {
                            watch('expiration')?.label=='abierto' &&
                            <Input label="Dias *" type="number" onChange={(e)=>{setValue("expiration.value",e.target.value)}} isDisabled={disableForm}/>
                            }
                            {errors.expiration && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors.expiration.message}</span>}
                        </div>
                        <div style={{ 'gridColumn': 'span 2' }}>
                            {!currentQuote && <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Controller
                                    name="notes"
                                    rules={{ required: "Este campo es obligatorio" }}
                                    control={control}
                                    render={({ field }) => (
                                        <Input label='Observaciones *' {...field}/>
                                    )}
                                />
                                {errors.notes && <span style={{ color: '#FF6B6B', fontSize: '12px' }}>{errors.notes.message}</span>}
                            </div>}
                        </div>
                        <div style={{display:'flex', justifyContent:'end',width:'100%',gridColumn:'span 3'}}>
                        <Button text='Guardar cotizacion' disabled={Object.keys(errors).length || (disableSubmit || disableForm)} bold bgColor={'primary_300'} textColor='neutral_50' type="submit" />
                        </div>
                        
                    </InputsContainer>
                    <RFQDataContainer>
                        {
                            !(radication || currentQuote.radication) ?
                                <div>
                                    <h3>Selecciona una cotizacion</h3>
                                    <p>Selecciona una cotizacion de la tabla o presiona "Nueva cotizacion" para empezar a operar</p>
                                </div> :
                                RFQ ?
                                    <DisplayData data={RFQ} radication={radication || currentQuote.radication} /> :
                                    <Loading />
                        }
                    </RFQDataContainer>
                    {/* <InputsContainer>
                        <Controller
                            name="load-type"
                            control={control}
                            render={({ field }) => (
                                <Input label='Tipo de carga ' {...field} />
                            )}
                        />
                        <Controller
                            name="Tariff heading"
                            control={control}
                            render={({ field }) => (
                                <Input label='Partida arancelaria' {...field} />
                            )}
                        />
                        <Controller
                            name="Transit-days"
                            control={control}
                            render={({ field }) => (
                                <Input label='Dias de transito' {...field} />
                            )}
                        />
                        
                    </InputsContainer> */}
                </FormContainer>
            </form>}
            {quoteData && <FinishQuotationModal quoteData={quoteData} open={openFinishModal} setOpen={setOpenFinishModal} />}
            <StyledFormModal
                closeButton
                aria-label="modal-title"
                open={deleteConfirmation}
                onClose={() => setDeleteConfirmation(false)}
                width="fit-content"
            >
                <Modal.Header justify='space-between'>
                    <h3>Confirmar</h3>
                </Modal.Header>
                <Modal.Body justify='space-between'>
                    <p>Confirma que desea borrar la cotizacion seleccionada</p>
                    <div style={{ display: 'flex', justifyContent: 'end', gap: '15px' }}>
                        <Button text='Cancelar' bold bgColor={'primary_300'} textColor='neutral_50' handler={() => setDeleteConfirmation(false)} />
                        <Button text='Borrar' bold bgColor={'delete'} textColor='neutral_50' disabled={!currentQuote} handler={() => handleDelete()} />
                    </div>
                </Modal.Body>
            </StyledFormModal>
        </div>
    )
}