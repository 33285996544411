import React, { useState, useEffect } from 'react'
import { styled, Switch } from '@nextui-org/react';
import SimpleAutocomplete from '../../../components/SimpleAutocomplete/SimpleAutocomplete'
import Button from '../../../components/Button/Button'
import Input from '../../../components/Input/Input'
import InputSelect from '../../../components/Select/inputSelect'
import { useForm, Controller } from 'react-hook-form';
import { calculateCO2byPerson } from '../../../services/CO2.services'
import { getPorts } from '../../../services/countries.services';
import toast from '../../../components/Toast/ToastTypes';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
const StyledContainer = styled("div", {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '40px',
    marginBottom: '30px',
})

const weightOptions = [
    { label: 'Kilograms', value: 'kilograms' },
    { label: 'Grams', value: 'grams' },
    { label: 'Metric Tons', value: 'metric tons' },
    { label: 'Imperial Tons', value: 'imperial tons' },
    { label: 'Pounds', value: 'pounds' },
    { label: 'Ounces', value: 'ounces' },
];

const specification = {
    "type": {
        "description": "Specification",
        "type": "string",
        "default": "average",
        "enum": [
            "average",
            "bulk-carrier",
            "container-ship",
            "general-cargo",
            "large-ropax-ferry",
            "refrigerated-cargo",
            "roro-ferry",
            "vehicle-transport",
        ],
    }
};

export default function ShipFreightForm() {
    const token = useSelector(state => state.user.token)
    const user = useSelector(state => state.user)
    const { control, handleSubmit, watch, formState: { errors } } = useForm();
    const [Ports, setPorts] = useState([]);
    useEffect(() => {
        getPorts(token).then(async res => {
            const data = await res.json();
            const portOptions = data.map(([code, details]) => ({
                value: details,
                label: `${details.country} - ${details.name} - ${code}`,
            }));

            setPorts(portOptions);
        });
    }, [token]);

    const navigate = useNavigate();
    function onSubmit(data) {
        console.log('datos:', data)
        const request = {
            type: "ship_freight",
            departure: data.departure.value,
            destination: data.destination.value,
            weight: parseInt(data.weight),
            weight_unit: data.weight_unit.value
        }
        calculateCO2byPerson(token, {request,user:user.idUser}).then((res)=>{
            if(res.status==200){
                toast("success", "Cálculo guardado exitosamente")
                navigate('/co2/history');
            }
            else{
                toast("error", "No se pudo guardar el cálculo")
            }
        }).catch(error=>{
            toast("error", "Ha ocurrido un error inesperado")
        })
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <StyledContainer>
                <Controller
                    name="departure"
                    control={control}
                    render={({ field }) => (
                        <InputSelect
                            label="Puerto de salida"
                            options={Ports}
                            {...field}
                            onChange={(selectedPort) => field.onChange(selectedPort)}
                        />
                    )}
                    rules={{ required: true }}
                />
                <Controller
                    name="destination"
                    control={control}
                    render={({ field }) => (
                        <InputSelect
                            label="Puerto de llegada"
                            options={Ports}
                            {...field}
                            onChange={(selectedPort) => field.onChange(selectedPort)}
                        />
                    )}
                    rules={{ required: true }}
                />

                <Controller
                    name="weight"
                    control={control}
                    render={({ field }) => (
                        <Input label="Peso de la carga" type="number" {...field} />
                    )}
                    rules={{ required: true }}
                />
                <Controller
                    name="weight_unit"
                    control={control}
                    render={({ field }) => (
                        <InputSelect label="Unidad" options={weightOptions} {...field} />
                    )}
                    rules={{ required: true }}
                />
                {/* <Controller
                    name="mode"
                    control={control}
                    render={({ field }) => (
                        <InputSelect label="Modo" options={specification} {...field} />
                    )}
                    rules={{ required: false }}
                /> */}

                <div style={{ display: 'flex', paddingTop: '30px', justifyContent: 'space-between' }}>

                    <Button text="Realizar calculo" bold outline type="submit" />
                </div>
            </StyledContainer>
        </form>
    )
}
