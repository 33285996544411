
import CreatableSelect from 'react-select/creatable';
import { styled } from '@nextui-org/react';
import makeAnimated from 'react-select/animated';
import theme from '../../theme';

const StyledLabel = styled('div', {
    color: '$primary_500',
    fontSize: '0.875rem',
    paddingLeft: '0.25rem',
    marginBottom: '0.55rem',
    ['span']: {
        color: '#ff676c'
    }
})
const animatedComponents = makeAnimated();

const colourStyles = {
    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    menu: provided => ({ ...provided, zIndex: 9999 }),
    control: (styles) => ({
        ...styles,
        border: '1px solid ' + theme.colors.primary_300,
        borderRadius: '8px',
        margin: 0,
        padding: 0,
        minHeight: '40px',
        minWidth: '200px',
        boxShadow: 'none',
        outline: 'none',
        [':hover']: {
            borderColor: 'grey',
        }
    }),
};

export default function CreatableInputSelect({ options, onChange, label, value, isDisabled, disableClear, defaultValue, isMulti }) {
    return (
        <div>
            {label &&
                <StyledLabel>
                    <label>{label}</label>
                </StyledLabel>
            }
            <CreatableSelect
                styles={colourStyles}
                value={value}
                isMulti={isMulti}
                isClearable={disableClear ? false : true}
                options={options}
                components={animatedComponents}
                onChange={onChange}
                isDisabled={isDisabled}
                defaultValue={defaultValue || ''}
            />
        </div>
    )
}
