import React, { useState, useEffect, useRef } from "react";
import GoogleMapReact from 'google-map-react';
import MapMarker from "./MapMarker";

export default function SimpleMap({coordinatesArray}){
  console.log("coordinatesArray :",coordinatesArray)
  const [map, setMap] = useState(null);
  const [center, setCenter] = useState();
  const [zoom, setZoom] = useState(2);
  const [polyline, setPolyline] = useState(null);
  const polylineRef = useRef(null);

  useEffect(() => {
    setMapParameters();
  }, [coordinatesArray]);
  
  function setMapParameters(){
    if (coordinatesArray?.length > 1) {
      let centerLat = 0;
      let centerLng = 0;
      for (const coord of coordinatesArray) {
        centerLat += coord.lat;
        centerLng += coord.lng;
      }
      centerLat /= coordinatesArray.length;
      centerLng /= coordinatesArray.length;
      
      setCenter({ lat: centerLat, lng: centerLng });
      // Calcular el nivel de zoom
      let totalDistance = 0;
      for (let i = 0; i < coordinatesArray.length - 1; i++) {
        const origin = coordinatesArray[i];
        const destination = coordinatesArray[i + 1];
        totalDistance += google.maps.geometry.spherical.computeDistanceBetween(
          new google.maps.LatLng(origin.lat, origin.lng),
          new google.maps.LatLng(destination.lat, destination.lng)
        );
      }
      const newZoom = Math.floor(16 - Math.log2(totalDistance / 900));
      setZoom(newZoom);

      // Eliminar la polilínea anterior si existe
      if (polylineRef.current) {
        polylineRef.current.setMap(null);
      }

      // Crear una nueva polilínea
      const path = coordinatesArray?.map(location => ({
        lat: location.lat,
        lng: location.lng
      }));

      let adjustedPath = [path[0], path[1]];
      if (center){
        adjustedPath = [path[0], center, path[1]];
      }
      const newPolyline = new window.google.maps.Polyline({
        path:adjustedPath,
        strokeColor: "#3D509A",
        strokeOpacity: 1,
        strokeWeight: 2,
        icons: [{
          icon: {
            path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
            scale: 3
          },
          offset: '100%',
          repeat: '100px'
        }]
      });
      if (path[0].lat && path[0].lng && path[1].lat && path[1].lng){
        newPolyline.setMap(map);
        setPolyline(newPolyline);
        polylineRef.current = newPolyline;
      }
    }
  }

  return (
    <div style={{ height: '80vh', width: '100%', borderRadius:'8px' }}>
      {zoom && 
      <GoogleMapReact
        bootstrapURLKeys={{ key: "" }}
        defaultCenter={center || {lat:0,lng:0}}
        yesIWantToUseGoogleMapApiInternals
        defaultZoom={zoom}
        options={{
          minZoom:0,
        }}
        onGoogleApiLoaded={({ map }) => setMap(map)}
      >
        {coordinatesArray?.map((location, i) => (
          location.lat && location.lng &&
          <div key={i} lat={location.lat} lng={location.lng}>
            {location.name && <MapMarker label={location.name} important/>}
          </div>
        ))}
      </GoogleMapReact>}
    </div>
  );
}

