import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { getServiceSplitRadication } from '../../../services/quotation.services';
import { getShippingMethods } from '../../../services/shippingMethods.services';
import TrackingDetailsOcean from './TrackingDetailsOcean';
import TrackingDetailsAir from './TrackingDetailsAir';

export default function TrackingDetails() {
    const token = useSelector(state=>state.user.token)
    const [searchParams] = useSearchParams();
    const shippingMethod = searchParams.get("shippingMethod");

    const radication = searchParams.get("radication");
    const containerNumber = searchParams.get("containerNumber");
    const mblNumber = searchParams.get("mblNumber");
    const bookingNumber = searchParams.get("bookingNumber");
    const shipmentNumber = searchParams.get("shipmentNumber");

    const getRef = () => {
        if (radication) return { type: 'tags', value: radication };
        if (containerNumber) return { type: 'containerNumber', value: containerNumber };
        if (mblNumber) return { type: 'mblNumber', value: mblNumber };
        if (bookingNumber) return { type: 'bookingNumber', value: bookingNumber };
        if (shipmentNumber) return { type: 'shipmentNumber', value: shipmentNumber };
        return null;
    };
      
    const trackingRef = getRef();
    console.log("trackingRef :",trackingRef)
    // const [shippingMethod, setShippingMethod] = useState("")

    // useEffect(()=>{
    //     const asyncTasks = [getServiceSplitRadication(radication,token),getShippingMethods(token)]
    //     Promise.all(asyncTasks).then(async res=>{
    //         const rfqData = await res[0].json();
    //         const shippingMethods = await res[1].json();
    //         const methodId = rfqData.results[0]?.shipping.shipping_method;
    //         const method = shippingMethods.find(method=>method.id === methodId)
            
    //         if (['ocean','bbk','ro-ro','special containers'].includes(method.name)){
    //             setShippingMethod('ocean')
    //         } else {setShippingMethod('air')}
    //     })
    // },[])
    console.log("shippingMethod :",shippingMethod)
    return (
        <div>
            {shippingMethod === 'ocean' && <TrackingDetailsOcean trackingRef = {trackingRef}/>}
            {shippingMethod !== 'ocean' && <TrackingDetailsAir trackingRef = {trackingRef}/>}
        </div>
    )
}
