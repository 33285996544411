import React, { useState } from 'react'
import airSrc from '../../../assets/sendMethods/aerial.jpg'
import oceanSrc from '../../../assets/sendMethods/maritime.jpg'
import landSrc from '../../../assets/sendMethods/land.jpg'
import { styled, Modal, Text, Textarea, Image } from '@nextui-org/react'
import Button from '../../../components/Button/Button'
import { approveQuotation } from '../../../services/quotations.services'
import toast from '../../../components/Toast/ToastTypes'
import { useSelector } from 'react-redux'
import PreliquidationTable2 from '../../ManageQuotes/PreLiquidation/CreatePreliquidation/PreliquidationTable2'
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from 'recharts';

const Info = styled("div", {
    display: 'flex',
    flexDirection: 'column',
    alignItems:'left',
    paddingLeft:'20px',
    paddingBottom:'10px',
})

const StyledModal = styled(Modal, {
    display: 'flex',
    padding: '20px',
    color: '$primary_500 !important',
    background: '$neutral_50',
})

export default function OfferCard({ clientChose, data, getData, shippingMethod }) {
    const [openModal, setOpenModal] = useState(false)
    const [openRejectModal, setOpenRejectModal] = useState(false)
    const [openCostModal, setOpenCostModal] = useState(false)
    const [note, setNote] = useState("")

    function getImgSrc(){
        switch(shippingMethod){
            case 'land': return landSrc;
            case 'ocean':return oceanSrc;
            default: return airSrc
        }
    }
    const imgSrc = getImgSrc()

    const Container = styled("div", {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        background: 'white',
        borderRadius: '8px',
        padding: '0px',
        maxWidth: data.suggested ? '220px' : '210px',
        boxShadow: '$elevation_1',
        transition: '300ms',
        ['.footer']: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: '10px',
        },
        ['&:hover']: {
            cursor: 'pointer',
            transform: 'scale(1.02)',
            boxShadow: '$elevation_2',
        }
    })

    const token = useSelector(state => state.user.token)

    const handleApprove = (id) => {
        approveQuotation(id, true, token).then(() => {
            setOpenModal(false)
            toast('success', 'Cotizacion aprobada')
            getData()
        }).catch(err => {
            toast('error', 'Error, no se pudo aprobar')
        })
    }

    const handleReject = (id) => {
        approveQuotation(id, false, token, note).then(() => {
            setOpenRejectModal(false)
            toast('success', 'Cotizacion rechazada')
            getData()
        }).catch(err => {
            toast('error', 'Error, no se pudo rechazar')
        })
    }

    const itemsTranslation = {
        "ORIGIN": "Gastos en Origen",
        "DESTINATION": "Gastos en Destino",
        "INSURANCE": "Gastos en Seguro",
        "OCEAN": "Gastos Maritimos",
    };

    // const getPriceStatus = () => {
    //     const { cost } = data;
    //     const costNumber = parseFloat(cost.replace(/[^0-9.-]/g, ''));
    //     if (costNumber < 100) {
    //         return { status: 'low', text: '✓ Precio bajo', color: 'green' };
    //     } else if (costNumber >= 100 && costNumber <= 400) {
    //         return { status: 'medium', text: '⚠️ Precio medio', color: 'orange' };
    //     } else {
    //         return { status: 'high', text: '⚠️ Precio alto', color: 'red' };
    //     }
    // };

    const handleOpenCostModal = (e) => {
        e.stopPropagation();
        setOpenCostModal(true);
    };

    const getCostAnalysisData = () => {
        const { totalsByItemType, air_cargo } = data;
        const chartData = Object.entries(totalsByItemType).map(([key, value]) => ({
            name: itemsTranslation[key] || key,
            costo: value
        }));
        if (air_cargo) {
            chartData.push({ name: 'Gastos flete aereo', costo: air_cargo.total_usd });
        }
        return chartData;
    };

    // const priceStatus = getPriceStatus();

    return (
        <div>
            {data && <Container onClick={() => { !clientChose && setOpenModal(true) }}>
                <img src={imgSrc} alt="card-image" />
                <Info>
                    <span style={{fontSize:'10px'}}>Opción {data?.option}</span>
                    <h3>
                        {data?.cost}
                        <span 
                            onClick={handleOpenCostModal}
                            style={{ 
                                marginLeft: '8px', 
                                fontSize: '12px', 
                                // color: priceStatus.color,
                                cursor: 'pointer'
                            }}
                        >
                            {/* {priceStatus.text} */}
                        </span>
                    </h3>
                    <p>{data.customer_approval && <span style={{ color: 'green' }}>Ya elegiste esta opción</span>}</p>
                    <p>{data.suggested && <span style={{ color: 'green' }}>Opción recomendada</span>}</p>
                    <div>
                        <ul style={{margin:0}}>
                            {Object.entries(data?.totalsByItemType).map(([itemType, total]) => (
                                itemType !== 'PROFIT-SHARE' &&
                                <li key={itemType} style={{ fontSize: "10px", color: "grey" }}>
                                    <b>{itemsTranslation[itemType] || itemType}</b> ${total.toFixed(2)}
                                </li>
                            ))}
                            {
                                data.air_cargo && 
                                <li style={{ fontSize: "10px", color: "grey" }}>
                                    <b>{'Gastos flete aereo :'}</b> ${data.air_cargo.total_usd}
                                </li>
                            }
                        </ul>
                    </div>
                    <div className='footer'>
                        {!clientChose && !data.customer_approval && (
                            <Button 
                                text={'Elegir y confirmar'} 
                                handler={() => setOpenModal(true)} 
                                startIcon={{ name: 'CheckModal', color: 'white' }} 
                                bgColor='excel' 
                                size={'sm'} 
                                textColor='neutral_50' 
                            />
                        )}
                    </div>
                </Info>
            </Container>}

            <StyledModal
                open={openModal}
                onClose={() => setOpenModal(false)}
                closeButton
                aria-label="modal-title"
                width="fit-content"
            >
                <h3 style={{ margin: '20px', }}>Confirma que desea elegir la opcion {data.option}</h3>
                <PreliquidationTable2 currentQuoteData={data} showPurchase={false}/>
                <Modal.Footer>
                    <Button text="Cancelar" outline handler={() => { setOpenModal(false) }} />
                    <Button text="Confirmar" handler={() => handleApprove(data.id)} startIcon={{ name: 'CheckModal', color: 'white' }} bgColor='excel' textColor='neutral_50' />
                </Modal.Footer>
            </StyledModal>

            <StyledModal
                open={openRejectModal}
                onClose={() => setOpenRejectModal(false)}
                closeButton
                aria-label="modal-title"
                width="fit-content"
            >
                <h3 style={{ margin: '20px' }}>Confirma que desea rechazar la opcion {data.option}</h3>
                <Textarea label={"Por que desaprobo esta opcion?"} value={note} onChange={(e)=>setNote(e.target.value)}/>
                <Modal.Footer>
                    <Button text="Cancelar" bold outline handler={() => { setOpenRejectModal(false) }} />
                    <Button text="Confirmar" textColor={'neutral_50'} bold outline bgColor={'delete'} handler={() => handleReject(data.id)} />
                </Modal.Footer>
            </StyledModal>

            <Modal
                open={openCostModal}
                onClose={() => setOpenCostModal(false)}
                width="fit-content"
            >
                <Modal.Header>
                    <Text id="modal-title" size={18}>
                        Análisis de Costos - Opción {data?.option}
                    </Text>
                </Modal.Header>
                <Modal.Body>
                    <Image
                        src="https://i.ibb.co/YNx1Qc2/MERGED.png"
                        alt="Cost Analysis"
                        width={600}
                        height={600}
                    />
                    <BarChart
                        width={500}
                        height={300}
                        data={getCostAnalysisData()}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="costo" fill="#8884d8" />
                    </BarChart>
                </Modal.Body>
            </Modal>
        </div>
    )
}