import React, { useEffect, useState } from "react";
import { styled } from "@nextui-org/react";
import { format, differenceInHours } from "date-fns";
import { Stepper, Step, StepLabel, IconButton } from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { MapContainer, TileLayer, Marker, Popup, Polyline } from "react-leaflet";
import theme from '../../theme'
import Icon from '../../components/Icon/Icon'

import "leaflet/dist/leaflet.css";
import { getServiceInfo, getServiceSplitRadication } from "../../services/quotation.services";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import ReactCountryFlag from "react-country-flag";
import { usePDF } from "react-to-pdf";
import Button from "../../components/Button/Button";

const Container = styled("div",{
  background:'white',
  margin:'20px 100px',
  padding:'30px',
  borderRadius:'8px',
  boxShadow:'$elevation_1',
  display:'flex',
  flexDirection:'column',
  gap:'10px',
  '.general':{
      display:'grid',
      gridTemplateColumns:'auto auto',
      gap:'30px',
      '.lines':{
          // border:'1px solid black',
          display:'flex',
          flexDirection:'column',
      },
      '.line':{
          display:'flex',
          justifyContent:'space-between',
          borderBottom:'1px solid grey',
          padding:'5px 20px',
          '.data':{
              fontWeight:'bold'
          }
      }
  },
  '.total':{
      background:'$primary_300',
      borderRadius:'8px',
      marginTop:'20px',
      color:'white',
      width:'100%',
      padding:'20px',
      display:'flex',
      justifyContent:'right',
  }
})

const Header = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  borderBottom: "1px solid #ddd",
  paddingBottom: "10px",
});

const Section = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "10px",
});

const StyledTable = styled("table", {
  width: "100%",
  borderCollapse: "collapse",
  fontSize: "14px",
  "th, td": {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left",
  },
  th: {
    backgroundColor: "#f2f2f2",
  },
});


const EmailBody = styled("div", {
  border: "1px solid #ddd",
  padding: "10px",
  borderRadius: "4px",
  backgroundColor: "#f9f9f9",
  marginTop: "10px",
  whiteSpace: "pre-wrap",
  wordBreak: "break-word",
});

const ToggleButton = styled(IconButton, {
  display: "flex",
  justifyContent: "flex-end",
  padding: "0px",
  marginBottom: "10px",
});

const MapWrapper = styled("div", {
  height: "400px",
  width: "100%",
  marginTop: "20px",
});
const ShipmentContainer = styled("div", {
  width:'100%',
  gridColumn:'span 2',
  display:'flex',
  justifyContent:'space-around',
  alignItems:'center',
  background:theme.colors.primary_300.value,
  color:'white',
  padding:'20px',
  marginBottom:'25px',
  borderRadius:'6px',
  'h3':{margin:0, fontSize:'20px'},
  'span':{fontSize:'12px'},
  "@media(max-width:1000px)":{
      width:'90%',
      margin:'auto',
      padding:'5px',
      'h3':{margin:0, fontSize:'9px'},
      'span':{fontSize:'10px'},
  }
})

const ServiceInfo = () => {

  const { toPDF, targetRef } = usePDF({ filename: "service_info.pdf" });
  const [searchParams] = useSearchParams();
  let id = searchParams.get("id")
  const [loadingPdf, setLoadingPdf] = useState(false)
  const [serviceData, setServiceData] = useState({
    radication:'',
    business_type:'',
    created_at:'',
    updated_at:'',
    movements:[],
  });

  const { radication, business_type, created_at, updated_at, movements } = serviceData;
  const movement = movements? movements[0] : {};
  const [expandedEmailIndex, setExpandedEmailIndex] = useState(null);
  const [error, setError] = useState(false);
  const [splitData, setSplitData] = useState(null);


  console.log("serviceData :",serviceData)
  const handleToggleEmail = (index) => {
    setExpandedEmailIndex(expandedEmailIndex === index ? null : index);
  };
  const token = useSelector(state=>state.user.token)

  useEffect(() => {
    const fetchData = async () => {
      try {
        // First, get the service info to obtain the radication
        const serviceRes = await getServiceInfo(token, id);
        const serviceInfo = await serviceRes.json();
        setServiceData(serviceInfo);

        // Now that we have the radication, we can fetch the split data
        if (serviceInfo.radication) {
          const splitRes = await getServiceSplitRadication(serviceInfo.radication, token);
          const splitInfo = await splitRes.json();
          setSplitData(splitInfo.results[0]);
        }
      } catch (err) {
        console.error("Error fetching data:", err);
        setError(err.status === 401 ? "No autorizado" : "No se encontró el servicio");
      }
    };

    fetchData();
  }, [id, token]);

  function downloadPDF() {
    setLoadingPdf(true);
    toPDF().then(() => {
      setLoadingPdf(false);
    }).catch((err) => {
      console.log("error al descargar pdf :", err);
      setLoadingPdf(false);
    });
  }

  useEffect(()=>{
    getServiceInfo(token,id).then(async res => {
      const serviceInfo = await res.json();
      setServiceData(serviceInfo)
      console.log("THEN ??")
    }).catch(err=>{
      console.log("err :",err)
      if (err.status === 401){
        setError("No autorizado")
      } else {
        setError("No se encontro el servicio")
      }
    })
  },[id])
  console.log("error :",error)
  const calculateTransitTimes = () => {
    const { sub_status_changes, customs_clearance, eta, ata, warehouse_times, road_times } = movement;
    const transitTimes = [];
    // Calculate time between status changes
    for (let i = 0; i < sub_status_changes.length - 1; i++) {
      const start = new Date(sub_status_changes[i].date);
      const end = new Date(sub_status_changes[i + 1].date);
      const duration = differenceInHours(end, start);
      
      transitTimes.push({
        type: "Status Change",
        from: sub_status_changes[i].operation_sub_status,
        to: sub_status_changes[i + 1].operation_sub_status,
        duration,
      });
    }

    // Add customs clearance time
    transitTimes.push({
      type: "Customs Clearance",
      from: "Start",
      to: "End",
      duration: differenceInHours(new Date(customs_clearance?.end), new Date(customs_clearance?.start)),
    });

    // Add ETA to ATA difference
    transitTimes.push({
      type: "ETA to ATA",
      from: "ETA",
      to: "ATA",
      duration: differenceInHours(new Date(ata), new Date(eta)),
    });

    // Add warehouse times
    warehouse_times?.forEach(wt => {
      transitTimes.push({
        type: "Warehouse",
        from: wt.activity,
        to: "Completed",
        duration: wt.duration,
      });
    });

    // Add road times
    road_times?.forEach(rt => {
      transitTimes.push({
        type: "Road",
        from: rt.from,
        to: rt.to,
        duration: rt.duration,
      });
    });

    return transitTimes;
  };

  const getShippingIcon = (method) => {
    switch (method?.toLowerCase()) {
      case 'air':
        return 'Aereo';
      case 'sea':
        return 'Maritimo';
      case 'ground':
        return 'Terrestre';
      default:
        return 'Aereo'; // Default icon if method is not specified
    }
  };
  
  const MapSection = () => {
    if (!splitData?.shipping) return null;

    const origin = splitData.shipping.origin_boarding_terminal;
    const destination = splitData.shipping.destination_boarding_terminal;

    const originCoords = [origin.city.latitude || 0, origin.city.longitude || 0];
    const destCoords = [destination.city.latitude || 0, destination.city.longitude || 0];

    // const flightPath = airports.map(airport => airport.coordinates);

    return (
      <Section>
        <h3>ACA Flight Route Map</h3>
        <MapWrapper>
          <MapContainer
            center={[(originCoords[0] + destCoords[0]) / 2, (originCoords[1] + destCoords[1]) / 2]}
            zoom={4}
            style={{ height: "100%", width: "100%" }}
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Marker position={originCoords}>
              <Popup>{origin.city.name}, {origin.city.country.name}</Popup>
            </Marker>
            <Marker position={destCoords}>
              <Popup>{destination.city.name}, {destination.city.country.name}</Popup>
            </Marker>
            <Polyline positions={[originCoords, destCoords]} color="red" />
          </MapContainer>
        </MapWrapper>
      </Section>
    );
  };

  const getClientInfo = () => {
    if (!splitData?.users) return null;
    return splitData.users.find(user => 
      user.user.groups.includes("Usuario") || user.user.groups.includes("Cliente")
    )?.user;
  };

  const getAgentInfo = () => {
    if (!splitData?.users) return null;
    return splitData.users.find(user => 
      user.user.groups.includes("Operations") ||
      user.user.groups.includes("Asesores") ||
      user.user.groups.includes("Pricing") ||
      user.user.groups.includes("Admin")
    )?.user;
  };

  const client = getClientInfo();
  const agent = getAgentInfo();
  // console.log("bandera"+JSON.stringify(splitData, null, 2))

  return (
    error? <div style={{background:''}}>
      Error : {error}
    </div> :
    <div>
    <div style={{margin:'20px auto', width:'fit-content'}}>
      <Button
        handler={() => downloadPDF()}
        text={loadingPdf ? "Cargando..." : "Descargar pdf"}
        endIcon={{ name: "Download" }}
        outline
        disabled={loadingPdf}
      />
    </div>
    <Container ref={targetRef}>
      <Header>
        <h2>Operation: {radication}</h2>
        <div>
          <p>Business Type: {business_type}</p>
          <p>Created: {movement?.created_at && format(new Date(movement?.created_at), "dd/MM/yyyy HH:mm:ss") }</p>
          <p>Updated: {movement?.updated_at && format(new Date(movement?.updated_at), "dd/MM/yyyy HH:mm:ss")}</p>
          </div>
      </Header>

      <Section>
        
          <h3>Movement Details</h3>
        
          <ShipmentContainer>
            <div>
              {console.log(splitData)}
                <h3>{splitData?.shipping.destination_boarding_terminal.city.country.name}, {splitData?.shipping.destination_boarding_terminal.city.country.name}</h3>
                <span>{splitData?.shipping.pickup_address}</span>
            </div>
            <Icon name='ArrowRight' color='white'/>
            <div>
                <Icon name="Aereo" size="40" color="white"/>
                <h3>{splitData?.shipping?.incoterm}</h3>
            </div>
            <Icon name='ArrowRight' color='white'/>
            <div>
                <h3>{splitData?.shipping.origin_boarding_terminal?.city.country.name}, {splitData?.shipping.origin_boarding_terminal?.city?.country?.name}</h3>
                <span>{splitData?.shipping.delivery_address}</span>
            </div>
        </ShipmentContainer>
        <div className='general' style={{margin:'30px 0'}}>
            <div className="lines">
                <div className='line'>
                    <span className='label'>Cliente :</span>
                    <span className='data'>{client ? `${client.first_name} ${client.last_name}` : 'N/A'}</span>
                </div>
                <div className='line'>
                  {console.log("AAAAAA"+JSON.stringify(client, null, 2))}
                    <span className='label'>Mail :</span>
                    <span className='data'>{client?.email}</span>
                </div>
                <div className='line'>
                    <span className='label'>Teléfono :</span>
                    <span className='data'>{client?.phone}</span>
                </div>
            </div>
            <div className="lines">
                <div className='line'>
                    <span className='label'>Agente :</span>
                    <span className='data'>{agent ? `${agent.first_name} ${agent.last_name}` : 'N/A'}</span>
                </div>
                <div className='line'>
                  {/* {console.log("EL AGENTE"+JSON.stringify(agent, null, 2))} */}
                    <span className='label'>Mail :</span>
                    <span className='data'>{agent?.email}</span>
                </div>
                <div className='line'>
                    <span className='label'>Teléfono :</span>
                    <span className='data'>{agent?.phone}</span>
                </div>
            </div>
            </div>
        </Section>

      <Section>
        <h3>Email Records</h3>
        <StyledTable>
          <thead>
            <tr>
              <th>Recipient</th>
              <th>CC</th>
              <th>BCC</th>
              <th>Subject</th>
              <th>Sent At</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {movement?.email_records?.map((record, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td>{record.recipient}</td>
                  <td>{record.cc}</td>
                  <td>{record.bcc}</td>
                  <td>{record.subject}</td>
                  <td>{format(new Date(record.sent_at), "dd/MM/yyyy HH:mm:ss")}</td>
                  <td>
                    <ToggleButton onClick={() => handleToggleEmail(index)}>
                      {expandedEmailIndex === index ? <ExpandLess /> : <ExpandMore />}
                    </ToggleButton>
                  </td>
                </tr>
                {expandedEmailIndex === index && (
                  <tr>
                    <td colSpan="6">
                      <strong>Email Body:</strong>
                      <EmailBody dangerouslySetInnerHTML={{ __html: record.body }}/>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </StyledTable>
      </Section>

      <Section>
        <h3>Sub-Status Changes</h3>
        <Stepper orientation="vertical">
          {movement?.sub_status_changes?.map((change, index) => (
            <Step key={index} active={true}>
              <StepLabel>
                <div>
                  <p>Status: {change.operation_sub_status}</p>
                  <p>Date: {format(new Date(change.date), "dd/MM/yyyy HH:mm:ss")}</p>
                  <p>Notes: <span dangerouslySetInnerHTML={{ __html: change.notes }} /></p>
                  <p>Timestamp: {format(new Date(change.timestamp), "dd/MM/yyyy HH:mm:ss")}</p>
                </div>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Section>

      <Section>
        <h3>Transit Times</h3>
        <StyledTable>
          <thead>
            <tr>
              <th>Type</th>
              <th>From</th>
              <th>To</th>
              <th>Duration (hours)</th>
            </tr>
          </thead>
          <tbody>
            {movement && calculateTransitTimes().map((transit, index) => (
              <tr key={index}>
                <td>{transit.type}</td>
                <td>{transit.from}</td>
                <td>{transit.to}</td>
                <td>{isNaN(transit.duration)? 0 : transit.duration}</td>
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </Section>

      <MapSection />
    </Container>
    </div>
  );
};

export default ServiceInfo;