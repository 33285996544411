export const getPreliquidationCosts = (preliquidation) => {
    let totalCost = 0;
    let totalProfit = 0
    let totalNet = 0
    if (preliquidation){

        preliquidation.items?.forEach((item)=>{
            totalCost += item.total_in_usd
            totalNet += item.rate?.total || 0
            totalProfit += item.total_in_usd - (item.rate?.total || 0)
        })

        if (preliquidation?.air_cargo){
            let freigthNet = 0
            let surchargeNet = 0
            if (preliquidation?.air_cargo?.air_rate?.length){
                freigthNet = preliquidation?.air_cargo?.air_rate[0]?.total || 0
                surchargeNet = preliquidation?.air_cargo?.air_rate[1]?.total || 0
            }
            totalCost += preliquidation.air_cargo.total_usd
            totalNet += freigthNet + surchargeNet
            totalProfit = totalCost - totalNet
        }
    }
    return {totalCost, totalProfit}
}