import React, { useState } from 'react'
import { styled, Switch } from '@nextui-org/react';
import SimpleAutocomplete from '../../../components/SimpleAutocomplete/SimpleAutocomplete'
import Button from '../../../components/Button/Button'
import Input from '../../../components/Input/Input'
import InputSelect from '../../../components/Select/inputSelect'
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { calculateCO2byPerson } from '../../../services/CO2.services'
import toast from '../../../components/Toast/ToastTypes';
import { useNavigate } from 'react-router-dom';
const StyledContainer = styled("div", {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '40px',
    marginBottom: '30px',
})

const vehicleOptions = [
    { label: 'Pequeño', value: 'small' },
    { label: 'Mediano', value: 'medium' },
    { label: 'Grande', value: 'large' },
    { label: 'Promedio', value: 'average' },
]
const fuelOptions = [
    { value: "unknown", label: "Desconocido" },
    { value: "diesel", label: "Diésel" },
    { value: "petrol", label: "Gasolina" },
    { value: "hybrid", label: "Híbrido" },
    { value: "cng", label: "Gas Natural Comprimido (CNG)" },
    { value: "lpg", label: "Gas Licuado de Petróleo (GLP)" },
    { value: "plug-in", label: "Híbrido Enchufable" },
    { value: "electric", label: "Eléctrico" }
]

export default function CarForm() {
    const token = useSelector(state => state.user.token)
    const user = useSelector(state => state.user)
    const { control, handleSubmit, watch, formState: { errors } } = useForm();
    const [origin, setOrigin] = useState('')
    const [destination, setDestination] = useState('')
    const [switchValue, setSwitchValue] = useState(false);
    const handleSwitchChange = () => {
        setSwitchValue((prevValue) => !prevValue);
    };
    const navigate = useNavigate();
    function onSubmit(data) {
        const request = {
            type: "car",
            departure: origin,
            destination,
            vehicle_class: data.vehicle_class.value,
            fuel: data.fuel.value,
            passengers: data.passengers,
            return_trip: switchValue
        }
        calculateCO2byPerson(token, { request, user: user.idUser }).then((res) => {
            if (res.status == 200) {
                toast("success", "Cálculo guardado exitosamente")
                navigate('/co2/history');
            }
            else {
                toast("error", "No se pudo guardar el cálculo")
            }
        }).catch(error => {
            toast("error", "Ha ocurrido un error inesperado")
        })
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <StyledContainer>
                <SimpleAutocomplete label={'Origen'} setState={setOrigin} />
                <SimpleAutocomplete label={'Destino'} setState={setDestination} />
                <Controller
                    name="vehicle_class"
                    control={control}
                    render={({ field }) => (
                        <InputSelect label="Tipo de vehiculo" options={vehicleOptions} {...field} />
                    )}
                    rules={{ required: true }}
                />
                <Controller
                    name="fuel"
                    control={control}
                    render={({ field }) => (
                        <InputSelect label="Combustible" options={fuelOptions} {...field} />
                    )}
                    rules={{ required: true }}
                />
                <Controller
                    name="passengers"
                    control={control}
                    render={({ field }) => (
                        <Input label="Cantidad de pasajeros" type="number" {...field} />
                    )}
                    rules={{ required: true }}
                />
                <div style={{ display: 'flex', paddingTop: '30px', justifyContent: 'space-between' }}>
                    <div>
                        <p style={{ margin: '4px', fontWeight: 'bold' }}>Calcular ida y vuelta</p>
                        <div style={{ margin: 'auto', width: 'fit-content' }}>
                            <Switch shadow onChange={handleSwitchChange} checked={switchValue} />
                        </div>
                    </div>
                    <Button text="Realizar calculo" bold outline type="submit" />
                </div>
            </StyledContainer>
        </form>
    )
}
