import React, { useState } from 'react';
import { 
  Grid, Card, CardContent, Typography, Button, Chip, Tooltip, 
  Dialog, DialogTitle, DialogContent, DialogActions, TextField,
  IconButton, LinearProgress, List, ListItem, ListItemIcon, ListItemText,
  Paper, Box, Divider
} from '@mui/material';
import { 
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, 
  Legend, ResponsiveContainer, ScatterChart, Scatter, ZAxis
} from 'recharts';
import { 
  AttachMoney, CreditScore, AccountBalance, CalendarToday,
  CheckCircle, Cancel, Chat, InfoOutlined, CheckCircleOutline,
  AssignmentTurnedIn, ReceiptLong, Person, Security, LocalAtm, AccessTime,ArticleOutlined, GavelOutlined,VerifiedUser,People,BusinessCenter 
} from '@mui/icons-material';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d'];

const initialOffers = [
  {
    id: 1,
    provider: "Banco A",
    type: "Préstamo Corporativo",
    amount: 300000, // Monto ajustado
    interestRate: 8.5,
    term: 60, // Término en meses (5 años)
    monthlyPayment: 6393.73, // Calculado con el nuevo monto
    minCreditScore: 720,
    includesInsurance: true,
    gracePeriod: 3, // Período de gracia en meses
    collateralRequired: true,
    useOfFunds: "Expansión de la planta",
    fees: {
      originationFee: 2.0,
      prepaymentPenalty: 2,
      latePaymentFee: 50,
    },
    variableRate: false,
    governmentBacked: true,
    industrySpecific: "Manufacturing",
  },
  {
    id: 2,
    provider: "Banco B",
    type: "Línea de Crédito Empresarial",
    amount: 300000, // Monto ajustado
    interestRate: 9.2,
    term: 12, // Línea de crédito renovable, término inicial de 12 meses
    monthlyPayment: 27500.00, // Calculado con el nuevo monto
    drawdownLimit: 100000,
    minCreditScore: 700,
    includesInsurance: false,
    gracePeriod: 0,
    collateralRequired: true,
    useOfFunds: "Capital de trabajo",
    fees: {
      originationFee: 1.5,
      prepaymentPenalty: 1,
      latePaymentFee: 40,
    },
    variableRate: true,
    governmentBacked: false,
    industrySpecific: "Retail",
  },
  {
    id: 3,
    provider: "Banco C",
    type: "Préstamo para Innovación Tecnológica",
    amount: 300000, // Monto ajustado
    interestRate: 7.8,
    term: 36, // Término en meses (3 años)
    monthlyPayment: 9116.68, // Calculado con el nuevo monto
    minCreditScore: 680,
    includesInsurance: true,
    gracePeriod: 6, // Período de gracia en meses
    collateralRequired: false,
    useOfFunds: "Desarrollo de software y adquisición de tecnología",
    fees: {
      originationFee: 1.8,
      prepaymentPenalty: 1.5,
      latePaymentFee: 45,
    },
    variableRate: false,
    governmentBacked: true,
    industrySpecific: "Technology",
  },
  {
    id: 4,
    provider: "Banco D",
    type: "Préstamo para Adquisición de Equipos",
    amount: 300000, // Monto ajustado
    interestRate: 6.9,
    term: 48, // Término en meses (4 años)
    monthlyPayment: 7186.45, // Calculado con el nuevo monto
    minCreditScore: 650,
    includesInsurance: false,
    gracePeriod: 0,
    collateralRequired: true,
    useOfFunds: "Compra de maquinaria industrial",
    fees: {
      originationFee: 1.2,
      prepaymentPenalty: 2.5,
      latePaymentFee: 30,
    },
    variableRate: false,
    governmentBacked: false,
    industrySpecific: "Industrial Equipment",
  },
  {
    id: 5,
    provider: "Banco E",
    type: "Préstamo Verde Empresarial",
    amount: 300000, // Monto ajustado
    interestRate: 5.5,
    term: 72, // Término en meses (6 años)
    monthlyPayment: 5047.51, // Calculado con el nuevo monto
    minCreditScore: 700,
    includesInsurance: true,
    gracePeriod: 2, // Período de gracia en meses
    collateralRequired: false,
    useOfFunds: "Proyectos de sostenibilidad y eficiencia energética",
    fees: {
      originationFee: 1.0,
      prepaymentPenalty: 1.0,
      latePaymentFee: 35,
    },
    variableRate: true,
    governmentBacked: true,
    industrySpecific: "Sustainable Energy",
  }
];

const RequirementsDialog = ({ open, handleClose, offer }) => {
  const requirements = [
    { icon: <AssignmentTurnedIn />, text: "RUT (con antigüedad menor a 30 días)", category: "Documentos Básicos" },
    { icon: <AssignmentTurnedIn />, text: "CC (con antigüedad menor a 30 días)", category: "Documentos Básicos" },
    { icon: <AccountBalance />, text: "Composición Acciones Firmado por representante y contador o revisor fiscal", category: "Documentos Básicos" },
    { icon: <ReceiptLong />, text: "Declaración renta empresa 2 últimos años", category: "Información Financiera" },
    { icon: <ReceiptLong />, text: "Declaración último periodo accionista", category: "Información Financiera" },
    { icon: <ReceiptLong />, text: "3 últimos estados financieros con notas", category: "Información Financiera" },
    { icon: <Person />, text: "Cédula representante legal", category: "Documentos Personales" },
    { icon: <AccountBalance />, text: "Referencias bancarias de los últimos 6 meses", category: "Información Financiera" },
    { icon: <AttachMoney />, text: "Certificación de ingresos emitida por contador o revisor fiscal", category: "Información Financiera" },
    { icon: <ReceiptLong />, text: "Estados de cuenta bancarios de los últimos 6 meses", category: "Información Financiera" },
    { icon: <GavelOutlined />, text: "Poderes otorgados al representante legal (si aplica)", category: "Documentos Legales" },
    { icon: <CreditScore />, text: "Informe de historial crediticio", category: "Información Financiera" },
    { icon: <VerifiedUser />, text: "Certificado de existencia y representación legal vigente", category: "Documentos Legales" },
    { icon: <Security />, text: "Autorización firmada para consulta en centrales de riesgo", category: "Documentos Legales" },
    { icon: <ArticleOutlined  />, text: "Copia del contrato social actualizado", category: "Documentos Legales" },
    { icon: <People />, text: "Declaración de beneficiarios finales", category: "Documentos Legales" },
    { icon: <BusinessCenter />, text: "Resumen del plan de negocios (proyección financiera, estrategia, etc.)", category: "Información de la Empresa" },
    { icon: <Security />, text: "Pedir seguro de vida que ampare deuda", category: "Seguros" },
    { icon: <LocalAtm />, text: "Desembolso", category: "Proceso" },
    { icon: <AccessTime />, text: "Tiempo de estudio desembolso y aprobación", category: "Proceso" }
  ];

  const groupedRequirements = requirements.reduce((acc, req) => {
    if (!acc[req.category]) {
      acc[req.category] = [];
    }
    acc[req.category].push(req);
    return acc;
  }, {});

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Typography variant="h5" component="div" gutterBottom>
          Requisitos para el préstamo de {offer?.provider}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {Object.entries(groupedRequirements).map(([category, reqs]) => (
            <Paper key={category} elevation={3} sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom color="primary">
                {category}
              </Typography>
              <List dense>
                {reqs.map((req, index) => (
                  <ListItem key={index}>
                    <ListItemIcon sx={{ color: 'primary.main' }}>
                      {req.icon}
                    </ListItemIcon>
                    <ListItemText 
                      primary={req.text}
                      primaryTypographyProps={{ variant: 'body1' }}
                    />
                  </ListItem>
                ))}
              </List>
            </Paper>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="contained">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CreditOffersModule = () => {
  const [offers, setOffers] = useState(initialOffers);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);
  const [confirmOfferId, setConfirmOfferId] = useState(null);
  const [requirementsDialogOpen, setRequirementsDialogOpen] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(null);

  const handleAccept = (id) => {
    console.log(`Accepted offer ${id}`);
    // Implementa la lógica para aceptar la oferta
  };

  const handleReject = (id) => {
    setOffers(offers.filter(offer => offer.id !== id));
  };

  const handleContact = (provider) => {
    setSelectedProvider(provider);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedProvider(null);
  };

  const handleSendMessage = () => {
    console.log(`Sent message to ${selectedProvider}`);
    handleCloseDialog();
  };

  const handleAcceptConfirmation = (id) => {
    setConfirmAction('accept');
    setConfirmOfferId(id);
    setConfirmDialogOpen(true);
  };

  const handleRejectConfirmation = (id) => {
    setConfirmAction('reject');
    setConfirmOfferId(id);
    setConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
    setConfirmAction(null);
    setConfirmOfferId(null);
  };

  const handleConfirmAction = () => {
    if (confirmAction === 'accept') {
      handleAccept(confirmOfferId);
    } else if (confirmAction === 'reject') {
      handleReject(confirmOfferId);
    }
    handleConfirmDialogClose();
  };

  const handleShowRequirements = (offer) => {
    setSelectedOffer(offer);
    setRequirementsDialogOpen(true);
  };

  return (
    <div className="p-4">
      <Typography variant="h4" component="h1" gutterBottom className="text-blue-800 font-bold">
        Ofertas de Créditos Financieros
      </Typography>
      
      <Grid container spacing={3} className="mb-8">
        {offers.map((offer) => (
          <Grid item xs={12} sm={6} md={4} key={offer.id}>
            <Card elevation={3} className="h-full">
              <CardContent>
                <div className="flex justify-between items-center mb-2">
                  <Typography variant="h6" component="div" className="font-bold">
                    {offer.provider}
                  </Typography>
                  <AccountBalance className="text-blue-500" />
                </div>
                <Chip 
                  label={offer.type} 
                  color="primary" 
                  className="mb-2" 
                />
                <Typography variant="h6" className="font-bold text-green-600">
                  Monto: ${offer.amount.toLocaleString()}
                </Typography>
                <Typography variant="body2">
                  Tasa de interés: {offer.interestRate}% anual
                </Typography>
                <Typography variant="body2">
                  Plazo: {offer.term} meses
                </Typography>
                <Typography variant="body2">
                  Cuota mensual: ${offer.monthlyPayment.toLocaleString()}
                </Typography>
                {/* <Typography variant="body2" gutterBottom>
                  Requisitos mínimos de crédito:
                </Typography> */}
                <LinearProgress 
                  variant="determinate" 
                  value={(offer.minCreditScore / 850) * 100} 
                  className="mb-2"
                />
                <Typography variant="body2">
                  Score mínimo: {offer.minCreditScore}
                </Typography>
                <div className="flex items-center mt-2">
                  <Tooltip title="Incluye seguro de desgravamen">
                    <CreditScore color={offer.includesInsurance ? "primary" : "disabled"} />
                  </Tooltip>
                  <Tooltip title="Periodo de gracia">
                    <CalendarToday color={offer.gracePeriod > 0 ? "primary" : "disabled"} className="ml-2" />
                  </Tooltip>
                </div>
                <div className="mt-4 flex justify-between">
                  <Tooltip title="Aceptar oferta">
                    <IconButton 
                      color="success" 
                      onClick={() => handleAcceptConfirmation(offer.id)}
                      size="large"
                    >
                      <CheckCircle />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Rechazar oferta">
                    <IconButton 
                      color="error" 
                      onClick={() => handleRejectConfirmation(offer.id)}
                      size="large"
                    >
                      <Cancel />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Contactar entidad">
                    <IconButton 
                      color="primary" 
                      onClick={() => handleContact(offer.provider)}
                      size="large"
                    >
                      <Chat />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Ver requisitos">
                    <IconButton 
                      color="info" 
                      onClick={() => handleShowRequirements(offer)}
                      size="large"
                    >
                      <InfoOutlined />
                    </IconButton>
                  </Tooltip>
                </div>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h5" className="mb-4 font-bold">Comparación de Montos y Tasas de Interés</Typography>
          <ResponsiveContainer width="100%" height={300}>
            <ScatterChart margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
              <CartesianGrid />
              <XAxis type="number" dataKey="amount" name="Monto del Préstamo" unit="$" />
              <YAxis type="number" dataKey="interestRate" name="Tasa de Interés" unit="%" />
              <ZAxis type="number" dataKey="term" range={[50, 400]} name="Plazo" unit=" meses" />
              <RechartsTooltip cursor={{ strokeDasharray: '3 3' }} />
              <Legend />
              {offers.map((offer, index) => (
                <Scatter key={offer.id} name={offer.provider} data={[offer]} fill={COLORS[index % COLORS.length]} />
              ))}
            </ScatterChart>
          </ResponsiveContainer>
        </Grid>
        
        <Grid item xs={12} md={6}>
          <Typography variant="h5" className="mb-4 font-bold">Comparación de Plazos y Cuotas Mensuales</Typography>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={offers}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="provider" />
              <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
              <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
              <RechartsTooltip />
              <Legend />
              <Bar yAxisId="left" dataKey="term" name="Plazo (meses)" fill="#8884d8" />
              <Bar yAxisId="right" dataKey="monthlyPayment" name="Cuota Mensual ($)" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </Grid>
      </Grid>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Contactar a {selectedProvider}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="message"
            label="Mensaje"
            type="text"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancelar</Button>
          <Button onClick={handleSendMessage} variant="contained" color="primary">Enviar</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={confirmDialogOpen} onClose={handleConfirmDialogClose}>
        <DialogTitle>
          {confirmAction === 'accept' ? '¿Confirmar aceptación?' : '¿Confirmar rechazo?'}
        </DialogTitle>
        <DialogContent>
          <Typography>
            ¿Estás seguro de que deseas {confirmAction === 'accept' ? 'aceptar' : 'rechazar'} esta oferta de crédito?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDialogClose}>Cancelar</Button>
          <Button onClick={handleConfirmAction} variant="contained" color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      <RequirementsDialog 
        open={requirementsDialogOpen}
        handleClose={() => setRequirementsDialogOpen(false)}
        offer={selectedOffer}
      />
    </div>
  );
};

export default CreditOffersModule;