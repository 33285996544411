import React, {useEffect, useState} from 'react'
import Joyride from 'react-joyride';
import { useSelector } from 'react-redux';
import { getUsers } from '../../services/user.services'
import { parseISO, differenceInMilliseconds } from 'date-fns';


export default function OnBoarding({steps}) {

  const user = useSelector(state => state.user)
  const token = user.token
  
  const [newUser, setNewUser] = useState(false)

  // useEffect(()=>{
  //   getUsers(token).then(async res => {
  //     const usersData = await res.json();
  //     const currentUser = usersData.find(e => e.id === user.idUser)

  //     const currentDate = new Date();
  //     const createdAtDate = parseISO(currentUser.created_at);
  //     const threshold = 2 * 60 * 60 * 1000; // 2 horas en milisegundos
  //     const isNewUser = differenceInMilliseconds(currentDate, createdAtDate) <= threshold;

  //     setNewUser(isNewUser)
  //   })
  // },[])
  
  return (
    <Joyride 
        steps={steps}
        run={newUser}
        continuous
        showProgress
        showSkipButton
        styles={{
            options: {
            primaryColor: '#3D509A',
            zIndex:301,
            },
            overlay:{
            zIndex:300,
            }
        }}
    />
  )
}
