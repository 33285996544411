import React from 'react'
import { useFormContext } from 'react-hook-form'

export default function Results() {
  const { watch, getValues } = useFormContext()

  const formData  = watch()
  console.log("formData :",formData)

  return (
    <h3>Results</h3>
  )
}
