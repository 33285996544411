/**
 * Constants
*/
const initialData = {
    co2emitted: 0,
    showTreeChart: false
}

/**
 * Action Types
 */

const CO2_EMITTED = "CO2_EMITTED"
const SHOW_TREE_CHART = "SHOW_TREE_CHART"

/**
 * Reducer
 */
const reducer = (state = initialData, action) => {
    switch (action.type) {
        case CO2_EMITTED:
            return { ...state, co2emitted: action.payload }
        case SHOW_TREE_CHART:
            return { ...state, showTreeChart: action.payload }
        default:
            return state
    }
};

export default reducer;

/**
 * Actions
 */

export const emittedAction = (value) => (dispatch, getState) => {
    dispatch({
        type: CO2_EMITTED,
        payload: value
    });
};

export const showTreeAction = (value) => (dispatch, getState) => {
    dispatch({
        type: SHOW_TREE_CHART,
        payload: value
    });
};